import { Component } from 'react';
import s from './Allocation.module.scss';
import cx from 'classnames';

import TriageStore from '../../../../stores/TriageStore';
import TriageActions from '../../../../actions/TriageActions';
import AllocationTypes from '../../../../constants/AllocationTypes';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import { List, ListItem } from 'material-ui/List';
import RoomIcon from 'material-ui/svg-icons/action/room';

const POSTCODE_REGEX =
  '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$';

class Allocation extends Component {
  state = {
    postCode: null,
    disableLookup: null,
    type: null,
    autoComplete: [],
    garages: [],
  };

  UNSAFE_componentWillMount() {
    const currentCase = TriageStore.getCase();
    let garages = currentCase.endpoints || [];
    const selectedGarage = currentCase.selectedEndpoint;

    for (let i in garages) {
      selectedGarage.Address === garages[i].Address
        ? (garages[i].isSelected = true)
        : (garages[i].isSelected = false);
    }

    this.setState({
      postCode: '',
      disableLookup: true,
      type: AllocationTypes.REPAIR,
      garages: garages,
      selectedGarage: selectedGarage,
    });
  }

  setSelected = (x) => {
    const garages = this.state.garages;
    for (let i in garages) {
      garages[i].isSelected = false;
      if (i == x) {
        garages[x].isSelected = true;
        TriageActions.setEndpoint(garages[x]);
      }
    }
    this.setState({
      garages: garages,
    });
  };

  generateList = (data) => {
    let dataList = [];
    //console.log(data);
    for (let i in data) {
      let isSelected = data[i].isSelected ? s.selected : s.unselected;
      dataList.push(
        <ListItem
          key={i}
          primaryText={data[i].Name}
          onClick={this.setSelected.bind(this, i)}
          className={isSelected}
          secondaryText={data[i].Address + ' (' + data[i].Distance + ')'}
          rightIcon={
            <div className={s.rightIcon}>
              <RoomIcon />
            </div>
          }
        />
      );
    }
    return dataList;
  };

  handleUpdateInput = (e) => {
    var regex = new RegExp(POSTCODE_REGEX, 'gi');
    let isValid = e.match(regex) !== null;
    this.setState({
      disableLookup: !isValid,
    });
    if (e.length > 2) {
      TriageStore.getPostcodes(e, (response) => {
        this.setState({
          autoComplete: response.data,
        });
      });
    }
  };

  handleSelection = (e) => {
    var regex = new RegExp(POSTCODE_REGEX, 'gi');
    let isValid = e.match(regex) !== null;
    //console.log(e);
    this.setState({
      disableLookup: !isValid,
      postCode: e,
    });
  };

  getGarageHandler = (e) => {
    TriageStore.getEndpoints(this.state.postCode, (response) => {
      this.setState({
        garages: response.data,
      });
      //console.log(this.state.postCode, response.data);
    });
  };

  render() {
    const listTitle =
      this.state.type === AllocationTypes.REPAIR
        ? 'Choose a repairer'
        : 'Choose a scrapper';

    const garages =
      this.state.garages.length === 0 ? null : (
        <div className={s.garageList}>
          <h3>{listTitle}</h3>
          <List className={s.customList}>
            {this.generateList(this.state.garages)}
          </List>
        </div>
      );

    return (
      <div className={cx(s.root, s.box)}>
        <h3>Work Allocation</h3>
        <div className={s.pCode}>
          <div>
            <h3>Postcode finder</h3>
            <AutoComplete
              autoComplete="off"
              onNewRequest={this.handleSelection}
              hintText="Please enter your Postcode"
              dataSource={this.state.autoComplete}
              filter={AutoComplete.fuzzyFilter}
              onUpdateInput={this.handleUpdateInput}
            />
          </div>
          <RaisedButton
            disabled={this.state.disableLookup}
            onClick={this.getGarageHandler}
            style={{ float: 'right' }}
            primary={true}
            label="Find Garages"
          />
        </div>
        {garages}
      </div>
    );
  }
}

let composed = Allocation;

export default composed;
