import { Component } from 'react';
import s from './Footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <span className={s.text}>© Inter-est</span>
        </div>
      </div>
    );
  }
}

export default Footer;
