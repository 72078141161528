import { Component } from 'react';

class GriddleActionCell extends Component {
  state = {
    editHandler: null,
    resetHandler: null,
    deleteHandler: null,
    data: null,
    username: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      editHandler: this.props.metadata.editHandler || function () {},
      resetHandler: this.props.metadata.resetHandler || function () {},
      deleteHandler: this.props.metadata.deleteHandler || function () {},
      data: this.props.data,
      username: this.props.rowData.UserName
        ? this.props.rowData.UserName
        : 'none',
    });
  }

  render() {
    const { data, editHandler, resetHandler, deleteHandler, username } =
      this.state;
    const url = '_user/' + data;

    return (
      <div>
        <a
          href={'/edit' + url}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span onClick={editHandler.bind(this, data)}>Edit</span>
        </a>{' '}
        |
        <a
          href={'/reset' + url}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span onClick={resetHandler.bind(this, data, username)}>Reset</span>
        </a>{' '}
        |
        <a
          href={'/delete' + url}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span onClick={deleteHandler.bind(this, data, username)}>Delete</span>
        </a>
      </div>
    );
  }
}

export default GriddleActionCell;
