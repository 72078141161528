import Dispatcher from '../core/Dispatcher.js';
import ActionTypes from '../constants/ActionTypes.js';

const TriageActions = {
  createCase(data, onError) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_CREATE,
      data: { data: data, onError: onError },
    });
  },

  updateCase(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_UPDATE,
      data: data,
    });
  },

  loadRemoteCase(data, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_LOAD_REMOTE,
      data: { data: data, callback: cb },
    });
  },

  resetCase: function () {
    Dispatcher.dispatch({
      actionType: ActionTypes.CASE_CLEAR,
    });
  },

  closeCase: function (cb) {
    Dispatcher.dispatch({
      actionType: ActionTypes.CASE_DESTROY,
      data: cb,
    });
  },

  getCaseFromServer: (data) => {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_LOAD,
      data: data,
    });
  },

  addPart(item) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_ITEM_ADD,
      data: item,
    });
  },

  addCompletedQuestionSets(items, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_QUESTIONSET_MARKCOMPLETE,
      data: { items: items, callback: cb },
    });
  },

  resetCompletedQuestionSets() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_QUESTIONSET_RESET,
      data: {},
    });
  },

  updatePart(name, item, update, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_ITEM_UPDATE,
      data: { name: name, item: item, update: update, cb: cb },
    });
  },

  registerSectionDamage(data, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_REG_DMG,
      data: data,
      cb: cb,
    });
  },

  registerMultipleSectionDamage(data, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_REG_MULTIPLE_DMG,
      data: data,
      cb: cb,
    });
  },

  resetParts() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_RESET_ALL,
    });
  },

  resetDamage(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_RESET_DMG,
      data: data,
    });
  },

  resetDamageFromLocal(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_RESET_LOCAL_DMG,
      data: data,
    });
  },

  setModal(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PARTS_SET_MODAL,
      data: data,
    });
  },

  setCriticalQuestions(crits, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_CRITS,
      data: { criticals: crits, callback: cb },
    });
  },

  makeDecision(cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_MAKE_DECISION,
      data: { callback: cb },
    });
  },

  setEndpoint(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_ENDPOINT,
      data: data,
    });
  },

  setVehicleModel(name, update) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.VEHICLE_SET_MODEL,
      data: { name: name, update: update },
    });
  },

  updateCarHireData(data, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CAR_HIRE_UPDATE,
      data: { carData: data, onComplete: cb, onError: errorCb },
    });
  },

  updateFurtherActionsModel(data, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_FOLLOWON_UPDATE,
      data: { data: data, callback: cb, errorCb: errorCb },
    });
  },

  submitReferenceNumber(ref, cb) {
    const data = { data: ref, callback: cb };
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SUBMIT_REF,
      data: data,
    });
  },

  submitSettlementAnswer(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_ANSWER_SETTLEMENT,
      data: data,
    });
  },

  submitCashInLieuAnswer(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_ANSWER_CASH_IN_LIEU,
      data: data,
    });
  },

  generatePDF(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_GET_PDF,
      data: data,
    });
  },

  setProgressState(i) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PROGRESS_SET,
      data: i,
    });
  },

  resetProgress() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.PROGRESS_RESET,
    });
  },

  selectVariantModel(i, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.SELECT_VARIANT,
      data: { value: i, callback: cb },
    });
  },

  confirmMobile(i) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CONFIRM_MOBILE,
      data: i,
    });
  },

  getVehicleHistory(reg, mileage, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_GET_VEHICLE_HISTORY,
      data: {
        registration: reg,
        mileage: mileage,
        callback: cb,
        onError: errorCb,
      },
    });
  },

  setBusinessLine(val, cb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_BL,
      data: { value: val, callback: cb },
    });
  },

  setCustomRetailValue(val, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_RETAIL,
      data: { value: val, callback: cb, onError: errorCb },
    });
  },

  setAtFaultStatus(status, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_ATFAULT_STATUS,
      data: { status: status, callback: cb, onError: errorCb },
    });
  },

  setTriagePostcode(postcode, cb, errorCb) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_SET_POSTCODE,
      data: { postcode: postcode, callback: cb, onError: errorCb },
    });
  },
};

export default TriageActions;
