import { Component } from 'react';
import s from './SelectionList.module.scss';
import cx from 'classnames';

import TriageActions from '../../../../actions/TriageActions';

const FILL_STYLES = {
  0: {},
  1: { backgroundColor: 'gold', color: '#333', fontWeight: 600 },
  2: { backgroundColor: 'orange', color: '#333', fontWeight: 600 },
  3: { backgroundColor: 'orangered', color: '#333', fontWeight: 600 },
  4: {
    backgroundColor: 'rgba(255, 0, 0, 0.8)',
    color: '#333',
    fontWeight: 600,
  },
};

const SORT_ORDERS = {
  Screens: ['Wind Screen', 'Rear Screen'],
  Mirrors: ['Passenger Door Mirror', 'Driver Door Mirror'],
  Windows: [
    'Front Passenger Window',
    'Front Driver Window',
    'Back Passenger Window',
    'Back Driver Window',
  ],
  Wheels: [
    'Front Passenger Wheel',
    'Front Driver Wheel',
    'Back Passenger Wheel',
    'Back Driver Wheel',
  ],
  Panels: [
    'Front Passenger Wing',
    'Front Driver Wing',
    'Front Passenger Door',
    'Front Driver Door',
    'Passenger Sill',
    'Driver Sill',
    'Left Sliding Door',
    'Right Sliding Door',
    'Back Passenger Door',
    'Back Driver Door',
    'Back Passenger Wing',
    'Back Driver Wing',
    'Left Back Door',
    'Right Back Door',
    'Bonnet',
    'Tailgate',
    'Flatbed',
    'Roof',
  ],
};

class SelectionList extends Component {
  state = {
    partsList: [],
  };

  UNSAFE_componentWillMount() {
    this.setState({
      partsList: this.props.partsList,
    });
  }

  update = (item, update) => {
    if (update && this.props.handleLatency) {
      this.props.handleLatency('add', item.name);
    }
    TriageActions.updatePart(item.name, item, update || false, () => {
      if (update) {
        TriageActions.makeDecision(() => {
          if (this.props.handleLatency) {
            this.props.handleLatency('remove', item.name);
          }
        });
      }
    });
  };

  clickHandler = (item, e) => {
    if (this.props.disabled) return;
    if (item.modalModel !== null) {
      const modalOptions = Object.assign({}, item.modalModel, {
        open: true,
        name: item.name,
      });
      TriageActions.setModal(modalOptions);
    } else {
      // As per investigation of I2-1152 - this route is uniquely used by Glass items - all the other sections have a modalModel defined and so use the first clause of the 'if'
      // see also mouseClickHandler function in _sectionComponent\index.js, which handles the same click for areas of the SVG sections
      item.damageSeverity = item.damageSeverity == 0 ? 4 : 0;
      this.update(item, true);
    }
  };

  setHighlighted = (e, isHighlighted) => {
    const parts = this.props.partsList;
    let name = e.target.innerHTML;
    let myItem;
    parts.map((part, i) => {
      if (part.name == name) {
        myItem = part;
      }
    });
    if (myItem !== undefined) {
      myItem.highlighted = isHighlighted;
      this.update(myItem);
    }
  };

  mouseLeaveHandler = (e) => {
    this.setHighlighted(e, false);
  };

  mouseEnterHandler = (e) => {
    this.setHighlighted(e, true);
  };

  getItems(partslist, category) {
    const items = partslist.filter((item) => {
      return item.category === category;
    });

    const sortedItems = items.sort((a, b) => {
      return SORT_ORDERS[category].indexOf(a.name) >
        SORT_ORDERS[category].indexOf(b.name)
        ? 1
        : -1;
    });

    const itemsList = sortedItems.map((item, i) => {
      if (item.highlighted) {
        return (
          <li
            key={i}
            style={FILL_STYLES[item.damageSeverity]}
            title={item.name + ' : Click to register damage'}
            className={cx(s.partsListItem, s.highlighted)}
          >
            <button
              onClick={(e) => {
                this.clickHandler(item, e);
              }}
            >
              {item.name}
            </button>
          </li>
        );
      } else {
        return (
          <li
            key={i}
            style={FILL_STYLES[item.damageSeverity]}
            className={s.partsListItem}
          >
            <button
              onClick={(e) => {
                this.clickHandler(item, e);
              }}
            >
              {item.name}
            </button>
          </li>
        );
      }
    });

    return itemsList;
  }

  renderCategory = (name, list, styles) => {
    return (
      <li className={s.categoryListItem} style={styles || null}>
        <span className={s.categoryName}>{name}</span>
        <ul
          className={s.partsList}
          onMouseOut={this.mouseLeaveHandler}
          onMouseOver={this.mouseEnterHandler}
        >
          {list}
        </ul>
      </li>
    );
  };

  render() {
    const halfWidth = { width: '49%' };

    const screens = this.renderCategory(
      'Screens',
      this.getItems(this.state.partsList, 'Screens'),
      halfWidth
    );
    const mirrors = this.renderCategory(
      'Mirrors',
      this.getItems(this.state.partsList, 'Mirrors'),
      halfWidth
    );
    const windows = this.renderCategory(
      'Windows',
      this.getItems(this.state.partsList, 'Windows')
    );
    const wheels = this.renderCategory(
      'Wheels',
      this.getItems(this.state.partsList, 'Wheels')
    );
    const panels = this.renderCategory(
      'Panels',
      this.getItems(this.state.partsList, 'Panels')
    );

    return (
      <div className={s.selectionTool}>
        <h3>Select a damaged section:</h3>
        <ul className={s.categoryList}>
          {screens}
          {mirrors}
          {windows}
          {wheels}
          {panels}
        </ul>
      </div>
    );
  }
}

let composed = SelectionList;

export default composed;
