import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontLeft4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M444.5,733.4l-0.9,97.7l189.3-0.4c-0.7-7.2,5.4-118.6,5.7-121.4l-180.2-0.2h-11.9C445.3,709.2,444.5,732.1,444.5,733.4z"
      />
    );
  }
}

let composed = DoorFrontLeft4x45d;
composed = SectionComponent({
  name: 'Front Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Passenger Door Damage',
    content: <Modal name="Front Passenger Door" />,
  },
})(composed);

export default composed;
