import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontRightHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontRight)}
        d="M673.5,200.5H475.7c82.4,73.4,108.4,79.5,117.5,82.5c28.6,5.4,89.5,1.9,89.3,1.9L673.5,200.5z"
      />
    );
  }
}

let composed = WindowFrontRightHatchback;
composed = SectionComponent({
  name: 'Front Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
