import { apiServer } from '../config.js';
import AppAPI from './appAPI';

const organisationAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },

  getAllOrganisations() {
    return new Promise((resolve, reject) => {
      fetch(apiServer + '/organisations/orgmodels', {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject(Error('getAllOrgModels failed'));
        });
    });
  },

  getOrganisation(id) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + '/organisations/orgmodel/' + id, {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject(Error('getOrganisation failed'));
        });
    });
  },

  getAllRulesets() {
    return new Promise((resolve, reject) => {
      fetch(apiServer + '/organisations/rulesets', {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject(Error('getAllRulesets failed'));
        });
    });
  },

  getRuleset(id, depID) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + `/organisations/ruleset/${id}/${depID}`, {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject(Error('getRuleset failed'));
        });
    });
  },

  getBusinessLinesForInsurer(insurerId) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + `/organisations/businesslines/${insurerId}`, {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((response) => {
          console.log(response);
          reject(Error(`getBusinessLinesForInsurer(${insurerId}) failed`));
        });
    });
  },

  getSubInsurersForInsurer(insurerId) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + `/organisations/subinsurers/${insurerId}`, {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((response) => {
          console.log(response);
          reject(Error(`getSubInsurersForInsurer(${insurerId}) failed`));
        });
    });
  },

  updateOrgSettings(data) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + `/organisations/orgmodel`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Auth: this.getToken(),
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((response) => {
          console.log(response);
          reject(Error(`updateOrgSettings failed`));
        });
    });
  },
  updateAppSettings(data) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + `/organisations/ruleset`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Auth: this.getToken(),
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((response) => {
          console.log(response);
          reject(Error(`updateAppSettings failed`));
        });
    });
  },
};

export default organisationAPI;
