import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class BonnetVan extends Component {
  render() {
    //console.log(this.props);
    return (
      <path
        className={s.bonnet}
        {...this.props}
        d="M342.2,608.3C320.1,576.8,316,524.7,316,470v-18c0-54.7,4.1-107,26.2-138.5c0-0.1-1.7-2.6-0.3-4.4  c0.5-0.6-2.1-1.8-2.7-1.9c-55-5.4-156.1,9.1-161.8,14.8c-0.2,0-1.2,0.4-1.4,1c-8.4,17.6-13.2,72.1-13.8,129l-0.1,18  c0.6,56.6,5.2,111.2,13.5,128.8c0.3,0.6,0.7,0.9,1,0.9c5.7,5.7,105.9,20.2,160.9,14.8c0.6-0.1,4.9-1.2,4.5-1.8  C340.6,610.8,342.3,608.4,342.2,608.3z"
      />
    );
  }
}

let composed = BonnetVan;
composed = SectionComponent({
  name: 'Bonnet',
  category: 'Panels',
  highlighted: false,
  modalModel: {
    title: 'Register Bonnet Damage',
    content: <Modal name="Bonnet" />,
    actions: [],
  },
})(composed);

export default composed;
