import { Dispatcher } from 'flux';
import util from 'util';

function AppDispatcher() {
  Dispatcher.call(this);
  this._queue = [];
}

util.inherits(AppDispatcher, Dispatcher);

AppDispatcher.prototype.dispatch = function (payload) {
  AppDispatcher.super_.prototype.dispatch.call(this, payload);

  var queue = this._queue,
    queuedPayload = queue.shift();
  while (queuedPayload) {
    AppDispatcher.super_.prototype.dispatch.call(this, queuedPayload);
    queuedPayload = queue.shift();
  }
};

AppDispatcher.prototype.queueDispatch = function (payload) {
  //console.log(this.isDispatching(), payload);
  if (this.isDispatching()) {
    this._queue.push(payload);
  } else {
    this.dispatch(payload);
  }
};

const dispatcher = new AppDispatcher();
export default dispatcher;
