import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackRight4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M813.9,210.9l-10.7,23.1l-3.3-10.5c-2.6-7.1-12.1-14.8-18.1-14.6L646,208.6l-6.5-121h83.1c8.6,40,38.6,77.5,90.2,75.3  c1.9,15.7,2.5,34.9,2.4,42.6C815.1,207.3,814.7,209.2,813.9,210.9z"
      />
    );
  }
}

let composed = DoorBackRight4x4;
composed = SectionComponent({
  name: 'Back Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Driver Door Damage',
    content: <Modal name="Back Driver Door" />,
  },
})(composed);

export default composed;
