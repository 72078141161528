import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const Results = (props) => {
  const s = props.styles;

  const { vehicleDetails, splat, displayValue, caseSettings } = props;

  const retailValue = displayValue
    ? displayValue === null
      ? vehicleDetails.DefaultRetail
        ? '£' + parseInt(vehicleDetails.DefaultRetail).toLocaleString()
        : ''
      : '£' + parseInt(displayValue).toLocaleString()
    : null;

  const commValuation =
    props.userData.TriageOrgModel.Name == 'AXA' &&
    props.selectedBusinessLine == 2 &&
    displayValue ? (
      <div className={s.customRetail}>
        <strong>{retailValue || 0}</strong>
        <RaisedButton
          primary={true}
          label="Change Retail Value"
          onClick={props.openModalHandler}
        />
      </div>
    ) : (
      <strong className={s.cols_6}>
        {vehicleDetails.hasError ||
        (displayValue === 0 && vehicleDetails.Registration)
          ? 'UNKNOWN'
          : vehicleDetails.DefaultRetail
          ? '£' + parseInt(vehicleDetails.DefaultRetail).toLocaleString()
          : null}
      </strong>
    );

  const valuation =
    caseSettings && caseSettings.ShowValuation ? (
      <li
        className={s.detList__item}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <span
          className={s.cols_6}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {props.selectedBusinessLine == 2 &&
          displayValue !== vehicleDetails.DefaultRetail
            ? 'Custom Retail Value'
            : 'Typical Retail Value'}
          :
        </span>
        {commValuation}
      </li>
    ) : null;

  return (
    <div className={s.results}>
      <h2>Current Vehicle Details: </h2>
      <ul className={s.detList}>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Registration: </span>
          <strong className={s.cols_6}>{vehicleDetails.Registration}</strong>
        </li>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Make: </span>
          <strong className={s.cols_6}>
            {vehicleDetails.Registration
              ? vehicleDetails.Manufacturer &&
                vehicleDetails.Manufacturer !== null &&
                vehicleDetails.Manufacturer !== ''
                ? vehicleDetails.Manufacturer
                : 'UNKNOWN'
              : null}
          </strong>
        </li>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Model: </span>
          <strong className={s.cols_6}>
            {vehicleDetails.Registration
              ? vehicleDetails.Model &&
                vehicleDetails.Model !== null &&
                vehicleDetails.Model !== ''
                ? vehicleDetails.Model
                : 'UNKNOWN'
              : null}
          </strong>
        </li>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Body: </span>
          <strong className={s.cols_6}>
            {vehicleDetails.Registration ? splat || 'UNKNOWN' : null}
          </strong>
        </li>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Year: </span>
          <strong className={s.cols_6}>
            {vehicleDetails.YearManufactured === 0
              ? 'UNKNOWN'
              : vehicleDetails.YearManufactured}
          </strong>
        </li>
        <li className={s.detList__item}>
          <span className={s.cols_6}>Date of Registration: </span>
          <strong className={s.cols_6}>
            {vehicleDetails.RegDate === null
              ? 'UNKNOWN'
              : props.getRegDate(vehicleDetails.RegDate)}
          </strong>
        </li>
        {valuation}
      </ul>
    </div>
  );
};
export default Results;
