import Dispatcher from '../core/Dispatcher.js';
import ActionTypes from '../constants/ActionTypes.js';

const UserActions = {
  createNewUser(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_CREATE,
      data: data,
    });
  },

  updateUserData(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_UPDATE,
      data: data,
    });
  },

  setNewUserDetails(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_UPDATE_NEW_USER_DETAILS,
      data: data,
    });
  },

  checkUserNameExists(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_CHECK_EXISTS,
      data: data,
    });
  },

  deleteUserDetails(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_DELETE_USER_DETAILS,
      data: data,
    });
  },

  setCurrentUser(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_SET_CURRENT,
      data: data,
    });
  },

  setLockState(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_SET_LOCK,
      data: data,
    });
  },

  resetUserPassword(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_RESET_EMAIL,
      data: data,
    });
  },

  ShowInvalidUserDialog(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.USER_INVALID_USER,
    });
  },
};

export default UserActions;
