import React from 'react';

const SelectedRepairLocation = (props) => {
  const s = props.styles;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Selected Repair Location: </span>
      <strong className={s.cols_6}>
        <span>{props.location ? props.location : '(none selected)'}</span>
      </strong>
    </li>
  );
};

export default SelectedRepairLocation;
