import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightEstate extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M998.8,129.3c0.4-0.7,6.8-12.6,6.9-31.9c0-12.5-2.9-31.3-4.5-40.4c-0.6-3.3-3.2-5.7-6.4-6.1c-6.7-0.8-20.2-2-43.8-2.6   l-27.1-0.5c-0.6,0-2.1,0.6-2.3,2.3l0.5,11.3l0.4,8.4c0,26-14.4,42.5-32.5,55.8c-14.9,10.9-35.7,12.6-54.3,5.5l0,0   c-1.3-0.5-2.6,0.8-2,2.1c8.1,18.1,14.7,54.3,15.6,62.8c0.1,0.8,0.8,1.4,1.6,1.4l98.3-0.1c0.1,0,0.2,0,0.2,0   c3.1,0.1,13.7-0.2,16.7,0.7c8.5,2.6-0.9,15.5-1.6,16.4l-0.1,0.1c-1.1,1.9-23.3,40.8-50.3,57.2c-15.7,11.5-182,14.7-202,15   c-1,0-1.8,1-1.5,1.9l2.8,13.6c0.2,0.7,0.8,1.3,1.5,1.3c39.5,1.1,181.1-8.6,184.6-8.9h0.1c62.9-9.2,91.6-88.5,93.3-93.6   c0.1-0.2,0.1-0.3,0.1-0.5c0.1-17.1,0.1-57.2-0.1-62.3c0-0.4,0.1-0.7,0.3-1c1-1.4,4-5.5,5.5-8C998.7,129.4,998.7,129.3,998.8,129.3z   "
        />
        <path
          {...this.props}
          d="M998.8,129.3c0.4-0.7,6.8-12.6,6.9-31.9c0-12.5-2.9-31.3-4.5-40.4c-0.6-3.3-3.2-5.7-6.4-6.1   c-6.7-0.8-20.2-2-43.8-2.6l-27.1-0.5c-0.6,0-2.1,0.6-2.3,2.3l0.5,11.3l0.4,8.4c0,26-14.4,42.5-32.5,55.8   c-14.9,10.9-35.7,12.6-54.3,5.5l0,0c-1.3-0.5-2.6,0.8-2,2.1c8.1,18.1,14.7,54.3,15.6,62.8c0.1,0.8,0.8,1.4,1.6,1.4l98.3-0.1   c0.1,0,0.2,0,0.2,0c3.1,0.1,13.7-0.2,16.7,0.7c8.5,2.6-0.9,15.5-1.6,16.4l-0.1,0.1c-1.1,1.9-23.3,40.8-50.3,57.2   c-15.7,11.5-182,14.7-202,15c-1,0-1.8,1-1.5,1.9l2.8,13.6c0.2,0.7,0.8,1.3,1.5,1.3c39.5,1.1,181.1-8.6,184.6-8.9h0.1   c62.9-9.2,91.6-88.5,93.3-93.6c0.1-0.2,0.1-0.3,0.1-0.5c0.1-17.1,0.1-57.2-0.1-62.3c0-0.4,0.1-0.7,0.3-1c1-1.4,4-5.5,5.5-8   C998.7,129.4,998.7,129.3,998.8,129.3z M960,156.3c0-1.9,2.4-4.2,4.4-4.2h22c1.9,0,2.6,2.2,2.6,4.2v28.9c0,1.9-0.6,2.9-2.6,2.9h-22   c-1.9,0-4.4-0.9-4.4-2.9V156.3z M994.3,123.8c-1.3,1.3-2,2.3-3.7,2.3h-86c-16,0-4.2-3.7-2.2-5.7c1.3-1.3,3-2.3,4.7-2.3h85.1   C998,118.1,996.4,121.9,994.3,123.8z"
        />
      </g>
    );
  }
}

let composed = WingBackRightEstate;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
