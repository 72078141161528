import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontLeft4x4 extends Component {
  render() {
    return (
      <g className={s.wingFrontLeft}>
        <path
          {...this.props}
          d="M673.8,608.7L550.4,609c-13-0.3-29.4,6.2-52.2,26c-13.6,11.8-41.6,36.2-52,44.6c-7.2,6.3-19.2,16-23.8,18.5L320,715.9  c0,0-60.4,6.1-64.9,38.3c-1,7-13.2,8.2-13.2,17.5c0,0,0,27.6,0,29.3c0.5,4.4-2.8,40.1,33.2,41.2h16.4c11-56,57.9-64,82.9-58.2  c18.8,4.3,47.9,26.3,49.5,68.5l16.2,0.3c0,0-5.7-128.6,3.7-149.7c5.5-12.4,62.1-61.9,66.6-65.9c15.5-13.6,31.1-18.2,39.6-18.5  l121.1,0.5L673.8,608.7z"
        />
        <path
          {...this.props}
          d="M673.8,608.7L550.3,609c-13-0.3-29.4,6.2-52.2,26c-13.6,11.8-41.6,36.2-52,44.6c-7.2,6.3-19.2,16-23.8,18.5   l-102.4,17.8c0,0-60.4,6.1-64.9,38.3c-1,7-13.2,8.2-13.2,17.5c0,0,0,27.6,0,29.3c0.5,4.4-2.8,40.1,33.2,41.2h3.8   c0.8-45.7,37.5-82.7,82.5-82.7c37.4,0,68,25.7,78.1,60.7c-1-45.1-1.5-104.3,4.3-117c5.6-12.3,62.1-61.9,66.6-65.9   c15.5-13.6,31.1-18.2,39.6-18.5l121.2,0.4L673.8,608.7z M293.3,737.8c-2.3,3.3-3.8,7.4-5.1,11.1c-1.9,5.4-2.3,10.5-1.9,16.2h-25.5   c0,0-0.4-8.5,1.2-13.9c1.7-5.9,6.7-11.1,10-13.7c3.8-2.9,14.7-6.4,26.3-6.2C296.7,733.2,294.9,735.5,293.3,737.8z"
        />
      </g>
    );
  }
}

let composed = WingFrontLeft4x4;
composed = SectionComponent({
  name: 'Front Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Passenger Wing Damage',
    content: <Modal name="Front Passenger Wing" />,
  },
})(composed);

export default composed;
