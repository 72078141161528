import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class Tailgate extends Component {
  render() {
    return (
      <g className={s.tailGate}>
        <path
          {...this.props}
          d="M1044.7,334.4L998,313.8c-2.6-1.1-5.3,0.7-5.3,3.4v281.3c0,2.7,2.7,4.5,5.1,3.5l46.7-17.6c1.5-0.6,2.5-2,2.5-3.5V337.8  C1047,336.4,1046.1,335,1044.7,334.4z"
        />
        <path
          {...this.props}
          d="M1044.7,334.6L998,314c-2.6-1.1-5.3,0.7-5.3,3.4v281.3c0,2.7,2.7,4.5,5.1,3.5l46.7-17.6c1.5-0.6,2.5-2,2.5-3.5V338  C1047,336.6,1046.1,335.2,1044.7,334.6z M1038.5,525.2c0,0.3-0.2,0.6-0.6,0.6c-3.4,0-21.8,0-25.8,0c-0.5,0-0.8-0.4-0.8-0.8V394.8  c0-2.6,2.1-4.7,4.7-4.7h21.4v0.1l0,0c0.6,0,1.1,0.5,1.1,1.1c0,5.5,0,31.6,0,66.8C1038.4,494.2,1038.5,521,1038.5,525.2z"
        />
      </g>
    );
  }
}

let composed = Tailgate;
composed = SectionComponent({
  name: 'Tailgate',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Tailgate Damage',
    content: <Modal name="Tailgate" />,
  },
})(composed);

export default composed;
