import s from '../TriageStatsPage.module.scss';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import cx from 'classnames';

const RequestForm = (props) => {
  return (
    <div className={s.requestFormWrap}>
      <form className={s.requestForm}>
        <fieldset className={cx(s.filterRow, s.cols_10, s.cols_sm_6)}>
          <legend>Search by Date Range:</legend>
          <DatePicker
            fullWidth={true}
            shouldDisableDate={props.validateMinDateHandler || function () {}}
            defaultDate={props.dateRange.minDate}
            maxDate={props.maxDate}
            formatDate={new Intl.DateTimeFormat('en-GB', {}).format}
            floatingLabelText="Start Date"
            onChange={props.minDateHandler}
            className={s.cols_4}
            hintText="Start Date"
            mode={props.isLandscape ? 'landscape' : 'portrait'}
          />
          <DatePicker
            shouldDisableDate={props.validateMaxDateHandler || function () {}}
            fullWidth={true}
            defaultDate={props.dateRange.maxDate}
            minDate={props.minDate}
            maxDate={new Date()}
            formatDate={new Intl.DateTimeFormat('en-GB', {}).format}
            floatingLabelText="End Date"
            onChange={props.maxDateHandler}
            className={s.cols_4}
            hintText="End Date"
            mode={props.isLandscape ? 'landscape' : 'portrait'}
          />
        </fieldset>
        <RaisedButton
          className={s.btn}
          label="Submit"
          onClick={props.submitHandler}
          primary={true}
          type="submit"
        />
      </form>
      {props.enforceBusinessLines === true ? (
        <fieldset className={s.filterField}>
          <legend>Filter Product Lines:</legend>
          <SelectField
            disabled={props.disableFilter}
            onChange={(e, i) => props.onFilter(i, props.dropdownItems[i])}
            floatingLabelText="Filter By"
            value={props.filterValue || 0}
            className={s.cols_4}
          >
            {props.dropdownItems.map((item, i) => {
              return <MenuItem key={i} value={i} primaryText={item} />;
            })}
          </SelectField>
        </fieldset>
      ) : null}
    </div>
  );
};

export default RequestForm;
