import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class BumperRear extends Component {
  render() {
    return (
      <g className={s.bumperRear}>
        <path
          {...this.props}
          d="M1154.7,321.9c27.8,0,30.9-3,30.9-29c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0-25.1-2.5-26.5-59.1-26.5  c-26.4,0-28.7,0.1-28.7,0.1c-0.1,0,0.5,388.8,0.5,388.9c0,0,20.2,0,20.3,0c63.6,0,66.8-1.3,66.8-29c0-0.9,0-1.7,0-2.5  c0-23.9-6.2-29-33.8-27.3l-20.5,1V321.9H1154.7z"
        />
        <path
          {...this.props}
          d="M1112.7,391.6L1112.7,391.6c3.9,0,7,3.1,7,7v117c0,3.9-3.1,7-7,7l0,0c-3.9,0-7-3.1-7-7v-117  C1105.7,394.8,1108.8,391.6,1112.7,391.6z"
        />
      </g>
    );
  }
}

let composed = BumperRear;
composed = SectionComponent({
  name: 'Rear Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Rear Bumper Damage',
    content: <Modal name="Rear Bumper" />,
  },
})(composed);

export default composed;
