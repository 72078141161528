const DamageDescription = (props) => {
  const s = props.styles;
  const damageDescription = props.decision
    ? props.decision.DamageDescription
    : null;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Damage: </span>
      <strong className={s.cols_6}>{damageDescription}</strong>
    </li>
  );
};

export default DamageDescription;
