import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRight extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M686,15.8H456.9l0.5,41.9h230.9L686,15.8z"
      />
    );
  }
}

let composed = SillRight;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
