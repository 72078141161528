import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class BumperRearPV extends Component {
  render() {
    return (
      <g className={s.bumperRear}>
        <path
          {...this.props}
          d="M1151.1,344.4c26.7,0,29.6-2.5,29.6-24.6c0-0.2,0-0.3,0-0.6c0-0.1,0-0.2,0-0.3c0-21.3-2.4-22.5-56.7-22.5  c-25.3,0-50.5,0.1-50.5,0.1c-0.1,0-0.5,329.8-0.5,329.9c0,0,43.4,0,43.5,0c61,0,64.1-1.1,64.1-24.6c0-0.8,0-1.4,0-2.1  c0-20.3-5.9-24.6-32.4-23.2l-8.5,0.8v-233L1151.1,344.4L1151.1,344.4z"
        />
        <path
          {...this.props}
          d="M1125.7,297H1073v329h52.7c7.4,0,13.3-5.9,13.3-13.3v-303C1139,302.4,1133,297,1125.7,297z M1121,529h-28V387  h28V529z"
        />
      </g>
    );
  }
}

let composed = BumperRearPV;
composed = SectionComponent({
  name: 'Rear Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Rear Bumper Damage',
    content: <Modal name="Rear Bumper" />,
  },
})(composed);

export default composed;
