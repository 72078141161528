import axios from 'axios';
import { apiServer, queryTimeout } from '../config.js';
import AppAPI from './appAPI.js';

const userAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },

  getAllUsersData(cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/getallusers/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getAllUsersDataByInsurer(insurerId, cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/getallusers/byinsurerid/' + insurerId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getAllUsersDataByInsurerAndDepartment(insurerId, departmentId, cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/getallusers/byinsurerid/' + insurerId + '/' + departmentId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getAllRoleData(cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/getrolesdata',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
        //  if(cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getUserDataById(id, cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/byid/' + id,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
        //  if(cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getAllOrganisationNamesAndIds(cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'organisations/namesandids',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getAllRoles(cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'roles/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getDepartmentsByInsurerId(id, cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'departments/?insurerId=' + id,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
        //  if(cb) cb(response.data);
      })
      .catch((err) => {});
  },

  createNewUser(data, cb) {
    axios({
      method: 'post',
      data: data.user,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/create',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {
        if (cb) cb(false);
      });
  },

  updateUser(data, cb) {
    axios({
      method: 'put',
      data: data.user,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/update/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {
        if (cb) cb(false);
      });
  },

  deleteUser(data, cb) {
    axios({
      method: 'delete',
      data: data.userid,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/delete/' + data.userid,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {
        if (cb) cb(false);
      });
  },

  checkUserNameExists(data, cb) {
    axios({
      method: 'post',
      data: { name: data.name },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/UserExists',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  setUserLockState(data, cb) {
    axios({
      method: 'post',
      data: data,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/setuserlockstate',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  getLockedUsers(cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/getLockedUsers',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {});
  },

  resetEmail(data, cb) {
    axios({
      method: 'put',
      data: data.userid,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'users/reset/' + data.userid,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {
        if (cb) cb(false);
      });
  },
};

export default userAPI;
