import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofTwoDoorPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M588,319.3c-25.3,0-51-1.2-56.9-2.5c-9.4-2.1-10.8-1.2-12.5,9.1c-9.4,51.1-11.8,158.7-5.6,225.4c2.1,21,4.2,41.6,4.5,46.2  c0.1,1.2,0.1,2.3,0,3.2c2.8,1,5.7,2.1,8.5,3.2c7.3-2.1,22.4-3,53-3.6l55.6-1.2V319.3H588z"
      />
    );
  }
}

let composed = RoofTwoDoorPickup;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
