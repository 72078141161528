import { Component } from 'react';
import StatsAPI from '../../../api/statsAPI';
import AppActions from '../../../actions/AppActions';
import AppStore from '../../../stores/AppStore';
import { listViewConfig, itemViewConfig } from '../_config';
import dateFormat from 'dateformat';

function withStatsData(ComposedComponent) {
  return class withStatsData extends Component {
    constructor(props) {
      super(props);
      this.state = {
        dateRange: {
          minDate: new Date(new Date().setDate(new Date().getDate() - 7)),
          maxDate: new Date(),
        },
        listViewData: listViewConfig,
        itemViewData: {},
        noBusinessLineClientData: {},
        genericData: {},
        userData: AppStore.userData(),
      };
    }

    UNSAFE_componentWillMount() {
      const userData = AppStore.userData();

      if (userData.AppSettings.EnforceBusinessLines === true) {
        this.getStatsByDateRange(this.state.dateRange);
      } else if (userData.AppSettings.EnforceBusinessLines === false) {
        this.getGenericStatsByDateRange(this.state.dateRange);
      } else {
        console.log(
          'Business Lines neither enforced nor unenforced - Please check component'
        );
      }
    }

    parseAsUKDate = (value) => {
      const date = value.split('/');
      const d = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        y = parseInt(date[2], 10);
      return new Date(y, m - 1, d);
    };

    getLastDayOfMonth = (d) => {
      return new Date(d.getFullYear(), d.getMonth() + 1, 0, 23, 59, 59);
    };

    getStatsByDateRange = (dateRange) => {
      AppActions.addSpinner();
      let genericClientData = {};
      StatsAPI.getStatisticsByDateRange(
        dateRange,
        (response) => {
          const summaryData = response.SummaryData || [];
          const detailData = response.DetailData || [];

          genericClientData = {
            data: summaryData['Direction Volumes'],
          };
          //This setState function needs to stay here as the setState functions below will result in a null value - This is being refactored.
          this.setState({
            genericData: genericClientData,
          });

          const listData =
            listViewConfig.map((item, i) => {
              item.values = summaryData[item.key];
              delete item.values['$id'];
              return item;
            }) || [];

          let viewData = {};

          Object.keys(itemViewConfig).forEach((view) => {
            const viewDetails = detailData[view];
            delete viewDetails['$id'];

            const monthlyData = Object.keys(viewDetails).map((d, i, arr) => {
              const monthData = viewDetails[d];
              delete monthData['$id'];

              let firstDayofMonth =
                this.parseAsUKDate(d).toLocaleDateString('en-GB');
              let lastDayOfMonth = this.getLastDayOfMonth(
                this.parseAsUKDate(d)
              ).toLocaleDateString('en-GB');

              try {
                if (i === 0) {
                  firstDayofMonth = dateFormat(dateRange.minDate, 'dd/mm/yyyy');
                }
                if (i === arr.length - 1) {
                  lastDayOfMonth = dateFormat(dateRange.maxDate, 'dd/mm/yyyy');
                }
                monthData.Date = firstDayofMonth + ' - ' + lastDayOfMonth;
              } catch (e) {
                console.log(e);
              }
              return monthData;
            });

            viewData[view] = monthlyData;
          });
          this.setState(
            {
              listViewData: [],
              itemViewData: {},
            },
            () => {
              this.setState({
                listViewData: listData,
                itemViewData: viewData,
              });
            }
          );
          AppActions.removeSpinner();
        },
        this.handleDataAccessError
      );
    };

    getGenericStatsByDateRange = (dateRange) => {
      AppActions.addSpinner();
      let genericClientData = {};
      StatsAPI.getStatisticsByDateRange(
        dateRange,
        (response) => {
          const summaryData = response.SummaryData || [];

          genericClientData = {
            data: summaryData['Direction Volumes'],
          };
          //This setState function needs to stay here as the setState functions below will result in a null value - This is being refactored.
          this.setState({
            genericData: genericClientData,
          });

          AppActions.removeSpinner();
        },
        this.handleDataAccessError
      );
    };

    getRawData = (dateRange, cb) => {
      AppActions.addSpinner();
      StatsAPI.getRawDataByDateRange(
        dateRange,
        (res) => {
          if (cb) cb(res);
          AppActions.removeSpinner();
        },
        this.handleDataAccessError
      );
    };

    handleDataAccessError = () => {
      AppActions.removeSpinner();
      AppActions.createModal({
        type: 'alert',
        title: 'An error occurred',
        content: (
          <div>
            <p>An error occurred while retrieving this data.</p>
            <p>Please check that you have the correct permissions.</p>
            <p>If this problem persists, please contact an administrator.</p>
          </div>
        ),
      });
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          dateRange={this.state.dateRange}
          getRawData={this.getRawData}
          listViewData={this.state.listViewData}
          itemViewData={this.state.itemViewData}
          refreshDataHandler={this.getStatsByDateRange}
          miSummaryData={this.state.genericData}
          userData={this.state.userData}
        />
      );
    }
  };
}

export default withStatsData;
