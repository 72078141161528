import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRearPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M818,582.8l-9.1,2.8c-3.2,1-6.4,1.6-6.4-1.7V332.3c0-3.3,3.2-2.7,6.4-1.7l9.1,2.8c2.1,0.6,3.5,2.6,3.5,4.7v240  C821.5,580.2,820.1,582.1,818,582.8z"
      />
    );
  }
}

let composed = ScreenRearPickup;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
