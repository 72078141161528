import { Component } from 'react';
import s from '../_vehicleComponent/VehicleTypes.module.scss';

import BumperFront from '../../VehicleSections/BumperFront/BumperFrontPV/';
import BumperRear from '../../VehicleSections/BumperRear/BumperRearPV/';
import Bonnet from '../../VehicleSections/Bonnet/BonnetPV/';
import DoorBackPassenger from '../../VehicleSections/DoorBackLeft/DoorBackLeftPV/';
import DoorBackDriver from '../../VehicleSections/DoorBackRight/DoorBackRightPV/';
import DoorBackLeft from '../../VehicleSections/DoorBackLeft/DoorBackLeftPV/back';
import DoorBackRight from '../../VehicleSections/DoorBackRight/DoorBackRightPV/back';
import DoorFrontLeft from '../../VehicleSections/DoorFrontLeft/DoorFrontLeftPV/';
import DoorFrontRight from '../../VehicleSections/DoorFrontRight/DoorFrontRightPV/';
import Roof from '../../VehicleSections/Roof/RoofPV/';
import ScreenRear from '../../VehicleSections/ScreenRear/ScreenRearPV/';
import ScreenWind from '../../VehicleSections/ScreenWind/ScreenWindPV/';
import SillLeft from '../../VehicleSections/SillLeft/SillLeftPV/';
import SillRight from '../../VehicleSections/SillRight/SillRightPV/';
import WheelFrontLeft from '../../VehicleSections/WheelFrontLeft/WheelFrontLeftPV/';
import WheelFrontRight from '../../VehicleSections/WheelFrontRight/WheelFrontRightPV/';
import WheelBackLeft from '../../VehicleSections/WheelBackLeft/WheelBackLeftPV/';
import WheelBackRight from '../../VehicleSections/WheelBackRight/WheelBackRightPV/';
import WindowFrontLeft from '../../VehicleSections/WindowFrontLeft/WindowFrontLeftPV/';
import WindowFrontRight from '../../VehicleSections/WindowFrontRight/WindowFrontRightPV/';
import WingFrontLeft from '../../VehicleSections/WingFrontLeft/WingFrontLeftPV/';
import WingFrontRight from '../../VehicleSections/WingFrontRight/WingFrontRightPV/';
import WingBackLeft from '../../VehicleSections/WingBackLeft/WingBackLeftPV/';
import WingBackRight from '../../VehicleSections/WingBackRight/WingBackRightPV/';
import WingMirrorLeft from '../../VehicleSections/WingMirrorLeft/WingMirrorLeftMPV';
import WingMirrorRight from '../../VehicleSections/WingMirrorRight/WingMirrorRightMPV';
import VehicleComponent from '../_vehicleComponent/';

class TwoDoorSaloon extends Component {
  render() {
    return (
      <div className={s.svgWrapper}>
        <canvas className={s.svgCanvas} height={93} width={120}></canvas>
        <svg className={s.svg} {...this.props}>
          <text
            transform="translate(0,24)"
            style={{ fill: 'rgb(0,188,212)', fontSize: '24px' }}
          >
            Panel Van
          </text>
          <BumperFront {...this.props} />
          <BumperRear {...this.props} />

          <Bonnet {...this.props} />

          <Roof {...this.props} />

          <ScreenWind {...this.props} />
          <ScreenRear {...this.props} />

          <DoorFrontLeft {...this.props} />
          <DoorFrontRight {...this.props} />

          <DoorBackPassenger {...this.props} />
          <DoorBackDriver {...this.props} />

          <DoorBackLeft {...this.props} />
          <DoorBackRight {...this.props} />

          <SillLeft {...this.props} />
          <SillRight {...this.props} />

          <WingFrontRight {...this.props} />
          <WingFrontLeft {...this.props} />
          <WingBackLeft {...this.props} />
          <WingBackRight {...this.props} />

          <WindowFrontRight {...this.props} />
          <WindowFrontLeft {...this.props} />

          <WheelFrontRight {...this.props} />
          <WheelFrontLeft {...this.props} />
          <WheelBackLeft {...this.props} />
          <WheelBackRight {...this.props} />

          <WingMirrorLeft {...this.props} />
          <WingMirrorRight {...this.props} />
        </svg>
      </div>
    );
  }
}

let composed = TwoDoorSaloon;
composed = VehicleComponent(composed);

export default composed;
