import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftMPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M615,598.1H469.6c-1.8,0-6.9,7.1-7.8,8.8l-41.3,75.6c-0.8,1.4-1.4,4.6-2.1,5.6h183.7L615,598.1z"
      />
    );
  }
}

let composed = WindowFrontLeftMPV;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
