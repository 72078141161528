import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftLightVan extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M912.9,843.9v-24.4c0-3.5-1.3-6.8-3.7-9.4l-7.2-7.7c0,0-0.6-146.2-7.3-192.2c-1-6.8-6.9-11.9-13.9-11.9    L606,599.6c-7.9,0-14.9,5.1-17.3,12.5c-4.9,15.3-8.1,34.5-10.7,45.2c-11.4,49.9-12.7,215.9-12.7,215.9c9.9,0.1,139.6,0,139.6,0    s-1.4-33.8,4.3-50.5c0-0.1,15.7-41.1,63.1-41.5c38.1-0.3,64.9,29.8,66.8,55l1.3,33l60.4-11C907.8,856.9,912.9,850.9,912.9,843.9z"
        />
        <path
          {...this.props}
          d="M909.2,810.1l-7.2-7.7c0,0-0.6-146.2-7.3-192.2c-1-6.8-6.9-11.9-13.9-11.9L606,599.6   c-7.9,0-14.9,5.1-17.3,12.5c-4.9,15.3-8.1,34.5-10.7,45.2c-11.4,49.9-12.7,215.9-12.7,215.9c9.9,0.1,139.6,0,139.6,0   s-1.4-33.8,4.3-50.5c0-0.1,15.7-41.1,63.1-41.5c38.1-0.3,64.9,29.8,66.8,55l1.3,33l60.4-11c7-1.3,12.1-7.3,12.1-14.3v-24.4   C912.9,816,911.6,812.7,909.2,810.1z M604.7,622.9c-7.2,25.5-11.7,86.1-12,116.1h-4c0.3-32,6.6-99.7,11.3-115.1   c0-0.1,0.1,0,0.1-0.1c0.3-0.3,1-1.7,4.1-1.7C604.6,622.1,604.8,622.5,604.7,622.9z M910,818.5h-71l-1.7-4.3h71.2L910,818.5z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftLightVan;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
