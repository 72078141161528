import Dispatcher from '../core/Dispatcher.js';
import ActionTypes from '../constants/ActionTypes.js';

const OrganisationActions = {
  getBusinessLinesForInsurer(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.BUSINESS_LINES_GET,
      data: data,
    });
  },
  updateAppSettings(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.APP_SETTINGS_UPDATE,
      data: data,
    });
  },
  updateOrgSettings(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.ORG_SETTINGS_UPDATE,
      data: data,
    });
  },
};

export default OrganisationActions;
