import React from 'react';
import Router from 'react-routing/src/Router';
import App from './components/App';
import AboutPage from './components/AboutPage/AboutPage';
import NotFoundPage from './components/NotFoundPage';
import ErrorPage from './components/ErrorPage';
import TriageMainPage from './components/TriagePage/MainPage/';
import DashboardPage from './components/DashboardPage/';
import PermissionsPage from './components/PermissionsPage';
import TriageStatsPage from './components/TriageStatsPage/';
import TriageReviewPage from './components/TriageReviewPage/';
import EventLoggingPage from './components/EventLoggingPage';
import SettingsPage from './components/SettingsPage';
import LoginPage from './components/LoginPage';
import Location from './core/Location';
import AppStore from './stores/AppStore';
import TriageStore from './stores/TriageStore';
import TriageActions from './actions/TriageActions';
import RoleTypes from './constants/RoleTypes.js';
import Embed from './components/Embed';

function GenerateTriagePage(stage) {
  let newState = TriageStore.getProgressState();
  let correctStage = Math.min(stage, newState.MAX_PERMITTED);
  newState.CURRENT_STAGE = correctStage;
  //console.log(newState);
  TriageActions.setProgressState(newState);
  return <TriageMainPage stage={correctStage} />;
}

async function CheckIsPermitted(values) {
  let isPermitted = false;
  let userData = await AppStore.userData();
  let matches = [];
  const roles = userData === null ? [] : userData.User.Roles;
  Array.from(values).map((x) => {
    isPermitted =
      userData !== undefined &&
      roles.find((y) => {
        return y.toUpperCase() === x.toUpperCase();
      }) !== undefined;
    if (isPermitted === true) matches.push(isPermitted);
  });
  return matches.length > 0;
}

const prefix = '';

const router = new Router((on) => {
  on(prefix + '/embed', async (state, next) => {
    return <Embed context={state.context} />;
  });

  on(prefix + '/reset/:token', async (state, next) => {
    return (
      <App context={state.context} reset={true} resetToken={state.params.token}>
        <span></span>
      </App>
    );
  });

  on('*', async (state, next) => {
    const component = await next();
    return component && <App context={state.context}>{component}</App>;
  });

  on(prefix + '/triage/review', async (state, next) => {
    return await GenerateTriagePage(4);
  });

  on(prefix + '/triage/furtherActions', async (state, next) => {
    return await GenerateTriagePage(3);
  });

  on(prefix + '/triage/assess', async (state, next) => {
    return await GenerateTriagePage(2);
  });

  on(prefix + '/triage/quickChecks', async (state, next) => {
    return await GenerateTriagePage(1);
  });

  on(prefix + '/triage/lookup', async (state, next) => {
    return await GenerateTriagePage(0);
  });

  on(prefix + '/triage/', async (state, next) => {
    Location.push('/triage/lookup');
    return await GenerateTriagePage(0);
  });

  on(prefix + '/dashboard', async (state, next) => {
    return <DashboardPage context={state.context} />;
  });

  on(prefix + '/about', async (state, next) => {
    return <AboutPage context={state.context} />;
  });

  on(prefix + '/permissions/:type', async (state, next) => {
    const allowed = await CheckIsPermitted([
      RoleTypes.ADMINISTRATOR,
      RoleTypes.USERSCOMPANYMANAGER,
      RoleTypes.USERSDEPARTMENTMANAGER,
    ]);
    if (!allowed) {
      Location.push('/dashboard');
      return <DashboardPage context={state.context} />;
    }
    return <PermissionsPage type={state.params.type} context={state.context} />;
  });

  on(prefix + '/permissions', async (state, next) => {
    const allowed = await CheckIsPermitted([
      RoleTypes.ADMINISTRATOR,
      RoleTypes.USERSCOMPANYMANAGER,
      RoleTypes.USERSDEPARTMENTMANAGER,
    ]);
    if (!allowed) {
      Location.push('/dashboard');
      return <DashboardPage context={state.context} />;
    }
    return <PermissionsPage context={state.context} />;
  });

  on(prefix + '/triageReview', async (state, next) => {
    return <TriageReviewPage context={state.context} />;
  });

  on(prefix + '/triageStats', async (state, next) => {
    const allowed = await CheckIsPermitted([
      RoleTypes.ADMINISTRATOR,
      RoleTypes.MICOMPANYMANAGER,
      RoleTypes.MIDEPARTMENTMANAGER,
    ]);
    if (!allowed) {
      Location.push('/dashboard');
      return <DashboardPage context={state.context} />;
    }
    return <TriageStatsPage context={state.context} />;
  });

  on(prefix + '/eventLogging', async (state, next) => {
    const allowed = await CheckIsPermitted([RoleTypes.ADMINISTRATOR]);
    if (!allowed) {
      Location.push('/dashboard');
      return <DashboardPage context={state.context} />;
    }
    return <EventLoggingPage context={state.context} />;
  });

  on(prefix + '/settings', async (state, next) => {
    return <SettingsPage context={state.context} />;
  });

  on(prefix + '/', async (state, next) => {
    Location.push('/dashboard');
    return <DashboardPage context={state.context} />;
  });

  on('error', (state, error) => {
    let errorPage =
      state.statusCode === 404 ? (
        <App context={state.context} error={error}>
          <NotFoundPage />
        </App>
      ) : (
        <App context={state.context} error={error}>
          <ErrorPage />
        </App>
      );
    if (state.statusCode === 401) {
      errorPage = (
        <App context={state.context} error={error}>
          <LoginPage />
        </App>
      );
    }
    return errorPage;
  });
});

export default router;
