import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRight4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M448.5,209.5h11.9l180.2-0.2c-0.3-2.8-6.4-114.2-5.7-121.4l-189.3-0.4l0.9,97.7C446.5,186.5,447.3,209.5,448.5,209.5z"
      />
    );
  }
}

let composed = DoorFrontRight4x45d;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
