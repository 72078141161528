import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FinishIcon from 'material-ui/svg-icons/action/done';

const CloseITG = (props) => {
  const s = props.styles;
  return (
    <div className={s.footerDialogContainer}>
      <div className={s.footerDialog}>
        <div className={s.closeITGButton}>
          <RaisedButton
            labelPosition="before"
            label="Close All ITG Cases"
            icon={<FinishIcon />}
            primary={true}
            onClick={props.closeITGCases}
          />
        </div>
      </div>
    </div>
  );
};

export default CloseITG;
