import { Component } from 'react';
import s from './WheelsModal.module.scss';

import SteeringQuestions from '../../QuestionSets/SteeringQuestions/';
import WheelsQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'steering', component: <SteeringQuestions /> },
  { stage: 2, section: 'wheels', component: <WheelsQuestions /> },
];

const SUBTITLE = 'Wheels Questions';

class WheelsModal extends Component {
  render() {
    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
          </div>
        </div>
      </div>
    );
  }
}

let composed = WheelsModal;
composed = ModalComponent(QUESTION_SETS, SUBTITLE)(composed);

export default composed;
