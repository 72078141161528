import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRightHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M473.7,197.8l199.5-0.2c-0.3-3.2-7.1-130.2-6.3-138.5l-209.6-0.4c0,0,0,31.5,0.9,63.2c0.6,20.4,3.9,42.3,5.2,51.2  C465.8,189.8,473.7,197.8,473.7,197.8L473.7,197.8z"
      />
    );
  }
}

let composed = DoorFrontRightHatchback;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
