import Dispatcher from '../core/Dispatcher.js';
import EventEmitter from 'eventemitter3';
import ActionTypes from '../constants/ActionTypes.js';
import OrgAPI from '../api/organisationAPI.js';

const CHANGE_EVENT = 'change';

let _businessLines = null;

const OrganisationStore = Object.assign({}, EventEmitter.prototype, {
  getBusinessLines() {
    return _businessLines;
  },

  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
});

Dispatcher.register(function (action) {
  let data = action.data;
  switch (action.actionType) {
    case ActionTypes.BUSINESS_LINES_GET:
      data = action.data;
      OrgAPI.getBusinessLinesForInsurer(data).then((res) => {
        _businessLines = res;
        OrganisationStore.emitChange();
      });
      break;
    case ActionTypes.ORG_SETTINGS_UPDATE:
      data = action.data;
      OrgAPI.updateOrgSettings(data).then((res) => {
        OrganisationStore.emitChange();
      });
      break;
    case ActionTypes.APP_SETTINGS_UPDATE:
      data = action.data;
      OrgAPI.updateAppSettings(data).then((res) => {
        OrganisationStore.emitChange();
      });
      break;
    default:
  }
  return true;
});

export default OrganisationStore;
