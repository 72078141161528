import { Component } from 'react';
import s from './FurtherActions.module.scss';

import AppStore from '../../../stores/AppStore';
import AppActions from '../../../actions/AppActions';
import TriageStore from '../../../stores/TriageStore';
import TriageActions from '../../../actions/TriageActions';
import RaisedButton from 'material-ui/RaisedButton';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import PrevIcon from 'material-ui/svg-icons/image/navigate-before';
import Allocation from './Allocation';
import OwnerImages from './OwnerImages';
import MobileRepair from './MobileRepair';
import RateComponent from './RateComponent';
import VariantSelection from './VariantSelection';

class FurtherActions extends Component {
  state = {
    allowContinue: true,
    direction: null,
    showCarHire: false,
    defaultRate: {
      Name: '',
      Code: '',
      Rate: 0,
    },
    mobileConfText: '',
  };

  UNSAFE_componentWillMount() {
    const decision = TriageStore.getDecision();
    this.setState(
      {
        userData: AppStore.userData(),
        currentCase: TriageStore.getCase(),
        direction: decision.Direction,
        showCarHire: decision.ShowCarHire,
      },
      () => {
        // Check if we have been redirected to this page when there is no damage that can be costed
        if (this.state.direction === 0) {
          // Shouldn't be on further actions if we haven't got a decision, so redirect back to the damage tool
          let newState = TriageStore.getProgressState();
          newState.CURRENT_STAGE = 2;
          newState.MAX_PERMITTED = 2;
          this.props.recordTime();
          TriageActions.setProgressState(newState);
        }

        const { currentCase } = this.state;
        // Decide whether to show mobile conf question
        const isMobileDecision =
          currentCase &&
          currentCase.decision &&
          currentCase.decision.OfferMobileRepairChoice &&
          currentCase.caseSettings.ShowConfirmMobileGroup;
        if (isMobileDecision && currentCase.mobileConfirmed === null) {
          this.handleMobileDecision();
        }

        this.setState({
          allowContinue: decision.RequestVariants
            ? !decision.RequestVariants
            : true,
        });
        TriageStore.addChangeListener(this.updateState);
      }
    );
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState(
      {
        userData: AppStore.userData(),
        currentCase: TriageStore.getCase(),
      },
      () => {
        //console.log(TriageStore.getDecision().Direction !== 6)
        const { currentCase } = this.state;
        this.setState({
          direction: currentCase.decision.Direction,
          allowContinue:
            currentCase && currentCase.decision
              ? !currentCase.decision.RequestVariants
              : true,
          showCarHire: currentCase.decision.ShowCarHire,
        });
      }
    );
  };

  gotoPrevious = (e) => {
    e.preventDefault();

    let newState = TriageStore.getProgressState();

    newState.CURRENT_STAGE = 2;
    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  handleSubmitAndProgress = () => {
    let newState = TriageStore.getProgressState();
    newState.MAX_PERMITTED = 4;
    newState.CURRENT_STAGE = 4;
    this.props.recordTime();
    //console.log('Continue...' + this.state.allowContinue);

    TriageActions.setProgressState(newState);
  };

  handleMobileDecision = () => {
    const { currentCase } = this.state;

    const mobileConfDefaultText = [
      'A mobile repair makes your life easier by bringing the bodyshop to you, saving you time and hassle. The repair quality and workmanship is equivalent to a traditional bodyshop and guaranteed in exactly the same way.',
      'Repairs to your vehicle are carried out at a location of your choosing as long as there is enough space (required space is equivalent to that of a double parking bay 5m x 5m). This could be at your home or your workplace.',
    ];

    const mobileConfTextOverride =
      currentCase.caseSettings.MobileConfTextOverride;
    const overrideTextArray = [];
    const carriageReturn = mobileConfTextOverride.indexOf('\n');
    const paraOne = mobileConfTextOverride.substr(0, carriageReturn);
    const paraTwo = mobileConfTextOverride.substr(carriageReturn);
    overrideTextArray.push(paraOne, paraTwo);

    AppActions.createModal({
      open: true,
      title: 'Confirm Mobile repair',
      type: 'alert',
      disableConfirm: true,
      content: (
        <MobileRepair
          changeHandler={this.handleMobileSelection}
          overrideTextArray={
            mobileConfTextOverride.length > 0
              ? overrideTextArray
              : mobileConfDefaultText
          }
        />
      ),
    });
  };

  handleMobileSelection = (e) => {
    const answer = e.target.value * 1 === 1;
    TriageActions.confirmMobile(answer);
    AppActions.updateModal({
      disableConfirm: false,
      onConfirm: () => {
        TriageActions.makeDecision(() => {
          const decision = TriageStore.getDecision();
          if (decision.Direction === 0) {
            //Has come back with direction of unknown - redirect to damage tool
            let newState = TriageStore.getProgressState();
            newState.MAX_PERMITTED = 2;
            newState.CURRENT_STAGE = 2;
            this.props.recordTime();
            TriageActions.setProgressState(newState);
          }
        });
      },
    });
  };

  setContinueState = (e) => {
    const decision = TriageStore.getDecision();
    if (decision.RequestVariants == true) {
      this.setState({
        allowContinue: false,
      });
    } else {
      this.setState({
        allowContinue: e,
      });
    }
  };

  render() {
    const { allowContinue, currentCase } = this.state;
    const caseSettings = currentCase.caseSettings;

    const showVariants =
      currentCase && currentCase.decision.RequestVariants
        ? currentCase.decision.RequestVariants
        : false;

    const variantSelection = showVariants ? (
      <VariantSelection
        data={currentCase.variantModels}
        setContinueState={this.setContinueState}
      />
    ) : null;

    const ownerImages =
      caseSettings &&
      caseSettings !== null &&
      caseSettings.ShowImagesComponent ? (
        <OwnerImages setContinueState={this.setContinueState} />
      ) : null;

    const rateComponent =
      caseSettings &&
      caseSettings !== null &&
      caseSettings.ShowCarHireGroup &&
      this.state.showCarHire ? (
        <RateComponent
          vehicleDetails={this.state.currentCase.vehicleDetails}
          setContinueState={this.setContinueState}
        />
      ) : null;

    const allocation =
      caseSettings &&
      caseSettings !== null &&
      caseSettings.ShowRepairerSelectionGroup ? (
        <Allocation />
      ) : null;

    const noDataMessage =
      variantSelection === null &&
      ownerImages === null &&
      rateComponent === null &&
      allocation === null ? (
        <p>
          There are no further actions required. Please click 'Continue' to
          review your case.
        </p>
      ) : null;

    return (
      <div className={s.root}>
        <div className={s.box}>
          <h2>Further actions</h2>
          {rateComponent}
          {variantSelection}
          {ownerImages}
          {allocation}
          {noDataMessage}
        </div>

        <div className={s.footerDialogContainer}>
          <div className={s.footerDialog}>
            <div className={s.nextButtons}>
              <div className={s.prevButton}>
                <RaisedButton
                  icon={<PrevIcon />}
                  label="Previous"
                  onClick={this.gotoPrevious}
                />
              </div>
              <div className={s.nextButton}>
                <RaisedButton
                  labelPosition="before"
                  label="Continue"
                  icon={<NextIcon />}
                  primary={true}
                  disabled={!allowContinue}
                  onClick={this.handleSubmitAndProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let composed = FurtherActions;

export default composed;
