import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRight4x4 extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M955,141v-11.5c-7-45.6-40.8-48.1-40.8-48.1s-40.2-2.7-62.9-4.2c-5.3,43-32.7,82.4-84.8,81.8  c-38.1-0.4-67.1-33.5-76.6-70.7l-30-0.1c-1,0-1.9,0.9-1.9,1.9c3.1,145.8,17.5,207.9,20,217.7c0.2,0.8,0.9,1.4,1.8,1.4  c16.5,0,154.2,0.3,184.5-0.1c3.2,0,12.1-5,14.6-7.1c18.2-15,64.9-73,70.2-83.7c2.7-5.4,4.9-9.5,5.9-24.7V143H831v-2H955z M932,196.6  v-34.7c0-0.1-0.2,0.1,0,0.1h19.5c0.1,0,0.4,0.1,0.7,0.5c0.5,0.5,0.8,1.1,0.8,1.8c0,2,0,8.2,0,26.5c0,0.3-0.6,15-1.6,13.3  c-1.6-2.5-8.6-4.6-11.4-5.3l-7.9-1.9C931.9,196.8,932,196.7,932,196.6z M773.4,243.6c-5.3,7.2-44.8,54.4-52.9,54.4h-33.1  c-0.5,0-1-1-1.1-1.5c0,0-6.2-31.1-9.4-76c-0.1-1.7,2.1-2,3.2-2l72.8,0.7C760.9,219.2,778.9,236.1,773.4,243.6z M837.1,226h75.6  c1.4,0,2.2,1,1.4,2.2c-8.9,12.2-60.6,69.5-66.5,69.5l-75.3-0.2c-1.4,0-9.1,0.4-5.2-3.7C767.2,293.7,828.3,226,837.1,226z"
        />
        <path
          {...this.props}
          d="M955,129c-7-45.6-40.8-48.1-40.8-48.1S850,76.5,838,75.7c-0.9-0.1-1.8,0.6-1.9,1.5c-7.2,39.3-45.1,68.3-83.3,57.5  c0,0-0.1,0-0.1,0c-17.6-4.2-37.2-15.9-48.1-45.7c-0.3-0.7-1-1.2-1.7-1.2l-42.9-0.1c-1,0-1.9,0.9-1.9,1.9  c3.1,145.8,17.5,207.9,20,217.7c0.2,0.8,0.9,1.4,1.8,1.4c16.5,0,154.2,0.3,184.5-0.1c3.2,0,12.1-5,14.6-7.1  c18.2-15,64.9-73,70.2-83.7c2.7-5.4,4.9-9.5,5.9-24.7V129z M773.4,242.8c-5.3,7.2-44.8,54.2-52.9,54.2h-33.1c-0.5,0-1-0.8-1.1-1.3  c0,0-6.2-30.8-9.4-75.7c-0.1-1.7,2.1-2,3.2-2l72.8,0.7C760.9,218.6,778.9,235.4,773.4,242.8z M914.1,227.4  c-8.9,12.2-60.6,69.7-66.5,69.7l-75.3-0.3c-1.4,0-9.1,0.3-5.2-3.8c0,0,61.1-67.9,70-67.9h75.6C914.2,225,915,226.2,914.1,227.4z"
        />
      </g>
    );
  }
}

let composed = WingBackRight4x4;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
