import RaisedButton from 'material-ui/RaisedButton';

const AcceptCashInLieu = (props) => {
  const s = props.styles;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Accept Cash in Lieu?: </span>
      <strong className={s.cols_6}>
        <span>{props.currentValue ? 'Yes' : 'No'}</span>
        {props.changeHandler ? (
          <RaisedButton
            className={'summaryBtn'}
            label="Edit"
            primary={true}
            onClick={props.changeHandler}
          />
        ) : null}
      </strong>
    </li>
  );
};

export default AcceptCashInLieu;
