import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontRightPV extends Component {
  render() {
    return (
      <g className={s.wingFrontRight}>
        <path
          {...this.props}
          d="M464.9,330h-14.3h-14c-3.9,0-7.8-1.2-11.1-3.4l-108.3-72.4c-1.2-0.4-4.7-1.1-8.3-1.7  c-6.7-1.1-13.1-3.5-18.8-7.1l-68.8-43c-7.1-4.4-12.9-10.8-16.6-18.3l-8.5-17.1l-4.2-4.5c-2.7-3-3.9-7-3.5-11l7.5-72l0,0  c0-1.9-0.3-3.6-0.2-5.6h0.3h41.4h5.7c-2.9,54,38.2,88,81,87.2c31.5-0.6,61.8-24.1,70.9-51.6c2.2-6.8,4.2-15.7,4.8-22.7H412  c-1.4,16-9.4,119.2,5.8,146l46.6,83.7c1.2,2.1,5.2,7.5,7.6,7.5l66.3,0.2l0.4,5.7L464.9,330L464.9,330z"
        />
        <path
          {...this.props}
          d="M537.4,330c0.9,0,1.6-0.7,1.5-1.6l-0.2-2.8c-0.1-0.8-0.7-1.4-1.5-1.4l-64.9-0.3c-2.4,0-6.4-5.4-7.6-7.5  l-46.6-83.6c-14.8-25.9-7.6-123.9-6-144.2c0.1-0.9-0.6-1.6-1.5-1.6h-9.1c-0.8,0-1.4,0.6-1.5,1.4c-0.7,6.8-2.6,14.9-4.6,21.2  c-9.1,27.5-39.5,50.9-70.9,51.5c-42.3,0.8-83.1-32.4-81-85.5c0-0.9-0.6-1.6-1.5-1.6h-4.1h-27.1c-0.8,0-1.4,0.6-1.5,1.4  c-0.1,1.4-0.3,2.6-0.3,4v-0.2l-7.2,72.1c-0.2,1.8,0,3.8,0.6,5.6c0.2,0.6,0.8,1.1,1.4,1.1h73.7c0.8,0,1.5,0.7,1.5,1.5l0,0  c0,0.8-0.7,1.5-1.5,1.5c-7.7,0-42.8,0-46.5,0c-0.2,0-0.4,0-0.6,0.1c-8.2,3.7,4.8,9.7,1.7,16.5c-2.6,5.7-3,12.9-0.1,21.2  c0.5,1.3,1.3,2.4,2.5,3.2c0.1,0.1,0.2,0.1,0.3,0.2l68.8,43c5.7,3.6,12.1,6,18.8,7.2c3.5,0.6,6.8,1.3,8.1,1.7c0.1,0,0.2,0.1,0.4,0.2  L439,326.5c3.3,2.2,7.1,3.5,11.1,3.5h0.8h14.3H537.4z M457.7,319.8c0.2,0.4-14.3-6.1-15.9-6.7c-0.1,0-0.2-0.1-0.3-0.2l-108-77.4  c-1.1-0.8-1.9-4.4-0.6-4.5c8.6-0.7,72,0,72,0c0.5,0-0.3,1.1,0,1.6L457.7,319.8z"
        />
      </g>
    );
  }
}

let composed = WingFrontRightPV;
composed = SectionComponent({
  name: 'Front Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Driver Wing Damage',
    content: <Modal name="Front Driver Wing" />,
  },
})(composed);

export default composed;
