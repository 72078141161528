import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofLightVan extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          className={s.roof}
          d="M453.9,576.3V338.4c0-2.2,1.8-4,4-4h115.6v246H457.9C455.7,580.3,453.9,578.5,453.9,576.3z"
        />
        <path
          {...this.props}
          d="M883,326H589.3c-10,0-10.3,7.6-10.3,17.5v230.1c0,9.9,0.3,17.5,10.3,17.5H883c0.6,0,6-0.1,6-0.7V326.7  C889,326.1,883.6,326,883,326z"
        />
        <path
          {...this.props}
          d="M883,326H589.3c-10,0-10.3,7.6-10.3,17.5v230.1c0,9.9,0.3,17.5,10.3,17.5H883c0.6,0,6-0.1,6-0.7V326.7  C889,326.1,883.6,326,883,326z M610.6,581.3c-0.4,0.2-3.8,0.2-4.2,0.2c-10.9,0-16.8-54.8-16.8-122.5s5.8-122.5,16.8-122.5  c0.4,0,3.8,0.1,4.2,0.2c-8.8,5.1-16.2,57.5-16.2,122.6S601.8,575.1,610.6,581.3z"
        />
      </g>
    );
  }
}

let composed = RoofLightVan;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
