import { Component } from 'react';
import PropTypes from 'prop-types';
import s from '../PermissionsPage.module.scss';
import AppActions from '../../../actions/AppActions';
import UserStore from '../../../stores/UserStore';
import UserActions from '../../../actions/UserActions';
import withLoggedInUser from '../../../decorators/withLoggedInUser';
import Link from '../../Link';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Griddle from 'griddle-react';
import UserPageConfig from './UserPageConfig';
import EditUserPage from './EditUserPage';
import CreateUserPage from './CreateUserPage';
import BulkUploadUsersPage from './BulkUploadUsersPage';
import CloseITG from './CloseITG';
import { CloseITGCases } from './CloseITG/Handlers';

const title = 'Inter-cept | Permissions | Users';

class UsersPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    currentUserData: {},
    userList: [],
    openEditor: null,
    selectedUser: '',
    actionType: '',
    currentlyLoggedInUser: this.props.currentUser,
    canSubmit: null,
  };

  UNSAFE_componentWillMount() {
    AppActions.addSpinner();
    this.context.onSetTitle(title);
    this.setState({
      actionType: 'edit',
      openEditor: false,
      canSubmit: true,
    });
    UserStore.addChangeListener(this.updateState);
    this.updateState();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    // Declared these to tidy code up a bit
    const currentInsurerId = this.state.currentlyLoggedInUser.InsurerId;
    const currentDepartmentId = this.state.currentlyLoggedInUser.DepartmentId;
    const isAdmin = this.state.currentlyLoggedInUser.isAdmin;
    const isUsersCompanyManager =
      this.state.currentlyLoggedInUser.isUsersCompanyManager;
    const isUsersDepartmentManager =
      this.state.currentlyLoggedInUser.isUsersDepartmentManager;

    if (isAdmin) {
      UserStore.getAllUsersData((data) => {
        this.setStateUserListCallback(data);
      });
    } else if (
      isUsersCompanyManager ||
      (isUsersDepartmentManager && !currentDepartmentId)
    ) {
      UserStore.getAllUsersDataByInsurer(currentInsurerId, (data) => {
        const filteredData = this.removeInterestUsers(data);
        this.setStateUserListCallback(filteredData);
      });
    } else if (isUsersDepartmentManager && currentDepartmentId) {
      UserStore.getAllUsersDataByInsurerAndDepartment(
        currentInsurerId,
        currentDepartmentId,
        (data) => {
          const filteredData = this.removeInterestUsers(data);
          this.setStateUserListCallback(filteredData);
        }
      );
    } else {
      Location.pushState('/');
    }
  };

  setStateUserListCallback = (userData) => {
    this.setState(
      {
        currentUserData: UserStore.getCurrentUser(),
        userList: userData,
      },
      () => {
        AppActions.removeSpinner();
      }
    );
  };

  newUserHandler = () => {
    AppActions.addSpinner();
    this.setState({
      openEditor: true,
      actionType: 'create',
      selectedUser: '',
    });
  };

  editHandler = (e, v) => {
    AppActions.addSpinner();
    this.setState({
      openEditor: true,
      actionType: 'edit',
      selectedUser: e,
    });
  };

  bulkUploadHandler = () => {
    AppActions.addSpinner();
    this.setState({
      openEditor: true,
      actionType: 'bulkUpload',
    });
  };

  onConfirmCancel = () => {
    this.setState({
      openEditor: false,
    });
    UserActions.setCurrentUser({}); //  UserActions.updateUserData({});
  };

  onConfirmEdit = () => {
    AppActions.addSpinner();
    const user = UserStore.getCurrentUser();
    if (user) {
      if (!UserStore.getUserLockStateFromRepo()) {
        UserActions.updateUserData({
          user: user,
          callback: (response) => {
            const userName = user && user.UserName ? user.UserName : '';
            const outcome =
              response && response === true
                ? userName + ' was successfully updated'
                : userName + ' was not updated';
            this.setState({ openEditor: false }, () => {
              AppActions.removeSpinner();
              AppActions.createModal({
                open: true,
                title: 'Edit User',
                type: 'alert',
                content: (
                  <div>
                    <p>{outcome}</p>
                  </div>
                ),
              });
            });
          },
        });
      } else {
        AppActions.removeSpinner();
      }
    } else {
      AppActions.removeSpinner();
      UserActions.ShowInvalidUserDialog();
    }
  };

  onConfirmCreate = (e) => {
    AppActions.addSpinner();
    const user = UserStore.getNewUser();
    if (user) {
      UserActions.createNewUser({
        user: user,
        callback: (response) => {
          const userName = user && user.UserName ? user.UserName : '';
          const outcome =
            response & (response === true)
              ? userName + ' was successfully created'
              : userName + ' was not created';
          UserActions.setNewUserDetails(null);
          this.setState({ openEditor: false }, () => {
            AppActions.removeSpinner();
            AppActions.createModal({
              open: true,
              title: 'Create User',
              type: 'alert',
              content: (
                <div>
                  <p>{outcome}</p>
                </div>
              ),
            });
          });
        },
      });
    } else {
      AppActions.removeSpinner();
      UserActions.ShowInvalidUserDialog();
    }
  };

  onConfirmBulkUpload = () => {
    AppActions.addSpinner();

    this.setState({ openEditor: false }, () => {
      AppActions.removeSpinner();

      // Commented this section out as it is not needed at the moment but it would be good for the modal to say 'n users created' as better feedback (MVS)
      //AppActions.createModal({
      //  open: true,
      //  title: "Create User",
      //  type: "alert",
      //  content: <div><p>Your users have been created successfully</p></div>
      //});
    });

    AppActions.removeSpinner();
  };

  deleteHandler = (e, userToDelete) => {
    const userName = userToDelete
      ? userToDelete
      : 'Unable to retrieve user name';
    AppActions.createModal({
      open: true,
      title: 'Delete User',
      type: 'confirm',
      content: (
        <div>
          <p>{userName} will be permanently deleted.</p>
          <p>Are you sure you want to do this?</p>
        </div>
      ),
      onConfirm: () => {
        AppActions.addSpinner();
        UserActions.deleteUserDetails({
          userid: e,
          callback: (response) => {
            const outcome =
              response & (response === true)
                ? userName + ' was successfully deleted'
                : userName + ' was not deleted'; //TODO: Error handling?
            AppActions.removeSpinner();
            AppActions.createModal({
              open: true,
              title: 'Delete User',
              type: 'alert',
              content: (
                <div>
                  <p>{outcome}</p>
                </div>
              ),
            });
          },
        });
      },
    });
  };

  resetHandler = (e, userToEmail) => {
    const apostrophe = "'";

    AppActions.createModal({
      open: true,
      title: 'Reset User Email',
      type: 'confirm',
      content: (
        <div>
          <p>
            {userToEmail}
            {apostrophe}s password will be reset
          </p>
          <p>Are you sure you want to do this?</p>
        </div>
      ),
      onConfirm: () => {
        AppActions.addSpinner();
        UserActions.resetUserPassword({
          userid: e,
          callback: (response) => {
            const outcome =
              response & (response === true)
                ? userToEmail + ' has been sent a password reset email'
                : 'Unable to send a password reset email to ' + userToEmail; //TODO: Error handling?
            AppActions.removeSpinner();
            AppActions.createModal({
              open: true,
              title: 'Reset User Email',
              type: 'alert',
              content: (
                <div>
                  <p>{outcome}</p>
                </div>
              ),
            });
          },
        });
      },
    });
  };

  removeInterestUsers = (users) => {
    return users.filter((userToCheck) => {
      if (userToCheck.Email.toLowerCase().indexOf('@inter-est.net') > -1) {
        return false;
      }
      return true;
    });
  };

  render() {
    const user = this.state.currentlyLoggedInUser;
    const columnMetadata = UserPageConfig.columnMetadata;
    const ignoreColumns = UserPageConfig.ignoreColumns;
    const editTitle = UserStore.getUserLockStateFromRepo()
      ? 'User Details (locked for edit)'
      : 'Edit User Details';

    columnMetadata.forEach((item) => {
      if (item.displayName === 'Actions') {
        item.editHandler = this.editHandler;
        item.deleteHandler = this.deleteHandler;
        item.resetHandler = this.resetHandler;
      }
    });

    const submitActions = {
      edit: {
        title: editTitle,
        component: (
          <EditUserPage
            userId={this.state.selectedUser}
            currentlyLoggedInUser={this.state.currentlyLoggedInUser}
          />
        ),
        handler: this.onConfirmEdit,
      },
      create: {
        title: 'Create new User',
        component: (
          <CreateUserPage
            currentlyLoggedInUser={this.state.currentlyLoggedInUser}
          />
        ),
        handler: this.onConfirmCreate,
      },
      bulkUpload: {
        title: 'Bulk Upload Users',
        component: (
          <BulkUploadUsersPage
            currentlyLoggedInUser={this.state.currentlyLoggedInUser}
          />
        ),
        handler: this.onConfirmBulkUpload,
      },
    };

    return (
      <div className={s.box}>
        <ul className={s.backLinks}>
          <li>
            <a href="/dashboard" onClick={Link.handleClick}>
              Back to Dashboard
            </a>
          </li>
        </ul>
        <h1 className={s.heading}>Users</h1>
        <div className={s.grid}>
          <Griddle
            results={this.state.userList}
            useFixedHeader={true}
            resultsPerPage={10}
            columnMetadata={columnMetadata}
            tableClassName={s.table}
            showFilter={true}
            enableInfiniteScroll={false}
            metadataColumns={ignoreColumns}
          />
        </div>

        <a
          className={s.addNew}
          href="/adduser"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span className={s.btn} onClick={this.newUserHandler.bind(this)}>
            Create new user
          </span>
        </a>
        <a
          className={s.addNew}
          href="/bulkuploadusers"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span className={s.btn} onClick={this.bulkUploadHandler.bind(this)}>
            Bulk upload
          </span>
        </a>
        <Dialog
          title={submitActions[this.state.actionType].title || ''}
          className={s.editModal}
          contentStyle={{ width: '98%', maxWidth: '1340px' }}
          open={this.state.openEditor}
          actions={[
            <RaisedButton
              key={1}
              title="Click to cancel"
              style={{ marginRight: 10 }}
              label="cancel"
              primary={true}
              onClick={this.onConfirmCancel}
            />,
            <RaisedButton
              key={2}
              title="Click to confirm"
              label="confirm"
              primary={true}
              disabled={!this.state.canSubmit}
              onClick={
                submitActions[this.state.actionType].handler || function () {}
              }
            />,
          ]}
          autoScrollBodyContent={true}
          autoDetectWindowHeight={true}
          repositionOnUpdate={true}
        >
          {submitActions[this.state.actionType].component || null}
        </Dialog>
        {user.isAdmin ? (
          <CloseITG styles={s} closeITGCases={CloseITGCases} />
        ) : null}
      </div>
    );
  }
}

const composed = withLoggedInUser(UsersPage);

export default composed;
