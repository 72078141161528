import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class TailgatePickup extends Component {
  render() {
    return (
      <g className={s.tailGate}>
        <path
          {...this.props}
          d="M1083.1,651.9h-47c-4.4,0-8-3.6-8-8v-372c0-4.4,3.6-8,8-8h47c4.4,0,8,3.6,8,8v372C1091.1,648.3,1087.5,651.9,1083.1,651.9z"
        />
        <path
          {...this.props}
          d="M1083.1,264.9L1083.1,264.9l-0.1,34.6h-46.9v-34.6c-4.4,0-8,3.6-8,8v372c0,4.4,3.6,8,8,8v-34.6h46.9v34.6h0.1  c4.4,0,8-3.6,8-8v-372C1091.1,268.5,1087.5,264.9,1083.1,264.9z"
        />
      </g>
    );
  }
}

let composed = TailgatePickup;
composed = SectionComponent({
  name: 'Tailgate',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Tailgate Damage',
    content: <Modal name="Tailgate" />,
  },
})(composed);

export default composed;
