import { Component } from 'react';
import s from './RoofModal.module.scss';

import AirbagsFrontQuestions from '../../QuestionSets/AirbagsFrontQuestions/';
import AirbagsSideQuestions from '../../QuestionSets/AirbagsSideQuestions/';
import DoorsQuestions from '../../QuestionSets/DoorsQuestions/';
import LampsRearQuestions from '../../QuestionSets/LampsRearQuestions/';
import PanelQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'airbagsfront', component: <AirbagsFrontQuestions /> },
  { stage: 2, section: 'airbagsside', component: <AirbagsSideQuestions /> },
  { stage: 3, section: 'doors', component: <DoorsQuestions /> },
  { stage: 4, section: 'lampsrear', component: <LampsRearQuestions /> },
  { stage: 5, section: 'panelStandard', component: <PanelQuestions /> },
];

const SUBTITLE = 'Roof Questions';

class RoofModal extends Component {
  render() {
    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
          </div>
        </div>
      </div>
    );
  }
}

const composed = ModalComponent(QUESTION_SETS, SUBTITLE)(RoofModal);

export default composed;
