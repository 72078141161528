import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftConvertible extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M999.7,789.8c-6.4-6.6-6.4-9.2-3-43.1c1.7-19.7,3.4-32,3.9-34.5c1.7-8.9-3.6-6.6-40.9-6.6h-29.5c-0.8,0-12.1-10.3-12.6-10.8  l-28.1-27.1c-31.8-30.8-43.3-38.2-62.8-40.4c-6.6-0.7-23.4-3-36.7-4.7c-25.4-3.6-75.9-7.7-75.9-7.7c0,1-4.2,28.6-7.9,52.5  c-6.2,39.2-7.7,53.5-11.6,118.8l-5.4,117H789c1.7,0,3-1.3,3.3-3l-0.2-29.1c1-37.4,4.7-45.6,11.6-56.6c19.5-32,61.9-39.4,87-21.2  c18.2,13.3,31.3,30.8,31.8,56.9l-1,18c0.2,1.7,1.5,3,3.3,3l27.1-0.5c37.4-1,45.1-2.8,45.1-10.6c0-3.4,2.5-8.1,4.9-10.3  c3.6-3,4.9-11.1,4.9-28.1C1006.4,801.6,1005.1,795.9,999.7,789.8z M707,720.4c1.5-15.6,10.1-84.3,10.1-84.3  c66.3,2,71.7,24.4,76.1,33.3c0.2,0.5,5.9,13.6,7.7,19.2c4.2,13.1,7.9,30.3,7.9,30.3L707,720.4L707,720.4z"
        />
        <path
          {...this.props}
          d="M999.7,789.8c-6.4-6.6-6.4-9.2-3-43.1c1.7-19.7,3.4-32,3.9-34.5c1.7-8.9-3.6-6.6-40.9-6.6h-29.5c-0.8,0-12.1-10.3-12.6-10.8  l-28.1-27.1c-31.8-30.8-43.3-38.2-62.8-40.4c-6.6-0.7-23.4-3-36.7-4.7c-25.4-3.6-75.9-7.7-75.9-7.7c0,1-4.2,28.6-7.9,52.5  c-6.2,39.2-7.7,53.5-11.6,118.8l-5.4,117H789c1.7,0,3-1.3,3.3-3l-0.2-29.1c1-37.4,4.7-45.6,11.6-56.6c19.5-32,61.9-39.4,87-21.2  c18.2,13.3,31.3,30.8,31.8,56.9l-1,18c0.2,1.7,1.5,3,3.3,3l27.1-0.5c37.4-1,45.1-2.8,45.1-10.6c0-3.4,2.5-8.1,4.9-10.3  c3.6-3,4.9-11.1,4.9-28.1C1006.4,801.6,1005.1,795.9,999.7,789.8z M707,720.4c1.5-15.6,10.1-84.3,10.1-84.3  c66.3,2,71.7,24.4,76.1,33.3c0.2,0.5,5.9,13.6,7.7,19.2c4.2,13.1,7.9,30.3,7.9,30.3L707,720.4L707,720.4z M958,729.4  c0-3.3,2.5-5.7,5.4-5.4c6.6,0.2,18,0.2,27.6,0c0,0-2,9.4-5.1,37.9h-26.1c-1.7,0-1.7-0.7-1.7-0.7L958,729.4z M887.3,697.4  c-5.9,0-18-14.3-32.3-28.6c-20-19-22-24.9-14.1-24.6c20.2,0.7,20,4.4,55.9,39.4c2,2,13.8,13.8,11.1,13.8H887.3z M990,793h-85.3  c-1.7,0-3.4-0.7-4.7-2c-2-2-13.8-5.9,2.2-5.4h85.3c1.7,0,3.4,0.7,4.7,2C994.3,789.5,995.9,793.3,990,793z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftConvertible;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
