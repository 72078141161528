import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftEstate extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M998.3,789.7c-1.5-2.6-4.5-6.7-5.5-8c-0.2-0.3-0.3-0.6-0.3-1c0.2-5.1,0.2-45.2,0.1-62.3c0-0.2,0-0.3-0.1-0.5   c-1.8-5.1-30.4-84.3-93.3-93.6h-0.1c-3.5-0.2-145.1-10-184.6-8.9c-0.8,0-1.4,0.6-1.5,1.3l-2.8,13.6c-0.2,1,0.5,1.9,1.5,1.9   c19.9,0.3,186.3,3.6,202,15c27,16.3,49.2,55.3,50.3,57.2c0,0.1,0.1,0.1,0.1,0.1c0.7,1,10.1,13.9,1.6,16.4c-3,0.9-13.6,0.6-16.7,0.7   c-0.1,0-0.2,0-0.2,0l-98.3-0.1c-0.8,0-1.5,0.6-1.6,1.4c-0.9,8.6-7.5,44.7-15.6,62.8c-0.6,1.3,0.7,2.6,2,2.1l0,0   c18.6-7.1,39.4-5.4,54.3,5.5c18.1,13.3,32.5,29.8,32.5,55.8l-0.4,8.4l-0.5,11.3c0.2,1.7,1.7,2.3,2.3,2.3l27.1-0.5   c23.6-0.6,37.1-1.8,43.8-2.6c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1005.2,802.2,998.8,790.4,998.3,789.7   C998.3,789.8,998.3,789.8,998.3,789.7z"
        />
        <path
          {...this.props}
          d="M998.3,789.7c-1.5-2.6-4.5-6.7-5.5-8c-0.2-0.3-0.3-0.6-0.3-1c0.2-5.1,0.2-45.2,0.1-62.3c0-0.2,0-0.3-0.1-0.5   c-1.8-5.1-30.4-84.3-93.3-93.6h-0.1c-3.5-0.2-145.1-10-184.6-8.9c-0.8,0-1.4,0.6-1.5,1.3l-2.8,13.6c-0.2,1,0.5,1.9,1.5,1.9   c19.9,0.3,186.3,3.6,202,15c27,16.3,49.2,55.3,50.3,57.2c0,0.1,0.1,0.1,0.1,0.1c0.7,1,10.1,13.9,1.6,16.4c-3,0.9-13.6,0.6-16.7,0.7   c-0.1,0-0.2,0-0.2,0l-98.3-0.1c-0.8,0-1.5,0.6-1.6,1.4c-0.9,8.6-7.5,44.7-15.6,62.8c-0.6,1.3,0.7,2.6,2,2.1l0,0   c18.6-7.1,39.4-5.4,54.3,5.5c18.1,13.3,32.5,29.8,32.5,55.8l-0.4,8.4l-0.5,11.3c0.2,1.7,1.7,2.3,2.3,2.3l27.1-0.5   c23.6-0.6,37.1-1.8,43.8-2.6c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1005.2,802.2,998.8,790.4,998.3,789.7   C998.3,789.8,998.3,789.8,998.3,789.7z M959.5,733.9c0-2,2.5-2.9,4.4-2.9h22c2,0,2.6,1,2.6,2.9v28.9c0,2-0.7,4.2-2.6,4.2h-22   c-2,0-4.4-2.3-4.4-4.2V733.9z M991.7,801h-85.1c-1.7,0-3.4-1-4.7-2.3c-2-2-13.8-5.7,2.2-5.7h86c1.7,0,2.4,1,3.7,2.3   C995.9,797.2,997.5,801,991.7,801z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftEstate;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
