import { Component } from 'react';
import s from './SettingsPage.module.scss';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import Link from '../Link';
import RaisedButton from 'material-ui/RaisedButton';
import { List } from 'material-ui/List';
import AppActions from '../../actions/AppActions';
import AppStore from '../../stores/AppStore';
import ProfileForm from './ProfileForm';
import ChangePassword from './ChangePassword';
import Drawer from 'material-ui/Drawer';

class SettingsPage extends Component {
  state = {
    setDisabled: true,
    username: null,
    password: null,
    avatar: null,
    selectedIndex: null,
    oldPassword: null,
    newPassword: null,
    openDrawer: null,
  };

  UNSAFE_componentWillMount() {
    const user = AppStore.userData().User;
    this.setState({
      username: user.UserName,
      openDrawer: false,
      selectedIndex: 0,
    });
  }

  setDisabled = (v, oldPassword, newPassword) => {
    //console.log("setting disabled:", v);
    AppActions.updateModal({ disableConfirm: !v });
    this.setState({
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  };

  toggleInfoHandler = (e) => {
    e.preventDefault();
    this.setState({
      openDrawer: this.state.openDrawer ? false : true,
    });
  };

  passwordChangeHandler = () => {
    AppActions.createModal({
      open: true,
      title: 'Change Password',
      type: 'confirm',
      content: (
        <ChangePassword
          setDisabled={this.setDisabled}
          openDrawer={this.toggleInfoHandler}
        />
      ),
      disableConfirm: this.state.setDisabled,
      onConfirm: () => {
        AppActions.changePassword({
          username: this.state.username,
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
          callback: (response) => {
            if (response.data === true) {
              AppActions.createModal({
                open: true,
                title: 'Your Password has been changed',
                type: 'alert',
                content: (
                  <div>
                    <p>Your Password has been successfully changed</p>
                  </div>
                ),
              });
            } else {
              AppActions.createModal({
                open: true,
                title: 'There was a problem changing your password',
                type: 'alert',
                content: (
                  <div>
                    <p>
                      Please ensure your old password is correct, and that your
                      password is strong.
                    </p>
                  </div>
                ),
              });
            }
          },
        });
      },
    });
  };

  handleReminderRequest = (e) => {
    e.preventDefault();

    AppActions.createModal({
      open: true,
      title: 'Reset User Password',
      type: 'confirm',
      content: (
        <div>
          <p>
            Your password will be reset. An email containing a reset link will
            be sent to you.{' '}
          </p>
          <p>Are you sure you want to do this?</p>
        </div>
      ),
      onConfirm: () => {
        AppActions.resetPassword({
          userName: this.state.username,
          callback: (response) => {
            response.data === true
              ? this.handleConfirmationPopup('A Reset Email has been sent.')
              : this.handleConfirmationPopup(
                  'An Error occurred: please contact an Administrator.'
                );
          },
        });
      },
    });
  };

  handleConfirmationPopup = (msg) => {
    AppActions.createModal({
      open: true,
      type: 'alert',
      content: (
        <div>
          <p>{msg}</p>
        </div>
      ),
    });
  };

  setSelectionIndex = (i) => {
    this.setState({
      selectedIndex: i,
    });
  };

  render() {
    const { selectedIndex } = this.state;
    const content =
      selectedIndex === 0 ? (
        <ProfileForm
          username={this.state.username}
          passwordChangeHandler={this.passwordChangeHandler}
          handleReminderRequest={this.handleReminderRequest}
        />
      ) : null;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.box}>
            <ul className={s.backLinks}>
              <li>
                <a href="/dashboard" onClick={Link.handleClick}>
                  Back to Dashboard
                </a>
              </li>
            </ul>
            <h1>
              <SettingsIcon className={s.icon} /> Settings
            </h1>
            <div className={s.contract}>
              <List className={s.settingsList}>
                <RaisedButton
                  label="Profile"
                  primary={selectedIndex === 0}
                  fullWidth={true}
                  onClick={this.setSelectionIndex.bind(this, 0)}
                />
              </List>
            </div>
            <div className={s.expand}>{content}</div>
          </div>
        </div>
        <Drawer
          width={300}
          className={s.customDrawer}
          openSecondary={true}
          open={this.state.openDrawer}
        >
          <h2>About Inter-cept Passwords</h2>
          <p>
            To ensure you have the best security, Inter-cept only accepts
            passwords that are difficult for a computer to crack.
          </p>
          <p>
            We use the{' '}
            <a
              href="https://github.com/dropbox/zxcvbn"
              target="_blank"
              rel="noreferrer"
            >
              zxcvbn
            </a>{' '}
            password check algorithm to test new passwords. See{' '}
            <a
              href="https://blogs.dropbox.com/tech/2012/04/zxcvbn-realistic-password-strength-estimation/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            for more information.
          </p>
          <p>
            We recommend a password consisting of 3-5 short words, with an added
            random number sequence.
          </p>
          <p>
            Special characters, capitals and punctuation will all make the
            password stronger, but are not required.
          </p>
          <p>The password must be at least 12 characters.</p>
          <p>
            <a href="" onClick={this.toggleInfoHandler}>
              Close
            </a>
          </p>
        </Drawer>
      </div>
    );
  }
}

export default SettingsPage;
