import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontLeftPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M408,776.6c0.4,22.2,3.2,55.3,3.2,55.3h121.7l-0.7-140H416.4C416.4,691.9,406.9,707.1,408,776.6z"
      />
    );
  }
}

let composed = DoorFrontLeftPV;
composed = SectionComponent({
  name: 'Front Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Passenger Door Damage',
    content: <Modal name="Front Passenger Door" />,
  },
})(composed);

export default composed;
