import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeft4x4 extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M831,777v-2h124v-51.1c-1-15.2-3.3-19.4-5.9-24.7c-5.3-10.7-52-68.6-70.2-83.7c-2.5-2.1-11.4-7-14.6-7.1  c-30.3-0.5-168-0.1-184.5-0.1c-0.8,0-1.5,0.6-1.8,1.4c-2.5,9.8-16.9,71.9-20,217.7c0,1,0.8,1.9,1.9,1.9l30-0.1  c9.5-37.2,38.6-70.3,76.6-70.7c52.1-0.6,79.5,38.8,84.8,81.8c22.7-1.5,62.9-4.2,62.9-4.2S948,833.6,955,788v-11H831z M932,720.7  l7.9-1.9c2.8-0.8,9.7-2.8,11.4-5.3c1.1-1.6,1.6,13,1.6,13.3c0,18.3,0,24.5,0,26.5c0,0.7-0.3,1.5-0.8,2c-0.3,0.3-0.7,0.7-0.7,0.7H932  c-0.1,0,0-0.3,0-0.4v-34.7C932,720.8,931.9,720.7,932,720.7z M753,698.5l-72.8,0.6c-1.2,0-3.4-0.2-3.2-1.9  c3.2-44.9,9.4-75.9,9.4-75.9c0.1-0.5,0.5-1.3,1.1-1.3h33.1c8.1,0,47.6,47,52.9,54.2C778.9,681.6,760.9,698.5,753,698.5z   M767.2,624.1c-3.9-4.1,3.8-3.9,5.2-3.9l75.3-0.2c5.8,0,57.6,57.4,66.5,69.6c0.8,1.1,0,2.4-1.4,2.4h-75.6  C828.3,692,767.2,624.1,767.2,624.1z"
        />
        <path
          {...this.props}
          d="M955,724.4c-1-15.2-3.3-19.4-5.9-24.7c-5.3-10.7-52-68.6-70.2-83.7c-2.5-2.1-11.4-7-14.6-7.1c-30.3-0.5-168-0.1-184.5-0.1  c-0.8,0-1.5,0.6-1.8,1.4c-2.5,9.8-16.9,71.9-20,217.7c0,1,0.8,1.9,1.9,1.9l42.9-0.1c0.8,0,1.5-0.5,1.7-1.2  c10.9-29.7,30.5-41.5,48.1-45.7c0,0,0.1,0,0.1,0c38.2-10.9,76.2,18.1,83.3,57.5c0.2,0.9,1,1.6,1.9,1.5c11.9-0.8,76.2-5.1,76.2-5.1  s33.8-2.5,40.8-48.1V724.4z M753,699l-72.8,0.6c-1.2,0-3.4-0.2-3.2-1.9c3.2-44.9,9.4-75.6,9.4-75.6c0.1-0.5,0.5-1.1,1.1-1.1h33.1  c8.1,0,47.6,46.8,52.9,54C778.9,682.4,760.9,699,753,699z M912.7,693h-75.6c-8.9,0-70-68.2-70-68.2c-3.9-4.1,3.8-4,5.2-4l75.3-0.2  c5.8,0,57.6,57.6,66.5,69.8C915,691.5,914.2,693,912.7,693z"
        />
      </g>
    );
  }
}

let composed = WingBackLeft4x4;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
