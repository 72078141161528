const TotalCost = (props) => {
  const s = props.styles;
  const currentCase = props.currentCase;
  const totalCost =
    currentCase && currentCase.decision
      ? currentCase.decision.TotalCost || 0
      : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Total Cost: </span>
      <strong className={s.cols_6}>£{parseFloat(totalCost).toFixed(2)}</strong>
    </li>
  );
};

export default TotalCost;
