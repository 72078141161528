import { Component } from 'react';
import s from './DecisionPrompt.module.scss';

import TextField from 'material-ui/TextField';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TriageStore from '../../../../stores/TriageStore';
import { validateReferenceNumber } from '../../../../lib/validateReferenceNumber';

const objectExists = (obj) => {
  return obj !== undefined && obj !== null;
};

class DecisionPrompt extends Component {
  state = {
    isSettleQuestionRequired: null,
    isRefQuestionRequired: null,
    isRefNoValid: null,
    regex: '^[a-z0-9]{7,24}$',
    referenceNumber: null,
    settlementAnswer: null,
  };

  UNSAFE_componentWillMount() {
    const { currentCase } = this.props.state;
    const isTotalLoss = TriageStore.getDecision().Direction === 5;
    const showSettle = currentCase.caseSettings.ShowSettlementPrompt;
    this.setState(
      {
        isSettleQuestionRequired: isTotalLoss && showSettle,
        isRefQuestionRequired:
          currentCase.caseSettings.IsClaimReferenceMandatory,
        regex: currentCase.caseSettings.ClaimReferenceNumberRegex,
        referenceNumber: objectExists(currentCase.metaData.ClaimReferenceNumber)
          ? currentCase.metaData.ClaimReferenceNumber.trim()
          : '',
        settlementAnswer: currentCase.settlementAnswer || null,
      },
      () => {
        this.setState({
          isRefNoValid: this.validateReferenceNumber(),
        });

        this.submitAnswerModel();
      }
    );
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);

    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    const { currentCase } = this.props.state;
    const isTotalLoss = TriageStore.getDecision().Direction === 5;
    const showSettle = currentCase.caseSettings.ShowSettlementPrompt;
    this.setState(
      {
        isSettleQuestionRequired: isTotalLoss && showSettle,
      },
      () => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      }
    );
  };

  validateReferenceNumber = () => {
    return validateReferenceNumber({
      regex: this.state.regex,
      referenceNumber: this.state.referenceNumber,
    });
  };

  handleSettlementAnswer = (e) => {
    e.preventDefault();
    this.state.settlementAnswer = e.target.value;
    this.submitAnswerModel();
  };

  handleTextChange = (e) => {
    const refNo = e.target.value.replace(/ /g, '').trim();
    this.state.referenceNumber = refNo;
    this.setState({
      isRefNoValid: this.validateReferenceNumber(),
    });
    this.submitAnswerModel();
  };

  submitAnswerModel = () => {
    const refValid =
      !this.state.isRefQuestionRequired ||
      (this.state.referenceNumber !== undefined &&
        this.validateReferenceNumber());

    const settleValid =
      !this.state.isSettleQuestionRequired ||
      (this.state.settlementAnswer !== undefined &&
        this.state.settlementAnswer !== null);

    if (this.props.onChange)
      this.props.onChange(refValid && settleValid, this.state);
  };

  render() {
    const referenceNumberLabel = this.state.isRefQuestionRequired
      ? 'claim reference number (required)'
      : 'claim reference number (optional)';

    const refQuestion = (
      <form className={s.form}>
        <h3>Claim Reference Number</h3>
        <TextField
          className={s.textField}
          floatingLabelText={referenceNumberLabel}
          multiLine={true}
          errorText={
            !this.state.isRefNoValid && this.state.isRefQuestionRequired
              ? 'invalid Claim Reference Number'
              : null
          }
          value={this.state.referenceNumber}
          onChange={this.handleTextChange}
          style={{ textTransform: 'capitalize' }}
        />
      </form>
    );

    const { currentCase } = this.props.state;
    const repairCostText =
      currentCase &&
      currentCase.vehicleDetails &&
      currentCase.vehicleDetails.Retail
        ? '(Typical Retail Value: £' +
          parseInt(currentCase.vehicleDetails.Retail).toLocaleString() +
          ')'
        : null;

    const settlementQuestion = this.state.isSettleQuestionRequired ? (
      <form onChange={this.handleSettlementAnswer} className={s.form}>
        <h3>Settlement {repairCostText}</h3>
        <fieldset className={s.row}>
          <legend>
            Is the customer prepared to accept the
            <span style={{ color: 'white', fontWeight: 'bold' }}>
              {' '}
              Total Loss
            </span>{' '}
            settlement? (required)
          </legend>
          <RadioButtonGroup
            defaultSelected={this.state.settlementAnswer}
            className={s.radios}
            labelPosition="left"
            name="settlement"
          >
            <RadioButton value="1" label="Yes" />
            <RadioButton value="0" label="No" />
            <RadioButton value="2" label="Not offered" />
          </RadioButtonGroup>
        </fieldset>
      </form>
    ) : null;
    return (
      <div className={s.root}>
        {refQuestion}
        {settlementQuestion}
      </div>
    );
  }
}

let composed = DecisionPrompt;

export default composed;
