import RaisedButton from 'material-ui/RaisedButton';

const SettlementAnswer = (props) => {
  const s = props.styles;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Settlement Answer: </span>
      <strong className={s.cols_6}>
        <span>
          {props.currentCase.settlementAnswer &&
          props.currentCase.settlementAnswer !== null
            ? { 2: 'Not offered', 0: 'No', 1: 'Yes' }[
                props.currentCase.settlementAnswer
              ]
            : '- - - - - - - '}
        </span>
        {props.onPromptHandler ? (
          <RaisedButton
            className={'summaryBtn'}
            label="Edit"
            primary={true}
            onClick={props.onPromptHandler}
          />
        ) : null}
      </strong>
    </li>
  );
};

export default SettlementAnswer;
