import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M430.1,576.6c-16.4-43.1-16.4-80.2-16.4-119.2c0-40.3,4.8-85.9,16.4-118.7c0-0.3,0-1.7,1.2-2.8  c0.7-0.7,1.7-1.2,2.8-1.2h475.6c3.2,41.3,3.2,81.9,3.2,123c0,40.9-0.2,82.3-3.2,123c0,0-317.1,0-475.6,0  C431.9,580.6,430.1,578.8,430.1,576.6z"
      />
    );
  }
}

let composed = RoofPV;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
