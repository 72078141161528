import React from 'react';
import dateFormat from 'dateformat';
import CarHireCost from './ListItems/CarHireCost';
import CarHireVAT from './ListItems/CarHireVAT';
import CarHireCostTotal from './ListItems/CarHireCostTotal';
import TotalCost from './ListItems/TotalCost';
import ClaimReference from './ListItems/ClaimReference';
import DamageDescription from './ListItems/DamageDescription';
import MobileConfirmed from './ListItems/MobileConfirmed';
import RepairCost from './ListItems/RepairCost';
import SettlementAnswer from './ListItems/SettlementAnswer';
import SummaryNote from './ListItems/SummaryNote';
import Valuation from './ListItems/Valuation';
import VariantSelection from './ListItems/VariantSelection';
import AcceptCashInLieu from './ListItems/AcceptCashInLieu';
import PAVPercentage from './ListItems/PAVPercentage';
import ReserveValue from './ListItems/ReserveValue';
import SalvageCategory from './ListItems/SalvageCategory';
import PAV from './ListItems/PAV';
import AtFaultStatus from './ListItems/AtFaultStatus';
import Postcode from './ListItems/Postcode';
import SelectedRepairLocation from './ListItems/SelectedRepairLocation';
import RepairDuration from './ListItems/RepairDuration';

const SummaryList = (props) => {
  /* NB:  The styles for any buttons in this component's child components are hard-coded
          and do not use the atomic CSS approach used elsewhere in the app
          (i.e. they use "summaryBtn" rather than "s.summaryBtn").
          This is to allow the PDF generation API call to pass in a string literal (from PDFStyles.js)
          of css classes and hide any buttons that are present on the summary page in the generated PDF.
  */
  const s = props.styles;
  const {
    currentCase,
    businessLineName,
    vehicleDetails,
    decision,
    userData,
    onPromptHandler,
    displaySettleAnswer,
    claimReferenceNumber,
    cashInLieuChangeHandler,
    variantChangeHandler,
    onMobileHandler,
    carHireResetHandler,
  } = props;
  const body =
    currentCase.splat && currentCase.splat !== ''
      ? currentCase.splat
      : 'user selected: ' + currentCase.userSelectedVehicle;
  const metaData = currentCase.metaData
    ? currentCase.metaData
    : {
        CreatedBy: null,
        CreatedOn: null,
        Organisation: null,
      };

  const isCashInLieuDecision =
    decision.CashInLieuDetails &&
    decision.CashInLieuDetails.CashInLieuAccepted === false &&
    decision.CashInLieuDetails.CashInLieuOffered === true;
  const organisationName =
    businessLineName === ''
      ? metaData.Organisation
      : `${metaData.Organisation} (${businessLineName})`;
  const showReserveValue =
    decision &&
    currentCase.caseSettings &&
    currentCase.caseSettings.ShowReserveValue &&
    decision.ShowReserveValue &&
    decision.ReserveValue != 0;
  const showRepairCost = currentCase.caseSettings
    ? currentCase.caseSettings.ShowRepairCost
    : false;
  const showDamageDescription = currentCase.caseSettings
    ? currentCase.caseSettings.ShowDamageDescription
    : false;
  const showValuation = currentCase.caseSettings
    ? currentCase.caseSettings.ShowValuation
    : false;
  const showCarHireCost =
    decision &&
    showRepairCost &&
    (isCashInLieuDecision || decision.CarHireCost * 1 > 0);
  const showCarHireVAT =
    decision && showRepairCost && decision.CarHireCostVat * 1 > 0;
  const showSummaryNote =
    decision && decision.Warning && decision.Warning !== '';
  const selectedVariant =
    currentCase.vehicleDetails === null
      ? null
      : currentCase.vehicleDetails.Variation || null;
  const showCashInLieuAnswer =
    decision && decision.CashInLieuDetails && decision.ShowCarHire;
  const showMobileConfirmed =
    decision &&
    decision.OfferMobileRepairChoice &&
    currentCase.mobileConfirmed !== null;
  const showPAVPercentage =
    decision &&
    decision.PercentageWriteOff &&
    currentCase.caseSettings &&
    currentCase.caseSettings.ShowPercentageWriteOff;
  const showPAV =
    decision &&
    decision.ShowPAV &&
    currentCase.caseSettings &&
    currentCase.caseSettings.ShowPAV;

  const directionText = decision ? decision.DirectionText || '' : '';
  const showSalvageCategories =
    currentCase.caseSettings &&
    currentCase.caseSettings.ShowTotalLossCategory &&
    directionText.toLowerCase() === 'total loss';
  const showAtFaultStatus =
    currentCase &&
    currentCase.caseSettings &&
    currentCase.caseSettings.AllowAtFaultStatusCapture &&
    currentCase.decision;
  const showPostcode =
    currentCase &&
    currentCase.caseSettings &&
    currentCase.caseSettings.AllowPostcodeCapture &&
    currentCase.decision;
  const showRepairLocation =
    currentCase &&
    currentCase.decision &&
    currentCase.decision.SelectedRepairer;
  const showRepairDuration = currentCase.caseSettings
    ? currentCase.caseSettings.ShowRepairDuration
    : false;

  return (
    <div className={s.root}>
      <div className={(s.details, s.cols_12)}>
        <h2>Details</h2>
        <ul className={s.detList}>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Case Created By:</span>
            <strong className={s.cols_6}>
              {metaData.CreatedBy || 'UNKNOWN AUTHOR'}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Case Created On:</span>
            <strong className={s.cols_6}>
              {dateFormat(
                metaData ? metaData.CreatedOn || '01/01/1970' : '01/01/1970'
              )}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Organisation:</span>
            <strong className={s.cols_6}>{organisationName}</strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Registration:</span>
            <strong className={s.cols_6}>
              {vehicleDetails ? vehicleDetails.Registration : ''}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Mileage:</span>
            <strong className={s.cols_6}>
              {vehicleDetails ? vehicleDetails.Mileage || 0 : null}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Make:</span>
            <strong className={s.cols_6}>
              {vehicleDetails && vehicleDetails.Manufacturer !== ''
                ? vehicleDetails.Manufacturer
                : 'UNKNOWN'}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Model:</span>
            <strong className={s.cols_6}>
              {vehicleDetails && vehicleDetails.Model !== ''
                ? vehicleDetails.Model
                : 'UNKNOWN'}
            </strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Body:</span>
            <strong className={s.cols_6}>{body}</strong>
          </li>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Date of Registration:</span>
            <strong className={s.cols_6}>
              {dateFormat(
                vehicleDetails ? vehicleDetails.RegDate : '',
                'fullDate'
              )}
            </strong>
          </li>

          {showValuation ? (
            <Valuation
              styles={s}
              isCustom={
                currentCase.businessLine == 2 &&
                vehicleDetails.Retail !== vehicleDetails.DefaultRetail
              }
              retailPrice={vehicleDetails ? vehicleDetails.Retail || 0 : 0}
            />
          ) : null}

          <ClaimReference
            styles={s}
            onPromptHandler={onPromptHandler}
            claimReferenceNumber={claimReferenceNumber}
          />

          {displaySettleAnswer ? (
            <SettlementAnswer
              styles={s}
              currentCase={currentCase}
              onPromptHandler={onPromptHandler}
            />
          ) : null}

          {showCashInLieuAnswer ? (
            <AcceptCashInLieu
              styles={s}
              currentValue={decision.CashInLieuDetails.CashInLieuAccepted}
              changeHandler={cashInLieuChangeHandler || function () {}}
            />
          ) : null}

          {showMobileConfirmed ? (
            <MobileConfirmed
              styles={s}
              currentCase={currentCase}
              onMobileHandler={onMobileHandler}
            />
          ) : null}

          {currentCase &&
          currentCase.vehicleDetails &&
          (currentCase.vehicleDetails.VariantIndex || 0) * 1 >= 0 ? (
            <VariantSelection
              styles={s}
              selected={selectedVariant}
              variantChangeHandler={variantChangeHandler || function () {}}
            />
          ) : null}

          {showAtFaultStatus ? (
            <AtFaultStatus
              styles={s}
              status={currentCase.decision.AtFaultStatus}
              onAtFaultHandler={props.onAtFaultHandler}
            />
          ) : null}

          {showPostcode ? (
            <Postcode
              styles={s}
              postcode={currentCase.decision.Postcode}
              onPostcodeHandler={props.onPostcodeHandler}
            />
          ) : null}
        </ul>
      </div>

      <div className={(s.results, s.cols_12)}>
        <h2>Decision</h2>
        <ul className={s.detList}>
          <li className={s.detList__item}>
            <span className={s.cols_6}>Decision:</span>
            <strong className={s.cols_6}>{directionText}</strong>
          </li>

          {showRepairLocation ? (
            <SelectedRepairLocation
              styles={s}
              location={currentCase.decision.SelectedRepairer}
            />
          ) : null}

          {showSalvageCategories ? (
            decision && decision.SalvageCat ? (
              <SalvageCategory
                styles={s}
                SalvageCategoryType="legacy"
                SalvageCategoryValue={decision.SalvageCat}
              />
            ) : null
          ) : null}
          {showSalvageCategories ? (
            decision && decision.SalvageCat2017 ? (
              <SalvageCategory
                styles={s}
                SalvageCategoryType="new"
                SalvageCategoryValue={decision.SalvageCat2017}
              />
            ) : null
          ) : null}

          {showSummaryNote ? (
            <SummaryNote styles={s} note={decision.Warning} />
          ) : null}

          <li className={s.detList__item}>
            <span className={s.cols_6}>Service Used:</span>
            <strong className={s.cols_6}>
              {vehicleDetails ? vehicleDetails.ServiceUsed : ''}
            </strong>
          </li>

          {showDamageDescription ? (
            <DamageDescription
              styles={s}
              userData={userData}
              decision={decision}
            />
          ) : null}

          {showRepairCost && !showReserveValue ? (
            <RepairCost styles={s} userData={userData} decision={decision} />
          ) : null}

          {showRepairDuration ? (
            <RepairDuration styles={s} decision={currentCase.decision} />
          ) : null}

          {showReserveValue ? (
            <ReserveValue
              styles={s}
              userData={userData}
              reserveValue={decision.ReserveValue}
            />
          ) : null}

          {showPAV ? (
            <PAV styles={s} userData={userData} pav={decision.PAV} />
          ) : null}

          {showCarHireCost ? (
            <CarHireCost
              styles={s}
              currentCase={currentCase}
              changeHandler={carHireResetHandler}
            />
          ) : null}

          {showCarHireVAT ? (
            <CarHireVAT styles={s} currentCase={currentCase} />
          ) : null}

          {showCarHireCost && !isCashInLieuDecision ? (
            <CarHireCostTotal styles={s} currentCase={currentCase} />
          ) : null}

          {showCarHireCost ? (
            <TotalCost styles={s} currentCase={currentCase} />
          ) : null}

          {showPAVPercentage ? (
            <PAVPercentage
              styles={s}
              percentage={decision.PercentageWriteOff}
            />
          ) : null}

          <li className={s.detList__item}>
            <span className={s.cols_6}>Message:</span>
            <strong className={s.cols_6}>
              {decision ? decision.DrivabilityDescription || '' : ''}
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SummaryList;
