import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class MirrorsQuestions extends Component {
  render() {
    const s = this.props.style;
    return (
      <RadioButtonGroup
        className={s.radios}
        name="panel"
        defaultSelected={this.props.defaultSelected.toString()}
        labelPosition="left"
      >
        <RadioButton
          value="4"
          label="Heavy: Glass is broken, cover is cracked, mirror is missing or hanging off"
        />
        <RadioButton value="1" label="Light: Scratched or scuffed" />
        <RadioButton value="0" label="None: There is no damage" />
      </RadioButtonGroup>
    );
  }
}
