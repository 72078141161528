import { FC } from 'react';
import dateFormat from 'dateformat';
import summaryStyles from '../Summary.module.scss';
import s from './EmailStatusInfo.module.scss';
import RaisedButton from 'material-ui/RaisedButton';
import AppActions from '../../../../../actions/AppActions';
import EditAndSendEmail from './EditAndSendEmail';

interface Props {
  furtherActions: {
    CaseId: string;
    EmailAddress: string | null;
    EmailStatus: string | null;
    EmailBounceDescription: string | null;
    EmailTimestamp: string | null;
    EmailOpened: boolean;
  };
  showImagesComponent: boolean;
  hasEmailBeenSent: boolean;
}

const EmailStatusInfo: FC<Props> = (props) => {
  const { furtherActions, showImagesComponent, hasEmailBeenSent } = props;

  const showEditEmailModal = () => {
    const content = (
      <div>
        <EditAndSendEmail
          caseId={furtherActions.CaseId}
          currentEmail={furtherActions.EmailAddress || ''}
        />
      </div>
    );

    const modalModel = {
      open: true,
      title: 'Edit Email',
      content,
      type: 'alert',
      actions: [
        <RaisedButton
          key={2}
          label="Close"
          primary={true}
          onClick={() => {
            AppActions.destroyModal();
          }}
        />,
      ],
    };

    AppActions.createModal(modalModel);
  };

  const onClickEmail = () => {
    showEditEmailModal();
  };

  const renderEmailSentInformation = () => {
    return (
      <>
        <li className={summaryStyles.detList__item}>
          <span className={summaryStyles.cols_6}>Email Address : </span>
          <strong
            className={summaryStyles.cols_6}
            style={{ textTransform: 'none' }}
          >
            {furtherActions.EmailAddress
              ? furtherActions.EmailAddress
              : 'Unknown'}
          </strong>
        </li>
        <li className={summaryStyles.detList__item}>
          <span className={summaryStyles.cols_6}>Email Status : </span>
          <strong className={summaryStyles.cols_6}>
            {furtherActions.EmailStatus
              ? furtherActions.EmailStatus
              : 'Unknown'}
          </strong>
        </li>
        <li className={summaryStyles.detList__item}>
          <span className={summaryStyles.cols_6}>Email Opened : </span>
          <strong className={summaryStyles.cols_6}>
            {furtherActions.EmailOpened !== undefined
              ? furtherActions.EmailOpened.toString()
              : 'Unknown'}
          </strong>
        </li>
        {furtherActions.EmailBounceDescription ? (
          <li className={summaryStyles.detList__item}>
            <span className={summaryStyles.cols_6}>Error Description : </span>
            <strong className={summaryStyles.cols_6}>
              {furtherActions.EmailBounceDescription}
            </strong>
          </li>
        ) : null}
        <li className={summaryStyles.detList__item}>
          <span className={summaryStyles.cols_6}>Timestamp: </span>
          <strong className={summaryStyles.cols_6}>
            {furtherActions.EmailTimestamp
              ? dateFormat(furtherActions.EmailTimestamp)
              : 'Unknown'}
          </strong>
        </li>
      </>
    );
  };

  const renderNoEmailSent = () => {
    return (
      <li className={summaryStyles.detList__item}>
        <span className={summaryStyles.cols_6}>Email Address : </span>
        <strong
          className={summaryStyles.cols_6}
          style={{ textTransform: 'none' }}
        >
          Not Set
        </strong>
      </li>
    );
  };

  const editEmailButton = showImagesComponent ? (
    <RaisedButton
      className={s.editEmail}
      label={hasEmailBeenSent ? 'Edit Email Address' : 'Set Email Address'}
      primary={true}
      onClick={onClickEmail}
      fullWidth
    />
  ) : null;

  return (
    <div>
      <h2>Sent Email Information:</h2>
      {hasEmailBeenSent ? renderEmailSentInformation() : renderNoEmailSent()}

      {editEmailButton}
    </div>
  );
};

export default EmailStatusInfo;
