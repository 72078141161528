import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftEstate extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackLeft)}>
        <path
          {...this.props}
          d="M809.5,638.8c0,0-93.7-3.8-121-4.1l-9.8,84.3h166.7C845.4,719,814.5,643.5,809.5,638.8z"
        />
        <path
          {...this.props}
          d="M814,639.5c7.5,17,28.5,64.7,33.6,76.3c0.9,2,1.2,3.2,1.2,3.2h111.4c3.7,0,5-3.5,3.2-6.7c-6.9-12.2-21.4-36-47.6-58.8   c-9.5-8.3-31.3-8.6-56.8-11.3L814,639.5z"
        />
      </g>
    );
  }
}

let composed = WindowBackLeftEstate;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
