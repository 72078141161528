import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRight4x4 extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackRight)}>
        <path
          {...this.props}
          d="M720.3,295h-30.2c-0.5,0-0.9-2.4-1-2.9c0,0-5.6-29-8.6-70c-0.1-1.5,1.9-2.2,3-2.2l66.5,0.3c7.2,0,23.6,17,18.6,23.8   C763.7,250.5,727.7,295,720.3,295z"
        />
        <path
          {...this.props}
          d="M908.1,230.2c-8.1,11.1-55.4,63.7-60.7,63.7l-68.9-0.3c-1.3,0-8.3,0.2-4.7-3.5c0,0,55.8-62.1,64-62.1h69.1   C908.1,228,908.9,229.2,908.1,230.2z"
        />
      </g>
    );
  }
}

let composed = WindowBackRight4x4;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
