import { Component } from 'react';
import PropTypes from 'prop-types';
import emptyFunction from 'fbjs/lib/emptyFunction';
import withViewport from '../../decorators/withViewport';
import Header from '../Header/Header';
import s from './App.module.scss';
import Footer from '../Footer';
import LoginPage from '../LoginPage';
import ResetPage from '../LoginPage/ResetPage';
import AppStore from '../../stores/AppStore';
import AppActions from '../../actions/AppActions';
import LockActions from '../../actions/LockActions';
import TriageActions from '../../actions/TriageActions';
import TriageStore from '../../stores/TriageStore';
import LockStore from '../../stores/LockStore';
import AppAPI from '../../api/appAPI';
import Spinner from '../Spinner';
import Modal from '../ModalDialog/';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { loginExpiry } from '../../config';
import ImgLogo from '../Logo.svg';
import ImgGrid from '../grid.svg';

let expiryInterval;
let verifyInterval;
let lockInterval;

const ORG_ERROR_MARKUP = (
  <div>
    <p>It was not possible to retrieve your organisation's settings.</p>
    <p>Please contact an Administrator.</p>
  </div>
);
class App extends Component {
  static propTypes = {
    context: PropTypes.shape({
      onSetTitle: PropTypes.func,
      onSetMeta: PropTypes.func,
      onPageNotFound: PropTypes.func,
    }),
    children: PropTypes.element.isRequired,
    error: PropTypes.object,
  };

  static childContextTypes = {
    onSetTitle: PropTypes.func.isRequired,
    onSetMeta: PropTypes.func.isRequired,
    onPageNotFound: PropTypes.func.isRequired,
  };

  state = {
    isLoggedIn: false,
    showSpinner: false,
    user: {},
  };

  getChildContext() {
    const context = this.props.context;
    return {
      onSetTitle: context.onSetTitle || emptyFunction,
      onSetMeta: context.onSetMeta || emptyFunction,
      onPageNotFound: context.onPageNotFound || emptyFunction,
    };
  }

  updateState = () => {
    this.setState(
      {
        showSpinner: AppStore.getSpinnerState(),
        modalOptions: AppStore.getModalState(),
        user: AppStore.userData(),
      },
      () => {
        this.setState(
          {
            isLoggedIn:
              this.state.user !== null && this.state.user.AppSettings !== null,
            modalOptions:
              this.state.user !== null && this.state.user.AppSettings === null
                ? {
                    open: true,
                    title: 'Error',
                    content: ORG_ERROR_MARKUP,
                    type: 'alert',
                    onConfirm: () => {
                      AppActions.logout();
                    },
                  }
                : this.state.modalOptions,
          },
          () => {
            if (!this.state.isLoggedIn) {
              if (expiryInterval) clearInterval(expiryInterval);
              if (verifyInterval) clearInterval(verifyInterval);
              if (lockInterval) clearInterval(lockInterval);
              setTimeout(() => {
                if (this.state.modalOptions.title !== 'Error')
                  this.state.modalOptions.open = false;
              }, 300);
            }
          }
        );
      }
    );
  };

  updateLocking = () => {
    const currentCase = TriageStore.getCase();

    if (
      this.state.isLoggedIn &&
      currentCase.caseId !== '' &&
      currentCase.relatedCases.length < 1
    ) {
      LockStore.isCaseLocked((isLocked) => {
        if (isLocked) {
          //open checkout modal
          AppActions.createModal({
            open: true,
            type: 'alert',
            content: <div>This case has been locked by another user.</div>,
            onConfirm: () => {
              TriageActions.resetCompletedQuestionSets();
              TriageActions.resetProgress();
              TriageActions.resetCase();
              LockActions.unlockCase({ caseId: TriageStore.getCase().caseId });
              setTimeout(() => {
                document.location.reload(true);
              }, 1000);
            },
          });
        }
      });
    }
  };

  onUnload = (event) => {
    const currentCase = TriageStore.getCase();
    if (
      this.state.isLoggedIn &&
      currentCase.caseId !== '' &&
      currentCase.decision.Direction
    ) {
      const text = 'Your case is incomplete. Are you sure you want to leave?';
      event.returnValue = text;
      return text;
    }
  };

  UNSAFE_componentWillMount() {
    const currentCase = TriageStore.getCase();
    this.setState({
      showSpinner: false,
    });

    this.checkVerification();
    this.checkExpiry();

    expiryInterval = setInterval(() => {
      this.checkExpiry();
    }, loginExpiry * 60000);

    verifyInterval = setInterval(() => {
      this.checkVerification();
    }, 120000);

    lockInterval = setInterval(() => {
      if (
        this.state.isLoggedIn &&
        currentCase.caseId !== '' &&
        currentCase.relatedCases.length < 1
      ) {
        LockActions.updateLock({ caseId: currentCase.caseId });
      }
    }, 20000);

    if (
      this.state.isLoggedIn &&
      currentCase.caseId !== '' &&
      currentCase.relatedCases.length < 1
    ) {
      LockActions.updateLock({ caseId: currentCase.caseId });
    }
    AppStore.addChangeListener(this.updateState);
    LockStore.addChangeListener(this.updateLocking);
  }

  checkExpiry = () => {
    if (
      this.state.user &&
      this.state.user.User &&
      this.state.user.User.InsurerName === 'ITG Group'
    )
      return;
    if (AppStore.isUserExpired()) {
      //setTimeout to send to end of JS exec stack
      setTimeout(() => {
        AppActions.destroyModal();
        AppActions.createModal({
          open: true,
          title: 'Your session has expired',
          type: 'alert',
          content: (
            <div>
              <p>Your session has expired</p>
              <p>Please sign in again.</p>
            </div>
          ),
          onConfirm: () => {
            if (TriageStore.getCase() !== null) {
              TriageActions.resetCompletedQuestionSets();
              TriageActions.resetProgress();
              TriageActions.resetCase();
              LockActions.unlockCase({ caseId: TriageStore.getCase().caseId });
              setTimeout(() => {
                document.location.reload(true);
              }, 1000);
            }
            AppActions.logout();
            AppActions.destroyModal();
            this.setState({
              isLoggedIn: false,
              user: null,
            });
          },
        });
      }, 0);
    }
  };

  checkVerification = () => {
    AppStore.verifyUser((isVerified) => {
      if (!isVerified) {
        AppActions.logout();
        document.location.reload(true);
      }
      this.setState({
        isLoggedIn: isVerified,
        user: isVerified ? AppStore.userData() : null,
      });
    });
  };

  resetExpiryHandler = (e) => {
    if (!this.state.isLoggedIn) return;
    AppAPI.setExpiry(new Date(), loginExpiry);
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this.updateState);
    window.removeEventListener('beforeunload', this.onUnload);
  }

  render() {
    const inProgress = this.state.showSpinner ? <Spinner /> : null;
    return !this.props.error ? (
      <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
        <div onClick={this.resetExpiryHandler}>
          <img
            style={{ height: 0 }}
            className={s.appImage}
            src={ImgLogo}
            alt=""
          />
          <img
            className={s.appImage}
            src={ImgGrid}
            height="100%"
            width="100%"
            style={{ opacity: 0.3 }}
            alt=""
          />
          <div className={s.root} style={{ opacity: '0' }}>
            <Header user={this.state.user} isLoggedIn={this.state.isLoggedIn} />
            <div className={s.app}>
              {this.state.isLoggedIn ? (
                this.props.children
              ) : this.props.reset ? (
                <ResetPage {...this.props} />
              ) : (
                <LoginPage />
              )}
            </div>
            <Footer isLoggedIn={this.state.isLoggedIn} />
            <Modal options={this.state.modalOptions} />
            {inProgress}
          </div>
        </div>
      </MuiThemeProvider>
    ) : (
      this.props.children
    );
  }
}

const composed = withViewport(App);

export default composed;
