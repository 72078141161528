import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class BumperFront extends Component {
  render() {
    return (
      <g className={s.bumperFront}>
        <path
          {...this.props}
          d="M147.9,265.2c-14.6,1.7-15.6,1.5-15.6-6.2c0-7.4-1.5-8.4-11.1-7.7c-6.2,0.2-33.3,1.5-60.2,1.7c-57.6,1.5-60.9,3.3-60.9,30.5  C0.1,308.1,6,313,35.9,313h23.4v292H35.4c-30.5,0-35,3.9-35,29.5c0,27.4,2.8,28.6,74,30.3l58.1,1.5v-7.9c0-7.4,1.5-7.9,15.1-7.4  l14.6,1l1-194.4l0.7-194.4L147.9,265.2L147.9,265.2z"
        />
        <path
          {...this.props}
          d="M147.8,265.2c-14.6,1.7-15.6,1.5-15.6-6.2c0-7.4-1.5-8.4-11.1-7.7c-6.2,0.2-33.3,1.5-60.2,1.7  C3.3,254.5,0,256.3,0,283.5C0,308.1,5.9,313,35.8,313h23.4v292H35.3c-30.5,0-35,3.9-35,29.5c0,27.4,2.8,28.6,74,30.3l58.1,1.5v-7.9  c0-7.4,1.5-7.9,15.1-7.4l14.6,1l1-194.4l0.7-194.4L147.8,265.2L147.8,265.2z M102.8,572c0,25.4-1.5,45.6-3.4,46.8  c-1.7,1-6.6,0.2-10.3-1.7c-6.6-3.4-6.9-6.4-6.9-146.7h3.3c0,126.9,1,143.1,5.7,144.6c3.3,1,6.2,2,6.9,2c0.7,0,1.5-71.9,1.5-160  s-1-160-2.5-159.5c-11.3,1.3-11.6,3-11.6,152.1h-3.4c0-138,0.2-149.3,6.2-152.1c3.3-2,7.7-3.3,9.4-2.5c2,0.2,3.9,19.7,4.4,46.6  l1,45.6l19.7,1l1.5,136.5l-21.5,2L102.8,572L102.8,572z M155.2,640.9l-14.6-1c-13.6-0.7-15.1,0-15.1,7.4v2c-1.5-2-3.3-3.4-5.1-4.9  l-1-51c-1.7-65.3-1.7-65,21.2-49.7l12.8,9.2c0.7,0.7,1.5,1,2,1.7L155.2,640.9z M153.7,361l-12.8,9.2c-22.9,15.3-22.9,15.6-21.2-49.7  l1-51c2-1,3.9-2.8,5.1-4.9v2c0,7.4,1.5,7.9,15.1,7.4l14.6-1l0.2,87C155.2,359.5,154.5,360,153.7,361L153.7,361z"
        />
      </g>
    );
  }
}

let composed = BumperFront;
composed = SectionComponent({
  name: 'Front Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front'],
  modalModel: {
    title: 'Register Front Bumper Damage',
    content: <Modal name="Front Bumper" />,
  },
})(composed);

export default composed;
