import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M682.3,634.6c0.2,0-60.7-3.6-89.3,1.9c-9.1,3-35.1,9.1-117.5,82.5h197.8L682.3,634.6z"
      />
    );
  }
}

let composed = WindowFrontLeftHatchback;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
