import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './EventLoggingPage.module.scss';
import Griddle from 'griddle-react';
import Link from '../Link';
import eventAPI from '../../api/eventAPI';
import Spinner from '../Spinner';

const title = 'Inter-cept | Event Logging';

class PermissionsPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    apiData: [],
    displaySpinner: true,
  };

  UNSAFE_componentWillMount() {
    this.context.onSetTitle(title);
    eventAPI
      .getAllElmahData()
      .then((data) => {
        const cleanData = data.map((item) => {
          return JSON.parse(item).error;
        });
        //console.log(cleanData);
        this.setState({
          apiData: cleanData,
          displaySpinner: false,
        });
      })
      .catch((error) => {
        this.setState({
          apiData: [],
          displaySpinner: false,
        });
      });
  }

  render() {
    const spinner = this.state.displaySpinner ? <Spinner /> : null;
    //console.log(this.state.apiData)
    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.box}>
            <ul className={s.backLinks}>
              <li>
                <a href="/dashboard" onClick={Link.handleClick}>
                  Back to Dashboard
                </a>
              </li>
            </ul>
            <h1 className={s.heading}>Event Logging</h1>
            <h2>WebAPI data</h2>
            <Griddle
              className={s.grid}
              results={this.state.apiData}
              useFixedHeader={true}
              tableClassName={s.table}
              showFilter={true}
              enableInfiniteScroll={false}
              columns={['@host', '@message', '@statusCode', '@time', '@type']}
            />
          </div>
        </div>
        {spinner}
      </div>
    );
  }
}

export default PermissionsPage;
