import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRightPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M403.1,36h348.3c1,0,1.9,0.8,1.9,1.9v43c0,1-0.8,1.9-1.9,1.9H403.1c-1,0-1.9-0.8-1.9-1.9v-43C401.3,36.8,402.1,36,403.1,36z  "
      />
    );
  }
}

let composed = SillRightPV;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
