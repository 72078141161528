import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './VehicleLookup.module.scss';
import withValidation from './_withValidation';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import TriageActions from '../../../actions/TriageActions';
import AppActions from '../../../actions/AppActions';
import TriageStore from '../../../stores/TriageStore';
import organisationAPI from '../../../api/organisationAPI';
import AppStore from '../../../stores/AppStore';
import AgeEstimator from './AgeEstimator';
import Results from './Results';
import NextButtons from './NextButtons';
import OpenCases from './OpenCases';
import dateFormat from 'dateformat';
import LockActions from '../../../actions/LockActions';
import BusinessLineSelect from './BusinessLineSelect';
import SubInsurerSelect from './SubInsurerSelect';
import CustomRetail from './CustomRetail';
import numberPlates from 'uk-numberplates';
import { validateReferenceNumber } from '../../../lib/validateReferenceNumber';

class VehicleLookup extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    claimReferenceNumber: null,
    regex: '^[a-zA-Z]{4}[0-9]{10}$',
    isRefValid: null,
    isRegValid: null,
    isNonUKReg: null,
    isMileageEstimated: null,
    regDisabled: true,
    progressDisabled: false,
    vehicleDetails: {
      Registration: null,
      Model: null,
    },
    registration: '',
    mileage: '',
    userData: null,
    splat: null,
    relatedCases: [],
    isHistoryModalClosed: false,
    metaData: {},
    businessLines: [],
    selectedBusinessLine: null,
    createdTime: null,
    isMileageExcessive: null,
    customRetail: null,
    previousCustomRetail: null,
    displayHighMileageWarning: false,
    displayLowMileageWarning: false,
    isEmbed: false,
    subInsurers: [],
    selectedSubInsurer: null,
  };

  UNSAFE_componentWillMount() {
    const currentCase = TriageStore.getCase();
    const userData = AppStore.userData();

    if (userData.AppSettings.AllowSubInsurers) {
      var subInsurers = [];
      organisationAPI
        .getSubInsurersForInsurer(userData.User.InsurerId)
        .then((result) => {
          subInsurers = result;
          this.setState({
            subInsurers: subInsurers,
            selectedSubInsurer: currentCase.subInsurerID,
          });
        });
    }

    if (userData.AppSettings.EnforceBusinessLines) {
      var businessLines = [];
      organisationAPI
        .getBusinessLinesForInsurer(userData.User.InsurerId)
        .then((result) => {
          businessLines = result;
          this.setState({
            businessLines: businessLines,
            selectedBusinessLine: currentCase.businessLine,
          });
        });
    }

    if (currentCase.registration && currentCase.registration) {
      this.setState({
        registration: currentCase.registration,
        mileage: currentCase.registration,
        businessLine: currentCase.businessLine || null,
      });
    }

    this.setState(
      {
        isRefValid: true,
        isRegValid: true,
        isNonUKReg: false,
        isMileageEstimated: false,
        userData: userData,
        isHistoryModalClosed: currentCase.isHistoryModalClosed,
        isEmbed: currentCase.isEmbed,
      },
      () => {
        if (currentCase) {
          this.props.validateResponse(currentCase.errorCode, this);
        }
      }
    );

    TriageStore.addChangeListener(this.updateState);
    this.updateState();
  }

  componentWillUnmount() {
    AppActions.destroyModal();
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    const currentCase = TriageStore.getCase();
    if (currentCase === undefined) return;

    this.setState({
      metaData: currentCase.metaData,
      createdTime: currentCase.metaData.CreatedOn
        ? dateFormat(currentCase.metaData.CreatedOn, 'ddd dd/mm/yyyy') +
          ' ' +
          new Date(currentCase.metaData.CreatedOn).toLocaleTimeString()
        : null,
    });
    if (currentCase.vehicleDetails) {
      this.setState(
        {
          customRetail: currentCase.vehicleDetails.Retail,
          vehicleDetails: currentCase.vehicleDetails,
          registration: currentCase.vehicleDetails.Registration,
          mileage: currentCase.vehicleDetails.Mileage,
          selectedBusinessLine: currentCase.businessLine,
          selectedSubInsurer: currentCase.subInsurerID,
          splat: currentCase.splat,
          isHistoryModalClosed: currentCase.isHistoryModalClosed,
          relatedCases: currentCase.relatedCases.filter((item) => {
            return item.CaseId !== currentCase.caseId;
          }),
        },
        () => {
          const casesArraySize = Object.keys(this.state.relatedCases).length;
          const openCases = this.state.relatedCases.map((item, i) => {
            return {
              DateCreated: dateFormat(item.DateCreated),
              Creator: item.Creator,
              Direction: item.Direction,
              Edit: JSON.stringify({
                caseId: item.CaseId,
                isLocked: item.IsLocked || false,
              }),
            };
          });
          if (casesArraySize > 0) {
            if (!this.state.isHistoryModalClosed) {
              AppActions.createModal({
                open: true,
                title: 'Create a New Case',
                type: 'alert',
                customStyle: { width: '98%' },
                content: (
                  <OpenCases
                    openCases={openCases}
                    casesArraySize={casesArraySize}
                    registration={currentCase.vehicleDetails.Registration}
                  />
                ),
                actions: [
                  <RaisedButton
                    primary={true}
                    label="Cancel"
                    onClick={this.resetCase}
                  />,
                  <span>&nbsp;</span>,
                  <RaisedButton
                    primary={true}
                    label="Create New Case"
                    onClick={this.createNewCase}
                  />,
                ],
              });
            }
          }
        }
      );
    }
  };

  createNewCase = () => {
    this.submitCaseData(
      {
        claimReferenceNumber: this.state.claimReferenceNumber,
        registration: this.state.registration,
        mileage: this.state.mileage,
        override: true,
        businessLine: this.state.selectedBusinessLine,
        subInsurer: this.state.selectedSubInsurer,
      },
      () => {
        const currentCase = TriageStore.getCase();
        if (currentCase.errorCode === 4) {
          AppActions.removeSpinner();
          AppActions.createModal({
            open: true,
            title: 'Warning: Unusual Mileage Value entered',
            type: 'alert',
            content: (
              <div>
                <p>
                  Warning: The mileage value entered is higher than expected for
                  a vehicle of this age.
                </p>
                <p>Please make sure that its correct before continuing.</p>
              </div>
            ),
            onConfirm: () => {
              document.location.reload(true);
            },
          });
        } else {
          setTimeout(() => {
            document.location.reload(true);
          }, 1000);
        }
      }
    );
  };

  resetCase = () => {
    const currentCase = TriageStore.getCase();
    const thisCase = Object.assign(
      { caseId: null },
      { caseId: currentCase.caseId }
    );
    const isEmbed = currentCase.isEmbed;
    TriageActions.resetCompletedQuestionSets();
    TriageActions.resetProgress();
    TriageActions.resetCase();
    LockActions.unlockCase(thisCase);
    AppActions.addSpinner();
    setTimeout(() => {
      isEmbed
        ? window.parent.postMessage('{ "action" : "reset" }', '*')
        : document.location.reload(true);
    }, 600);
  };

  handleRefChange = (e) => {
    this.setState(
      {
        claimReferenceNumber: e.target.value,
        regex: this.state.userData.AppSettings.ClaimReferenceNumberRegex,
      },
      () => {
        this.validateReferenceNumber();
      }
    );
  };

  handleRegChange = (e) => {
    //validation goes here
    this.setState(
      {
        registration: e.target.value.replace(/ /g, ''),
        regDisabled:
          e.target.value.replace(/ /g, '') == '' || this.state.mileage === ''
            ? true
            : false,
      },
      () => {
        this.props.validateRegistration(this);
      }
    );
    this.checkMileage();
  };

  handleMlgChange = (e) => {
    //validation goes here
    if (!isNaN(e.target.value)) {
      this.setState(
        {
          isMileageEstimated: false,
          mileage: e.target.value,
          regDisabled: e.target.value == '' ? true : false,
        },
        () => {
          this.checkMileage();
        }
      );
    }
  };

  clearMlg = () => {
    this.setState({
      isMileageEstimated: false,
      mileage: null,
    });
    AppActions.destroyModal();
  };

  submitCaseData = (data, cb) => {
    if (data.registration !== undefined && data.mileage !== undefined) {
      if (cb) data.callback = cb;
      AppActions.addSpinner();
      TriageActions.resetCompletedQuestionSets();
      TriageActions.resetCase();
      TriageActions.resetProgress();
      TriageActions.createCase(data, () => {
        AppActions.removeSpinner();
        AppActions.createModal({
          open: true,
          title: 'Error creating Triage case',
          type: 'alert',
          content: (
            <div>
              <p>It was not possible to create a Triage case at this time.</p>
              <p>Please ensure you have the correct permissions.</p>
              <p>If the problem persists, please contact an Administrator.</p>
            </div>
          ),
        });
      });
      this.setState({
        regDisabled: true,
      });
    }
  };

  validateReferenceNumber = () => {
    const { subInsurers, selectedSubInsurer } = this.state;

    // Find the selected sub insurer so the claim ref regex can be
    // used for validation
    const subInsurer = subInsurers.find((el) => selectedSubInsurer === el.ID);

    const isValid = validateReferenceNumber({
      referenceNumber: this.state.claimReferenceNumber,
      regex: subInsurer ? subInsurer.ClaimReferenceNumberRegex : null,
    });

    this.setState({
      isRefValid: isValid,
    });
  };

  handleRegSubmit = (e) => {
    e.preventDefault();
    if (!this.state.isRegValid) return;
    let caseData = {
      claimReferenceNumber: this.state.claimReferenceNumber,
      registration: this.state.registration,
      mileage: this.state.mileage,
      override: false,
      businessLine: this.state.selectedBusinessLine,
      subInsurer: this.state.selectedSubInsurer,
    };

    const currentCase = TriageStore.getCase();

    const { userData } = this.state;

    // Only validate ref if Insurer has ref available at lookup
    if (userData.AppSettings.ShowClaimReferenceAtStart) {
      this.validateReferenceNumber();
    }

    if (currentCase.caseId !== '') {
      AppActions.createModal({
        open: true,
        title: 'Confirm New Case',
        type: 'confirm',
        content: (
          <div>
            <p>
              You already have an open case. This will close this case and open
              a new one.
            </p>
            <p>Do you wish to continue?</p>
          </div>
        ),
        onConfirm: () => {
          TriageActions.resetCompletedQuestionSets();
          TriageActions.resetProgress();
          TriageActions.resetCase();
          LockActions.unlockCase({ caseId: currentCase.caseId });
          AppActions.addSpinner();
          this.submitCaseData(caseData, () => {
            const currentCase = TriageStore.getCase();
            this.props.validateResponse(currentCase.errorCode, this);
            setTimeout(() => {
              document.location.reload(true);
            }, 1000);
          });
        },
      });
    } else {
      this.submitCaseData(caseData, () => {
        const currentCase = TriageStore.getCase();
        this.props.validateResponse(currentCase.errorCode, this);
        AppActions.removeSpinner();
      });
    }
  };

  handleBusinessLineChange = (e, index, value) => {
    if (value && value > 0) {
      this.setState({ selectedBusinessLine: value });
    } else {
      this.setState({ selectedBusinessLine: null });
    }
    if (this.state.isEmbed)
      window.parent.postMessage(
        JSON.stringify({ InsurerBusinessLineID: value }),
        '*'
      );

    const currentCase = TriageStore.getCase();
    if (currentCase.caseId != '') {
      const data = { caseId: currentCase.caseId, businessLineID: value };
      TriageActions.setBusinessLine(data, () => {
        if (data.businessLineID == 2) {
          TriageActions.setCustomRetailValue(this.state.customRetail, () => {
            TriageActions.makeDecision();
          });
        } else {
          TriageActions.makeDecision();
        }
      });
    }
  };

  handleSubInsurerChange = (e, index, value) => {
    const onStateSaved = () => {
      this.validateReferenceNumber();
    };

    if (value && value > 0) {
      this.setState({ selectedSubInsurer: value }, onStateSaved);
    } else {
      this.setState({ selectedSubInsurer: null }, onStateSaved);
    }

    if (this.state.isEmbed) {
      window.parent.postMessage(JSON.stringify({ SubInsurerID: value }), '*');
    }
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    let newState = TriageStore.getProgressState();
    if (newState.MAX_PERMITTED === 0) newState.MAX_PERMITTED = 1;
    newState.CURRENT_STAGE = 1;

    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  resetLookup = (e) => {
    e.preventDefault();
    AppActions.createModal({
      open: true,
      title: 'Close Case',
      type: 'confirm',
      content: (
        <div>
          <p>
            This will begin a new case. The current case will not be completed.
          </p>
          <p>Are you sure?</p>
        </div>
      ),
      onConfirm: () => {
        this.resetCase();
      },
    });
  };

  handleNonUKPlate = () => {
    this.setState(
      {
        isNonUKReg: this.state.isNonUKReg ? false : true,
      },
      () => {
        this.props.validateRegistration(this);
      }
    );
  };

  estimateMileage = () => {
    AppActions.createModal({
      open: true,
      title: 'Please provide the age of your vehicle:',
      type: 'alert',
      actions: [
        <RaisedButton primary={true} label="Cancel" onClick={this.clearMlg} />,
        <span>&nbsp;</span>,
        <RaisedButton
          primary={true}
          label="OK"
          onClick={AppActions.destroyModal}
        />,
      ],
      content: (
        <AgeEstimator
          registration={this.state.registration}
          disableEstimate={this.state.isNonUKReg}
          submitHandler={(val) => {
            this.setState(
              {
                isMileageEstimated: true,
                mileage: val,
                regDisabled: val == '' ? true : false,
              },
              () => {
                this.checkMileage();
              }
            );
          }}
        />
      ),
    });
  };

  getRegDate = (val) => {
    return val === undefined ? '' : dateFormat(val, 'fullDate');
  };

  changeRetailHandler = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState(
        {
          customRetail: e.target.value,
        },
        () => {
          parseInt(this.state.customRetail) > 9999999
            ? (this.state.customRetail = 9999999)
            : (this.state.customRetail = this.state.customRetail);
          const shouldWarn =
            parseInt(this.state.customRetail) >
            parseInt(this.state.vehicleDetails.DefaultRetail) * 1.5;
          AppActions.updateModal({
            open: true,
            content: (
              <CustomRetail
                value={this.state.customRetail}
                styles={s}
                warn={shouldWarn}
                vehicleDetails={this.state.vehicleDetails}
                changeHandler={this.changeRetailHandler}
                resetHandler={this.resetCustomRetail}
              />
            ),
          });
        }
      );
    }
  };

  submitRetailHandler = (e) => {
    if (isNaN(this.state.customRetail)) return;
    AppActions.updateModal({ open: false });
    const currentCase = TriageStore.getCase();
    if (
      this.state.customRetail * 1 ===
        currentCase.vehicleDetails.DefaultRetail * 1 ||
      this.state.customRetail * 1 === this.state.previousCustomRetail * 1
    )
      return;
    TriageActions.setCustomRetailValue(this.state.customRetail, () => {
      TriageActions.makeDecision();
    });
  };

  openCustomRetail = () => {
    this.setState({
      previousCustomRetail: this.state.customRetail,
    });
    const shouldWarn =
      parseInt(this.state.customRetail) >
      parseInt(this.state.vehicleDetails.DefaultRetail) * 1.5;
    AppActions.createModal({
      open: true,
      type: 'confirm',
      title: 'Enter custom retail value',
      content: (
        <CustomRetail
          value={this.state.customRetail}
          styles={s}
          warn={shouldWarn}
          vehicleDetails={this.state.vehicleDetails}
          changeHandler={this.changeRetailHandler}
        />
      ),
      actions: [
        <RaisedButton
          primary={true}
          label="Reset"
          onClick={this.resetCustomRetail}
        />,
        <span>&nbsp;</span>,
        <RaisedButton
          primary={true}
          label="Cancel"
          onClick={this.cancelRetailHandler}
        />,
        <span>&nbsp;</span>,
        <RaisedButton
          primary={true}
          label="Confirm"
          onClick={this.submitRetailHandler}
        />,
      ],
    });
  };

  resetCustomRetail = () => {
    const currentCase = TriageStore.getCase();
    if (currentCase.vehicleDetails) {
      this.setState(
        {
          customRetail: currentCase.vehicleDetails.DefaultRetail,
        },
        () => {
          AppActions.updateModal({
            open: false,
          });
          TriageActions.setCustomRetailValue(this.state.customRetail, () => {
            TriageActions.makeDecision();
          });
        }
      );
    }
  };

  cancelRetailHandler = () => {
    this.setState(
      {
        customRetail: this.state.previousCustomRetail,
      },
      () => {
        AppActions.updateModal({ open: false });
      }
    );
  };

  checkMileage = () => {
    const registration =
      this.state.registration === undefined ? '' : this.state.registration;

    numberPlates.validate(registration, (err, data) => {
      if (data.year_of_issue === 'DAT' || data.year_of_issue === null) {
        this.setState({
          displayHighMileageWarning: false,
          displayLowMileageWarning: false,
        });
      } else {
        const likelyAge = new Date().getFullYear() - data.year_of_issue;
        const highMileageWarningPoint =
          likelyAge === 0 ? 10000 : likelyAge * 10000 * 1.5;
        this.setState({
          displayHighMileageWarning:
            this.state.mileage > highMileageWarningPoint,
        });
        const lowMileageWarningPoint =
          likelyAge === 0 ? 10000 : likelyAge * 10000 * 0.5;
        this.setState({
          displayLowMileageWarning: this.state.mileage < lowMileageWarningPoint,
        });
      }
    });
  };

  setVehicleLookupEnabled = (businessLinesEnabled, subInsurersEnabled) => {
    if (!businessLinesEnabled && !subInsurersEnabled) {
      return true;
    } //neither dropdowns will be shown - enable lookup to proceed

    if (
      subInsurersEnabled &&
      this.state.subInsurers &&
      this.state.selectedSubInsurer > 0
    ) {
      return true;
    } //using subinsurers and the selected ID is > 0 - return true (subinsurers takes precedence over businesslines)

    if (
      businessLinesEnabled &&
      this.state.businessLines &&
      this.state.selectedBusinessLine > 0
    ) {
      return true;
    } //using businesslines and the selected ID is > 0 - return true

    return false; //none of the true conditions have been met - don't allow lookups yet
  };

  render() {
    const currentCase = TriageStore.getCase();
    const {
      regDisabled,
      progressDisabled,
      vehicleDetails,
      registration,
      userData,
      mileage,
      isNonUKReg,
      splat,
      metaData,
      selectedBusinessLine,
      businessLines,
      isEmbed,
      selectedSubInsurer,
      subInsurers,
    } = this.state;

    const showSubInsurers = userData.AppSettings.AllowSubInsurers;
    const enforceBusinessLines =
      userData.AppSettings.EnforceBusinessLines && !showSubInsurers; //Only show businesslines if they are enforced and there are no sub-insurers
    const nonUKBtn = isNonUKReg ? s.selectedBtn : s.btn;
    const registrationError = this.state.isRegValid
      ? null
      : 'invalid uk license plate';
    const enforceRef = userData.AppSettings.IsClaimReferenceMandatory;
    const ref = this.state.claimReferenceNumber;
    const isRefValid = this.state.isRefValid;
    const refError = this.state.isRefValid
      ? null
      : 'invalid Claim Reference Number';

    const { ShowClaimReferenceAtStart } = userData.AppSettings;

    const refDisabled =
      (ShowClaimReferenceAtStart && enforceRef && !isRefValid) ||
      (ShowClaimReferenceAtStart && enforceRef && ref === '') ||
      (ShowClaimReferenceAtStart && enforceRef && !ref)
        ? true
        : false;
    const vehicleLookupEnabled = this.setVehicleLookupEnabled(
      enforceBusinessLines,
      showSubInsurers
    );

    const businessLineSelect = enforceBusinessLines ? (
      <div className={s.sixColsLookup}>
        <BusinessLineSelect
          selectedLine={selectedBusinessLine}
          lines={businessLines}
          onChangeHandler={this.handleBusinessLineChange}
        />
      </div>
    ) : null;

    const subInsurerSelect = showSubInsurers ? (
      <div className={s.sixColsLookup}>
        <SubInsurerSelect
          selectedSubInsurer={selectedSubInsurer}
          subInsurers={subInsurers}
          onChangeHandler={this.handleSubInsurerChange}
          disabled={currentCase.caseId != '' || isEmbed}
        />
      </div>
    ) : null;

    const highMileageWarning =
      this.state.displayHighMileageWarning === true ? (
        <div className={s.mileageWarning}>
          <h4>
            The mileage entered for this registration looks a little high
            <br />
            Please check it is correct before searching
          </h4>
        </div>
      ) : null;

    const lowMileageWarning =
      this.state.displayLowMileageWarning === true ? (
        <div className={s.mileageWarning}>
          <h4>
            The mileage entered for this registration looks a little low
            <br />
            Please check it is correct before searching
          </h4>
        </div>
      ) : null;

    //Saving this for later (I2-1052/I2-1059)
    //const caseRef = currentCase.metaData.ClaimReferenceNumber && currentCase.metaData.ClaimReferenceNumber.length > 0 ? currentCase.metaData.ClaimReferenceNumber : this.state.claimReferenceNumber;

    const referenceNumberLabel = userData.AppSettings.IsClaimReferenceMandatory
      ? 'claim reference number (required)'
      : 'claim reference number (optional)';

    const showClaimRefNum = userData.AppSettings.ShowClaimReferenceAtStart ? (
      <div className={s.sixColsLookup}>
        <TextField
          className={s.textBox}
          floatingLabelText={referenceNumberLabel}
          value={currentCase.metaData.ClaimReferenceNumber}
          fullWidth={true}
          autoComplete="off"
          errorText={refError}
          onChange={this.handleRefChange}
        />
      </div>
    ) : null;

    return (
      <div className={s.root}>
        <form className={s.container} onSubmit={this.handleRegSubmit}>
          <div className={s.lookup}>
            <h2>Vehicle Lookup</h2>
            <div>
              {businessLineSelect}
              {subInsurerSelect}
              {showClaimRefNum}
            </div>
            <div className={s.lookupField}>
              <div className={s.sixColsLookup}>
                <TextField
                  className={s.textBox}
                  floatingLabelText="enter your registration"
                  value={registration || ''}
                  fullWidth={true}
                  autoComplete="off"
                  errorText={registrationError}
                  onChange={this.handleRegChange}
                  disabled={!vehicleLookupEnabled || isEmbed}
                />
              </div>
              <div className={s.sixColsLookup}>
                <div className={s.btnWrap}>
                  <FlatButton
                    className={nonUKBtn}
                    label="This is not a UK licence plate"
                    onClick={this.handleNonUKPlate}
                    disabled={!vehicleLookupEnabled || isEmbed}
                  />
                </div>
              </div>
            </div>
            <div className={s.lookupField}>
              <div className={s.sixColsLookup}>
                <TextField
                  className={s.textBox}
                  floatingLabelText="enter your mileage"
                  fullWidth={true}
                  value={mileage || ''}
                  autoComplete="off"
                  onChange={this.handleMlgChange}
                  disabled={!vehicleLookupEnabled || isEmbed}
                />
              </div>
              <div className={s.sixColsLookup}>
                <div className={s.btnWrap}>
                  <FlatButton
                    className={s.btn}
                    label="I don't know my mileage"
                    onClick={this.estimateMileage}
                    disabled={!vehicleLookupEnabled || isEmbed}
                  />
                </div>
              </div>
            </div>
            {highMileageWarning}
            {lowMileageWarning}
            <div className={s.submitBtn}>
              <RaisedButton
                disabled={regDisabled || refDisabled}
                label="search"
                type="submit"
              />
            </div>
          </div>

          <Results
            styles={s}
            displayValue={this.state.customRetail}
            userData={userData}
            vehicleDetails={vehicleDetails}
            splat={splat}
            getRegDate={this.getRegDate}
            openModalHandler={this.openCustomRetail}
            selectedBusinessLine={this.state.selectedBusinessLine}
            caseSettings={currentCase.caseSettings}
          />
        </form>

        <div
          className={s.box}
          style={{ borderRadius: '4px 4px 4px 20px', fontSize: '0.76em' }}
        >
          Case Created by : {metaData.CreatedBy || ''}&nbsp; | Case Created on{' '}
          {this.state.createdTime || ''}&nbsp; | Organisation :{' '}
          {metaData.Organisation || ''}
        </div>

        <div className={s.footerDialogContainer}>
          <div className={s.footerDialog}>
            <NextButtons
              vehicleDetails={vehicleDetails}
              progressDisabled={
                (enforceBusinessLines && !selectedBusinessLine) ||
                progressDisabled
              }
              resetHandler={this.resetLookup}
              submitHandler={this.saveAndContinue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withValidation(VehicleLookup);
