import { Component } from 'react';
import s from './AirbagsFrontQuestions.module.scss';
import QuestionComponent from '../_questionsComponent/';
import FlatButton from 'material-ui/FlatButton';
import Checkbox from 'material-ui/Checkbox';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TriageActions from '../../../../../actions/TriageActions';
import TriageStore from '../../../../../stores/TriageStore';

const CURRENT_QUESTION = 'airbagsfront';

class AirbagsFrontQuestions extends Component {
  state = {
    radio: null,
    checkBoxes: {},
  };

  UNSAFE_componentWillMount() {
    const { sectionDamage, modalModel } = this.props;

    this.setState(
      {
        modalModel: modalModel,
        radio: sectionDamage ? sectionDamage.Options['0'] : 0,
        checkBoxes: {
          0: {
            name: 'passenger',
            value:
              sectionDamage && sectionDamage.SubQuestion
                ? sectionDamage.SubQuestion.Options.find(
                    (item) => item * 1 === 0
                  ) !== undefined
                : false,
          },
          1: {
            name: 'driver',
            value:
              sectionDamage && sectionDamage.SubQuestion
                ? sectionDamage.SubQuestion.Options.find(
                    (item) => item * 1 === 1
                  ) !== undefined
                : false,
          },
        },
        enabledAction: null,
        disabledAction: (
          <FlatButton
            key={2}
            label="Complete form to continue"
            primary={true}
            disabled={true}
          />
        ),
      },
      () => {
        if (!this.state.modalModel.actions[2]) {
          return;
        }

        const oldAction = Object.assign(this.state.modalModel.actions[2], {});
        this.setState({
          enabledAction: oldAction,
        });
      }
    );

    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      modalModel: TriageStore.getCurrentModal(),
    });
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  };

  onRadioChangeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      {
        radio: e.target.value,
      },
      () => {
        this.setState({
          modalModel: this.props.setCorrectActions(this.state),
        });

        TriageActions.setModal(this.state.modalModel);
        if (this.state.radio == 0) {
          TriageActions.registerSectionDamage({
            Name: CURRENT_QUESTION,
            Options: [this.state.radio],
            SubQuestion: undefined,
          });
        } else {
          if (this.props.getSubQuestionValues(this.state).length > 0) {
            TriageActions.registerSectionDamage({
              Name: CURRENT_QUESTION,
              Options: [this.state.radio],
              SubQuestion: {
                Name: CURRENT_QUESTION,
                Options: this.props.getSubQuestionValues(this.state),
              },
            });
          }
        }
      }
    );
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  };

  setCheckBox = (e) => {
    this.state.checkBoxes[e].value =
      this.state.checkBoxes[e].value == true ? false : true;

    this.state.modalModel.actions[2] = this.props.checkSubQuestionsValid(
      this.state
    )
      ? this.state.enabledAction
      : this.state.disabledAction;

    TriageActions.setModal(this.state.modalModel);

    TriageActions.registerSectionDamage({
      Name: CURRENT_QUESTION,
      Options: [this.state.radio],
      SubQuestion: {
        Name: CURRENT_QUESTION,
        Options: this.props.getSubQuestionValues(this.state),
      },
    });
  };

  render() {
    const { radio, checkBoxes } = this.state;

    const subQuestions =
      radio == 1 ? (
        <div className={s.subQuestions}>
          <div className={s.formGroup}>
            <Checkbox
              className={s.checkbox}
              label="Driver's side"
              checked={checkBoxes[1].value}
              onClick={this.setCheckBox.bind(this, 1)}
              labelPosition="left"
            />
            <Checkbox
              className={s.checkbox}
              label="Passenger's side"
              checked={checkBoxes[0].value}
              onClick={this.setCheckBox.bind(this, 0)}
              labelPosition="left"
            />
          </div>
        </div>
      ) : null;

    return (
      <form className={s.formItem}>
        <h4>Have any front airbags deployed?</h4>
        <div>
          <RadioButtonGroup
            onChange={this.onRadioChangeHandler}
            className={s.radios}
            name={CURRENT_QUESTION}
            defaultSelected={radio.toString()}
            labelPosition="left"
          >
            <RadioButton value="1" label="Yes" />
            <RadioButton value="0" label="No airbag has deployed" />
          </RadioButtonGroup>
        </div>
        {subQuestions}
      </form>
    );
  }
}

let composed = AirbagsFrontQuestions;
composed = QuestionComponent(CURRENT_QUESTION)(composed);

export default composed;
