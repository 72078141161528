import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightSaloon extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M1007.8,97c0-17-1.3-25.1-4.9-28.1c-2.4-2.2-4.9-6.9-4.9-10.3c0-7.8-7.7-9.6-45.1-10.6l-27.1-0.5c-1.8,0-3.1,1.3-3.3,3  l1,17.9v0.1c-0.5,26.1-13.6,43.5-31.8,56.8c-25.1,18.2-67.5,10.8-87-21.2c-6.9-11-10.6-19.2-11.6-56.6l0.2-29.1  c-0.3-1.7-1.6-3-3.3-3h-98.1c-0.9,0-1.7,0.8-1.6,1.7l5.3,115.3c3.9,65.3,5.4,79.6,11.6,118.8c3.2,20.9,6.8,44.6,7.7,50.9  c0.1,0.9,0.9,1.5,1.8,1.4c6.4-0.8,30.1-3.9,45.3-8.6h0.1C864.6,257,942.2,205.4,943,205.4l58-0.2c1.1,0,1.8-1,1.6-2  c-1.1-4.5-3.5-14.9-4.9-31.3c-3.4-33.9-3.4-36.5,3-43.1C1006.1,122.8,1007.4,117.1,1007.8,97z M708,198.3l202.3,1.4  c2,0-125.9,83.1-190.7,85.4c-0.8,0-1.6-3.1-1.7-4C716.7,271.1,709.4,212.5,708,198.3L708,198.3z"
        />
        <path
          {...this.props}
          d="M1008.3,97.3c0-17-1.3-25.1-4.9-28.1c-2.4-2.2-4.9-6.9-4.9-10.3c0-7.8-7.7-9.6-45.1-10.6l-27.1-0.5   c-1.8,0-3.1,1.3-3.3,3l1,17.9v0.1c-0.5,26.1-13.6,43.5-31.8,56.8c-25.1,18.2-67.5,10.8-87-21.2c-6.9-11-10.6-19.2-11.6-56.6   l0.2-29.1c-0.3-1.7-1.6-3-3.3-3h-98.1c-0.9,0-1.7,0.8-1.6,1.7l5.3,115.3c3.9,65.3,5.4,79.6,11.6,118.8c3.2,20.9,6.8,44.6,7.7,50.9   c0.1,0.9,0.9,1.5,1.8,1.4c6.4-0.8,30.1-3.9,45.3-8.6h0.1c102.5-37.9,180.1-89.5,180.9-89.5l58-0.2c1.1,0,1.8-1,1.6-2   c-1.1-4.5-3.5-14.9-4.9-31.3c-3.4-33.9-3.4-36.5,3-43.1C1006.6,123.1,1007.9,117.4,1008.3,97.3z M961.8,182.5l0.1-31.8   c0,0,0-0.7,1.7-0.7h26.1c3.1,28.5,5.1,37.9,5.1,37.9c-9.6-0.2-21-0.2-27.6,0C964.3,188.2,961.8,185.8,961.8,182.5z M884.8,200   c2,0-99.9,83.1-164.7,85.4c-0.8,0-1.6-3.1-1.7-4c-1.3-10.1-8.5-68.6-9.9-82.8L884.8,200z M996,124.3c-1.3,1.3-3,2-4.7,2H906   c-16,0.5-4.2-3.4-2.2-5.4c1.3-1.3,3-2,4.7-2h85.3C999.7,118.6,998.1,122.4,996,124.3z"
        />
      </g>
    );
  }
}

let composed = WingBackRightSaloon;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
