const PAVPercentage = (props) => {
  const s = props.styles;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Write Off %: </span>
      <strong className={s.cols_6}>
        {props.percentage ? parseFloat(props.percentage).toFixed(2) : 0}%
      </strong>
    </li>
  );
};

export default PAVPercentage;
