import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeft4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M444,833.7l1.8,19.1H703l0.2-14.7c-0.2-2.5,1.1-4.5,1.1-4.5l-257.1,0.1H444z"
      />
    );
  }
}

let composed = SillLeft4x4;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
