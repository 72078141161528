import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class Tailgate4x4 extends Component {
  render() {
    return (
      <g className={s.tailGate}>
        <path
          {...this.props}
          d="M1047.8,654.4h-47c-4.4,0-8-3.6-8-8V273.7c0-4.4,3.6-8,8-8h47c4.4,0,8,3.6,8,8v372.7  C1055.8,650.8,1052.2,654.4,1047.8,654.4z"
        />
        <path
          {...this.props}
          d="M1045.8,265.7l0.1,17.3h-45.2v-17.3c-7.5,0-13.7,0-13.7,0v388.7c0,0,6.3,0,13.8,0l-0.1-16.1l45.2,0l0,16.1h48.9  l-0.6-388.7C1094.1,265.7,1053.3,265.7,1045.8,265.7z"
        />
      </g>
    );
  }
}

let composed = Tailgate4x4;
composed = SectionComponent({
  name: 'Tailgate',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Tailgate Damage',
    content: <Modal name="Tailgate" />,
  },
})(composed);

export default composed;
