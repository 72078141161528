import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelFrontLeftVan extends Component {
  render() {
    return (
      <g className={s.wheelFrontLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M303.7,786.1c-35,0-63.4,29.8-63.4,66.3c0,36.7,28.5,66.3,63.4,66.3s63.4-29.8,63.4-66.3  C367.2,815.6,338.7,786.1,303.7,786.1z M303.7,892.9c-21.5,0-38.8-18.2-38.8-40.7s17.3-40.7,38.8-40.7c21.5,0,38.8,18.2,38.8,40.7  S325.2,892.9,303.7,892.9z"
        />
        <path
          {...this.props}
          d="M303.7,814.2c-20.3,0-36.4,17-36.4,38.2s16.3,38.2,36.4,38.2c20.2,0,36.4-17,36.4-38.2  C340.2,831.5,324,814.2,303.7,814.2z M303.7,829c11.3,0,20.5,8.9,22,20.2h-1.3c-1.5-10.6-10.2-19-21-19c-10.8,0-19.5,8.1-21,19H281  C283,837.9,292.5,829,303.7,829z M275.7,852.4h2.5H275.7z M303.7,875.8c-11.3,0-20.5-8.7-22-20h1.3c1.5,10.6,10.2,18.7,21,18.7  c10.5,0,19.3-8.1,21-18.7h1.3C324.3,867.2,314.9,875.8,303.7,875.8z M329.2,852.4h1.5H329.2z"
        />
      </g>
    );
  }
}

let composed = WheelFrontLeftVan;
composed = SectionComponent({
  name: 'Front Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Front Passenger Wheel Damage',
    content: <Modal name="Front Passenger Wheel" />,
  },
})(composed);

export default composed;
