import { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import s from './MajorDamage.module.scss';
import TriageActions from '../../../actions/TriageActions';
import AppActions from '../../../actions/AppActions';
import TriageStore from '../../../stores/TriageStore';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import _ from 'underscore';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import PrevIcon from 'material-ui/svg-icons/image/navigate-before';
import QuickDamageCheckAirbags from './QuickDamageCheckAirbags';

const styles = {
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 16,
  },
  summary: {
    textAlign: 'right',
    height: '60px',
    paddingTop: '15px',
  },
  number: {
    width: '500px',
    marginLeft: '0px',
    marginTop: '100px',
    marginBottom: '20px',
    textIndent: '15px',
    fontSize: '20px',
    hr: {
      marginLeft: '15px',
    },
  },
  errorMessage: {
    fontSize: '20px',
  },
  submitButton: {
    marginTop: '20px',
  },
};

class MajorDamage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      criticals: [],
      disableContinue: false,
      disableConfirm: true,
      airbagsShowing: false,
      airbagsData: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    const currentCase = TriageStore.getCase();
    const airbags = currentCase.sectionDamage
      ? this.formatAirbagsData(currentCase.sectionDamage)
      : [];

    this.setState({
      criticals: currentCase.criticals,
      airbagsData: airbags,
    });
  };

  formatAirbagsData = (sectionDamage) => {
    const airbagQuestionsTemp = sectionDamage.filter((section) => {
      return (
        (section.Name === 'airbagsfront' &&
          parseInt(section.Options[0]) == 1 &&
          section.SubQuestion) ||
        (section.Name === 'airbagsside' &&
          parseInt(section.Options[0]) == 1 &&
          section.SubQuestion)
      );
    });

    if (!airbagQuestionsTemp) return [];

    const airbagQuestions =
      airbagQuestionsTemp.map((airbagQuestion) => {
        if (airbagQuestion.Name === 'airbagsfront') {
          const drivers = airbagQuestion.SubQuestion.Options.find((option) => {
            return parseInt(option) == 1;
          });
          const passengers = airbagQuestion.SubQuestion.Options.find(
            (option) => {
              return parseInt(option) == 0;
            }
          );
          return {
            area: 'front',
            drivers:
              drivers !== undefined && parseInt(drivers) == 1 ? true : false,
            passengers:
              passengers !== undefined && parseInt(passengers) == 0
                ? true
                : false,
          };
        }

        if (airbagQuestion.Name === 'airbagsside') {
          const drivers = airbagQuestion.SubQuestion.Options.find((option) => {
            return parseInt(option) == 1;
          });
          const passengers = airbagQuestion.SubQuestion.Options.find(
            (option) => {
              return parseInt(option) == 0;
            }
          );
          return {
            area: 'side',
            drivers:
              drivers !== undefined && parseInt(drivers) == 1 ? true : false,
            passengers:
              passengers !== undefined && parseInt(passengers) == 0
                ? true
                : false,
          };
        }
      }) || [];
    return airbagQuestions;
  };

  handleSelection = (e, value) => {
    let currentCase = TriageStore.getCase();
    let criticals = Array.from(currentCase.criticals);

    if (e.currentTarget.value === 'y') {
      criticals.push(e.currentTarget.name);
    } else {
      criticals = _.reject(criticals, (i) => {
        return i === e.currentTarget.name;
      });
    }
    criticals = _.uniq(criticals);
    this.setState(
      {
        criticals: criticals,
      },
      () => {
        currentCase.criticals = criticals;
        currentCase.mobileConfirmed = null;
        TriageActions.updateCase(currentCase);
        AppActions.addSpinner();
        TriageActions.setCriticalQuestions(criticals, () => {
          TriageActions.makeDecision(() => {
            AppActions.removeSpinner();
          });
        });
      }
    );

    let newState = TriageStore.getProgressState();
    newState.MAX_PERMITTED = 1;

    TriageActions.setProgressState(newState);
  };

  getSelected = (value) => {
    const v =
      _.find(this.state.criticals, (i) => {
        return i === value;
      }) === undefined
        ? 'n'
        : 'y';

    return v;
  };

  gotoPrevious = (e) => {
    e.preventDefault();
    let newState = TriageStore.getProgressState();
    newState.CURRENT_STAGE = 0;
    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    const currentCase = TriageStore.getCase();
    const caseSettings = currentCase.caseSettings;
    const shouldShowFurtherActions =
      currentCase.decision.RequestVariants ||
      caseSettings.ShowImagesComponent ||
      caseSettings.ShowRepairerSelectionGroup ||
      (caseSettings.ShowConfirmMobileGroup &&
        currentCase.decision.OfferMobileRepairChoice &&
        currentCase.mobileConfirmed === null) ||
      (caseSettings.ShowCarHireGroup && currentCase.decision.ShowCarHire);

    let newState = TriageStore.getProgressState();

    if (this.state.criticals.length != 0) {
      if (newState.DISABLED_STAGES.indexOf(2) === -1)
        newState.DISABLED_STAGES.push(2);
      if (shouldShowFurtherActions) {
        const index = newState.DISABLED_STAGES.indexOf(3);
        if (index > -1) newState.DISABLED_STAGES.splice(index, 1);
        newState.MAX_PERMITTED = 3;
        newState.CURRENT_STAGE = 3;
      } else {
        if (newState.DISABLED_STAGES.indexOf(3) === -1)
          newState.DISABLED_STAGES.push(3);
        newState.MAX_PERMITTED = 4;
        newState.CURRENT_STAGE = 4;
      }
    } else {
      newState.MAX_PERMITTED = 2;
      newState.CURRENT_STAGE = 2;
      const index = newState.DISABLED_STAGES.indexOf(2);
      if (index > -1) newState.DISABLED_STAGES.splice(index, 1);
    }

    const answeredAirbagQuestions = currentCase.answeredQuestionSets
      ? currentCase.answeredQuestionSets.filter((question) => {
          return question === 'airbagsfront' || question === 'airbagsside';
        })
      : [];

    if (answeredAirbagQuestions.length != 2) {
      AppActions.addSpinner();
      TriageActions.addCompletedQuestionSets(
        ['airbagsfront', 'airbagsside'],
        () => {
          AppActions.removeSpinner();
        }
      );
    }

    TriageActions.setProgressState(newState);
    this.props.recordTime();
  };

  captureAirbags = (showAirbags) => {
    this.setState({
      disableContinue: showAirbags,
      airbagsShowing: showAirbags,
    });
  };

  setContinueButton = (showContinue) => {
    let newState = TriageStore.getProgressState();
    newState.MAX_PERMITTED = 1;

    TriageActions.setProgressState(newState);
    this.setState({ disableContinue: !showContinue });
  };

  render() {
    const selectFire = this.getSelected('fire');
    const selectFlood = this.getSelected('flood');
    const selectDash = this.getSelected('dashboard');

    return (
      <div className={s.box}>
        <div className={s.container}>
          <div className={s.quickCheckSection}>
            <div>
              <h2>Airbags</h2>
              <ul className={s.checklist}>
                <li>
                  <QuickDamageCheckAirbags
                    onYesNoChange={this.captureAirbags}
                    setContinue={this.setContinueButton}
                    existingAirbagData={this.state.airbagsData}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.quickCheckSection}>
            <div className={s.criticals}>
              <h2>Critical Damage Check</h2>

              <div className={s.radiosInlineOuterContainer}>
                <div className={s.radiosInlineLabel}>
                  <strong>Has the vehicle suffered fire damage?</strong>
                </div>
                <div className={s.radiosInlineInnerContainer}>
                  <RadioButtonGroup
                    className={s.radiosInline}
                    ref="fire"
                    name="fire"
                    onChange={this.handleSelection}
                    defaultSelected={selectFire}
                  >
                    <RadioButton
                      className={s.crit}
                      value="y"
                      label="Yes"
                      style={styles.radioButton}
                    />
                    <RadioButton
                      value="n"
                      label="No"
                      style={styles.radioButton}
                    />
                  </RadioButtonGroup>
                </div>
              </div>

              <div className={s.radiosInlineOuterContainer}>
                <div className={s.radiosInlineLabel}>
                  <strong>Has the vehicle been flooded?</strong>
                </div>
                <div className={s.radiosInlineInnerContainer}>
                  <RadioButtonGroup
                    className={s.radiosInline}
                    ref="flood"
                    name="flood"
                    onChange={this.handleSelection}
                    defaultSelected={selectFlood}
                  >
                    <RadioButton
                      className={s.crit}
                      value="y"
                      label="Yes"
                      style={styles.radioButton}
                    />
                    <RadioButton
                      value="n"
                      label="No"
                      style={styles.radioButton}
                    />
                  </RadioButtonGroup>
                </div>
              </div>

              <div className={s.radiosInlineOuterContainer}>
                <div className={s.radiosInlineLabel}>
                  <strong>Has the dashboard moved?</strong>
                </div>
                <div className={s.radiosInlineInnerContainer}>
                  <RadioButtonGroup
                    className={s.radiosInline}
                    ref="dash"
                    name="dashboard"
                    onChange={this.handleSelection}
                    defaultSelected={selectDash}
                  >
                    <RadioButton
                      className={s.crit}
                      value="y"
                      label="Yes"
                      style={styles.radioButton}
                    />
                    <RadioButton
                      value="n"
                      label="No"
                      style={styles.radioButton}
                    />
                  </RadioButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.footerDialogContainer}>
          <div className={s.footerDialog}>
            <div className={s.nextButtons}>
              <div className={s.prevButton}>
                <RaisedButton
                  onClick={this.gotoPrevious}
                  label="Previous"
                  icon={<PrevIcon />}
                />
              </div>
              <div className={s.nextButton}>
                <RaisedButton
                  label="Continue"
                  primary={true}
                  disabled={this.state.disableContinue}
                  labelPosition="before"
                  icon={<NextIcon />}
                  onClick={this.saveAndContinue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MajorDamage;
