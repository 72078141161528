import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontLeftPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M617.8,823.8l0-114.2l-175.7-0.2c-2.6,10.4-1.6,21.9-2,36.8c-0.6,25.5-0.2,41.8,0.5,58.7c0.3,7,1.7,26.3,1.7,26.3h175.5  C617.8,831.2,617.7,827.9,617.8,823.8z"
      />
    );
  }
}

let composed = DoorFrontLeftPickup;
composed = SectionComponent({
  name: 'Front Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Passenger Door Damage',
    content: <Modal name="Front Passenger Door" />,
  },
})(composed);

export default composed;
