import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackLeftPV extends Component {
  render() {
    return (
      <g className={s.doorFrontLeft}>
        <path
          {...this.props}
          d="M537,598c0,0-1,60.1-0.7,121.8l1,112.2h73h73V716.3V605.4c0-4.1-3.3-7.4-7.4-7.4H537z"
        />
        <path
          {...this.props}
          d="M536.9,603.6l-0.7,89.8c-0.3,15.9,1.2,134.7,1,134.2h142.1V615.6c0-8.9-2-11.9-8.6-12H536.9z M571.9,710.8  c-18.7,8.1-30.2,6.8-24.2-3.7c2.4-3.1,12.7-5.6,22.9-5l19.3,0.6L571.9,710.8z"
        />
      </g>
    );
  }
}

let composed = DoorBackLeftPV;
composed = SectionComponent({
  name: 'Left Sliding Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Left Sliding Door Damage',
    content: <Modal name="Left Sliding Door" />,
  },
})(composed);

export default composed;
