import { Component } from 'react';
import AppActions from '../../../actions/AppActions';
import TriageActions from '../../../actions/TriageActions';
import TriageStore from '../../../stores/TriageStore';

const REGISTRATION_REGEXS = [
  '^[A-Z]{2}[0-9]{2}[A-Z]{3}$',
  '^[A-Z][0-9]{1,3}[A-Z]{3}$',
  '^[A-Z]{3}[0-9]{1,3}[A-Z]$',
  '^[0-9]{1,4}[A-Z]{1,2}$',
  '^[0-9]{1,3}[A-Z]{1,3}$',
  '^[A-Z]{1,2}[0-9]{1,4}$',
  '^[A-Z]{1,3}[0-9]{1,3}$',
  '^[A-Z]{2,3}[0-9]{1,4}$',
  '^[0-9]{1,4}[A-Z]{2,3}$',
];

function withValidation(ComposedComponent) {
  return class withValidation extends Component {
    validateRegistration = (self, cb) => {
      if (
        self.state.registration !== undefined &&
        self.state.registration !== ''
      ) {
        let isRegValid = false;
        for (let i in REGISTRATION_REGEXS) {
          var regex = new RegExp(REGISTRATION_REGEXS[i], 'i');
          isRegValid = self.state.registration.match(regex) !== null;
          if (isRegValid) break;
        }
        self.setState(
          {
            isRegValid: isRegValid === true || self.state.isNonUKReg,
          },
          () => {
            if (cb) cb(self);
          }
        );
      }
    };

    validateResponse = (errorCode, self) => {
      const currentCase = TriageStore.getCase();
      let newState = TriageStore.getProgressState();
      switch (errorCode) {
        case 0:
          self.setState({
            progressDisabled: false,
          });
          break;
        case 1:
          const note =
            currentCase.notes !== undefined && currentCase.notes !== '' ? (
              <p>{currentCase.notes}</p>
            ) : (
              <p>An unknown error occured</p>
            );
          //OutOfScope
          AppActions.createModal({
            open: true,
            title: 'VEHICLE IS OUT OF SCOPE',
            type: 'alert',
            content: (
              <div>
                {note}
                <p>You will not be able to continue.</p>
              </div>
            ),
            onConfirm: () => {
              self.setState({
                progressDisabled: true,
              });
              newState.MAX_PERMITTED = 0;
              TriageActions.closeCase();
            },
          });

          break;
        case 2:
          //ServiceUnreachable
          AppActions.createModal({
            open: true,
            title: 'ALERT: Valuation service unreachable',
            type: 'alert',
            content: (
              <div>
                <p>
                  Warning: the valuation service appears to be unreachable.
                  Please contact an administrator.
                </p>
              </div>
            ),
          });
          break;
        case 3:
          //VehicleNotFound
          AppActions.createModal({
            open: true,
            title: 'Warning: Vehicle Not Found',
            type: 'alert',
            content: (
              <div>
                <p>
                  Warning: there is no vehicle data in our records for this
                  vehicle registration.
                </p>
                <p>This may result in an inaccurate valuation.</p>
              </div>
            ),
          });
          break;
        case 4:
          //Unusual Mileage

          if (self.state.isMileageEstimated === false) {
            AppActions.createModal({
              open: true,
              title: 'Warning: Unusual Mileage Value entered',
              type: 'alert',
              content: (
                <div>
                  <p>
                    Warning: The mileage value entered is higher than expected
                    for a vehicle of this age.
                  </p>
                  <p>Please make sure that its correct before continuing.</p>
                </div>
              ),
            });
          }
          break;
        case 5:
          //NoValuation
          AppActions.createModal({
            open: true,
            title: 'Warning: No Valuation Data',
            type: 'alert',
            content: (
              <div>
                <p>
                  Warning: there is no valuation data in our records for this
                  vehicle registration.
                </p>
                <p>This may result in an inaccurate valuation.</p>
              </div>
            ),
          });
          break;
        default:
          console.log(`not handled: ${errorCode}`);
      }
      TriageActions.setProgressState(newState);
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          validateResponse={this.validateResponse}
          validateRegistration={this.validateRegistration}
          validateReferenceNumber={this.validateReferenceNumber}
        ></ComposedComponent>
      );
    }
  };
}
export default withValidation;
