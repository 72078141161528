import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackRight)}
        d="M790.8,248.8c-4.3,8.3-9.1,29.1-71.2,30.9c0,0-8.1-64.1-9.2-78.6l95,1.4c0,0-3.6,16-7.4,28.6  C796.3,236.5,791,248.6,790.8,248.8z"
      />
    );
  }
}

let composed = WindowBackRightConvertible;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
