import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontLeftConvertible extends Component {
  render() {
    return (
      <g className={s.wingFrontLeft}>
        <path
          {...this.props}
          d="M542.8,625.7c-0.1,0-0.2,0-0.4,0c-2.2,0.4-23.9,5.6-83.7,41.8c-44.5,26.8-69.1,39.6-76.1,39.6c-14.6,0-85.2,16.3-122.7,28.2  c-35.2,11.1-41,16-56.6,47.3c-12.2,24.6-15,54.2-5.2,58c4.1,1.8,4.8,5.2,3.2,17c-2.1,13.9-1.8,15.3,7.7,19.5  c5.2,2.8,26.4,5.9,46.6,7l33,1.9c1.9,0.1,3.4-1.4,3.4-3.3l-0.5-30.3c0-0.2,0-0.3,0-0.4c8.2-72.6,68.3-71.1,85.9-64.6  c26.9,10,44.1,32.2,45,73.8l-1.1,39.1c0,1.9,1.4,3.3,3.3,3.3h26.5c3.6-0.1,3.3-0.1,3.3-3.3l1.2-98.2c2.6-60.5,8.3-77.6,15.7-84  c45-39.2,63.3-51.7,63.3-51.7s22.9-16.3,29.2-20.3c9.8-6.2,23.2-11,23.2-11c14.3-6.2,101.5-5,120.1-3l3.3-16.9  C650.3,611.9,589.3,610.4,542.8,625.7L542.8,625.7z"
        />
        <path
          {...this.props}
          d="M542.8,625.7c-0.1,0-0.2,0-0.4,0c-2.2,0.4-23.9,5.6-83.7,41.8c-44.5,26.8-69.1,39.6-76.1,39.6  c-14.6,0-85.2,16.3-122.7,28.2c-35.2,11.1-41,16-56.6,47.3c-12.2,24.6-15,54.2-5.2,58c4.1,1.8,4.8,5.2,3.2,17  c-2.1,13.9-1.8,15.3,7.7,19.5c5.2,2.8,26.4,5.9,46.6,7l33,1.9c1.9,0.1,3.4-1.4,3.4-3.3l-0.5-30.3c0-0.2,0-0.3,0-0.4  c8.2-72.6,68.3-71.1,85.9-64.6c26.9,10,44.1,32.2,45,73.8l-1.1,39.1c0,1.9,1.4,3.3,3.3,3.3h26.5c3.6-0.1,3.3-0.1,3.3-3.3l1.2-98.2  c2.6-60.5,8.3-77.6,15.7-84c45-39.2,63.3-51.7,63.3-51.7s22.9-16.3,29.2-20.3c9.8-6.2,23.2-11,23.2-11c14.3-6.2,101.5-5,120.1-3  l3.3-16.9C650.3,611.9,589.3,610.4,542.8,625.7L542.8,625.7z M253.6,760.1c-1,0.2-25,22.9-30,27.6c-0.6,0.6-1.4,0.9-2.2,0.9  c-8.5,0-15.1,0-15.1-0.7c0-2.1,11-22.4,14.4-27.5c0.2-0.2,0.3-0.4,0.5-0.6C226.9,754.7,268.1,750,253.6,760.1L253.6,760.1z   M457.8,710.1c-6.9,3.4-37.6,2.7-51.1,1.5c-3.2-0.3-1.4-6,5.7-10.6c0,0,88.4-53.4,107.8-60.8c0.5-0.2,3-2.9,13.8-3.7h33.1  c-11.5,3.4-40.4,21.6-63.2,37.9C482.1,689.8,463,707.6,457.8,710.1L457.8,710.1z"
        />
      </g>
    );
  }
}

let composed = WingFrontLeftConvertible;
composed = SectionComponent({
  name: 'Front Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Passenger Wing Damage',
    content: <Modal name="Front Passenger Wing" />,
  },
})(composed);

export default composed;
