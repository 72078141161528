const CarHireVAT = (props) => {
  const s = props.styles;
  const currentCase = props.currentCase;
  const carHireCostVat =
    currentCase && currentCase.decision
      ? currentCase.decision.CarHireCostVat || 0
      : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Car Hire VAT: </span>
      <strong className={s.cols_6}>
        £{parseFloat(carHireCostVat).toFixed(2)}
      </strong>
    </li>
  );
};

export default CarHireVAT;
