import { Component } from 'react';

function withDataHelpers(ComposedComponent) {
  return class WithDataHelpers extends Component {
    constructor() {
      super();
      this.state = {};
    }

    processAppData = (result) => {
      return {
        AllowPDFDownload: {
          name: 'Allow PDF Download',
          displayValue: result['AllowPDFDownload'],
          data: result['AllowPDFDownload'],
          isBool: true,
        },
        AllowVehicleHistoryQuery: {
          name: 'Allow Vehicle History Query',
          displayValue: result['AllowVehicleHistoryQuery'],
          data: result['AllowVehicleHistoryQuery'],
          isBool: true,
        },
        ClaimReferenceNumberRegex: {
          name: 'Claim ReferenceNumber Regex',
          displayValue: result['ClaimReferenceNumberRegex'],
          data: result['ClaimReferenceNumberRegex'],
          regex: null,
        },
        EnforceBusinessLines: {
          name: 'Enforce Business Lines',
          displayValue: result['EnforceBusinessLines'],
          data: result['EnforceBusinessLines'],
          isBool: true,
        },
        IsClaimReferenceMandatory: {
          name: 'Is ClaimReference Mandatory',
          displayValue: result['IsClaimReferenceMandatory'],
          data: result['IsClaimReferenceMandatory'],
          isBool: true,
        },
        ShowCarHireGroup: {
          name: 'ShowCarHireGroup',
          displayValue: result['ShowCarHireGroup'],
          data: result['ShowCarHireGroup'],
          isBool: true,
        },
        ShowConfirmMobileGroup: {
          name: 'Show Confirm Mobile Group',
          displayValue: result['ShowConfirmMobileGroup'],
          data: result['ShowConfirmMobileGroup'],
          isBool: true,
        },
        ShowDamageDescription: {
          name: 'Show Damage Description',
          displayValue: result['ShowDamageDescription'],
          data: result['ShowDamageDescription'],
          isBool: true,
        },
        ShowImagesComponent: {
          name: 'Show Images Component',
          displayValue: result['ShowImagesComponent'],
          data: result['ShowImagesComponent'],
          isBool: true,
        },
        ShowPAV: {
          name: 'Show PAV',
          displayValue: result['ShowPAV'],
          data: result['ShowPAV'],
          isBool: true,
        },
        ShowPercentageWriteOff: {
          name: 'Show Percentage WriteOff',
          displayValue: result['ShowPercentageWriteOff'],
          data: result['ShowPercentageWriteOff'],
          isBool: true,
        },
        ShowRepairCost: {
          name: 'Show Repair Cost',
          displayValue: result['ShowRepairCost'],
          data: result['ShowRepairCost'],
          isBool: true,
        },
        ShowRepairerSelectionGroup: {
          name: 'Show Repairer SelectionGroup',
          displayValue: result['ShowRepairerSelectionGroup'],
          data: result['ShowRepairerSelectionGroup'],
          isBool: true,
        },
        ShowReserveValue: {
          name: 'Show Reserve Value',
          displayValue: result['ShowReserveValue'],
          data: result['ShowReserveValue'],
          isBool: true,
        },
        ShowSettlementPrompt: {
          name: 'Show Settlement Prompt',
          displayValue: result['ShowSettlementPrompt'],
          data: result['ShowSettlementPrompt'],
          isBool: true,
        },
        ShowTotalLossCategory: {
          name: 'Show TotalLoss Category',
          displayValue: result['ShowTotalLossCategory'],
          data: result['ShowTotalLossCategory'],
          isBool: true,
        },
        ShowValuation: {
          name: 'Show Valuation',
          displayValue: result['ShowValuation'],
          data: result['ShowValuation'],
          isBool: true,
        },
      };
    };

    processOrgData = (result) => {
      return {
        PreferredService: {
          name: 'Preferred Service (CDL/GLASS/GLASS MS)',
          displayValue: result['PreferredService'],
          data: result['PreferredService'],
          regex: /(GLASS MS|glass ms|GLASS|CDL|glass|cdl)\b/,
        },
        AlternativeServices: {
          name: 'Alternative Services',
          displayValue: result['AlternativeServices'],
          data: result['AlternativeServices'],
          regex: null,
        },
        CherisedVehicleAge: {
          name: 'Cherished Vehicle Age',
          displayValue:
            result['CherisedVehicleAge'] !== null
              ? result['CherisedVehicleAge'] + ' years'
              : null,
          data: result['CherisedVehicleAge'],
          regex: /^\d+$/,
        },
        CherishedVehicleAnnualMileage: {
          name: 'Cherished Vehicle Annual Mileage',
          displayValue:
            result['CherishedVehicleAnnualMileage'] !== null
              ? result['CherishedVehicleAnnualMileage'] + ' miles'
              : null,
          data: result['CherishedVehicleAnnualMileage'],
          regex: /^\d+$/,
        },
        ClassicVehicleAge: {
          name: 'Classic Vehicle Age',
          displayValue:
            result['ClassicVehicleAge'] !== null
              ? result['ClassicVehicleAge'] + ' years'
              : null,
          data: result['ClassicVehicleAge'],
          regex: /^\d+$/,
        },
        MaximumValuation: {
          name: 'Maximum Valuation',
          displayValue:
            result['MaximumValuation'] !== null
              ? '£' + result['MaximumValuation']
              : null,
          data: result['MaximumValuation'],
          regex: /^\d+$/,
        },
        NewForOldAge: {
          name: 'New For Old Age',
          displayValue:
            result['NewForOldAge'] !== null
              ? result['NewForOldAge'] + ' years'
              : null,
          data: result['NewForOldAge'],
          regex: /^\d+$/,
        },
        PremiumVehiclePrice: {
          name: 'Premium Vehicle Price',
          displayValue:
            result['PremiumVehiclePrice'] !== null
              ? '£' + result['PremiumVehiclePrice']
              : null,
          data: result['PremiumVehiclePrice'],
          regex: /^\d+$/,
        },
        TradeLabourRate: {
          name: 'Trade Labour Rate',
          displayValue:
            result['TradeLabourRate'] !== null
              ? '£' + result['TradeLabourRate']
              : null,
          data: result['TradeLabourRate'],
          regex: /^(\d+\.?\d{0,9}|\.\d{1,9})$/,
        },
        TradePaintDiscount: {
          name: 'Trade Paint Discount ',
          displayValue:
            result['TradePaintDiscount'] !== null
              ? result['TradePaintDiscount'] + '%'
              : null,
          data: result['TradePaintDiscount'],
          regex: /^(\d+\.?\d{0,9}|\.\d{1,9})$/,
        },
        TradePartsDiscount: {
          name: 'Trade Parts Discount',
          displayValue:
            result['TradePartsDiscount'] !== null
              ? result['TradePartsDiscount'] + '%'
              : null,
          data: result['TradePartsDiscount'],
          regex: /^(\d+\.?\d{0,9}|\.\d{1,9})$/,
        },
        WriteOffMultiplier: {
          name: 'Write Off Multiplier',
          displayValue:
            result['WriteOffMultiplier'] !== null
              ? 'x ' + result['WriteOffMultiplier']
              : null,
          data: result['WriteOffMultiplier'],
          regex: /^(\d+\.?\d{0,9}|\.\d{1,9})$/,
        },
        GTARatesCode: {
          name: 'GTA Rates Code',
          displayValue: result['GTARatesCode'],
          data: result['GTARatesCode'],
          regex: null,
        },
      };
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          processAppSettings={this.processAppData}
          processOrgSettings={this.processOrgData}
        />
      );
    }
  };
}

export default withDataHelpers;
