import { Component } from 'react';
import s from './MobileRepair.module.scss';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

class MobileRepair extends Component {
  static defaultProps = {
    changeHandler: () => {},
    overrideTextArray: [],
  };

  UNSAFE_componentWillMount() {
    try {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    } catch (e) {}
  }

  render() {
    const overrideText = this.props.overrideTextArray.map((para, index) => (
      <p key={index}>{para}</p>
    ));

    return (
      <div className={s.root}>
        <p>Would you like to take advantage of a mobile repair?</p>

        {overrideText}

        <RadioButtonGroup
          onChange={this.props.changeHandler}
          defaultSelected={this.props.defaultSelected || null}
          className={s.radios}
          name={'confirmMobile'}
          labelPosition="left"
        >
          <RadioButton value="1" label="Yes" />
          <RadioButton value="0" label="No" />
        </RadioButtonGroup>
      </div>
    );
  }
}

let composed = MobileRepair;

export default composed;
