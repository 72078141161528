import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackLeftHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M678.8,721.7l-8.5,138.7l120.9,0.4c-0.5-25.5,5-38.2,11.3-48.3c6.9-11.5,24.4-21.5,24.6-21.6c6.8-10.1,19.7-65.4,18.7-69.2  L678.8,721.7z"
      />
    );
  }
}

let composed = DoorBackLeftHatchback;
composed = SectionComponent({
  name: 'Back Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Passenger Door Damage',
    content: <Modal name="Back Passenger Door" />,
  },
})(composed);

export default composed;
