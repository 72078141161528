import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRightPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M442.2,84.5l191.4,0.1V45.9l-191.2-0.4L442.2,84.5L442.2,84.5z"
      />
    );
  }
}

let composed = SillRightPickup;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
