import createHistory from 'history/lib/createBrowserHistory';
import useQueries from 'history/lib/useQueries';
import { useBasename } from 'history';
import { baseUrl } from '../config';

const location = useQueries(() =>
  useBasename(createHistory)({ basename: baseUrl })
)();

export default location;
