import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class BonnetPV extends Component {
  render() {
    //console.log(this.props);
    return (
      <path
        className={s.bonnet}
        {...this.props}
        d="M310.1,608.3c-15-31.5-17.7-83.6-17.7-138.3v-18c0-54.7,2.8-107,17.7-138.5c0-0.1-1.2-2.6-0.2-4.4  c0.3-0.6-1.4-1.8-1.8-1.9c-37.3-5.4-105.7,9.1-109.6,14.8c-0.1,0-0.8,0.4-0.9,1c-5.7,17.6-8.9,72.1-9.3,129l-0.1,18  c0.4,56.6,3.5,111.2,9.1,128.8c0.2,0.6,0.5,0.9,0.7,0.9c3.9,5.7,71.7,20.2,109,14.8c0.4-0.1,3.3-1.2,3-1.8  C309.1,610.8,310.2,608.4,310.1,608.3z"
      />
    );
  }
}

let composed = BonnetPV;
composed = SectionComponent({
  name: 'Bonnet',
  category: 'Panels',
  highlighted: false,
  modalModel: {
    title: 'Register Bonnet Damage',
    content: <Modal name="Bonnet" />,
    actions: [],
  },
})(composed);

export default composed;
