import React from 'react';

const SuggestedRate = (props) => {
  const s = props.styles;
  const { defaultSuggestedRate } = props;

  //console.log(props.dropdownItems)
  return (
    <ul className={s.rateDisplay}>
      <li>Suggested Hire Rate: </li>
      <li>
        <strong>Group: </strong>
        <span>
          {defaultSuggestedRate ? defaultSuggestedRate.GroupName || '' : ''}
        </span>
      </li>
      <li>
        <strong>Code: </strong>
        <span>
          {defaultSuggestedRate ? defaultSuggestedRate.GroupCode || '' : ''}
        </span>
      </li>
      <li>
        <strong>Rate: </strong>
        <span>
          {'£' +
            parseFloat(
              defaultSuggestedRate
                ? defaultSuggestedRate.GroupCodeRate || '0'
                : '0'
            ).toFixed(2)}
        </span>
      </li>
    </ul>
  );
};

export default SuggestedRate;
