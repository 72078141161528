import { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const BUTTON_STYLE = {
  width: '100%',
  float: 'right',
  minWidth: 55,
  maxWidth: 300,
};

export default class EditButton extends Component {
  state = {
    editHandler: null,
    data: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      editHandler: this.props.metadata.editHandler || function () {},
      data: this.props.data,
    });
  }

  render() {
    const { data, editHandler } = this.state;
    return (
      <div>
        <RaisedButton
          primary={true}
          style={BUTTON_STYLE}
          onClick={editHandler.bind(this, data)}
        >
          Edit
        </RaisedButton>
      </div>
    );
  }
}
