import { Component } from 'react';
import s from './BulkUploadUsersPage.module.scss';
import cx from 'classnames';
import UserActions from '../../../../actions/UserActions';
import AppActions from '../../../../actions/AppActions';
import withLoggedInUser from '../../../../decorators/withLoggedInUser';
import ManageUserComponent from '../_ManageUserComponent';
import UsersSelectControl from '../UsersSelectControl';
import Dropzone from 'react-dropzone';

class BulkUploadUsers extends Component {
  state = {
    filename: '',
    pendingUsersArr: [],
    uploadOutcome: null,
    createdUsers: [],
    failedUsers: [],
  };

  buildUserModel = (user) => {
    UserActions.createNewUser({
      user: user,
      callback: (response) => {
        if (response === true) {
          user.outcome = 'Success';
          this.state.createdUsers.push(user);
          this.setState({ uploadOutcome: 'Users created successfully' });
        } else if (response === false) {
          user.outcome = 'Failed';
          this.state.failedUsers.push(user);
          this.setState({ uploadOutcome: 'Users not created' });
        } else {
          this.setState({
            uploadOutcome: 'Response somehow not true or false',
          });
        }

        UserActions.setNewUserDetails(null);

        this.setState({ openEditor: false }, () => {
          AppActions.removeSpinner();
          AppActions.createModal({
            open: true,
            title: 'Create User',
            type: 'alert',
            content: (
              <div>
                <p>
                  {this.state.createdUsers.length} users successfully created
                </p>
                <p>{this.state.failedUsers.length} users failed</p>
              </div>
            ),
          });
        });
      },
    });
  };

  processCSV = (acceptedFiles) => {
    let fileUpload = acceptedFiles[0];
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
    if (regex.test(fileUpload.name.toLowerCase())) {
      if (typeof FileReader != 'undefined') {
        let reader = new FileReader();
        reader.onload = (e) => {
          const rows = e.target.result.split('\n');
          const tempUserString = rows;
          const pendingUsersString = tempUserString.filter((e) => {
            return e;
          });
          const InsurerId = this.props.submitData.insurer.value;
          const DepartmentId = this.props.submitData.department.value;
          const pendingUsersArr = pendingUsersString.map((user) => {
            return {
              Email: user,
              comments: '',
              DepartmentId: DepartmentId,
              InsurerId: InsurerId,
              IsApproved: true,
              IsLocked: false,
              UserName: user.substring(0, user.indexOf('@')),
              outcome: 'Pending',
              roles: ['TriageUser'],
            };
          });

          this.setState({ pendingUsersArr: pendingUsersArr });
          pendingUsersArr.map((user) => {
            this.buildUserModel(user);
          });
          this.setState({ filename: '' });
        };
        reader.readAsText(fileUpload);
      } else {
        alert('This browser does not support HTML5.');
        this.setState({ filename: '' });
      }
    } else {
      alert('Please upload a valid CSV file.');
      this.setState({ filename: '' });
    }
  };

  checkDepartment = (acceptedFiles) => {
    this.props.submitData.department.isValid
      ? this.processCSV(acceptedFiles)
      : this.rejectUpload();
  };

  rejectUpload = () => {
    this.setState({ filename: '' });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ filename: acceptedFiles[0].name });
    this.props.submitData.insurer.isValid
      ? this.checkDepartment(acceptedFiles)
      : this.rejectUpload();
  };

  render() {
    const submitData = this.props.submitData;
    const isLocked = this.props.isLocked;

    const insurersUI = this.props.showInsurer ? (
      <UsersSelectControl
        changeHandler={this.props.handleSelectChange}
        dataName="insurer"
        data={submitData.insurer}
        items={this.props.insurers}
        valMessage="Valid Insurer is required"
        label="Insurer"
        reference="insurerField"
        initialValueText="Select Insurer..."
        initialValue={0}
        onChange={this.handleInsurerChange}
      />
    ) : null;

    const departmentsUI = this.props.showDepartment ? (
      <UsersSelectControl
        data={submitData.department}
        dataName="department"
        changeHandler={this.props.handleSelectChange}
        items={this.props.departments}
        valMessage="Valid Department is required"
        label="Department"
        reference="departmentField"
        initialValueText="Select Department..."
        initialValue={0}
      />
    ) : null;

    const validationMessage = this.props.showValidationMessage ? (
      <div className={s.validationMessage}>
        Please make sure all fields are valid before trying to save
      </div>
    ) : null;

    const dropzoneContainer = this.state.filename ? (
      <div className={s.dropzone}>Creating users</div>
    ) : (
      <div>
        <Dropzone onDrop={this.onDrop} className={s.dropzone}>
          Please select an insurer <br />
          (and department where necessary)
          <br /> Then drag and drop your file here to upload
        </Dropzone>
        <label>{this.state.filename}</label>
      </div>
    );

    const tableHeader =
      this.state.pendingUsersArr.length > 0 ? (
        <th>Pending Users</th>
      ) : (
        <th></th>
      );

    const pendingUserList = this.state.pendingUsersArr.map((user) => {
      return (
        <tr key={user.Email}>
          <td>{user.Email}</td>
          <td>{user.outcome}</td>
        </tr>
      );
    });

    return (
      <div className={s.uploadUsersModal}>
        <div className={cx(s.root, s.row)}>
          <div className={s.cols_4}>
            {insurersUI}
            {departmentsUI}
          </div>
          <div className={s.cols_4}>{dropzoneContainer}</div>
          <div className={s.cols_4}>
            <table>
              <thead>
                <tr>{tableHeader}</tr>
              </thead>
              <tbody>{pendingUserList}</tbody>
            </table>
          </div>
        </div>
        {validationMessage}
      </div>
    );
  }
}

let composed = withLoggedInUser(BulkUploadUsers);
composed = ManageUserComponent('BULKUPLOAD')(composed);

export default composed;
