import { Component } from 'react';
import s from './EditUserPage.module.scss';

import cx from 'classnames';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import withLoggedInUser from '../../../../decorators/withLoggedInUser';
import ManageUserComponent from '../_ManageUserComponent';
import UsersSelectControl from '../UsersSelectControl';
import UsersRolesList from '../UsersRolesList';

class EditUser extends Component {
  componentDidUpdate = () => {
    try {
      window.dispatchEvent(new Event('resize'));
    } catch (e) {}
  };

  render() {
    const submitData = this.props.submitData;
    const isLocked = this.props.isLocked;

    const insurersUI = this.props.showInsurer ? (
      <UsersSelectControl
        isLocked={isLocked}
        dataName="insurer"
        changeHandler={this.props.handleSelectChange}
        data={this.props.submitData.insurer}
        items={this.props.insurers}
        valMessage="Valid Insurer is required"
        label="Insurer"
        reference="insurerField"
        initialValueText="Select Insurer..."
        initialValue={0}
      />
    ) : null;

    const departmentsUI = this.props.showDepartment ? (
      <UsersSelectControl
        isLocked={isLocked}
        dataName="department"
        data={submitData.department}
        changeHandler={this.props.handleSelectChange}
        items={this.props.departments}
        valMessage="Valid Department is required"
        label="Department"
        reference="departmentField"
        initialValueText="Select Department..."
        initialValue={0}
      />
    ) : null;

    const validationMessage = this.props.showValidationMessage ? (
      <div className={s.validationMessage}>
        Please make sure all fields are valid before trying to save
      </div>
    ) : null;

    return (
      <div>
        <div className={cx(s.root, s.row)}>
          <div className={s.cols_4}>
            <TextField
              floatingLabelText="Username"
              value={submitData.name.value}
              disabled={true}
            />
            <TextField
              floatingLabelText="Email"
              value={submitData.email.value}
              onChange={this.props.handleDataChange.bind(
                this,
                'email',
                this.props.EMAIL_REGEX
              )}
              errorText={
                submitData.email.isValid ? null : 'Valid email is required'
              }
              disabled={isLocked}
            />
            {insurersUI}
            {departmentsUI}
          </div>
          <div className={s.cols_4}>
            <TextField
              floatingLabelText="Comment"
              value={submitData.comment.value}
              multiLine={true}
              onChange={this.props.handleDataChange.bind(this, 'comment', null)}
              disabled={isLocked}
              rows={5}
            />
            <Checkbox
              style={{ margin: '20px 0' }}
              label="Locked out"
              labelPosition="left"
              checked={submitData.isLocked.value}
              disabled={isLocked}
              onClick={this.props.handleCheckToggle.bind(this, 'isLocked')}
            />
          </div>
          <div className={s.cols_4}>
            <UsersRolesList
              allRoles={this.props.roles}
              userRoles={submitData.roles}
              currentlyLoggedInUser={this.props.currentlyLoggedInUser}
              handler={this.props.handleRoleChange}
              isLocked={isLocked}
              caller={this}
            />
          </div>
        </div>
        {validationMessage}
      </div>
    );
  }
}

let composed = withLoggedInUser(EditUser);
composed = ManageUserComponent('EDIT')(composed);

export default composed;
