import { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { isValid } from 'postcode';

class PostcodeQuestion extends Component {
  state = {
    isPostcodeValid: true,
    allowInvalidPostcode: false,
  };

  onChangePostcode = (evt) => {
    let postcode = evt.target.value;

    // Outcode (2-4 characters) + space (1 character) + incode (3 characters)
    const maxPostcodeLength = 8;

    if (postcode.length > maxPostcodeLength) {
      postcode = postcode.substring(0, maxPostcodeLength);
    }

    this.setState({
      isPostcodeValid: true,
      allowInvalidPostcode: false,
    });

    this.props.postcodeChangeHandler(postcode);
  };

  onClickSave = () => {
    const postcode = this.props.currentValue;

    const isPostcodeValid =
      postcode.length > 0 ? isValid(this.props.currentValue) : true;

    if (!isPostcodeValid) {
      this.setState({
        isPostcodeValid: isPostcodeValid,
        allowInvalidPostcode: true,
      });

      if (!this.state.allowInvalidPostcode) {
        return;
      }
    }

    this.setState({
      isPostcodeValid: true,
      allowInvalidPostcode: false,
    });

    this.props.postcodeSaveHandler();
  };

  render() {
    return (
      <div>
        <p>Current Vehicle Location:</p>

        <div className={this.props.styles.row}>
          <span className={this.props.styles.cols_10}>
            <TextField
              id="postcode"
              name="postcode"
              value={
                this.props.currentValue === null ? '' : this.props.currentValue
              }
              onChange={this.onChangePostcode}
              inputStyle={{ textTransform: 'uppercase' }}
              fullWidth={true}
              floatingLabelText="enter the full postcode of the vehicle (eg SK11 6JX) "
              floatingLabelFixed={true}
              errorText={
                this.state.isPostcodeValid
                  ? null
                  : 'The postcode you have entered looks invalid, please ensure it is correct as it may impact routing decisions. If it is correct, press [Save] again to continue.'
              }
            />
          </span>
          <span className={this.props.styles.cols_2}>
            <RaisedButton
              label="Save"
              primary={true}
              disabled={this.props.currentValue === this.props.submittedValue}
              onClick={this.onClickSave}
              style={{ verticalAlign: 'bottom' }}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default PostcodeQuestion;
