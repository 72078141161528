import axios from 'axios';
import { apiServer, queryTimeout } from '../config.js';
import AppAPI from './appAPI.js';

const lockAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },
  unlockCase(data, cb) {
    axios({
      method: 'post',
      data: { CaseID: data.caseId },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/unlockCase/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((err) => {
        if (cb) cb(false);
      });
  },

  getLockedCases(data, cb) {
    axios({
      method: 'post',
      baseURL: apiServer,
      data: { Insurer: data },
      timeout: queryTimeout,
      url: 'triage/getLockedCases/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(() => {
        if (cb) cb([]);
      });
  },

  updateLock(data, cb) {
    axios({
      method: 'post',
      data: { CaseId: data.caseId },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/updateLock',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(() => {
        if (cb) cb(false);
      });
  },
};

export default lockAPI;
