import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelFrontLeft extends Component {
  render() {
    return (
      <g className={s.wheelFrontLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M357.3,787.1c-34.5,0-62.5,29.8-62.5,66.3c0,36.7,28.1,66.3,62.5,66.3s62.5-29.8,62.5-66.3  C419.9,816.6,391.8,787.1,357.3,787.1z M357.3,893.9c-21.2,0-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7s38.2,18.2,38.2,40.7  S378.5,893.9,357.3,893.9z"
        />
        <path
          {...this.props}
          d="M357.3,815.1c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2c20,0,35.9-17,35.9-38.2C393.3,832.4,377.2,815.1,357.3,815.1z   M357.3,876.7c-11.1,0-20.2-8.7-21.7-20h1.3c1.5,10.6,10.1,18.7,20.7,18.7c10.3,0,19-8.1,20.7-18.7h1.3  C377.5,868.1,368.5,876.7,357.3,876.7z M378,850.1c-1.5-10.6-10.1-19-20.7-19c-10.6,0-19.2,8.1-20.7,19h-1.3  c1.5-11.3,10.6-20.2,21.7-20.2c11,0,20.2,8.9,21.7,20.2H378z"
        />
      </g>
    );
  }
}

let composed = WheelFrontLeft;
composed = SectionComponent({
  name: 'Front Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Front Passenger Wheel Damage',
    content: <Modal name="Front Passenger Wheel" />,
  },
})(composed);

export default composed;
