import { Component } from 'react';

import s from './BusinessLineSelect.module.scss';
import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui/Menu';

class BusinessLineSelect extends Component {
  state = {
    selectedLine: null,
    lines: [],
  };

  UNSAFE_componentWillMount() {
    this.setState(
      {
        selectedLine: this.props.selectedLine,
        lines: this.props.lines,
      },
      () => {}
    );
  }

  createItems = (items) => {
    let results = null;

    if (items) {
      results = items.map((item, index, list) => {
        return (
          <MenuItem key={index + 1} value={item.ID} primaryText={item.Name} />
        );
      });
    }
    return results;
  };

  render() {
    const menuItems =
      this.props.lines && this.props.lines.length > 0
        ? this.createItems(this.props.lines)
        : null;
    //console.log("BusinessLineSelect - props:", this.props);
    return (
      <div className={s.businessLineSelect}>
        <SelectField
          fullWidth={true}
          floatingLabelText="product line"
          value={this.props.selectedLine || null}
          onChange={this.props.onChangeHandler.bind(this)}
          errorText={
            this.props.selectedLine ? null : 'Choose a product line to continue'
          }
        >
          {menuItems}
        </SelectField>
      </div>
    );
  }
}

let composed = BusinessLineSelect;

export default composed;
