import { Component } from 'react';
import PropTypes from 'prop-types';
import s from '../PermissionsPage.module.scss';

import Link from '../../Link';
import { Tabs, Tab } from 'material-ui/Tabs';
import withLoggedInUser from '../../../decorators/withLoggedInUser';
import OrgSettingsTable from './Tables/OrgSettingsTable';
import AppSettingsTable from './Tables/AppSettingsTable';
import AppActions from '../../../actions/AppActions';
import OrgActions from '../../../actions/OrganisationActions';
import AppDialog from './Dialogs/AppSettingsDialog';
import OrgDialog from './Dialogs/OrgSettingsDialog';
import OrgAPI from '../../../api/organisationAPI.js';

const title = 'Inter-cept | Permissions | Organisations';

class OrganisationsPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    currentTab: 'org',
    currentUser: null,
    orgSettings: {
      submitData: null,
      isValid: true,
    },
    appSettings: {
      submitData: null,
      isValid: true,
    },
  };

  UNSAFE_componentWillMount() {
    this.context.onSetTitle(title);
    this.setState(
      {
        currentUser: this.props.currentUser,
      },
      () => {
        if (!this.state.currentUser.isAdmin) {
          Location.pushState('/');
        }
      }
    );
  }

  handleTabbing = (currentTab) => {
    this.setState({
      currentTab: currentTab,
    });
  };

  editSubmitData = (table, key, data) => {
    this.state[table].submitData[key] = data;
  };

  checkErrorState = (table, errors) => {
    this.state[table].isValid = errors.length > 0 ? false : true;
    AppActions.updateModal({
      disableConfirm: !this.state[table].isValid,
    });
  };

  openOrgSettings = (id) => {
    OrgAPI.getOrganisation(id)
      .then((result) => {
        this.state.orgSettings.submitData = result;
        AppActions.createModal({
          open: true,
          title: `Edit Organisation Settings : ${result['Name']} `,
          customStyle: { maxWidth: '100%' },
          type: 'confirm',
          disableConfirm: false,
          content: (
            <OrgDialog
              styles={s}
              data={result}
              table={'orgSettings'}
              editErrorState={this.checkErrorState}
              editSubmitModel={this.editSubmitData}
            />
          ),
          onConfirm: () => {
            if (this.state.orgSettings.isValid) {
              OrgActions.updateOrgSettings(this.state.orgSettings.submitData);
            }
          },
        });
      })
      .catch(() => {
        throw 'getOganisation failed';
      });
  };

  openAppSettings = (data) => {
    const model = JSON.parse(data);

    OrgAPI.getRuleset(model.OrganisationID, model.DepartmentID)
      .then((result) => {
        this.state.appSettings.submitData = result;
        const title = result['DepartmentName']
          ? `Edit App Settings : ${result['InsurerName']}  | ${result['DepartmentName']}`
          : `Edit App Settings : ${result['InsurerName']} `;

        AppActions.createModal({
          open: true,
          title: title,
          customStyle: { maxWidth: '100%' },
          type: 'confirm',
          disableConfirm: false,
          content: (
            <AppDialog
              styles={s}
              data={result}
              table={'appSettings'}
              editErrorState={this.checkErrorState}
              editSubmitModel={this.editSubmitData}
            />
          ),
          onConfirm: () => {
            if (this.state.appSettings.isValid) {
              OrgActions.updateAppSettings(this.state.appSettings.submitData);
            }
          },
        });
      })
      .catch(() => {
        throw 'getRuleset failed';
      });
  };

  render() {
    return this.state.currentUser.isAdmin ? (
      <div className={s.box}>
        <ul className={s.backLinks}>
          <li>
            <a href="/dashboard" onClick={Link.handleClick}>
              Back to Dashboard
            </a>
          </li>
        </ul>
        <h1 className={s.heading}>Organisations</h1>
        <Tabs value={this.state.currentTab} onChange={this.handleTabbing}>
          <Tab label="Organisation Settings" value="org">
            <OrgSettingsTable editHandler={this.openOrgSettings} styles={s} />
          </Tab>
          <Tab label="App Settings" value="app">
            <AppSettingsTable editHandler={this.openAppSettings} styles={s} />
          </Tab>
        </Tabs>
      </div>
    ) : (
      <div>You do not have permission to view this page</div>
    );
  }
}

let composed = withLoggedInUser(OrganisationsPage);

export default composed;
