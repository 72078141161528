import { Component } from 'react';
import s from './CashInLieu.module.scss';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TriageStore from '../../../../stores/TriageStore';

class CashInLieu extends Component {
  static defaultProps = {
    changeHandler: () => {},
    decision: {},
  };

  state = {
    selectedIndex: null,
    currentCase: TriageStore.getCase(),
  };

  UNSAFE_componentWillMount() {
    try {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    } catch (e) {}
  }

  onChangeHandler = (e, i) => {
    this.setState({
      selectedIndex: i,
    });
    this.props.changeHandler(e, i);
  };

  render() {
    const blurb =
      this.state.selectedIndex * 1 === 0 ? (
        <legend>
          {this.props.decision.CashInLieuDetails.CashInLieuAdvice || ''}
        </legend>
      ) : null;

    const settlementAmount =
      this.state.currentCase.vehicleDetails.Retail.toLocaleString();

    const paymentInfo = {
      backgroundColor: 'rgba(255,255,255,0.1)',
      padding: '15px',
    };

    return (
      <div className={s.root}>
        <form onChange={this.submitSettlementAnswer} className={s.form}>
          <fieldset className={s.row}>
            <legend>
              <p>
                {this.props.decision.CashInLieuDetails.CashInLieuReason || ''}
              </p>
              <p style={paymentInfo}>
                Cash in lieu payment: £{settlementAmount}
              </p>
              <p>Do you wish to: </p>
            </legend>

            <RadioButtonGroup
              defaultSelected={null}
              value={this.state.selectedIndex}
              onChange={this.onChangeHandler}
              className={s.radios}
              labelPosition="left"
              name="settlement"
            >
              <RadioButton
                value="1"
                label="Accept: Settles the claim with a cash in lieu payment. "
              />
              <RadioButton
                value="0"
                label="Reject: Claim may become a total loss."
              />
            </RadioButtonGroup>
            {blurb}
          </fieldset>
        </form>
      </div>
    );
  }
}

let composed = CashInLieu;

export default composed;
