import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeft4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M670.5,621.9c0.2,0-104-1.8-123.9,1.5c-9.2,2.8-24.4-2.5-95.1,77h209.1L670.5,621.9z"
      />
    );
  }
}

let composed = WindowFrontLeft4x4;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
