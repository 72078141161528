import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftPickup4d extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M1025.3,787.5l-6.3-3.4v-26.5c0-14.8,1.7-27.9,3.5-28.9c2.1-1,3.5-5.7,3.5-10.4v-8.4H789v-91v-10h-9.3H636v10h144v175v78   h10.9c0,0-0.1-17.6-0.1-17.7c0-24.2,8.8-45.6,26.8-58.3c3.6-2.4,7-4.4,9.9-5.8c8.3-4,17.4-6.3,27.1-6.6c12.3,0,26.4,3.2,39.2,11   c14,9.1,22.5,23.3,27.2,40.1c0,0,59-0.1,63.2-0.2c15-0.3,42.5,3.2,42.5-30.9L1025.3,787.5z"
        />
        <path
          {...this.props}
          d="M1024.2,787l-6.2-3.4V757c0-14.8,1.7-27.9,3.5-28.9c2.1-1,3.5-5.7,3.5-10.4v-7.8H789v-90.3l-1-10.7h-9H636v11  h143v89.7v85.3v59c1.2-51.2,27-92.6,77.5-92.6c43.7,0,68.4,30.8,78.1,72.6c15.9,0,46.1-0.1,48.4-0.1c15-0.3,42.5,3.2,42.5-30.9  L1024.2,787z M1012,730.9h4v53h-4V730.9z M1022,789.9h-96v-2h96V789.9z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftPickup4d;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
