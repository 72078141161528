import { useState, FC } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import s from './EditAndSendEmail.module.scss';
import triageAPI from '../../../../../api/triageAPI';
import AppActions from '../../../../../actions/AppActions';

interface Props {
  caseId: string;
  currentEmail: string;
}

const EditAndSendEmail: FC<Props> = (props) => {
  const { caseId, currentEmail } = props;

  const [email, setEmail] = useState(currentEmail);
  const [hasError, setHasError] = useState(false);

  const onChangeEmail = (evt) => {
    const email = evt.target.value;

    setEmail(email);
  };

  const onClickSave = () => {
    setHasError(false);
    AppActions.addSpinner();

    const success = () => {
      AppActions.removeSpinner();
      AppActions.createModal({
        open: true,
        title: 'Email Updated',
        type: 'alert',
        content: (
          <div>
            <p>
              The email address was successfully updated. An email has been sent
              to this address requesting images.
            </p>
          </div>
        ),
      });
    };

    const error = () => {
      AppActions.removeSpinner();
      setHasError(true);
    };

    triageAPI.updateAndResendImageEmail(caseId, email, success, error);
  };

  return (
    <div>
      <p>Customer email to request vehicle photos:</p>

      <div className={s.row}>
        <div className={s.email}>
          <TextField
            id="email"
            name="email"
            value={email}
            onChange={onChangeEmail}
            fullWidth={true}
            floatingLabelText="Enter customer email"
            floatingLabelFixed={true}
          />
        </div>
        <div className={s.action}>
          <RaisedButton
            label="Save & Send Email"
            primary={true}
            disabled={email === currentEmail}
            onClick={onClickSave}
            style={{ verticalAlign: 'bottom' }}
          />
        </div>
      </div>

      {hasError ? (
        <p className={s.error}>
          There was a problem updating the email address. Please try again.
        </p>
      ) : null}
    </div>
  );
};

export default EditAndSendEmail;
