import { Component } from 'react';
import s from './UsersRolesList.module.scss';
import RoleTypes from '../../../../constants/RoleTypes.js';

import Checkbox from 'material-ui/Checkbox';

class UsersRolesList extends Component {
  filterRolesBasedOnCurrentUserRole = (fullRoleList, currentlyLoggedInUser) => {
    let results = fullRoleList;

    results = this.removeRoleFromListIfExists(results, RoleTypes.MANAGER);

    if (!currentlyLoggedInUser.isAdmin) {
      //if user is Admin, don't bother checking to see if any more roles should be removed
      results = this.removeRoleFromListIfExists(
        results,
        RoleTypes.ADMINISTRATOR
      );

      if (!currentlyLoggedInUser.isUsersCompanyManager) {
        // Not a company-wide user, remove both company wide roles (users and MI)
        results = this.removeRoleFromListIfExists(
          results,
          RoleTypes.USERSCOMPANYMANAGER
        );
        results = this.removeRoleFromListIfExists(
          results,
          RoleTypes.MICOMPANYMANAGER
        );
      } else {
        //User is UserCompMan, but does their Insurer have departments? If not, we don't want them to be able to create UserDeptMans or UserMIMans, as they are not relevant
        if (currentlyLoggedInUser.DepartmentId === null) {
          results = this.removeRoleFromListIfExists(
            results,
            RoleTypes.MIDEPARTMENTMANAGER
          );
          results = this.removeRoleFromListIfExists(
            results,
            RoleTypes.USERSDEPARTMENTMANAGER
          );
        }
      }
    }
    return results;
  };

  removeRoleFromListIfExists = (roleList, roleToRemove) => {
    let tmpItem = null;
    let tmpItemIndex = -1;

    tmpItem = roleList.find((role) => {
      return role.toUpperCase() === roleToRemove;
    });
    tmpItemIndex = roleList.indexOf(tmpItem);
    if (tmpItemIndex > -1) {
      roleList.splice(tmpItemIndex, 1);
    }

    return roleList;
  };

  render = () => {
    const allRoles = this.props.allRoles;
    const userRoles = this.props.userRoles;
    const currentlyLoggedInUser = this.props.currentlyLoggedInUser;
    const handler = this.props.handler;
    const isLocked = this.props.isLocked;
    const caller = this.props.caller;

    let results = null;
    if (allRoles && userRoles && allRoles.length > 0) {
      const filteredRoles = this.filterRolesBasedOnCurrentUserRole(
        allRoles,
        currentlyLoggedInUser
      );
      results = filteredRoles.map(function (role, i) {
        return (
          <tr key={i}>
            <td>{role}</td>
            <td style={{ padding: '10px' }}>
              <Checkbox
                disabled={isLocked}
                defaultValue={role}
                checked={userRoles.lastIndexOf(role) > -1}
                onClick={handler}
              />
            </td>
          </tr>
        );
      }, caller);
    } else {
      return null;
    }
    return (
      <table className={s.userRoles}>
        <thead>
          <tr>
            <th style={{ width: '1%', textAlign: 'left' }}>Role</th>
            <th style={{ width: '1%', textAlign: 'left' }}>Allowed</th>
          </tr>
        </thead>
        <tbody>{results}</tbody>
      </table>
    );
  };
}

export default UsersRolesList;
