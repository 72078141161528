import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeftPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M397.5,836.3h349.6c0.7,0,1.2,0.5,1.2,1.2v44.3c0,0.7-0.5,1.2-1.2,1.2H397.5c-0.7,0-1.2-0.5-1.2-1.2v-44.3  C396.3,836.8,396.8,836.3,397.5,836.3z"
      />
    );
  }
}

let composed = SillLeftPV;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
