import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class WheelsQuestions extends Component {
  render() {
    const s = this.props.style;
    //console.log(this.props.modalModel);
    return (
      <RadioButtonGroup
        className={s.radios}
        name="panel"
        defaultSelected={this.props.defaultSelected.toString()}
        labelPosition="left"
      >
        <RadioButton value="4" label="Heavy: Beyond scratched or scuffed" />
        <RadioButton value="1" label="Light: Scratched or scuffed" />
        <RadioButton value="0" label="None: There is no damage" />
      </RadioButtonGroup>
    );
  }
}
