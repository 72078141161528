import { Component } from 'react';
import s from './SettingsPage.module.scss';

import TextField from 'material-ui/TextField';
import AppActions from '../../actions/AppActions';

class SettingsPage extends Component {
  state = {
    oldPasswordMessage: null,
    newPasswordMessage: null,
    passwordStrength: null,
    minPasswordStrength: null,
    retypePasswordMessage: null,
    oldPassword: null,
    newPassword: null,
    retypePassword: null,
    inProgress: null,
    isValid: {},
  };

  UNSAFE_componentWillMount() {
    this.setState({
      oldPasswordMessage: 'Please enter your old password',
      newPasswordMessage: '',
      passwordStrength: 0,
      minPasswordStrength: 3,
      retypePasswordMessage: '',
      oldPassword: '',
      newPassword: '',
      retypePassword: '',
      inProgress: false,
      isValid: {
        old: false,
        new: false,
        retype: false,
        strength: false,
        matches: false,
      },
    });
  }

  checkState = () => {
    const { isValid, oldPassword, newPassword } = this.state;
    if (
      !isValid.old ||
      !isValid.new ||
      !isValid.retype ||
      !isValid.strength ||
      !isValid.matches
    ) {
      this.props.setDisabled(false, oldPassword, newPassword);
    } else {
      this.props.setDisabled(true, oldPassword, newPassword);
    }
  };

  oldPasswordHandler = (e) => {
    this.setState(
      {
        oldPassword: e.target.value,
      },
      () => {
        if (this.state.oldPassword === '') {
          this.setState({
            oldPasswordMessage: 'Please enter your old password',
          });
          this.state.isValid.old = false;

          this.checkState();
        } else {
          this.setState({
            oldPasswordMessage: '',
          });
          this.state.isValid.old = true;

          this.checkState();
        }
      }
    );
  };

  passwordChangeHandler = (e) => {
    this.setState(
      {
        newPassword: e.target.value,
        isValid: Object.assign(
          this.state.isValid,
          e.target.value === '' ? { new: false } : { new: true }
        ),
      },
      () => {
        if (this.state.newPassword.length < 12) {
          this.setState({
            passwordStrength: 0,
            isValid: Object.assign(this.state.isValid, {
              strength: false,
            }),
          });
        } else {
          this.setState({
            isValid: Object.assign(this.state.isValid, {
              strength: true,
            }),
          });
        }
        this.compareWithRetype();
      }
    );

    this.checkState();
  };

  retypeChangeHandler = (e) => {
    this.setState(
      {
        retypePassword: e.target.value,
        isValid: Object.assign(
          this.state.isValid,
          e.target.value === '' ? { retype: false } : { retype: true }
        ),
      },
      () => {
        if (this.state.newPassword.length < 12) {
          this.setState({
            passwordStrength: 0,
          });
          this.state.isValid.strength = false;
        }
        this.compareWithRetype();
      }
    );

    this.checkState();
  };

  compareWithRetype = () => {
    if (this.state.newPassword !== this.state.retypePassword) {
      this.setState({
        retypePasswordMessage: 'Your new password must match',
      });
      this.state.isValid.matches = false;
    } else {
      this.setState({
        retypePasswordMessage: '',
      });
      this.state.isValid.matches = true;
    }

    this.checkState();
  };

  evaluatePassword = () => {
    if (this.state.newPassword.length > 7) {
      this.setState(
        {
          inProgress: true,
        },
        () => {
          this.state.isValid.strength = false;
          setTimeout(() => {
            AppActions.evaluatePassword({
              password: this.state.newPassword,
              callback: (response) => {
                this.setState({
                  passwordStrength: response.data,
                  inProgress: false,
                });
                this.state.isValid.strength =
                  response.data < this.state.minPasswordStrength ? false : true;

                this.checkState();
              },
            });
          }, 1000);
        }
      );
    }
  };

  render() {
    //console.log(this.state.isValid, this.props)
    const strengthBar = {
      0: { markup: <ul></ul>, message: 'very weak' },
      1: {
        markup: (
          <ul>
            <li></li>
          </ul>
        ),
        message: 'weak',
      },
      2: {
        markup: (
          <ul>
            <li></li>
            <li></li>
          </ul>
        ),
        message: 'moderate',
      },
      3: {
        markup: (
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        ),
        message: 'strong',
      },
      4: {
        markup: (
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        ),
        message: 'very strong',
      },
    };

    return (
      <div>
        <ul className={s.changePasswordForm}>
          <li>
            <span className={s.col}>
              <TextField
                hintText="Enter your existing password"
                onChange={this.oldPasswordHandler}
                floatingLabelText="Old Password"
                type="password"
              />
            </span>
            <span className={s.col}>{this.state.oldPasswordMessage}</span>
          </li>
          <li>
            <span className={s.col}>
              <TextField
                hintText="Minimum 12 Characters"
                autoComplete="new-password"
                onFocus={this.evaluatePassword}
                floatingLabelText="New Password"
                onChange={this.passwordChangeHandler}
                type="password"
              />
            </span>
            <span className={s.col}>
              <span className={s.strengthBar}>
                Password strength:{' '}
                {this.state.inProgress
                  ? 'checking'
                  : strengthBar[this.state.passwordStrength].message}
                {strengthBar[this.state.passwordStrength].markup}
              </span>
            </span>
          </li>
          <li>
            <span className={s.col}>
              <TextField
                floatingLabelText="Retype New Password"
                hintText="Passwords must match"
                autoComplete="new-password"
                onChange={this.retypeChangeHandler}
                type="password"
                onFocus={this.evaluatePassword}
              />
            </span>
            <span className={s.col}>{this.state.retypePasswordMessage}</span>
          </li>
          <li>
            We only accept Strong or Very Strong passwords. &nbsp;
            <a href="" onClick={this.props.openDrawer}>
              Why do we do this?
            </a>
          </li>
          <li>
            The best way to create a strong password is just{' '}
            <a>four short words</a>.
          </li>
          <li>
            For extra strength, you can add numbers, special characters,
            capitals and punctuation, but this is not required.
          </li>
          <li>
            If you are finding it difficult to make a strong password, click
            here:&nbsp;
            <a href="http://correcthorsebatterystaple.net/" target="_blank">
              Struggling to create a strong password?
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default SettingsPage;
