import { Component } from 'react';
import triageAPI from '../../../api/triageAPI';
import TriageStore from '../../../stores/TriageStore';
import AppStore from '../../../stores/AppStore';

const UPDATE_INTERVAL = 30000;
const COUNTER_INTERVAL = 1000;

let millisecsCounter = 0;
let counterInterval = null;
let updateInterval = null;

function withTriageSession(ComposedComponent) {
  return class withTriageSession extends Component {
    constructor() {
      super();
      this.state = {
        isSessionOpen: false,
      };
    }

    UNSAFE_componentWillMount() {
      const userData = AppStore.userData();

      this.setState({
        isTriageUser:
          userData &&
          userData.User &&
          (userData.User.IsTriageUser || userData.User.IsAdmin),
      });

      this.updateState();
      counterInterval = setInterval(() => {
        if (this.state.isSessionOpen) millisecsCounter += COUNTER_INTERVAL;
      }, COUNTER_INTERVAL);

      updateInterval = setInterval(() => {
        if (this.state.isSessionOpen) this.recordSessionTime();
      }, UPDATE_INTERVAL);

      TriageStore.addChangeListener(this.updateState);
    }

    componentWillUnmount() {
      this.recordSessionTime();
      clearInterval(updateInterval);
      clearInterval(counterInterval);

      TriageStore.removeChangeListener(this.updateState);
    }

    updateState = () => {
      const currentCase = TriageStore.getCase();
      this.setState(
        {
          isSessionOpen: !(
            currentCase.caseId === '' || currentCase.relatedCases.length > 0
          ),
        },
        () => {
          if (!this.state.isSessionOpen) millisecsCounter = 0;
        }
      );
    };

    //public prop method for manually calling the api to record the time
    recordSessionTime = (cb, token) => {
      if (this.state.isSessionOpen) {
        try {
          triageAPI.recordTime(millisecsCounter, token, cb, (res) =>
            console.log(res)
          );
        } catch (e) {}
      }
      millisecsCounter = 0;
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          isTriageUser={this.state.isTriageUser}
          recordTime={this.recordSessionTime}
        />
      );
    }
  };
}

export default withTriageSession;
