import Dispatcher from '../core/Dispatcher.js';
import EventEmitter from 'eventemitter3';
import ActionTypes from '../constants/ActionTypes.js';
import userAPI from '../api/userAPI.js';

const CHANGE_EVENT = 'change';
const LOCK_EVENT = 'lock';
const INVALID_USER_EVENT = 'invalidUser';

let _currentUser = { roles: [] };
let _isLocked = false;
let socket;
let _newUser = null;

const UserStore = Object.assign({}, EventEmitter.prototype, {
  getCurrentUser() {
    return _currentUser;
  },

  getNewUser() {
    return _newUser;
  },

  getUserDataById(id, cb) {
    return userAPI.getUserDataById(id, cb);
  },

  getAllUsersData(cb) {
    userAPI.getAllUsersData(cb);
  },

  getAllUsersDataByInsurer(insurerId, cb) {
    userAPI.getAllUsersDataByInsurer(insurerId, cb);
  },

  getAllUsersDataByInsurerAndDepartment(insurerId, departmentId, cb) {
    userAPI.getAllUsersDataByInsurerAndDepartment(insurerId, departmentId, cb);
  },

  getAllOrganisationNamesAndIds(cb) {
    userAPI.getAllOrganisationNamesAndIds(cb);
  },

  getAllRoles(cb) {
    userAPI.getAllRoles(cb);
  },

  getAllRoleData(cb) {
    userAPI.getAllRoleData(cb);
  },

  getDepartmentsByInsurerId(id, cb) {
    return userAPI.getDepartmentsByInsurerId(id, cb);
  },

  getUserLockStateFromRepo() {
    return _isLocked;
  },

  getUserLockState(itemId, userId, cb) {
    if (userId === undefined) return;
    let isLocked = false;
    userAPI.getLockedUsers((response) => {
      const foundItems = response.find((value) => {
        return value.ItemId === itemId;
      });
      if (foundItems) {
        isLocked = foundItems.UserId === userId ? false : true;
      }
      _isLocked = isLocked;
      if (cb) cb(_isLocked);
    });
  },

  emitChange() {
    if (socket) {
      socket.emit(CHANGE_EVENT, {});
    } else {
      this.emit(CHANGE_EVENT);
    }
  },

  emitLock() {
    if (socket) {
      socket.emit(LOCK_EVENT, {});
    } else {
      this.emit(LOCK_EVENT);
    }
  },

  emitInvalidUser() {
    if (socket) {
      socket.emit(INVALID_USER_EVENT, {});
    } else {
      this.emit(INVALID_USER_EVENT);
    }
  },

  addChangeListener(callback) {
    if (socket) {
      socket.on('refresh', callback);
    } else {
      this.on(CHANGE_EVENT, callback);
    }
  },

  removeChangeListener(callback) {
    if (socket) socket.disconnect();
    if (!socket) {
      this.removeListener(CHANGE_EVENT, callback);
    }
  },

  addLockListener(callback) {
    if (socket) {
      socket.on('lock', callback);
    } else {
      this.on(LOCK_EVENT, callback);
    }
  },

  removeLockListener(callback) {
    if (socket) socket.disconnect();
    if (!socket) {
      this.removeListener(LOCK_EVENT, callback);
    }
  },

  addInvalidUserListener(callback) {
    if (socket) {
      socket.on('lock', callback);
    } else {
      this.on(INVALID_USER_EVENT, callback);
    }
  },

  removeInvalidUserListener(callback) {
    if (socket) socket.disconnect();
    if (!socket) {
      this.removeListener(INVALID_USER_EVENT, callback);
    }
  },
});

Dispatcher.register(function (action) {
  switch (action.actionType) {
    case ActionTypes.USER_CREATE:
      userAPI.createNewUser(action.data, (response) => {
        action.data.callback(response);
        UserStore.emitChange();
      });
      break;

    case ActionTypes.USER_UPDATE_NEW_USER_DETAILS:
      _newUser = action.data;
      break;

    case ActionTypes.USER_UPDATE:
      userAPI.updateUser(action.data, (response) => {
        action.data.callback(response);
        UserStore.emitChange();
      });
      break;

    case ActionTypes.USER_SET_CURRENT:
      _currentUser = action.data;
      break;

    case ActionTypes.USER_SET_LOCK:
      userAPI.setUserLockState(action.data, () => {
        UserStore.emitLock();
      });
      break;

    case ActionTypes.USER_CHECK_EXISTS:
      userAPI.checkUserNameExists(action.data, (response) => {
        action.data.callback(response);
        UserStore.emitChange();
      });
      break;

    case ActionTypes.USER_DELETE_USER_DETAILS:
      userAPI.deleteUser(action.data, (response) => {
        action.data.callback(response);
        UserStore.emitChange();
      });
      break;

    case ActionTypes.USER_RESET_EMAIL:
      userAPI.resetEmail(action.data, (response) => {
        action.data.callback(response);
        UserStore.emitChange();
      });
      break;

    case ActionTypes.USER_INVALID_USER:
      UserStore.emitInvalidUser();
      break;
    default:
  }
  return true;
});

export default UserStore;
