import { Component } from 'react';
import SettingsDialog from './SettingsDialog';
import withDataHelpers from '../decorators/withDataHelpers';

class OrgSettingsDialog extends Component {
  render() {
    return (
      <SettingsDialog
        data={this.props.data}
        styles={this.props.styles}
        table={'orgSettings'}
        editSubmitModel={this.props.editSubmitModel}
        editErrorState={this.props.editErrorState}
        processSettings={this.props.processOrgSettings}
      />
    );
  }
}

const composed = withDataHelpers(OrgSettingsDialog);

export default composed;
