import { Component } from 'react';
import s from './SideModal.module.scss';

import AirbagsSideQuestions from '../../QuestionSets/AirbagsSideQuestions/';
import DoorsQuestions from '../../QuestionSets/DoorsQuestions/';
import PanelQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'airbagsside', component: <AirbagsSideQuestions /> },
  { stage: 2, section: 'doors', component: <DoorsQuestions /> },
  { stage: 3, section: 'panelStandard', component: <PanelQuestions /> },
];

const SUBTITLE = 'Side Questions';

class SideModal extends Component {
  render() {
    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
          </div>
        </div>
      </div>
    );
  }
}

let composed = SideModal;
composed = ModalComponent(QUESTION_SETS, SUBTITLE)(composed);

export default composed;
