import React from 'react';
import AppActions from '../../../../actions/AppActions';
import triageAPI from '../../../../api/triageAPI';

export const CloseITGCases = () => {
  AppActions.createModal({
    open: true,
    type: 'confirm',
    title: 'Close All Cases for ITG?',
    content: (
      <div>
        <p>This will close all ITG Cases currently listed as 'open'.</p>
        <p>This action cannot be undone. Are you sure?</p>
      </div>
    ),
    onConfirm: () => {
      AppActions.addSpinner();
      triageAPI
        .closeAllITGCases()
        .then(() => {
          AppActions.removeSpinner();
          AppActions.updateModal({
            open: true,
            title: 'Request Successful',
            content: (
              <div>
                <p>All ITG Cases closed.</p>
              </div>
            ),
            type: 'alert',
          });
        })
        .catch(() => {
          AppActions.removeSpinner();
          AppActions.updateModal({
            open: true,
            title: 'Request Unsuccessful',
            content: (
              <div>
                <p>It was not possible to perform this action.</p>
              </div>
            ),
            type: 'alert',
          });
        });
    },
  });
};
