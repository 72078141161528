import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftCoupe extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M996.1,790.1c-1.8-3.1-5.9-8.6-5.9-8.6c0.2,0.7,0.2-44.8,0.1-63.1c0-3.2-2.6-5.8-5.8-5.8l-16.8,0.1  c-0.2,0-142.7-84.2-188.7-90.9c0,0-33.7-6.4-64.6-6.3c-0.9-0.1-1.7,0.5-1.8,1.4c-0.9,6.3-4.5,30-7.6,50.9  c-6.2,39.2-7.6,53.5-11.5,118.8l-5.3,115.5c0,0.9,0.7,1.9,1.6,1.9h97.3c1.7,0,3-1.5,3.3-3.2l-0.2-29.2c1-37.4,4.7-45.6,11.5-56.6  c19.3-32,61.1-39.4,86-21.2c18.1,13.3,31.2,30.8,31.2,56.8v0.1l-0.7,17.9c0.2,1.7,1.7,3,3.4,3l27-0.5c23.5-0.6,37-1.8,43.7-2.6  c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1002.9,802.2,996.1,790.2,996.1,790.1z M909.6,721.9l-201.4-0.6  c-1,0-1.9-0.9-1.8-1.9c1.8-17,8.5-71.2,9.6-80.8c0.1-0.8,0.8-4,1.7-4C830.1,634.5,911.6,721.9,909.6,721.9z"
        />
        <path
          {...this.props}
          d="M996.8,790.4c-1.8-3.1-5.9-8.6-5.9-8.6c0.2,0.7,0.2-44.8,0.1-63.1c0-3.2-2.6-5.8-5.8-5.8l-16.8,0.1  c-0.2,0-142.7-84.2-188.7-90.9c0,0-33.7-6.4-64.6-6.3c-0.9-0.1-1.7,0.5-1.8,1.4c-0.9,6.3-4.5,30-7.6,50.9  c-6.2,39.2-7.6,53.5-11.5,118.8l-5.3,115.3c0,0.9,0.7,1.8,1.6,1.8H788c1.7,0,3-1.3,3.3-3l-0.2-29.1c1-37.4,4.7-45.6,11.5-56.6  c19.3-32,61.2-39.4,86.1-21.2c18.1,13.3,31.3,30.8,31.3,56.8v0.1l-0.8,17.9c0.2,1.7,1.6,3,3.4,3l26.9-0.5c23.5-0.6,37-1.8,43.6-2.6  c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1003.7,802.5,996.9,790.5,996.8,790.4z M988,757.5c0,2-1.6,3.5-3.5,3.5h-21.9  c-2,0-3.5-1.6-3.5-3.5v-28.9c0-2,1.6-3.5,3.5-3.5h21.9c2,0,3.5,1.6,3.5,3.5V757.5z M708.6,721.1c-1,0-1.9-0.9-1.8-1.9  c1.8-17,8.5-71.2,9.6-80.8c0.1-0.8,0.8-4,1.7-4c112.4,0,193.9,87.4,191.9,87.4L708.6,721.1z M989.7,801H905c-1.7,0-3.4-1-4.7-2.3  c-2-2-13.7-5.7,2.2-5.7h85.6c1.7,0,2.4,1,3.7,2.3C993.9,797.2,995.5,801,989.7,801z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftCoupe;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
