const PAV = (props) => {
  const s = props.styles;
  const pav = props.pav ? props.pav || 0 : 0;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>PAV: </span>
      <strong className={s.cols_6}>£{parseInt(pav).toLocaleString()}</strong>
    </li>
  );
};

export default PAV;
