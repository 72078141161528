import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightPickup4d extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M1025.3,131.1l-6.3,3.4V161c0,14.8,1.7,27.9,3.5,28.9c2.1,1,3.5,5.7,3.5,10.4v8.6H789v90.8v10.2h-9.3H636v-10h144V124.7   V46.9h10.9c0,0-0.1,17.5-0.1,17.6c0,24.2,8.8,45.5,26.8,58.2c3.6,2.4,7,4.4,9.9,5.8c8.3,4,17.4,6.3,27.1,6.6   c12.3,0,26.4-3.2,39.2-11c14-9.1,22.5-23.3,27.2-40.1c0,0,59,0.1,63.2,0.2c15,0.3,42.5-3.2,42.5,30.9L1025.3,131.1z"
        />
        <path
          {...this.props}
          d="M1026.6,115.7c0-34.1-27.5-30.6-42.5-30.9c-2.3,0-32.5-0.1-48.4-0.1c-9.7,41.8-34.4,72.6-78.1,72.6  c-50.5,0-76.3-41.4-77.5-92.6v59V209v89.9H637v11h143h9l1-10.9v-90.1h236v-8c0-4.7-1.4-9.4-3.5-10.4c-1.8-1-3.5-14.1-3.5-28.9V135  l6.2-3.4L1026.6,115.7z M1013,134.9h4v53h-4V134.9z M1023,130.9h-96v-2h96V130.9z"
        />
      </g>
    );
  }
}

let composed = WingBackRightPickup4d;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
