import React from 'react';
import AtFaultStatuses from '../../../../../../constants/AtFaultStatusTypes';

const AtFaultStatus = (props) => {
  const s = props.styles;
  const currentStatus = AtFaultStatuses[props.status - 1];

  const button = null; //<RaisedButton className={"summaryBtn"} label="Edit" primary={true} onClick={props.onAtFaultHandler} hidden={true}/>; // MWHP - leave in for now, I expect we will need it again relatively soon

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Fault Status: </span>
      <strong className={s.cols_6}>
        <span>{currentStatus}</span>
        {button}
      </strong>
    </li>
  );
};

export default AtFaultStatus;
