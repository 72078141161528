import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRearPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M956,603.1l-37.5-21.6c-0.9-0.5-1.5-1.5-1.5-2.6V336.5c0-1.1,0.6-2.1,1.6-2.6l37.5-19.8c2-1.1,4.4,0.4,4.4,2.6v283.9  C960.5,602.8,958,604.3,956,603.1z"
      />
    );
  }
}

let composed = ScreenRearPV;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
