import React from 'react';
import s from '../TriageStatsPage.module.scss';
import RaisedButton from 'material-ui/RaisedButton';

const ResultsFooter = (props) => {
  return (
    <div className={s.footer}>
      <div className={s.btnsWrap}>
        <RaisedButton
          onClick={props.generatePDFHandler}
          className={s.footerBtn}
          style={{ display: 'none' }}
          disabled={props.disabled}
          label="Generate PDF"
          primary={true}
        />
        {props.showRawDataButton ? (
          <RaisedButton
            onClick={props.generateRawDataHandler}
            className={s.footerBtn}
            label="Generate Raw Data"
            primary={true}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ResultsFooter;
