const VehicleData = [
  { key: 0, name: '2 Door Saloon', type: 'TwoDoorSaloon' },
  { key: 1, name: '4 Door Saloon', type: 'FourDoorSaloon' },
  { key: 2, name: '2 Door Convertible', type: 'TwoDoorConvertible' },
  { key: 3, name: '3 Door Hatchback', type: 'ThreeDoorHatchback' },
  { key: 4, name: 'Car Derived Van', type: 'CarDerivedVan' },
  { key: 5, name: '2 Door Coupe', type: 'Coupe' },
  { key: 6, name: '2 Door Pickup', type: 'TwoDoorPickup' },
  { key: 7, name: '3 Door 4x4', type: 'ThreeDoor4x4' },
  { key: 8, name: '5 Door 4x4', type: 'FiveDoor4x4' },
  { key: 9, name: '4 Door Pickup', type: 'FourDoorPickup' },
  { key: 10, name: '5 Door Hatchback', type: 'FiveDoorHatchback' },
  { key: 11, name: '5 Door Estate', type: 'FiveDoorEstate' },
  { key: 12, name: 'Light Van', type: 'LightVan' },
  { key: 13, name: 'Panel Van', type: 'PanelVan' },
  { key: 14, name: '5 Door MPV', type: 'MPV' },
];

export default VehicleData;
