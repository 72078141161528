import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRight4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M449.8,84.4l292.8,0.1c0,0,0,1.6-0.7-7.6s-1-11.6-1-11.6H448.4l-1.8,19.1H449.8z"
      />
    );
  }
}

let composed = SillRight4x45d;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
