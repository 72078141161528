import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftHatchback5d extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackLeft)}>
        <path
          {...this.props}
          d="M811,647.3c-9.9-9.8-95.2-12.3-122.5-12.6l-9.8,84.3h166.7C845.4,719,816,652,811,647.3z"
        />
        <path
          {...this.props}
          d="M819.1,652.6c0.5,1.8,30,66.4,30,66.4h38.1c5,0,7.2-6.2,3.4-9.4C875.9,697.5,846.9,673.9,819.1,652.6z"
        />
      </g>
    );
  }
}

let composed = WindowBackLeftHatchback5d;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
