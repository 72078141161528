const SalvageCategory = (props) => {
  const s = props.styles;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>
        Salvage Category ({props.SalvageCategoryType}):
      </span>
      <strong className={s.cols_6}>{props.SalvageCategoryValue}</strong>
    </li>
  );
};

export default SalvageCategory;
