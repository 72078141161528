import { Component } from 'react';
import TriageStore from '../../../../../stores/TriageStore';
import TriageActions from '../../../../../actions/TriageActions';

const FILL_STYLES = {
  0: { fill: 'rgba(0,188,212, 0.6)' }, //highlight style
  1: { stroke: 'rgba(0,188,212, 1)', fillOpacity: 0 }, //default style
  2: { stroke: 'gold', fill: 'gold', fillOpacity: 0.157 },
  3: { stroke: 'orange', fill: 'orange', fillOpacity: 0.157 },
  4: { stroke: 'orangered', fill: 'orangered', fillOpacity: 0.157 },
  5: { stroke: 'red', fill: 'red', fillOpacity: 0.157 },
};

function SectionComponent(newItem) {
  return (ComposedComponent) =>
    class SectionComponent extends Component {
      state = {
        isHighLighted: false,
      };

      UNSAFE_componentWillMount() {
        this.setState({
          isHighLighted: false,
        });
        if (newItem !== null) {
          if (newItem.damageSeverity === undefined) {
            newItem.damageSeverity = 0;
          }
          if (newItem.hasDangerousDamage === undefined) {
            newItem.hasDangerousDamage = false;
          }
          if (newItem.modalModel === undefined) {
            newItem.modalModel = null;
          }
          if (newItem.questionSets === undefined) {
            newItem.questionSets = [];
          }
          const damagedItem = this.props.damageModel.find((i) => {
            return i.PartName == newItem.name;
          });
          if (damagedItem !== undefined) {
            newItem.damageSeverity = damagedItem.DamageSeverity;
            newItem.hasDangerousDamage = damagedItem.HasDangerousDamage;
          }
          TriageActions.addPart(newItem);
        }
        TriageStore.addChangeListener(this.updateState);
        TriageStore.addHoverListener(this.updateHover);
        this.updateState();
      }

      componentWillUnmount() {
        TriageStore.removeChangeListener(this.updateState);
        TriageStore.removeHoverListener(this.updateHover);
      }

      updateState = () => {
        if (newItem === null) return;
        let item = this.getItem(newItem.name);
        if (item === null || item.highlighted === null) return;
        this.setState({
          damageSeverity: item.damageSeverity,
        });
      };

      updateHover = () => {
        if (newItem === null) return;
        let item = this.getItem(newItem.name);
        if (item === null || item.highlighted === null) return;
        this.setState({
          isHighLighted: item.highlighted,
        });
      };

      getItem = (name) => {
        for (let i in this.props.partsList) {
          let item = this.props.partsList[i];
          if (item.name === name) return item;
        }
        return null;
      };

      mouseEnterHandler = (e) => {
        if (newItem === null) return;
        let item = this.getItem(newItem.name);
        if (item === null) return;

        TriageActions.updatePart(item.name, { highlighted: true }, false);
      };

      mouseLeaveHandler = (e) => {
        if (newItem === null) return;
        let item = this.getItem(newItem.name);
        if (item === null) return;
        TriageActions.updatePart(item.name, { highlighted: false }, false);
      };

      mouseClickHandler = (e) => {
        if (this.props.disabled) return;
        e.preventDefault();
        if (newItem.modalModel !== null) {
          const modalOptions = Object.assign({}, newItem.modalModel, {
            open: true,
            name: newItem.name,
            updateHandler: this.props.onSelectHandler,
          });
          TriageActions.setModal(modalOptions);
        } else {
          // see also clickHandler function in SelectionList\index.js, which handles the same click for text-only section areas (discovered during investigation of I2-1152)
          const newSeverity = this.state.damageSeverity == 0 ? 4 : 0;
          if (this.props.handleLatency) {
            this.props.handleLatency('add', newItem.name);
          }

          TriageActions.updatePart(
            newItem.name,
            { damageSeverity: newSeverity },
            true,
            () => {
              TriageActions.makeDecision(() => {
                if (this.props.handleLatency) {
                  this.props.handleLatency('remove', newItem.name);
                }
              });
            }
          );
        }
      };

      render() {
        if (newItem === null) return null;

        let highlight = this.state.isHighLighted
          ? FILL_STYLES[0]
          : FILL_STYLES[this.state.damageSeverity * 1 + 1];

        if (!highlight) {
          highlight = FILL_STYLES[1];
        }

        const title = !this.props.disabled
          ? newItem.name + ' : Click to register damage'
          : null;

        return (
          <ComposedComponent
            {...this.props}
            style={highlight}
            onClick={this.mouseClickHandler}
            onMouseEnter={this.mouseEnterHandler}
            onMouseLeave={this.mouseLeaveHandler}
          >
            <title>{title}</title>
          </ComposedComponent>
        );
      }
    };
}

export default SectionComponent;
