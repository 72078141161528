import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofThreeDoorHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M867.8,579.1c4-35.2,4-203.3,0-238.4c-3.3-23.2-3.6-21.5-10.3-21.6c-28.8,1.5-206.1,2.2-296-2.8  c-26.3-3.2-38.6-3.4-39.4-0.7c-5.7,15.7-12.3,94.2-12.3,144.2c0,56.6,7.8,133.9,14.8,142.8c2.1,3.2,12.7,3.2,43,0  c94.5-0.2,257.5-0.2,289.8,1.4C864.3,604,864.5,602.4,867.8,579.1z"
      />
    );
  }
}

let composed = RoofThreeDoorHatchback;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
