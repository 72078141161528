import { Component } from 'react';
import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class FlatBed extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          d="M1013.3,262.9H651.6c-6.4,0-11.5,5.1-11.5,11.5v368c0,6.4,5.1,11.5,11.5,11.5h361.7c6.4,0,11.5-5.1,11.5-11.5v-368  C1024.8,268,1019.6,262.9,1013.3,262.9z"
        />
      </g>
    );
  }
}

let composed = FlatBed;
composed = SectionComponent({
  name: 'Flatbed',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Flatbed Damage',
    content: <Modal name="Flatbed" />,
  },
})(composed);

export default composed;
