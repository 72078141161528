import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontLeftPV extends Component {
  render() {
    return (
      <g className={s.wingFrontLeft}>
        <path
          {...this.props}
          d="M459.9,589h-14.3h-14c-3.9,0-7.8,1.2-11.1,3.4l-108.3,72.4c-1.2,0.4-4.7,1.1-8.3,1.7  c-6.7,1.1-13.1,3.6-18.8,7.1l-68.8,43c-7.1,4.4-12.9,10.8-16.6,18.3l-8.5,17.1l-4.2,4.5c-2.7,3-4.1,7-3.7,11l7.3,72l0,0  c0,1.9,0.1,3.7,0.2,5.6h0.3h41.4h5.7c-2.9-54,38.2-88,81-87.2c31.5,0.6,61.8,24.1,70.9,51.6c2.2,6.8,4.2,15.7,4.8,22.7H407  c-1.4-16-9.4-119.2,5.8-146l46.6-83.7c1.2-2.1,5.2-7.5,7.6-7.5l66.3-0.2l0.4-5.7L459.9,589L459.9,589z"
        />
        <path
          {...this.props}
          d="M460.2,589h-14.3h-0.8c-3.9,0-7.8,1.3-11.1,3.5l-108.1,72.4c-0.1,0.1-0.2,0.1-0.4,0.2c-1.3,0.4-4.7,1.1-8.1,1.7  c-6.7,1.1-13.1,3.6-18.8,7.2l-68.8,43c-0.1,0.1-0.2,0.1-0.3,0.2c-1.2,0.7-2,1.9-2.5,3.2c-2.9,8.2-2.5,15.5,0.1,21.2  c3.1,6.8-9.9,12.8-1.7,16.5c0.2,0.1,0.4,0.1,0.6,0.1c3.7,0,38.8,0,46.5,0c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-73.7  c-0.7,0-1.3,0.4-1.4,1.1c-0.5,1.8-0.7,3.7-0.6,5.6L204,840v-0.2c0,1.4,0.2,2.6,0.3,4c0.1,0.8,0.7,1.4,1.5,1.4h27.1h4.1  c0.9,0,1.5-0.7,1.5-1.6c-2-53.1,38.7-86.3,81-85.5c31.5,0.6,61.8,24,70.9,51.5c2.1,6.3,4,14.5,4.6,21.2c0.1,0.8,0.7,1.4,1.5,1.4h9.1  c0.9,0,1.6-0.7,1.5-1.6c-1.7-20.3-8.8-118.2,6-144.2l46.6-83.6c1.2-2.1,5.2-7.5,7.6-7.5l64.9-0.3c0.8,0,1.4-0.6,1.5-1.4l0.2-2.8  c0.1-0.9-0.6-1.6-1.5-1.6h-72.2V589z M400,686.4c-0.3,0.4,0.5,1.6,0,1.6c0,0-63.4,0.7-72,0c-1.3-0.1-0.5-3.7,0.6-4.5l108-77.4  c0.1-0.1,0.2-0.1,0.3-0.2c1.6-0.7,16.1-7.1,15.9-6.7L400,686.4z"
        />
      </g>
    );
  }
}

let composed = WingFrontLeftPV;
composed = SectionComponent({
  name: 'Front Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Passenger Wing Damage',
    content: <Modal name="Front Passenger Wing" />,
  },
})(composed);

export default composed;
