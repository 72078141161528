import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightCoupe extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackRight)}
        d="M722.4,281.8c-0.8,0-1.5-2.9-1.6-3.7c-1.1-8.8-7.4-58.8-9.1-74.4c-0.1-1,0.7-1.8,1.7-1.8l190.3-0.6   C905.6,201.4,828.6,281.8,722.4,281.8z"
      />
    );
  }
}

let composed = WindowBackRightCoupe;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
