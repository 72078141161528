import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class DoorBackRightPV extends Component {
  render() {
    return (
      <g className={s.doorBackRight}>
        <path
          {...this.props}
          d="M1060.5,293h-89c-4.5,0-8.5,3.8-8.5,8.6V458h106V301.6C1069,296.8,1065,293,1060.5,293z"
        />
        <path
          {...this.props}
          d="M1060.5,293h-2.5v31h-26.2c-10.6,0-18.8-8.6-18.8-19.7V293h-41.5c-4.5,0-8.5,3.8-8.5,8.6V458h106V301.6  C1069,296.8,1065,293,1060.5,293z"
        />
      </g>
    );
  }
}

let composed = DoorBackRightPV;
composed = SectionComponent({
  name: 'Right Back Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Right Damage',
    content: <Modal name="Right Back Door" />,
  },
})(composed);

export default composed;
