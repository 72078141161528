import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightEstate extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackRight)}>
        <path
          {...this.props}
          d="M845.9,200.1H679.2l9.8,84.3c27.3-0.3,121-4.1,121-4.1C815,275.6,845.9,200.1,845.9,200.1z"
        />
        <path
          {...this.props}
          d="M859.5,276.9c25.4-2.7,47.2-2.9,56.8-11.3c26.2-22.8,40.8-46.6,47.6-58.8c1.8-3.2,0.5-6.7-3.2-6.7H849.3   c0,0-0.3,1.3-1.2,3.2c-5.2,11.5-26.1,59.3-33.6,76.3L859.5,276.9z"
        />
      </g>
    );
  }
}

let composed = WindowBackRightEstate;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
