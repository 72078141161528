import LoadCaseButton from './LoadCaseButton';

const OpenCasesConfig = {
  columnMetadata: [
    {
      columnName: 'DateCreated',
      locked: false,
      order: 1,
      displayName: 'Date Created',
    },
    {
      columnName: 'Vehicle',
      locked: true,
      order: 2,
      displayName: 'Vehicle',
    },
    {
      columnName: 'Creator',
      locked: false,
      order: 3,
      displayName: 'Created By',
    },
    {
      columnName: 'Direction',
      locked: true,
      order: 4,
      displayName: 'Decision',
    },
    {
      columnName: 'InScope',
      locked: true,
      order: 5,
      displayName: 'In Scope?',
    },
    {
      columnName: 'Edit',
      locked: true,
      order: 6,
      customComponent: LoadCaseButton,
      displayName: 'Edit Case',
    },
  ],

  ignoreColumns: ['$id', 'CaseId'],
};

export default OpenCasesConfig;
