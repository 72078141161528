import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class TailGateMPV extends Component {
  render() {
    return (
      <g className={s.boot}>
        <path
          {...this.props}
          d="M1060.5,293.1h-89c-4.5,0-8.5,3.9-8.5,8.6v217.9v101.9c0,4.7,4,8.6,8.5,8.6h89c4.5,0,8.5-3.8,8.5-8.6V301.7  C1069,297,1065,293.1,1060.5,293.1z"
        />
        <path
          {...this.props}
          d="M1060.5,293.1H1019v11.4c0,11.2-8.3,20.6-18.8,20.6H974v-32h-2.5c-4.5,0-8.5,3.9-8.5,8.6v108.4v49v6v37.5v118.9  c0,4.7,4,8.6,8.5,8.6h2.5v-31h26.2c10.6,0,18.8,8.6,18.8,19.7v11.3h41.5c4.5,0,8.5-3.8,8.5-8.6V502.6v-37.5v-6v-49V301.7  C1069,297,1065,293.1,1060.5,293.1z M1009,543.9c0,5.8-5.1,10.2-10.8,10.2H980v-186h18.2c5.8,0,10.8,4.9,10.8,10.7V543.9z"
        />
      </g>
    );
  }
}

let composed = TailGateMPV;
composed = SectionComponent({
  name: 'Tailgate',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Tailgate Damage',
    content: <Modal name="Tailgate" />,
  },
})(composed);

export default composed;
