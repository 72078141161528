import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightPickup extends Component {
  render() {
    return (
      <path
        className={cx(s.glass, s.windowBackRight)}
        {...this.props}
        d="M775.6,211.3h-137c-0.6,0-1.1,0.5-1.1,1.1v82.8c0,0.6,0.5,1.1,1.1,1.1h137c0.6,0,1.1-0.5,1.1-1.1v-82.8  C776.7,211.8,776.2,211.3,775.6,211.3z"
      />
    );
  }
}

let composed = WindowBackRightPickup;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
