import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftCoupe extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackLeft)}
        d="M720.4,637.7c-0.8,0-1.5,2.9-1.6,3.7c-1.1,8.8-7.4,58.8-9.1,74.4c-0.1,1,0.7,1.8,1.7,1.8l190.3,0.6   C903.6,718.1,826.6,637.7,720.4,637.7z"
      />
    );
  }
}

let composed = WindowBackLeftCoupe;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
