import React from 'react';
import s from '../TriageStatsPage.module.scss';
import Griddle from 'griddle-react';
import RaisedButton from 'material-ui/RaisedButton';
import CustomPieChart from './CustomPieChart.js';
import CustomBarChart from './CustomBarChart.js';

const ResultItem = (props) => {
  const chart =
    props.data.type === 'pie' ? (
      <CustomPieChart
        name={props.data.key}
        data={props.data.values}
        colors={props.colors}
        activeIndex={props.activeIndex}
        isAnimationActive={props.isAnimationActive}
        canFilter={props.data.canFilter}
        maxGraphWidth={props.maxGraphWidth}
      />
    ) : (
      <CustomBarChart
        name={props.data.key}
        data={props.data.values}
        isAnimationActive={props.isAnimationActive}
        colors={props.colors}
        activeIndex={props.activeIndex}
        canFilter={props.data.canFilter}
        maxGraphWidth={props.maxGraphWidth}
      />
    );

  return props.data.values ? (
    <div className={s.result}>
      <h2>{props.data.key}</h2>
      <div className={s.resultRow}>
        <div className={s.resultColumn}>
          <Griddle
            className={s.grid}
            results={[props.data.values]}
            columnMetadata={props.griddleConfig}
            useFixedHeader={true}
            tableClassName={s.table}
            showFilter={false}
            showPager={false}
            fullWidth={true}
            enableInfiniteScroll={true}
            bodyHeight={40}
            showSettings={false}
          />
        </div>
        <div className={s.resultColumn}>{chart}</div>
      </div>

      <RaisedButton
        className={s.btnWrap}
        primary={true}
        label={'View ' + props.data.key}
        onClick={(e) => props.clickHandler(e, props.data.key)}
      />
    </div>
  ) : null;
};

export default ResultItem;
