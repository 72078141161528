import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackLeftPV extends Component {
  render() {
    return (
      <g className={s.wheelBackLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M827.5,764c-40.3,0-73,34.8-73,77.4c0,42.9,32.8,77.4,73,77.4s73-34.8,73-77.4C900.6,798.5,867.8,764,827.5,764z   M782.9,841.2c0-26.3,19.9-47.5,44.6-47.5c2.8,0,5.5,0.2,8.1,0.8c15.9,3,28.7,15,34,30.8c1.6,5,2.6,10.4,2.6,16  c0,7.1-1.4,13.8-4.1,19.9c-7,16.4-22.4,27.7-40.5,27.7c-1.8,0-3.5-0.1-5.3-0.4C799.9,885.7,782.9,865.7,782.9,841.2z"
        />
        <path
          {...this.props}
          d="M827.5,886c0.5,0,0.9,0,1.5,0c22.7-0.8,40.4-20.3,40.4-44.6c0.1-23.9-17.9-43.8-40.5-44.6c-0.5,0-0.9,0-1.4,0  c-15.1,0-28.1,8.3-35.5,20.8c-4.1,6.9-6.4,15.1-6.4,23.8c0,4.8,0.7,9.5,2.1,13.8C793,873.1,808.9,886,827.5,886z M816,864  c3.5,2,7.5,3.2,11.8,3.2c4.8,0,9.3-1.5,13.2-4.2c5.7-4,9.8-10.3,11-17.6h1.1h0.5c-2.3,12.8-12.3,22.7-24.8,23.2  c-0.5,0-0.8,0.1-1.3,0.1c-13,0-23.6-10.2-25.3-23.4h1.5C804.8,853.4,809.5,860.2,816,864z M852.5,837.6h-0.8  c-1.2-8.3-6-15.3-12.7-19.2c-3.4-1.9-7.2-3-11.4-3c-3.5,0-6.8,0.8-9.8,2.1c-7.6,3.5-13.2,10.9-14.4,20.1h-1.5  c1.8-13.2,12.4-23.6,25.3-23.6c0.5,0,0.9,0,1.5,0.1c7.6,0.5,14.2,4.6,18.7,10.5C850,828.4,851.9,832.8,852.5,837.6z"
        />
      </g>
    );
  }
}

let composed = WheelBackLeftPV;
composed = SectionComponent({
  name: 'Back Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Passenger Wheel Damage',
    content: <Modal name="Back Passenger Wheel" />,
  },
})(composed);

export default composed;
