import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M533,598h-63.4c-1.8,0-6.9,7.1-7.8,8.8l-41.3,75.6c-0.8,1.4-1.4,4.6-2.1,5.6h113.7C532,641,533,598,533,598z"
      />
    );
  }
}

let composed = WindowFrontLeftPV;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
