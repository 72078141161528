import axios from 'axios';
import { apiServer, queryTimeout } from '../config.js';
import AppAPI from './appAPI';

const statsAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },

  getStatisticsByDateRange(dateRange, cb, errorCb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: `reports/getStatisticsByDateRange?start=${dateRange.minDate.toUTCString()}&end=${dateRange.maxDate.toUTCString()}`,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(function (response) {
        if (errorCb) errorCb(response.data);
      });
  },

  getRawDataByDateRange(dateRange, cb, errorCb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: 300000,
      url: `reports/getRawDataByDateRange?start=${dateRange.minDate.toUTCString()}&end=${dateRange.maxDate.toUTCString()}`,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(function (response) {
        if (errorCb) errorCb(response.data);
      });
  },
};

export default statsAPI;
