import Dispatcher from '../core/Dispatcher.js';
import EventEmitter from 'eventemitter3';
import ActionTypes from '../constants/ActionTypes.js';
import triageAPI from '../api/triageAPI';

let _parts = []; //specific to UI; requires no serverside hookup
let _completedQuestionSets = [];
let _modalModel = {
  name: '',
  actions: [],
  title: '',
  content: '',
  open: false,
  currentStage: 0,
  disableProgress: false,
  severity: 0,
  updateHandler: () => {},
};

function addPart(data) {
  const newPart = {
    name: data.name,
    category: data.category,
    highlighted: data.highlighted,
    damageSeverity: data.damageSeverity,
    hasDangerousDamage: data.hasDangerousDamage,
    modalModel: data.modalModel,
    questionSets: data.questionSets,
  };

  const items = _parts.filter((i) => {
    return i.name === newPart.name;
  });
  if (items && items.length > 0) {
    Object.assign(items[0], data.item);
  } else {
    _parts.push(newPart);
  }
}

function updatePart(name, data, update, cb) {
  let foundItem = _parts.find((i) => {
    return i.name === name;
  });
  if (foundItem !== undefined) {
    Object.assign(foundItem, data.item);
    triageAPI.updatePartDamage(
      foundItem.name,
      foundItem.damageSeverity,
      foundItem.hasDangerousDamage,
      update,
      cb
    );
  }
}

function setModalModel(data) {
  if (data !== undefined) {
    _modalModel = {
      name: data.name || '',
      styles: data.styles || {},
      title: data.title || '',
      actions: data.actions || [],
      content: data.content || '',
      open: data.open || false,
      disableProgress: data.disableProgress || false,
      currentStage: data.currentStage || 0,
      updateHandler: data.updateHandler || function () {},
    };
  }
}

function setVehicleModel(name, update, cb) {
  triageAPI.setVehicleModel(name, update, cb);
}

function resetDamage(cb) {
  triageAPI.resetDamage(cb);
  _parts.map((part) => {
    part = Object.assign(part, { damageSeverity: 0 });
  });
}

const CHANGE_EVENT = 'change';
const HOVER_EVENT = 'hover';

const TriageStore = Object.assign({}, EventEmitter.prototype, {
  //public methods
  getCase() {
    return triageAPI.getCurrentCaseFromLocal();
  },

  getProgressState() {
    return triageAPI.getProgressState();
  },

  getAllParts() {
    return _parts;
  },

  getPartDamage(cb) {
    return triageAPI.getPartDamage(cb);
  },

  getSectionDamage(cb) {
    triageAPI.getSectionDamageFromLocal(cb);
  },

  getCompletedQuestionSets() {
    return triageAPI.getCurrentCaseFromLocal().answeredQuestionSets;
  },

  getCurrentModal() {
    return _modalModel;
  },

  getVehicleModel() {
    const currentCase = triageAPI.getCurrentCaseFromLocal();
    return currentCase.userSelectedVehicle
      ? currentCase.userSelectedVehicle
      : currentCase.splat; // Still prioritise the user-selected version, but fall back on the splat if not
  },

  getEndpoints(data, cb) {
    triageAPI.getEndpoints({ postcode: data, callback: cb });
  },

  getPostcodes(partial, cb) {
    triageAPI.getPostcodes(partial, cb);
  },

  getDecision() {
    return triageAPI.getCurrentCaseFromLocal().decision;
  },

  getCarHireData(cb) {
    triageAPI.getCarHireDataById(cb);
  },

  getCarHireRatesForGroupName(groupName, cb) {
    triageAPI.getCarHireRatesForGroupName(groupName, cb);
  },

  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  emitHover() {
    this.emit(HOVER_EVENT);
  },

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  addHoverListener(callback) {
    this.on(HOVER_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  removeHoverListener(callback) {
    this.removeListener(HOVER_EVENT, callback);
  },
});

Dispatcher.register(function (action) {
  var data;

  switch (action.actionType) {
    case ActionTypes.CASE_CREATE:
      data = action.data.data;
      if (data !== {}) {
        triageAPI.createNewCase(
          data.registration,
          data.mileage,
          data.override,
          data.businessLine,
          data.claimReferenceNumber,
          data.subInsurer,
          () => {
            data.callback();
            TriageStore.emitChange();
          },
          () => {
            if (action.data.onError) action.data.onError();
          }
        );
      }
      break;

    case ActionTypes.CASE_CLEAR:
      triageAPI.resetCase(() => {
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_DESTROY:
      triageAPI.closeCase(() => {
        if (action.data) action.data();
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_MAKE_DECISION:
      data = action.data;
      return triageAPI.makeDecision(
        () => {
          if (data.callback) data.callback();
          TriageStore.emitChange();
        },
        () => {
          if (data.onError) data.onError();
        }
      );

    case ActionTypes.CASE_UPDATE:
      data = action.data;
      if (data !== {}) {
        triageAPI.updateCase(data, () => {
          TriageStore.emitChange();
        });
      }

      break;

    case ActionTypes.CASE_LOAD:
      data = action.data;
      if (data !== {}) {
        return triageAPI.getCaseFromServer(data, () => {
          if (data.callback) data.callback();
          TriageStore.emitChange();
        });
      }

      break;

    case ActionTypes.CASE_LOAD_REMOTE:
      data = action.data;
      if (data) {
        return triageAPI.loadNewCaseRemotely(data.data, () => {
          if (data.callback) data.callback();
          TriageStore.emitChange();
        });
      }

      break;

    case ActionTypes.CASE_SET_CRITS:
      data = action.data;
      triageAPI.setCriticals(data, () => {
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_GET_PDF:
      data = action.data;
      triageAPI.generatePDF(data, () => {
        //TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_SUBMIT_REF:
      data = action.data;
      triageAPI.submitClaimReference(data.data, () => {
        if (data.callback) data.callback();
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_ANSWER_SETTLEMENT:
      data = action.data;
      triageAPI.submitSettlementAnswer(data, () => {
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_GET_VEHICLE_HISTORY:
      data = action.data;
      triageAPI.getVehicleHistory(
        data,
        (res) => {
          if (data.callback) data.callback(res);
          TriageStore.emitChange();
        },
        (res) => {
          if (data.onError) data.onError(res);
        }
      );
      break;

    case ActionTypes.PARTS_ITEM_ADD:
      data = action.data;
      addPart(data);
      TriageStore.emitChange();
      break;

    case ActionTypes.PARTS_QUESTIONSET_MARKCOMPLETE:
      _completedQuestionSets =
        triageAPI.getCurrentCaseFromLocal().answeredQuestionSets;
      action.data.items.map((item) => {
        if (_completedQuestionSets.indexOf(item) < 0)
          _completedQuestionSets.push(item);
      });
      triageAPI.updateCompletedQuestionSets(_completedQuestionSets, () => {
        if (action.data.callback) action.data.callback();
        TriageStore.emitChange();
      });

      break;

    case ActionTypes.PARTS_QUESTIONSET_RESET:
      triageAPI.updateCompletedQuestionSetsOnLocal([]);
      TriageStore.emitChange();
      break;

    case ActionTypes.PARTS_ITEM_UPDATE:
      data = action.data;
      updatePart(data.name, data, data.update, data.cb);
      if (action.data.update) {
        TriageStore.emitChange();
      } else {
        TriageStore.emitHover();
      }
      break;

    case ActionTypes.PARTS_RESET_ALL:
      _parts = [];
      TriageStore.emitChange();
      break;

    case ActionTypes.PARTS_RESET_DMG:
      resetDamage(() => {
        TriageStore.emitChange();
      });

      break;

    case ActionTypes.PARTS_RESET_LOCAL_DMG:
      triageAPI.resetDamageFromLocal(() => {
        TriageStore.emitChange();
      });

      break;

    case ActionTypes.PARTS_SET_MODAL:
      setModalModel(action.data);
      TriageStore.emitChange();
      break;

    case ActionTypes.PARTS_GET_DAMAGE:
      getPartDamage(action.data);
      TriageStore.emitChange();
      break;

    case ActionTypes.VEHICLE_SET_MODEL:
      setVehicleModel(action.data.name, action.data.update, () => {
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.PROGRESS_SET:
      if (action.data !== null) triageAPI.setProgressState(action.data);
      TriageStore.emitChange();
      break;

    case ActionTypes.PROGRESS_RESET:
      triageAPI.resetProgress();
      TriageStore.emitChange();
      break;

    case ActionTypes.CASE_FOLLOWON_UPDATE:
      triageAPI.updateFurtherActionsModel(action.data);
      TriageStore.emitChange();
      break;

    case ActionTypes.CASE_SET_ENDPOINT:
      triageAPI.setEndpoint(action.data);
      TriageStore.emitChange();
      break;

    case ActionTypes.PARTS_REG_DMG:
      triageAPI.updateSectionDamage(action.data, (response) => {
        if (action.cb) action.cb(response);
        TriageStore.emitChange();
      });

      break;

    case ActionTypes.PARTS_REG_MULTIPLE_DMG:
      triageAPI.updateMultipleSectionDamage(action.data, (response) => {
        if (action.cb) action.cb(response);
        TriageStore.emitChange();
      });

      break;

    case ActionTypes.SELECT_VARIANT:
      triageAPI.setSelectedVariant(action.data.value, (response) => {
        if (action.data.callback) action.data.callback(response.data);
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CAR_HIRE_UPDATE:
      data = action.data;
      if (data !== {}) {
        triageAPI.updateCarHireData(
          data.carData,
          (res) => {
            if (data.onComplete) data.onComplete(res);
            TriageStore.emitChange();
          },
          (res) => {
            if (data.onError) data.onError(res);
          }
        );
      }
      break;

    case ActionTypes.CONFIRM_MOBILE:
      data = action.data;
      triageAPI.confirmMobile(data, () => {
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_ANSWER_CASH_IN_LIEU:
      data = action.data;
      triageAPI.setCashInLieuAnswer(data, () => {
        //TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_SET_BL:
      data = action.data;
      triageAPI.setBusinessLine(data.value, () => {
        if (data.callback) data.callback();
        TriageStore.emitChange();
      });
      break;

    case ActionTypes.CASE_SET_RETAIL:
      data = action.data;
      triageAPI.setCustomRetailValue(
        data.value,
        (res) => {
          if (data.callback) data.callback(res);
          TriageStore.emitChange();
        },
        (res) => {
          if (data.onError) data.onError(res);
        }
      );
      break;

    case ActionTypes.CASE_SET_ATFAULT_STATUS:
      data = action.data;
      triageAPI.setAtFaultStatus(
        data.status,
        (res) => {
          if (data.callback) data.callback(res);
          TriageStore.emitChange();
        },
        (res) => {
          if (data.onError) data.onError(res);
        }
      );
      break;

    case ActionTypes.CASE_SET_POSTCODE:
      data = action.data;
      triageAPI.setTriagePostcode(
        data.postcode,
        (res) => {
          if (data.callback) data.callback(res);
          TriageStore.emitChange();
        },
        (res) => {
          if (data.onError) data.onError(res);
        }
      );
      break;

    default:
  }

  return true;
});

export default TriageStore;
