import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRight extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M473.5,198.6c0,0-7.9-8-10.3-24.7c-1.3-8.9-4.6-30.8-5.2-51.2c-0.9-31.7-0.9-63.2-0.9-63.2h231c0-0.5,1.5,63.6,7.2,129.2  c0.3,3.4,0.6,6.7,0.9,9.9H473.5L473.5,198.6z"
      />
    );
  }
}

let composed = DoorFrontRight;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
