import { Component } from 'react';
import s from './TriageReviewPage.module.scss';
import RaisedButton from 'material-ui/RaisedButton';
import TriageActions from '../../actions/TriageActions';
import AppActions from '../../actions/AppActions';
import TriageStore from '../../stores/TriageStore';
import triageAPI from '../../api/triageAPI';
import ReportPage from '../TriagePage/ReportPage';
import pdfStyles from '../TriagePage/ReportPage/PDFStyles';
import dateFormat from 'dateformat';

let report;

class LoadCaseReviewButton extends Component {
  state = {
    data: {},
    currentCase: null,
    partsList: [],
    loading: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: JSON.parse(this.props.data),
    });
  }
  componentWillUnmount() {
    this.setState({
      currentCase: null,
    });
  }

  pdfRequestHandler = (data) => {
    AppActions.addSpinner();
    TriageActions.generatePDF({
      pdfMarkup: this.state.reportHtml,
      pdfStyles: pdfStyles,
      callback: (res) => {
        AppActions.removeSpinner();
        try {
          const fileName =
            'report(' + dateFormat(new Date(), 'fullDate') + ').pdf';

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(res, fileName);
          } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            link.download = fileName;
            link.click();
          }
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  clickHandler = () => {
    const { data } = this.state;
    this.setState({ loading: true });
    if (data.id !== '00000000-0000-0000-0000-000000000000') {
      triageAPI.getSectionDamage(data.id, (res) => {
        this.setState(
          {
            sectionDamage: res,
          },
          () => {}
        );
      });
    }

    triageAPI.getCaseReview(
      data,
      (res) => {
        this.setState(
          {
            currentCase: res,
            loading: false,
          },
          () => {
            this.setState(
              {
                partsList: TriageStore.getAllParts() || [],
              },
              () => {
                this.setState({
                  reportHtml: this.refs.wrap.innerHTML,
                });
                const modalModel = {
                  open: true,
                  title: 'Review Case',
                  content: report,
                  type: 'alert',
                  actions: [
                    <RaisedButton
                      key={0}
                      label="Generate PDF"
                      primary={true}
                      onClick={() => {
                        this.pdfRequestHandler(data);
                      }}
                    />,
                    <span key={1}>&nbsp;</span>,
                    <RaisedButton
                      key={2}
                      label="Close"
                      primary={true}
                      onClick={() => {
                        AppActions.destroyModal();
                      }}
                    />,
                  ],
                };
                setTimeout(() => {
                  AppActions.createModal(modalModel);
                }, 0);
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 300);
              }
            );
          }
        );
      },
      (res) => {
        console.log(res || 'JS ERROR');
        this.setState({ loading: false });

        const modalModel = {
          open: true,
          title: 'Error Retrieving Case Review',
          content: (
            <div>
              <p>There was an error retrieving this request.</p>
              <p>If this error persists, please contact an Administrator.</p>
            </div>
          ),
          type: 'alert',
          conConfirm: () => {
            AppActions.destroyModal();
          },
        };
        setTimeout(() => {
          AppActions.createModal(modalModel);
        }, 0);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300);
      }
    );
  };

  render() {
    const { currentCase } = this.state;

    report =
      currentCase && currentCase !== null ? (
        <ReportPage case={currentCase} status={this.state.data.status} />
      ) : null;

    return (
      <div className={s.loadButton}>
        <div ref="wrap" style={{ display: 'none' }}>
          {report}
        </div>
        <RaisedButton
          primary={true}
          label={this.state.loading ? 'Loading...' : 'Load'}
          onClick={this.clickHandler}
        />
      </div>
    );
  }
}

let composed = LoadCaseReviewButton;

export default composed;
