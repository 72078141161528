import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontRightPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontRight)}
        d="M617.8,292.7l0.1-81h-84c-27.1,0-89.7,0.2-89.7,0.2c-1.9,0.7-0.1,3.5-0.1,3.5c2.6,10,72.1,68.9,72.3,68.9   c10.3,8.9,25.9,12.1,32,12.4c0.3,0,46,0.1,63.8,0C616.6,296.7,617.6,297.1,617.8,292.7z"
      />
    );
  }
}

let composed = WindowFrontRightPickup;
composed = SectionComponent({
  name: 'Front Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
