import { Component } from 'react';
import s from './QuestionsList.module.scss';

import TriageStore from '../../../../stores/TriageStore';
import TriageActions from '../../../../actions/TriageActions';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import AirbagsFrontQuestions from '../QuestionSets/AirbagsFrontQuestions';
import AirbagsSideQuestions from '../QuestionSets/AirbagsSideQuestions';
import DoorsQuestions from '../QuestionSets/DoorsQuestions';
import ExhaustDamageQuestions from '../QuestionSets/ExhaustDamageQuestions';
import TowbarQuestions from '../QuestionSets/TowbarQuestions';
import FluidLeakQuestions from '../QuestionSets/FluidLeakQuestions';
import LampsFrontQuestions from '../QuestionSets/LampsFrontQuestions';
import LampsRearQuestions from '../QuestionSets/LampsRearQuestions';
import SteeringQuestions from '../QuestionSets/SteeringQuestions';

class QuestionsList extends Component {
  state = {
    data: {},
    sectionDamage: [],
    answeredQuestionSets: [],
    mounted: null,
  };

  UNSAFE_componentWillMount() {
    this.state.mounted = true;
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    this.state.mounted = false;
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    const answeredQuestionSets = this.props.allowEdit
      ? TriageStore.getCompletedQuestionSets()
      : [
          'airbagsfront',
          'airbagsside',
          'fluidleak',
          'steering',
          'doors',
          'lampsfront',
          'lampsrear',
          'exhaustdamage',
          'towbarpresent',
        ];
    let sectionDamage = [];

    if (this.props.sectionDamage) {
      sectionDamage = this.props.sectionDamage;
    } else {
      TriageStore.getSectionDamage((response) => {
        sectionDamage = response;
      });
    }

    if (this.state.mounted) {
      this.setState({
        answeredQuestionSets: answeredQuestionSets,
        sectionDamage: sectionDamage,
      });
    }
  };

  buttonHandler = () => {
    TriageActions.setModal({
      open: false,
    });

    TriageActions.makeDecision();
  };

  clickHandler = (val, e) => {
    TriageActions.setModal({
      title: 'Review your answer',
      open: true,
      content: val.component,
      actions: [
        <span key={0}></span>,
        <span key={1}></span>,
        <FlatButton
          key={2}
          label="Close"
          primary={true}
          onClick={this.buttonHandler.bind(this, true)}
        />,
      ],
      updateHandler: () => {
        TriageActions.makeDecision();
      },
    });
  };

  render() {
    const { sectionDamage } = this.state;
    const SECTION_DATA = {
      airbagsfront: {
        title: 'Have any front airbags deployed?',
        answer: false,
        component: <AirbagsFrontQuestions sectionDamage={sectionDamage} />,
      },
      airbagsside: {
        title: 'Have any side airbags deployed?',
        answer: false,
        component: <AirbagsSideQuestions sectionDamage={sectionDamage} />,
      },
      fluidleak: {
        title: 'Are fluids leaking from the vehicle?',
        answer: false,
        component: <FluidLeakQuestions sectionDamage={sectionDamage} />,
      },
      steering: {
        title: 'Are you having difficulty steering the vehicle?',
        answer: false,
        component: <SteeringQuestions sectionDamage={sectionDamage} />,
      },
      doors: {
        title:
          'Are you having difficulty closing the doors, bonnet, boot or tailgate?',
        answer: false,
        component: <DoorsQuestions sectionDamage={sectionDamage} />,
      },
      lampsfront: {
        title: 'Are the front lamps damaged / not working?',
        answer: false,
        component: <LampsFrontQuestions sectionDamage={sectionDamage} />,
      },
      lampsrear: {
        title: 'Are the rear lamps broken / not working?',
        answer: false,
        component: <LampsRearQuestions sectionDamage={sectionDamage} />,
      },
      exhaustdamage: {
        title: 'Is the exhaust damaged / detached from the vehicle?',
        answer: false,
        component: <ExhaustDamageQuestions sectionDamage={sectionDamage} />,
      },
      towbarpresent: {
        title: 'Is a towbar attached to the rear of the vehicle?',
        answer: false,
        component: <TowbarQuestions sectionDamage={sectionDamage} />,
      },
    };

    this.state.sectionDamage.map((item) => {
      SECTION_DATA[item.Name].answer =
        (item.Options === undefined || item.Options['0']) === '0'
          ? false
          : true;
    });

    return this.state.answeredQuestionSets.length > 0 ? (
      <div className={s.questionsList}>
        <h2>Section Questions: </h2>
        <ul className={s.list}>
          {this.state.answeredQuestionSets.map((item, i) => {
            return SECTION_DATA[item] ? (
              <li key={i} className={s.listItem}>
                <span>{SECTION_DATA[item].title}</span>
                <strong
                  className={s.answer}
                  style={{ fontWeight: 'bold', width: '100%' }}
                >
                  {SECTION_DATA[item].answer ? '-Yes' : '-No'}
                </strong>
                {this.props.allowEdit ? (
                  <span>
                    <RaisedButton
                      primary={true}
                      className={s.btn}
                      onClick={this.clickHandler.bind(this, SECTION_DATA[item])}
                    >
                      Edit
                    </RaisedButton>
                  </span>
                ) : null}
              </li>
            ) : null;
          })}
        </ul>
      </div>
    ) : this.props.allowEdit ? (
      <div className={s.questionsList}>
        <h3>There are currently no answered Section Questions</h3>
        <ul className={s.list}>
          <li className={s.listItem}>
            <span>
              Clicking on any panel within the Damage Tool will bring up
              unanswered Section Questions for that panel.
            </span>
          </li>
          <li className={s.listItem}>
            <span>
              You will not be asked a Section Question more than once.
            </span>
          </li>
          <li className={s.listItem}>
            <span>
              Answered Section Questions will appear here. Click on the "Edit"
              buttons to edit them.
            </span>
          </li>
        </ul>
      </div>
    ) : null;
  }
}

let composed = QuestionsList;

export default composed;
