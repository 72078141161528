import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightVan extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M1001.1,97.2c0-17-1.3-25.1-4.8-28.1c-2.3-2.2-4.8-6.9-4.8-10.3c0-7.8-7.5-9.6-44-10.6L920,47.7  c9.2,30.1-6.3,64.8-30.3,78.8c-27.7,15.6-67.9,9.8-86.9-22.2c-6.7-11-10.3-19.2-11.3-56.6l0.2-28.9c-0.3-1.7-1.6-2.8-3.2-2.8h-95.8  c-0.9,0-1.6,0.6-1.6,1.5l5.2,115.1c3.8,65.3,5.3,79.6,11.3,118.8c3.1,20.9,6.6,44.6,7.5,50.9c0.1,0.9,0.9,2.5,1.8,2.4  C911.5,293.3,918.5,278,918.5,278c5.2-1.8,50-72.2,53.4-94.7l3.7-38.2C980.9,139.1,1002,132.7,1001.1,97.2z M937,150.9  c0-1,0.8-1.9,1.7-1.9c0,0,0,0,0.1,0l28.8,0.1c1.1,0,1.9,1,1.8,2.1l-3.4,34c-0.1,1-1,1.7-1.9,1.6c-6.3-0.5-27.1-2.4-27.1-5.2  L937,150.9L937,150.9z M985.4,130.2c-1.1,1.3-2.7,1.8-4.4,1.8h-81c-15.3,1-4-3.2-2.1-5.2c1.2-1.3,2.8-1.8,9.5-1.8h80.9  C988.9,125,987.3,128.2,985.4,130.2z"
        />
      </g>
    );
  }
}

let composed = WingBackRightVan;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
