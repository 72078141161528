import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontRightVan extends Component {
  render() {
    return (
      <g className={s.wingFrontRight}>
        <path
          {...this.props}
          d="M568,249.7c-12.7-0.1-14.5,0-35.5-1c-16.9-0.8-37.5-10.4-52.4-16.4H480c-27.6-13.3-54.4-28.2-75.4-50.4   c-0.1-0.1-0.2-0.2-0.2-0.3c-8.2-16-4.9-115.3-0.3-134.3c0.2-0.9-0.5-1.8-1.4-1.8H368c-0.9,0-1.6,0.9-1.4,1.8   c2.8,10.4,13.7,62.7-46.8,87.7c-0.1,0-0.1,0-0.2,0.1c-20.3,6-53.1,4.5-75.1-25.7c-7.9-10.9-11.9-26.9-12.4-39.8   c-0.2-6,1.8-19.5,2.5-24.2c0.1-0.9-0.5-1.7-1.4-1.7l-69.5,0.4c-3.5,0.1-9.3,0-11.8,4.3c-0.5,0.9,0.1,2.1,1.2,2.2l0,0   c0.8,0,1.4,0.7,1.4,1.5l-0.1,11.5c0,0.7-0.5,1.3-1.2,1.4l-0.8,0.1c-0.7,0.1-1.2,0.7-1.2,1.4v39.1c0,0.7,0.6,1.4,1.3,1.4   c0.8,0.1,1.7,0.4,1.9,1.8c1.6,10.6,6.9,28.4,25,38.3c36.9,20.1,130.1,34.5,149.2,36.5c12,1.2,55.7,30.8,91.8,49.4   c9.6,4.9,59.6,32.8,82.9,43.3c32.6,14.7,64.5,14.9,72.1,14.7c0.9,0,1.5-0.8,1.4-1.7l-7.3-38.5C569.3,250.2,568.7,249.7,568,249.7z"
        />
        <path
          {...this.props}
          d="M576.7,289.4l-7.3-38.7c-0.1-0.6-0.7-1.1-1.3-1.1c-12.7-0.1-14.5,0-35.6-1c-16.9-0.8-37.5-10.4-52.4-16.4H480   c-27.6-13.3-54.4-28.2-75.4-50.4c-0.1-0.1-0.2-0.2-0.2-0.3c-8.2-16-4.9-115.7-0.3-134.4c0.2-0.8-0.4-1.7-1.3-1.7h-35   c-0.9,0-1.5,0.8-1.3,1.7c2.8,10.1,13.9,62.7-46.8,87.9c-0.1,0-0.1,0-0.2,0.1c-20.3,6-53.1,4.5-75.1-25.7   c-7.9-10.9-11.9-26.9-12.4-39.8c-0.2-6.1,1.8-19.7,2.5-24.3c0.1-0.8-0.5-1.6-1.3-1.6l-69.6,0.4c-3.5,0.1-9.4,0-11.8,4.5   c-0.5,0.9,0.1,2,1.1,2h0.2c0.7,0,1.3,0.6,1.3,1.4l-0.1,11.7c0,0.7-0.5,1.2-1.2,1.3l-0.9,0.1c-0.7,0.1-1.2,0.7-1.2,1.3v39.3   c0,0.7,0.5,1.3,1.2,1.3c0.8,0,1.8,0.4,2,1.8c1.6,10.6,6.9,28.4,25,38.3c36.9,20.1,130.1,34.5,149.2,36.5c0.6,0.1,1.3,0.2,2,0.4   h57.4c23.4,21.8,62,46,97.2,61l-16.9,13.7c13.1,6.9,26.1,13.6,35.1,17.6c32.8,14.8,64.9,14.9,72.2,14.7   C576.3,291,576.9,290.2,576.7,289.4z M159.8,112.1c0.2,0,40.9,0.1,41,0.1c0.8,15.7,3.3,24.1,6.7,36c-10.3-1.8-22-3.2-29.1-7.8   C168.6,133.9,162.7,122.3,159.8,112.1z M240.3,109.9h-81.5l-0.5-3h80.3L240.3,109.9z"
        />
      </g>
    );
  }
}

let composed = WingFrontRightVan;
composed = SectionComponent({
  name: 'Front Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Driver Wing Damage',
    content: <Modal name="Front Driver Wing" />,
  },
})(composed);

export default composed;
