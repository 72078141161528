import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './ReportPage.module.scss';
import cx from 'classnames';

import Summary from '../Review/Summary';
import QuestionsList from '../DamageTool/QuestionsList';
import VehicleHistory from '../Review/VehicleHistory';
import TriageStore from '../../../stores/TriageStore';
import AppStore from '../../../stores/AppStore';
import ErrorIcon from 'material-ui/svg-icons/content/block';
import { GridList, GridTile } from 'material-ui/GridList';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import Lightbox from 'react-images';
import EmailStatusInfo from '../Review/Summary/Components/EmailStatusInfo';

const title = 'TRIAGE DAMAGE REPORT';
const DECISION_TYPES = {
  0: 'Unknown',
  1: 'Cosmetic',
  2: 'Mobile',
  3: 'Fast Track',
  4: 'Traditional',
  5: 'Total Loss',
  6: 'Possible Total Loss',
  7: 'Potential Total Loss',
  8: 'Cash in Lieu',
};

var galleryURLs = [];
var uniqueGalleryURLs = [];
var uniqueLightboxImages = [];

class ReportPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    isDialogOpen: false,
    displayedImage: null,
    userData: PropTypes.func,
    currentCase: null,
    fallbackImage: null,
    isLightboxOpen: false,
    currentImage: 0,
    showThumbnails: true,
    uniqueGalleryURLs: [],
    galleryUrls: [],
  };

  UNSAFE_componentWillMount() {
    galleryURLs = [];
    uniqueLightboxImages = [];

    this.setState({
      userData: this.props.userData ? this.props.userData : AppStore.userData(),
      currentCase: this.props.case ? this.props.case : TriageStore.getCase(),
    });

    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      currentCase: this.props.case ? this.props.case : TriageStore.getCase(),
    });
  };

  componentDidMount() {
    const splat = this.state.currentCase.splat;
    if (splat) {
      try {
        // TODO: remove require?
        const url = require('../../TriagePage/Review/DefaultImages/' +
          splat +
          '.png');

        this.getFallbackImage(url).then((response) => {
          this.setState({
            fallbackImage: response,
          });
        });
      } catch (e) {
        console.log('fallbackImage error', e);
      }
    }
    if (
      this.state.currentCase &&
      this.state.currentCase.images &&
      this.state.currentCase.images.length > 0
    ) {
      this.createImageURLs();
    }
  }

  getFallbackImage = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.send();
    });
  };

  openLightbox = (tile, i) => {
    this.state.uniqueGalleryURLs.forEach((img) => {
      uniqueLightboxImages.push({
        src: img,
      });
    });
    this.setState({
      isLightboxOpen: true,
      currentImage: i,
    });
  };

  closeLightbox = () => {
    this.setState({
      isLightboxOpen: false,
      displayedImage: null,
      uniqueGalleryURLs: [],
    });
  };

  gotoPrevLightboxImage = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNextLightboxImage = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  selectImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  createImageURLs = () => {
    this.state.currentCase.images.map((img) => {
      galleryURLs.push('data:image/png;base64,' + img);
    });
    uniqueGalleryURLs = galleryURLs.filter(function (elem, index, self) {
      return index == self.indexOf(elem);
    });
    this.setState({
      uniqueGalleryURLs: uniqueGalleryURLs,
    });
  };

  render() {
    const vehicleHistory =
      this.state.currentCase.vehicleHistory &&
      this.state.currentCase.caseSettings.AllowVehicleHistoryQuery ? (
        <div className={'row'}>
          <div className={'vehicleHistory'}>
            <h2>Vehicle History</h2>
            <VehicleHistory
              styles={s}
              data={this.state.currentCase.vehicleHistory}
            />
          </div>
        </div>
      ) : null;

    const galleryImages =
      this.state.currentCase &&
      this.state.currentCase.images &&
      this.state.currentCase.images.length > 0
        ? this.state.currentCase.images
        : [];

    const mainImage =
      this.state.currentCase.SVG === undefined ||
      this.state.currentCase.SVG === '' ? (
        this.state.fallbackImage !== null ? (
          <img className={'image'} src={this.state.fallbackImage} alt="" />
        ) : null
      ) : this.state.currentCase.SVG !== '' ? (
        <img className={'image'} src={this.state.currentCase.SVG} alt="" />
      ) : null;

    const errorOverlay =
      this.state.currentCase.criticals &&
      this.state.currentCase.criticals.length > 0 &&
      mainImage !== null ? (
        <div className={'criticalDamage'}>
          <div>
            {this.state.currentCase.criticals.map((item, i) => {
              return (
                <div className={'criticalDamageText'} key={i}>
                  {item} damage reported
                </div>
              );
            })}{' '}
          </div>
          <ErrorIcon className={'criticalDamageIcon'} />
        </div>
      ) : null;

    const statusText = this.props.status ? (
      <span>
        | STATUS:{' '}
        <span className={s[this.props.status.toLowerCase()]}>
          {this.props.status}
        </span>
      </span>
    ) : null;

    const imageList =
      galleryImages.length > 0 ? (
        <div className={cx(s.gallery, 'gallery')}>
          <h2>Upload Gallery</h2>
          <GridList className={cx(s.galleryGrid, 'galleryGrid')}>
            {uniqueGalleryURLs.map((tile, i) => (
              <GridTile
                key={i}
                titleBackground="linear-gradient(to top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
              >
                <div
                  className={cx(s.galleryImgContainer, 'galleryImgContainer')}
                >
                  <img
                    src={tile}
                    alt="Gallery"
                    className={s.galleryImg}
                    onClick={() => this.openLightbox(tile, i)}
                  />
                </div>
              </GridTile>
            ))}
          </GridList>
        </div>
      ) : null;

    const { furtherActionsModel } = this.state.currentCase;
    const hasEmailBeenSent =
      furtherActionsModel && furtherActionsModel.EmailAddress
        ? furtherActionsModel.EmailAddress !== ''
        : false;

    const { caseSettings } = this.state.currentCase;

    const showImagesComponent =
      caseSettings && caseSettings !== null && caseSettings.ShowImagesComponent
        ? true
        : false;

    return (
      <div className={'pdfReportPage'}>
        <div className={'row'}>
          <h1>
            {title} {statusText}
          </h1>
          <div className={'column'}>
            <div style={{ position: 'relative' }}>
              {mainImage}
              {errorOverlay}
            </div>
            <QuestionsList
              allowEdit={false}
              className={'questions'}
              sectionDamage={this.state.currentCase.sectionDamage}
            />
            {hasEmailBeenSent || showImagesComponent ? (
              <EmailStatusInfo
                furtherActions={this.state.currentCase.furtherActionsModel}
                showImagesComponent={showImagesComponent}
                hasEmailBeenSent={hasEmailBeenSent}
              />
            ) : null}
          </div>
          <div className={'column'}>
            <div className={'summary'}>
              <Summary
                decisionTypes={DECISION_TYPES}
                userData={this.state.userData}
                case={this.state.currentCase}
              />
            </div>
          </div>
        </div>
        {vehicleHistory}
        {imageList}
        <Dialog
          autoScrollBodyContent={true}
          contentStyle={{
            width: '100%',
            maxWidth: 1500,
          }}
          actions={[
            <FlatButton
              label="Close"
              primary={true}
              keyboardFocused={true}
              onClick={() => this.setImageDisplay(null)}
            />,
          ]}
          modal={false}
          open={this.state.isDialogOpen}
          onRequestClose={() => this.setImageDisplay(null)}
        >
          <img
            src={`data:image/png;base64,${this.state.displayedImage}`}
            className={s.displayedImage}
            alt=""
          />
        </Dialog>

        <div className={'row'}>
          <Lightbox
            images={uniqueLightboxImages}
            currentImage={this.state.currentImage}
            isOpen={this.state.isLightboxOpen}
            onClickPrev={this.gotoPrevLightboxImage}
            onClickNext={this.gotoNextLightboxImage}
            onClickThumbnail={this.selectImage}
            onClose={this.closeLightbox}
            showThumbnails={this.state.showThumbnails}
          />
        </div>
      </div>
    );
  }
}

export default ReportPage;
