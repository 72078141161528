import React from 'react';

export const PoundCell = (props) => {
  return <div>£{props.data}</div>;
};

export const PercentCell = (props) => {
  return <div>{props.data}%</div>;
};

export const PRODUCT_LINES = [
  'All',
  'Unknown',
  'Axa Direct',
  'Commercial Lines',
  'Personal Lines',
  'Swiftcover',
];
export const PRODUCT_COLORS = [
  '#ccc',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
];

export const listViewConfig = [
  {
    key: 'Total Volumes',
    selected: true,
    type: 'pie',
    values: {
      Unknown: 0,
      'Commercial Lines': 0,
      'Personal Lines': 0,
      'Axa Direct': 0,
      'Swift Cover': 0,
    },
  },
  {
    key: 'Total Loss Volumes',
    selected: true,
    type: 'pie',
    values: {
      Unknown: 0,
      'Commercial Lines': 0,
      'Personal Lines': 0,
      'Axa Direct': 0,
      'Swift Cover': 0,
    },
  },
  {
    key: 'Total Loss Ratios',
    selected: true,
    type: 'bar',
    values: {
      Unknown: 0,
      'Commercial Lines': 0,
      'Personal Lines': 0,
      'Axa Direct': 0,
      'Swift Cover': 0,
    },
    unit: '%',
  },
  {
    key: 'Agreed Settlements',
    selected: true,
    type: 'bar',
    values: {
      Unknown: 0,
      'Commercial Lines': 0,
      'Personal Lines': 0,
      'Axa Direct': 0,
      'Swift Cover': 0,
    },
    unit: ' (GBP)',
  },
  {
    key: 'Total Loss Agreements',
    canFilter: false,
    type: 'pie',
    values: { Agreed: 0, 'Not Agreed': 0, 'Not offered': 0 },
  },
];

export const itemViewConfig = {
  'Total Volumes': [],
  'Total Loss Volumes': [],
  'Total Loss Ratios': [],
  'Agreed Settlements': [],
  'Total Loss Agreements': [],
};

export const listViewCSS = `

body {
  font-family: sans-serif !important;
  font-size: 0.618em;
  color: #333;
  margin: 0;
  padding: 1%;
  padding-right: 0;
}
a, select{
  display: none;
}
div {
    text-align: center;
    overflow: inherit !important;
}

body > div > ul {
    display: block;
    text-align: left;
    width: 40%!important;
    margin: 0;
    padding: 0;
}

body > div > ul > li {
  list-style-type: none!important;
  display: inline-block;
  width: 100%!important;
  padding-right: 1%;
  padding-bottom: 2%;
}

path{
  stroke: #333 !important;
  stroke-width: 1 !important;
}

h2{
  text-align: left;
}

button {
  display: none !important;
}

table{
  width: 100%!important;
  border-collapse: collapse !important;
}

.recharts-legend-item{
  color: #333;
}

th, td {
    width: 20%;
    text-align: center;
    vertical-align: top;
}

.griddle-container {
  width: 40%!important;
}

.result-column {
  background-color: red;
  width: 40%;
}

.recharts-legend-wrapper {
  width: 400px!important;
}
`;

export const griddleConfig = {
  settlementsConfig: [
    {
      columnName: 'Unknown',
      customComponent: PoundCell,
    },
    {
      columnName: 'Axa Direct',
      customComponent: PoundCell,
    },
    {
      columnName: 'Commercial Lines',
      customComponent: PoundCell,
    },
    {
      columnName: 'Personal Lines',
      customComponent: PoundCell,
    },
    {
      columnName: 'Swiftcover',
      customComponent: PoundCell,
    },
  ],
  ratiosConfig: [
    {
      columnName: 'Unknown',
      customComponent: PercentCell,
    },
    {
      columnName: 'Axa Direct',
      customComponent: PercentCell,
    },
    {
      columnName: 'Commercial Lines',
      customComponent: PercentCell,
    },
    {
      columnName: 'Personal Lines',
      customComponent: PercentCell,
    },
    {
      columnName: 'Swiftcover',
      customComponent: PercentCell,
    },
  ],
};
