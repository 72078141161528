import React from 'react';
import s from '../TriageStatsPage.module.scss';
import ResultItem from './ResultItem';

const ResultsList = (props) => {
  const items = props.data.map((item, i) => {
    return (
      <li key={i}>
        <ResultItem
          data={item}
          maxGraphWidth={props.maxGraphWidth}
          isAnimationActive={props.isAnimationActive}
          clickHandler={props.clickHandler}
          colors={props.colors}
          griddleConfig={
            props.configArray[i] ? props.configArray[i].value || null : null
          }
          activeIndex={props.filterIndex}
        />
      </li>
    );
  });

  return <ul className={s.resultsList}>{items}</ul>;
};

export default ResultsList;
