import { Component } from 'react';
import s from './ProgressBar.module.scss';
import cx from 'classnames';

import TriageStore from '../../../stores/TriageStore';
import TriageActions from '../../../actions/TriageActions';

class ProgressBar extends Component {
  clickHandler = (e) => {
    let progress = TriageStore.getProgressState();
    e.stopPropagation();
    let disabled = false;
    let nodes = Array.prototype.slice.call(e.currentTarget.children);
    let index = nodes.indexOf(e.target.parentNode.parentNode);
    for (let i in progress.DISABLED_STAGES) {
      if (progress.DISABLED_STAGES[i] === index) {
        disabled = true;
      }
    }

    if (index === -1 || disabled) return;

    let newState = TriageStore.getProgressState();
    newState.CURRENT_STAGE = index;

    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  getItem = (c, k, text, title) => {
    return (
      <li title={title} className={c} key={k}>
        <span>
          <span>{text}</span>
        </span>
      </li>
    );
  };

  getNavList = () => {
    let list = [];
    const stages = this.props.stages;
    let progress = TriageStore.getProgressState();
    //console.log(progress);
    for (let i = 0; i < progress.MAX_STAGE; i++) {
      let disabledStyle = null;
      for (let x in progress.DISABLED_STAGES) {
        if (progress.DISABLED_STAGES[x] == i) {
          disabledStyle = s.disabled;
        }
      }
      //console.log(i, progress);
      if (i < progress.CURRENT_STAGE) {
        list.push(
          this.getItem(
            cx(disabledStyle, s.complete),
            i,
            stages[i].name,
            stages[i].name + ': complete'
          )
        );
      } else if (i === progress.CURRENT_STAGE) {
        list.push(
          this.getItem(
            cx(disabledStyle, s.active),
            i,
            stages[i].name,
            stages[i].name + ': in progress'
          )
        );
      } else {
        if (i <= progress.MAX_PERMITTED) {
          list.push(
            this.getItem(
              cx(disabledStyle, s.visited),
              i,
              stages[i].name,
              stages[i].name + ': in progress'
            )
          );
        } else {
          list.push(
            this.getItem(cx(disabledStyle, s.inactive), i, stages[i].name, '')
          );
        }
      }
    }
    if (progress.CURRENT_STAGE >= 0) {
      return (
        <ul className={s.progressBar} onClick={this.clickHandler}>
          {list}
        </ul>
      );
    }
    return <span />;
  };

  render() {
    return this.getNavList();
  }
}

let composed = ProgressBar;

export default composed;
