import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightSaloon extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackRight)}
        d="M876.1,202.3l-163.4-1c1.1,14.5,9.2,80.8,9.2,80.8C801.1,268.6,876.1,202.3,876.1,202.3z"
      />
    );
  }
}

let composed = WindowBackRightSaloon;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
