import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class BumperFrontPV extends Component {
  render() {
    return (
      <g className={s.bumperFront}>
        <path
          {...this.props}
          d="M165,306.7c-16.3,1.3-17.5,1.2-17.5-4.8c0-5.8-1.7-6.6-12.4-6c-6.9,0.2-37.2,1.2-67.2,1.3  C3.7,298.4,0,299.8,0,321c0,19.2,6.5,23,40,23H66v227.9H39.5c-34,0-39,3-39,23c0,21.4,3.1,22.3,82.5,23.6l64.9,1.2v-6.2  c0-5.8,1.7-6.2,16.9-5.8l16.3,0.8l1.1-151.7l0.8-151.7L165,306.7L165,306.7z"
        />
        <path
          {...this.props}
          d="M165,306.7c-16.3,1.3-17.5,1.2-17.5-4.8c0-5.8-1.7-6.5-12.4-6c-6.9,0.2-37.2,1.2-67.2,1.3  C3.7,298.4,0,299.8,0,321c0,19.1,6.5,23,40,23H66v227.2H39.4c-34,0-39,3-39,23c0,21.3,3.1,22.3,82.5,23.6l64.9,1.2v-6.1  c0-5.8,1.7-6.1,16.9-5.8l16.3,0.8l1.1-151.3l0.8-151.3L165,306.7L165,306.7z M114.7,545.4c0,19.8-1.7,35.5-3.8,36.4  c-2,0.8-7.3,0.2-11.5-1.3c-7.3-2.6-7.7-5-7.7-114.1h3.7c0,98.7,1.1,111.3,6.3,112.5c3.7,0.8,6.9,1.6,7.7,1.6s1.7-55.9,1.7-124.5  s-1.1-124.5-2.8-124.1c-12.6,1-13,2.3-13,118.3h-3.8c0-107.4,0.2-116.2,6.9-118.3c3.7-1.6,8.6-2.6,10.4-1.9  c2.2,0.2,4.4,15.3,4.9,36.3l1.1,35.5l21.9,0.8l1.7,106.2l-24,1.6L114.7,545.4L114.7,545.4z M173.2,599.1l-16.3-0.8  c-15.2-0.5-16.9,0-16.9,5.8v1.6c-1.7-1.6-3.7-2.6-5.7-3.8l-1.1-39.7c-2-50.8-2-50.6,23.6-38.7l14.2,7.2c0.8,0.5,1.7,0.8,2.2,1.3  V599.1z M171.5,381.3l-14.2,7.2c-25.6,11.9-25.6,12.1-23.6-38.7l1.1-39.7c2.2-0.8,4.4-2.2,5.7-3.8v1.6c0,5.8,1.7,6.1,16.9,5.8  l16.3-0.8l0.2,67.7C173.2,380.1,172.4,380.5,171.5,381.3L171.5,381.3z"
        />
      </g>
    );
  }
}

let composed = BumperFrontPV;
composed = SectionComponent({
  name: 'Front Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front'],
  modalModel: {
    title: 'Register Front Bumper Damage',
    content: <Modal name="Front Bumper" />,
  },
})(composed);

export default composed;
