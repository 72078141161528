import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class Bonnet extends Component {
  render() {
    //console.log(this.props);
    return (
      <path
        className={s.bonnet}
        {...this.props}
        d="M314.3,266.1c-43.8,3.2-108.1,11.8-115.7,15.3c-6.3,2.8-10.4,9.8-16,26.1c-4.1,12.5-14.3,29.4-14.3,148.7  c-2.8,95.2,10,140.1,13.9,152.3c5.2,17,9.1,23.2,15.7,26.4c11.4,5.2,78.6,13.6,135.5,16.6c43.1,2.1,43.1,3.4,54.6-3.8  c-0.3-0.9-4.5-6.9-16.3-30.9c-17.3-34.8-22.6-71.9-22.6-160.2c0-79.3,5.2-119.9,19.1-150.2c11.4-25.4,23.4-34.2,23.2-35.5  C380,263.7,361.9,262.7,314.3,266.1L314.3,266.1z"
      />
    );
  }
}

let composed = Bonnet;
composed = SectionComponent({
  name: 'Bonnet',
  category: 'Panels',
  highlighted: false,
  modalModel: {
    title: 'Register Bonnet Damage',
    content: <Modal name="Bonnet" />,
    actions: [],
  },
})(composed);

export default composed;
