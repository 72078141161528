import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './TriageStatsPage.module.scss';

import withViewport from '../../decorators/withViewport';
import withLoggedInUser from '../../decorators/withLoggedInUser';
import withStatsData from './decorators/withStatsData';
import withFileGenerator from './decorators/withFileGenerator';
import Link from '../Link';
import RequestForm from './components/RequestForm';
import ResultsList from './components/ResultsList';
import ResultView from './components/ResultView';
import ResultsFooter from './components/ResultsFooter';
import { PRODUCT_COLORS, listViewConfig, griddleConfig } from './_config';
import { getPDFData } from './_helpers';
import dateFormat from 'dateformat';

import GenericMI from './components/GenericMI/GenericMI';
import BusinessLinesMI from './components/BusinessLinesMI/BusinessLinesMI';
class TriageStatisticsPage extends Component {
  static defaultProps = {
    fileOptions: null,
    setFileOptions: () => {},
    generatePDFHandler: () => {},
    generateExcelHandler: () => {},
    dateRange: null,
    listViewData: null,
    itemViewData: null,
    noBusinessLineClientData: {},
    miSummaryData: {},
    userData: {},
    refreshDataHandler: () => {},
  };

  state = {
    allowRawDataExport: false,
    displayMode: null,
    selectedCategory: null,
    filterIndex: null,
    dateRange: {},
  };

  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.currentUser &&
      !this.props.currentUser.IsAdmin &&
      !this.props.currentUser.IsMICompanyManager
    ) {
      // If the user doesn't have a role that allows them access to this page,
      // redirect them to the login page
      try {
        Location.pushState('/');
      } catch (e) {}
    }
    this.context.onSetTitle('Inter-cept | Triage Statistics');
    this.setState({
      allowRawDataExport:
        this.props.currentUser &&
        (this.props.currentUser.IsAdmin ||
          this.props.currentUser.IsMICompanyManager ||
          this.props.currentUser.IsMIDepartmentManager),
      displayMode: 'list',
      dateRange: this.props.dateRange,
    });
    let options = this.props.fileOptions;
    options.PDF.removeClasses = true;
    options.EXCEL.isJSONFormat = true;
    this.props.setFileOptions(options);
  }

  changeMinDate = (event, date) => {
    this.state.dateRange.minDate = date;
  };

  changeMaxDate = (event, date) => {
    this.state.dateRange.maxDate = date;
  };

  submitDateRangeHandler = (e) => {
    e.preventDefault();

    this.props.refreshDataHandler(this.state.dateRange);
  };

  handleNavigation = (e, i) => {
    e.preventDefault();
    this.setState({
      displayMode: this.state.displayMode === 'list' ? 'view' : 'list',
      selectedCategory: i,
    });
  };

  filterHandler = (i, v) => {
    /// NOTE -- TRIGGER FULL RE-RENDER OF RESULTVIEW BARCHART, AS IT DOES NOT
    /// RECOGNISE MUTATION OF SVG DOM. DO NOT REMOVE PRIOR TO COMPONENT FIX.
    /// YES, THIS IS NON-PERFORMANT.
    this.setState(
      {
        displayMode: this.state.displayMode === 'list' ? 'list' : null,
        filterIndex: i - 1 >= 0 ? i - 1 : null,
      },
      () => {
        if (this.state.displayMode === null) {
          this.setState({ displayMode: 'view' });
        }
      }
    );
  };

  sanitiseMinDate = (date) => {
    const newDate = new Date(date);
    return (
      newDate <
        new Date(this.state.dateRange.maxDate).setMonth(
          this.state.dateRange.maxDate.getMonth() - 12
        ) || newDate > this.state.dateRange.maxDate
    );
  };

  sanitiseMaxDate = (date) => {
    const newDate = new Date(date);
    return newDate < this.state.dateRange.minDate;
  };

  generatePDFHandler = () => {
    this.props.generatePDFHandler(getPDFData(this.refs, this.state.dateRange));
  };

  generateRawDataHandler = () => {
    const { dateRange } = this.state;
    this.props.getRawData(dateRange, (res) => {
      const fileName =
        'Raw Data: ' +
        dateFormat(dateRange.minDate) +
        ' - ' +
        dateFormat(dateRange.maxDate);
      if (this.state.allowRawDataExport) {
        this.props.generateExcelHandler({ data: res, name: fileName });
      }
    });
  };

  getProductLines = () => {
    const productLines =
      this.props.listViewData.filter((item) => {
        return item.key !== 'Total Loss Agreements';
      }) || [];
    return productLines;
  };

  getAgreements = () => {
    const agreements =
      this.props.listViewData.filter((item) => {
        return item.key === 'Total Loss Agreements';
      }) || [];
    return agreements;
  };

  getUnit = () => {
    const currentSetting = Object.keys(listViewConfig)
      .map((item) => {
        if (listViewConfig[item].key === this.state.selectedCategory) {
          return listViewConfig[item];
        }
      })
      .filter((item) => item !== undefined)[0];
    return currentSetting.unit;
  };

  getConfigArray = () => {
    const configArray = Object.keys(this.props.itemViewData).map((item, i) => {
      return item === 'Total Loss Ratios'
        ? { name: item, value: griddleConfig.ratiosConfig }
        : item === 'Agreed Settlements'
        ? { name: item, value: griddleConfig.settlementsConfig }
        : null;
    });
    return configArray;
  };

  getProductLineNames = () => {
    if (this.props.userData.AppSettings.EnforceBusinessLines === true) {
      if (!this.props.listViewData || !this.props.listViewData[0])
        return ['All'];
      const lineNames = Object.keys(this.props.listViewData[0].values).map(
        (k) => {
          return k;
        }
      );
      lineNames.unshift('All');
      return lineNames;
    } else {
      return [];
    }
  };

  render() {
    const configArray = this.getConfigArray() || [];
    const viewGriddleConfig = configArray.filter((i) => {
      return i !== null && i.name === this.state.selectedCategory;
    })[0];

    const display =
      this.state.displayMode === 'list' ? (
        <div>
          <ResultsList
            data={this.getProductLines()}
            isAnimationActive={true}
            clickHandler={this.handleNavigation}
            colors={PRODUCT_COLORS}
            filterIndex={this.state.filterIndex}
            configArray={configArray}
            maxGraphWidth={Math.min(this.props.viewport.width - 100, 500)}
          />
          <ResultsList
            data={this.getAgreements()}
            clickHandler={this.handleNavigation}
            isAnimationActive={true}
            colors={PRODUCT_COLORS}
            filterIndex={null}
            configArray={configArray}
            maxGraphWidth={Math.min(this.props.viewport.width - 100, 500)}
          />
        </div>
      ) : this.state.displayMode === 'view' ? (
        <ResultView
          data={this.props.itemViewData[this.state.selectedCategory]}
          unit={this.getUnit() || null}
          isAnimationActive={true}
          graphHeight={400}
          graphWidth={Math.min(this.props.viewport.width - 50, 1500)}
          filterIndex={this.state.filterIndex}
          colors={PRODUCT_COLORS}
          canFilter={this.state.selectedCategory !== 'Total Loss Agreements'}
          selectedCategory={this.state.selectedCategory}
          productLines={this.getProductLineNames().filter((item) => {
            return item !== 'All';
          })}
          clickHandler={this.handleNavigation}
          griddleConfig={viewGriddleConfig ? viewGriddleConfig.value : null}
        />
      ) : null;

    const genericDataElement = this.props.miSummaryData.data ? (
      <GenericMI
        colour="red"
        miSummaryData={this.props.miSummaryData}
        insurerID={this.props.currentUser.InsurerId}
      />
    ) : null;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.box}>
            <ul className={s.backLinks}>
              <li>
                <a href="/dashboard" onClick={Link.handleClick}>
                  Back to Dashboard
                </a>
              </li>
            </ul>

            <h1 className={s.heading}>Triage Statistics</h1>
            <RequestForm
              enforceBusinessLines={
                this.props.userData['AppSettings'].EnforceBusinessLines
              }
              validateMinDateHandler={this.sanitiseMinDate}
              validateMaxDateHandler={this.sanitiseMaxDate}
              dateRange={this.state.dateRange}
              disableFilter={
                this.state.selectedCategory === 'Total Loss Agreements'
              }
              minDateHandler={this.changeMinDate}
              maxDateHandler={this.changeMaxDate}
              dropdownItems={this.getProductLineNames()}
              isLandscape={this.props.viewport.width > 600}
              onFilter={this.filterHandler}
              filterValue={
                this.state.filterIndex === null ? 0 : this.state.filterIndex + 1
              }
              submitHandler={this.submitDateRangeHandler}
            />

            {this.props.currentUser.InsurerId === 1 ? (
              <div ref="report">
                <BusinessLinesMI display={display} />
              </div>
            ) : null}

            {this.props.currentUser.InsurerId === 31 ||
            this.props.currentUser.InsurerId === 32 ||
            this.props.currentUser.InsurerId === 33 ||
            this.props.currentUser.InsurerId === 37 ? (
              <div ref="report">{genericDataElement}</div>
            ) : null}
          </div>
        </div>

        <ResultsFooter
          displayMode={this.state.displayMode}
          showRawDataButton={this.state.allowRawDataExport}
          generatePDFHandler={this.generatePDFHandler}
          generateRawDataHandler={this.generateRawDataHandler}
        />
      </div>
    );
  }
}

let composed = TriageStatisticsPage;
composed = withLoggedInUser(composed);
composed = withViewport(composed);
composed = withStatsData(composed);
composed = withFileGenerator(composed);

export default composed;
