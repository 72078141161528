import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightHatchback5d extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M998.6,129.7c-1.8,3.1-5.9,8.6-5.9,8.6c0.2-0.7,0.2,44.8,0.1,63.1c0,3.2-2.6,5.8-5.8,5.8l-16.8-0.1  c-0.2,0-117.5,81.4-177.8,90.1c0,0-42.6,5.8-79,6.9l-3.5-16.8c0,0,86-0.1,102.7-12.3c12.7-9.3,65.6-49.5,81.3-62.1  c7.8-6.3,0.6-14.5-2.9-15.1l-41.9,0.1c0-4.8-8-50.5-17.5-68c1.3,0.6,2.6,1.1,3.9,1.6c18.6,7.1,39.4,5.4,54.3-5.5  c18.1-13.3,32.5-29.8,32.5-55.8l-0.4-8.4l-0.5-11.3c0.2-1.7,1.7-2.3,2.3-2.3l27.1,0.5c23.6,0.6,37.1,1.8,43.8,2.6  c3.2,0.4,5.8,2.8,6.4,6.1c1.6,9.1,4.5,27.9,4.5,40.4C1005.4,117.6,998.6,129.6,998.6,129.7z"
        />
        <path
          {...this.props}
          d="M1005.6,97.8c0-12.5-2.9-31.3-4.5-40.4c-0.6-3.3-3.2-5.7-6.4-6.1c-6.7-0.8-20.3-2-43.8-2.6l-27.1-0.5  c-0.6,0-2.1,0.6-2.3,2.3l0.5,11.3l0.4,8.4c0,26-14.4,42.5-32.5,55.8c-14.9,10.8-35.8,12.6-54.3,5.5c-1.3-0.5-2.6-1-3.9-1.6  c9.4,17.5,17.4,63.2,17.5,68l41.9-0.1c3.4,0.6,10.7,8.9,2.9,15.1c-15.7,12.5-68.6,52.8-81.3,62.1c-16.7,12.3-102.7,12.3-102.7,12.3  l3.5,16.8c36.4-1.2,79-6.9,79-6.9c60.3-8.8,177.6-90.1,177.8-90.1l16.8,0.1c3.2,0,5.8-2.6,5.8-5.8c0.1-18.3,0.1-63.8-0.1-63.1  c0,0,4.1-5.5,5.9-8.6C998.5,129.6,1005.3,117.6,1005.6,97.8z M960,162.7c0-1.9,2.6-4.7,4.6-4.7h22c1.9,0,2.4,2.7,2.4,4.7v28.9  c0,1.9-0.4,2.4-2.4,2.4h-22c-1.9,0-4.6-0.4-4.6-2.4V162.7z M994,123.7c-1.3,1.3-2,2.3-3.7,2.3h-86c-16,0-4.2-3.7-2.2-5.7  c1.3-1.3,3-2.3,4.7-2.3h85.1C997.7,118,996.1,121.8,994,123.7z"
        />
      </g>
    );
  }
}

let composed = WingBackRightHatchback5d;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
