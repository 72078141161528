const pdfStyles = `
  @page {
    size: 21cm 29.7cm;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body {
    page: "Triage Damage Report";
    margin: 0;
    padding:0;
    color: #333333;
    font-size: 80%;
  }

  .pdfReportPage {
    text-transform: uppercase;
  }

  .pdfReportPage .row {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 1%;
    page-break-after: always;
  }

  .column {
    width:48%;
    margin:1%;
    display:inline-block;
    vertical-align:top;
    position: relative;
  }

  .image {
    display: block !important;
    width: 100% !important;
  }

  h1 {
    text-align:center;
    width: 100%;
  }

  h3 {
    margin: 0 !important;
    padding: 15px 0 0 !important;
  }

  ul {
    display: block;
    list-style: outside none none;
    margin: 20px 0 0;
    padding: 0;
    width: 100%;
    text-transform: uppercase;
  }

  li {
    display: table;
    margin: 0 0 10px;
    width: 100%;
    font-weight: 600;
    border: 2px solid #333333;
    border-radius: 2px;
    color: #333;
    font-family: sans-serif;
  }

  li span, li strong{
    display: table-cell;
    vertical-align: middle;
    float: none;
    width: 100%;
    padding: 6px;
  }

  li strong {
    min-width: 150px;
    width: 1%;
    text-align: right;
  }

  .summary li strong, .summary li span {
    width: 1%;
  }

  .summaryBtn {
    display: none !important;
  }

  .criticalDamage {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 80%;
    display: table;
    color: black;
  }

  .criticalDamage > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    position: relative;
    width: 100%;
  }

  .criticalDamageIcon {
    position: absolute;
    left: 0;
    opacity: 0.4;
    color: red !important;
    width: 100% !important;
    height: auto !important;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  .vehicleHistory {
    padding: 1%;
  }

  .vehicleHistory > div > div > h2 {
    width: 100%;
  }

  .vehicleHistory > div > div > ul {
    display: inline-block;
    width: 31%;
    margin: 1%;
    float: left;
  }

  .vehicleHistory > div > div > div > ul {
    display: inline-block;
    width: 33.3%;
    padding: 5px;
    border: 0px solid;
    vertical-align: top;
  }

  .gallery {
    margin-top: 20px;
    display: block;
    display: table;
    padding: 10px;
  }

  .gallery > h2 {
    display: table-row;
    width: 90%;
    padding-bottom: 20px !important;
    text-align: center;
  }

  .galleryGrid {
    padding: 20px;
    width: 100%;
    display: table;
    overflow: visible;
  }

  .galleryGrid > div {
    max-width: 100% !important;
    height: auto!important;
    margin: 0 auto!important;
    text-align: center!important;
    margin-bottom: 20px!important;
  }

  .galleryImgContainer {
  	max-width: 100%;
  }

  .galleryImgContainer > img {
    height: auto!important;
    width: 100%!important;
    max-height: 600px!important;
  }

  .galleryImg {
    width: 600px!important;
    display: inline-block !important;
    margin-bottom: 20px!important;
    overflow: visible;
  }

  button {
    display: none !important;
  }
`;

export default pdfStyles;
