import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRightPickup4d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M442.2,84.9l334,0.1V46.3l-333.6-0.4L442.2,84.9L442.2,84.9z"
      />
    );
  }
}

let composed = SillRightPickup4d;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
