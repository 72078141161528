import { Component } from 'react';

import s from './VehicleLookup.module.scss';
import RaisedButton from 'material-ui/RaisedButton';
import TriageActions from '../../../actions/TriageActions';
import AppActions from '../../../actions/AppActions';
import AppStore from '../../../stores/AppStore';
import LockActions from '../../../actions/LockActions';
import LockStore from '../../../stores/LockStore';

let lockInterval;

class LoadCaseButton extends Component {
  clickHandler = () => {
    const data = JSON.parse(this.props.data);
    const socket = LockStore.getWSClient();
    AppActions.addSpinner();
    TriageActions.getCaseFromServer({
      caseId: data.caseId,
      callback: () => {
        try {
          socket.leave();
        } catch (e) {}
        AppActions.removeSpinner();
        LockActions.refreshLocks({
          Insurer: AppStore.userData().User.InsurerName,
        });
        lockInterval = setInterval(() => {
          if (data.caseId !== '') {
            LockActions.updateLock({ caseId: data.caseId });
          }
        }, 20000);
      },
    });
    setTimeout(() => {
      AppActions.updateModal({
        open: false,
      });
    }, 0);
  };

  render() {
    //const isDisabled = disabledUsers.some((i)=> {return i === this.props.data.caseId}) || false;
    //console.log(disabledUsers, isDisabled);
    return (
      <div className={s.loadButton}>
        <RaisedButton
          primary={true}
          label="load"
          onClick={this.clickHandler}
          disabled={JSON.parse(this.props.data).isLocked}
        />
      </div>
    );
  }
}

let composed = LoadCaseButton;

export default composed;
