import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftVan extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M560,672.7c-6.1,0.1-11.7,0.2-26,0.8c-15.8,0.7-35.1,9.6-49.1,15.1c-0.1,0-0.1,0-0.2,0.1   c-25.9,12.2-50.3,25.4-70.6,46.6l0,0c-1,1-0.2,2.7,1.3,2.7l131.2-0.5c0.8,0,1.4-0.5,1.6-1.2C551.2,724.8,560,672.7,560,672.7z"
      />
    );
  }
}

let composed = WindowFrontLeftVan;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
