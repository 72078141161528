import { Component } from 'react';
import cx from 'classnames';
import s from './Navigation.module.scss';

import Link from '../Link';
import { List, ListItem } from 'material-ui/List';
import LogoutIcon from 'material-ui/svg-icons/action/power-settings-new';
import TriageIcon from 'material-ui/svg-icons/image/healing';
import PermissionsIcon from 'material-ui/svg-icons/hardware/security';
import LoggingIcon from 'material-ui/svg-icons/alert/error';
import HomeIcon from 'material-ui/svg-icons/action/home';
import HelpIcon from 'material-ui/svg-icons/action/help';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import UserIcon from 'material-ui/svg-icons/action/account-circle';
import AssessIcon from 'material-ui/svg-icons/action/assessment';
import GroupIcon from 'material-ui/svg-icons/social/group';
import UserSubIcon from 'material-ui/svg-icons/action/account-box';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import SearchIcon from 'material-ui/svg-icons/action/search';
import RoleIcon from 'material-ui/svg-icons/file/folder-shared';
import AppActions from '../../actions/AppActions';
import TriageActions from '../../actions/TriageActions';
import LockActions from '../../actions/LockActions';
import TriageStore from '../../stores/TriageStore';
import AppStore from '../../stores/AppStore';
import RoleTypes from '../../constants/RoleTypes.js';
import Location from '../../core/Location';

let closeInterval;

export class Navigation extends Component {
  state = {
    isClosing: false,
    showOptions: false,
    user: null,
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    AppStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      user: AppStore.userData(),
    });
  };

  clickHandler = (e) => {
    clearTimeout(closeInterval);
    this.setState({
      showOptions: this.state.showOptions ? false : true,
      isClosing: false,
    });
  };

  closeHandler = (e) => {
    this.setState({
      isClosing: true,
    });
    closeInterval = setTimeout(() => {
      this.setState({
        showOptions: false,
      });
    }, 300);
  };

  logout = (e) => {
    e.preventDefault();
    const currentCase = TriageStore.getCase();
    const hasCase = currentCase.caseId !== '';

    if (hasCase) {
      AppActions.createModal({
        open: true,
        title: 'Logout',
        type: 'confirm',
        content: (
          <div>
            <p>Logging out will exit your existing case.</p>
            <p>Are you sure?</p>
          </div>
        ),
        onConfirm: () => {
          TriageActions.resetCompletedQuestionSets();
          TriageActions.resetProgress();
          TriageActions.resetCase();
          LockActions.unlockCase({ caseId: currentCase.caseId });
          AppActions.logout();
          try {
            if (window) window.history.pushState(null, null, '/dashboard');
          } catch (error) {
            Location.push('/dashboard');
          }
          setTimeout(() => {
            document.location.reload(true);
          }, 1000);
        },
      });
    } else {
      TriageActions.resetCompletedQuestionSets();
      TriageActions.resetProgress();
      TriageActions.resetCase();
      LockActions.unlockCase({ caseId: currentCase.caseId });
      AppActions.logout();
      try {
        if (window) window.history.pushState(null, null, '/dashboard');
      } catch (error) {
        Location.push('/dashboard');
      }
      this.setState({
        showOptions: false,
      });
    }
  };

  preparePermissionsListHtml = (roles) => {
    if (
      roles.find((i) => {
        return (
          i.toUpperCase() === RoleTypes.ADMINISTRATOR ||
          i.toUpperCase() === RoleTypes.USERSCOMPANYMANAGER ||
          i.toUpperCase() === RoleTypes.USERSDEPARTMENTMANAGER
        );
      }) === undefined
    )
      return null;

    const adminPermissions =
      roles.find((i) => {
        return i.toUpperCase() === RoleTypes.ADMINISTRATOR;
      }) !== undefined
        ? [
            <ListItem
              key={1}
              href="/permissions/organisations"
              onClick={Link.handleClick}
              primaryText="Organisations"
              rightIcon={<GroupIcon />}
            />,
            <ListItem
              key={2}
              href="/permissions/roles"
              onClick={Link.handleClick}
              primaryText="Roles"
              rightIcon={<RoleIcon />}
            />,
          ]
        : [];

    const permissionList = [
      <ListItem
        key={0}
        href="/permissions/users"
        onClick={Link.handleClick}
        primaryText="Users"
        rightIcon={<UserSubIcon />}
      />,
    ];

    const permissions =
      roles.find((i) => {
        return (
          i.toUpperCase() === RoleTypes.ADMINISTRATOR ||
          i.toUpperCase() === RoleTypes.USERSCOMPANYMANAGER ||
          i.toUpperCase() === RoleTypes.USERSDEPARTMENTMANAGER
        );
      }) !== undefined ? (
        <ListItem
          className={s.icon}
          onClick={(e) => {
            e.stopPropagation();
          }}
          primaryText="Permissions"
          initiallyOpen={false}
          primaryTogglesNestedList={true}
          nestedItems={permissionList.concat(adminPermissions)}
          leftIcon={<PermissionsIcon />}
        />
      ) : null;

    return permissions;
  };

  render() {
    const user = this.state.user !== null ? this.state.user.User : null;
    const roles = user == null || user.Roles === null ? [] : user.Roles;
    const permissions = this.preparePermissionsListHtml(roles);

    const caseHistory =
      roles.find((i) => {
        return (
          i.toUpperCase() === RoleTypes.ADMINISTRATOR ||
          i.toUpperCase() === RoleTypes.MICOMPANYMANAGER ||
          i.toUpperCase() === RoleTypes.MIDEPARTMENTMANAGER
        );
      }) !== undefined ? (
        <ListItem
          className={s.icon}
          onClick={(e) => {
            e.stopPropagation();
          }}
          leftIcon={<HistoryIcon />}
          primaryText="Case History"
          initiallyOpen={false}
          primaryTogglesNestedList={true}
          nestedItems={[
            <ListItem
              key={0}
              href="/triageStats"
              onClick={Link.handleClick}
              primaryText="Search Triage Statistics"
              rightIcon={<AssessIcon />}
            />,
            <ListItem
              key={1}
              href="/triageReview"
              onClick={Link.handleClick}
              primaryText="Review a Case"
              rightIcon={<SearchIcon />}
            />,
          ]}
        />
      ) : null;

    const errorLogging =
      roles.find((x) => {
        return x.toUpperCase() === RoleTypes.ADMINISTRATOR;
      }) !== undefined ? (
        <ListItem
          className={s.icon}
          primaryText="Event Logging"
          href="/eventLogging"
          onClick={Link.handleClick}
          leftIcon={<LoggingIcon />}
        />
      ) : null;

    const isClosing = this.state.isClosing ? s.fadeOut : null;
    let options = this.state.showOptions ? (
      <div className={cx(s.options, isClosing)}>
        <List>
          <ListItem
            className={s.icon}
            onClick={this.logout}
            primaryText="Log out"
            leftIcon={<LogoutIcon />}
          />
          <ListItem
            className={s.icon}
            primaryText="Home"
            href="/dashboard"
            onClick={Link.handleClick}
            leftIcon={<HomeIcon />}
          />
          <ListItem
            className={s.icon}
            primaryText="Triage"
            href="/triage/lookup"
            onClick={Link.handleClick}
            leftIcon={<TriageIcon />}
          />
          {user.Roles.length > 0 ? (
            <ListItem
              className={s.icon}
              primaryText="Review a case"
              href="/triageReview"
              onClick={Link.handleClick}
              leftIcon={<HistoryIcon />}
            />
          ) : (
            <p>You do not have permission to view case history</p>
          )}
          <ListItem
            className={s.icon}
            primaryText="Settings"
            href="/settings"
            onClick={Link.handleClick}
            leftIcon={<SettingsIcon />}
          />
          <ListItem
            className={s.icon}
            primaryText="About"
            href="/about"
            onClick={Link.handleClick}
            leftIcon={<HelpIcon />}
          />

          {permissions}
          {caseHistory}
          {errorLogging}
        </List>
      </div>
    ) : null;
    let name;
    if (this.props.user && this.props.user.User) {
      name = this.props.user.User.UserName;
    }
    let nav = this.props.isLoggedIn ? (
      <div className={cx(s.root, this.props.className)}>
        <span className={s.wrap}>
          {name} <UserIcon className={s.avatar} />
        </span>
        {options}
      </div>
    ) : null;

    return (
      <div
        onClick={this.clickHandler}
        onMouseLeave={this.closeHandler}
        role="navigation"
      >
        {nav}
      </div>
    );
  }
}

export default Navigation;
