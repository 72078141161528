import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRight4x45d extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackRight)}>
        <path
          {...this.props}
          d="M757,293.9c-13,1.4-38.8,1.6-101.3,2.1l-8.9-82.9H757V293.9z"
        />
        <path
          {...this.props}
          d="M795.2,246.6c-10.7,18.5-23.1,40.4-25.6,42.8c-2.2,1.9-4.6,3.1-9.6,3.9V213h15.6C793,213,803.9,231.5,795.2,246.6z"
        />
        <path
          {...this.props}
          d="M949.4,229.9c-8.2,11.6-56,65.7-61.4,65.7l-69.5-0.1c-1.3,0-8.4,0.5-4.8-3.4c0,0,56.4-64.1,64.6-64.1h69.8  C949.5,228,950.2,228.9,949.4,229.9z"
        />
      </g>
    );
  }
}

let composed = WindowBackRight4x45d;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
