import { Component } from 'react';
import s from './SettingsPage.module.scss';
import RaisedButton from 'material-ui/RaisedButton';
import UserIcon from 'material-ui/svg-icons/action/account-circle';

export class ProfileForm extends Component {
  static defaultProps = {
    username: '',
    handleReminderRequest: () => {},
    passwordChangeHandler: () => {},
  };

  render() {
    return (
      <form className={s.amendForm}>
        <h2>Your profile</h2>
        <div className={s.formValues}>
          <div className={s.row}>
            <span className={s.column}>User name :</span>
            <span className={s.column}>{this.props.username || ''}</span>
          </div>
          <div className={s.row}>
            <span className={s.column}>Password :</span>
            <span className={s.column}>
              <a
                className={s.resetPwd}
                href="/"
                onClick={this.props.handleReminderRequest}
                title="request a password reset"
              >
                I've forgotten my password
              </a>
            </span>
            <span className={s.column}>
              <RaisedButton
                className={s.btn}
                label="Change Password"
                fullWidth={true}
                onClick={this.props.passwordChangeHandler}
                title="click to change your password"
              />
            </span>
          </div>
          <div className={s.row}>
            <span className={s.column}>Avatar :</span>
            <span className={s.column}>
              <UserIcon className={s.icon} />
            </span>
            <span className={s.column}>
              <RaisedButton
                className={s.btn}
                label="Change Avatar"
                fullWidth={true}
                disabled={true}
                title="this feature is currently disabled"
              />
            </span>
            <span className={s.column}></span>
          </div>
        </div>
      </form>
    );
  }
}

export default ProfileForm;
