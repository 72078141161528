import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftPickup extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M1025.2,787.5l-6.3-3.4v-26.5c0-14.8,1.7-27.9,3.5-28.9c2.1-1,3.5-5.7,3.5-10.4v-8.1H637.4c0,0,1,129.9,0,144.4h106.4  c0,0,0,0,0-0.1h43.9l0,0h0.1c0-0.1,3-0.2,3-0.3c0-24.2,8.8-45.6,26.8-58.3c3.6-2.4,7-4.4,9.9-5.8c8.3-4,17.4-6.3,27.1-6.6  c12.3,0,26.4,3.2,39.2,11c14,9.1,22.5,23.3,27.2,40.1c0,0,59-0.1,63.2-0.2c15-0.3,42.5,3.2,42.5-30.9L1025.2,787.5z"
        />
        <path
          {...this.props}
          d="M1025.4,787.6l-6.3-3.4v-26.6c0-14.8,1.7-27.9,3.5-28.9c2.1-1,3.5-5.7,3.5-10.4v-8.1H637.6c0,0,1,129.9,0,144.4  H744c0,0,0,0,0-0.1c0,0,16.9,0,27.3,0c1.2-51.2,36-92.6,86.5-92.6c43.7,0,73.4,30.8,83.1,72.6c15.9,0,41.1-0.1,43.4-0.1  c15-0.3,42.5,3.2,42.5-30.9L1025.4,787.6z M1013.6,731.6h3.6v52.9h-3.6V731.6z M1023.3,790H927v-1.7h96.3V790z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftPickup;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
