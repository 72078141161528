import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackLeft)}
        d="M790.8,669.5c-4.3-8.3-9.1-29.1-71.2-30.9c0,0-8.1,64.1-9.2,78.6l95-1.4c0,0-3.6-16-7.4-28.6  C796.3,682.1,791,670,790.8,669.5z"
      />
    );
  }
}

let composed = WindowBackLeftConvertible;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
