import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftPV extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M961.6,756.3L961,756l1.6-58.6c0.3-11-2-21.9-6.6-31.9l-28.3-61.2c-4.3-9.3-13.6-15.3-23.9-15.3L537,588.9v5.5  l144.2,0.4c3.8,0,6.8,3.1,6.8,6.8V832h0.1H727h21.5c5-46.8,39.6-77.1,82.3-75c38.6,1.9,75.4,33.7,76.4,79.5  c0.1,4.3-0.3,8.4-0.9,12.5h2.1v0.4l34.8-4.3c9.4-1.2,17.7-6.6,22.5-14.8c0.9-1.5,1.4-3.3,1.4-5.1v-60.3  C967,761.2,964.9,757.9,961.6,756.3z"
        />
        <path
          {...this.props}
          d="M961.6,756.3L961,756l1.6-58.6c0.3-11-2-21.9-6.6-31.9l-28.3-61.2c-4.3-9.3-13.6-15.3-23.9-15.3L537,588.9v5.5  l144.2,0.4c3.8,0,6.8,3.1,6.8,6.8V832h0.1H727h21.5c5-46.8,39.6-77.1,82.3-75c9,0.4,17.9,2.5,26.2,6v-1h109.5  C965.7,760,964,757.5,961.6,756.3z M956,751h-23v-49l23,3.5V751z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftPV;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
