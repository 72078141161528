import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeftVan extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M695.9,901.8H377.3c-2,0-6.9-2-6.9-4.6l-1.1-21.4h335.4l-1.5,22.3C703.2,900.4,697.9,901.8,695.9,901.8z"
      />
    );
  }
}

let composed = SillLeftVan;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
