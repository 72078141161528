import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './NotFoundPage.module.scss';

import Link from '../Link';
import ImgLogo from '../Logo.svg';

const title = 'Page Not Found';

class NotFoundPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
    onPageNotFound: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.context.onSetTitle(title);
    this.context.onPageNotFound();
  }

  render() {
    return (
      <div className={s.root}>
        <img
          style={{ height: 0 }}
          className={s.appImage}
          src={ImgLogo}
          alt=""
        />
        <div className={s.content}>
          <h1>{title}</h1>
          <p>Sorry, but the page you were trying to view does not exist.</p>
          <a href="/" onClick={Link.handleClick}>
            Home
          </a>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
