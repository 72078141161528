import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftSaloon extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackLeft)}
        d="M720.9,637.6c0,0-8.1,66.3-9.2,80.8l163.4-1C875.1,717.4,800.1,651.1,720.9,637.6z"
      />
    );
  }
}

let composed = WindowBackLeftSaloon;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
