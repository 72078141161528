import { Component } from 'react';
import s from './SideModal.module.scss';

import AirbagsSideQuestions from '../../QuestionSets/AirbagsSideQuestions/';
import SteeringQuestions from '../../QuestionSets/SteeringQuestions/';
import DoorsQuestions from '../../QuestionSets/DoorsQuestions/';
import LampsRearQuestions from '../../QuestionSets/LampsRearQuestions/';
import ExhaustDamageQuestions from '../../QuestionSets/ExhaustDamageQuestions/';
import TowbarQuestions from '../../QuestionSets/TowbarQuestions/';
import PanelQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'airbagsside', component: <AirbagsSideQuestions /> },
  { stage: 2, section: 'steering', component: <SteeringQuestions /> },
  { stage: 3, section: 'doors', component: <DoorsQuestions /> },
  { stage: 4, section: 'lampsrear', component: <LampsRearQuestions /> },
  { stage: 5, section: 'exhaustdamage', component: <ExhaustDamageQuestions /> },
  { stage: 6, section: 'towbarpresent', component: <TowbarQuestions /> },
  { stage: 7, section: 'panelStandard', component: <PanelQuestions /> },
];

const SUBTITLE = 'Side Questions';

class RearSideModal extends Component {
  render() {
    const wheelWarning =
      QUESTION_SETS[this.props.modalModel.currentStage].section ===
      'panelStandard' ? (
        <div className={s.wheelWarning}>
          <h4>Check wheel damage</h4>
        </div>
      ) : null;

    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
            {wheelWarning}
          </div>
        </div>
      </div>
    );
  }
}

let composed = RearSideModal;
composed = ModalComponent(QUESTION_SETS, SUBTITLE)(composed);

export default composed;
