import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontRightConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontRight)}
        d="M707,282.4c0.2,0-91.9,7.7-117,0.2c-9.7-3-26.4-7.7-114.5-81.1h221L707,282.4L707,282.4z"
      />
    );
  }
}

let composed = WindowFrontRightConvertible;
composed = SectionComponent({
  name: 'Front Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
