const RepairDuration = (props) => {
  const s = props.styles;
  const repairDuration = props.decision
    ? props.decision.RepairDuration || 0
    : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Repair Duration (Days): </span>
      <strong className={s.cols_6}>
        {parseFloat(repairDuration).toFixed(0)}
      </strong>
    </li>
  );
};

export default RepairDuration;
