import { Component } from 'react';
import PropTypes from 'prop-types';
import Griddle from 'griddle-react';
import OrgAPI from '../../../../api/organisationAPI.js';
import withLoggedInUser from '../../../../decorators/withLoggedInUser';
import TableConfig from './TableConfig';
import AppActions from '../../../../actions/AppActions';
import OrgStore from '../../../../stores/OrganisationStore';

class OrgSettingsTable extends Component {
  static propTypes = {
    editHandler: PropTypes.func,
  };

  static defaultProps = {
    editHandler: () => {},
  };

  state = {
    data: [],
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    OrgStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    OrgStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    AppActions.addSpinner();
    OrgAPI.getAllOrganisations()
      .then((result) => {
        this.setState(
          {
            data: result,
          },
          () => AppActions.removeSpinner()
        );
      })
      .catch(() => {
        AppActions.removeSpinner();
        throw 'getAllCases failed';
      });
  };

  render() {
    const { orgColumns, orgIgnores } = TableConfig;
    orgColumns.forEach((item) => {
      if (item.displayName.toLowerCase() === '[edit]') {
        item.editHandler = this.props.editHandler;
      }
    });

    const s = this.props.styles;

    return this.props.currentUser.isAdmin ? (
      <div className={s.gridWrap}>
        <Griddle
          className={s.grid}
          results={this.state.data}
          useFixedHeader={true}
          tableClassName={s.table}
          showFilter={true}
          resultsPerPage={100}
          columnMetadata={orgColumns}
          metadataColumns={orgIgnores}
        />
      </div>
    ) : (
      <div>You do not have permission to view this page</div>
    );
  }
}

const composed = withLoggedInUser(OrgSettingsTable);

export default composed;
