import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class BumperRear extends Component {
  render() {
    return (
      <g className={s.bumperRear}>
        <path
          {...this.props}
          d="M1148,605l-20.5,1.1V312.2h23.6c27.8,0,30.9-3.2,30.9-30.9c0-26.8-2.5-28.2-59.1-28.2c-26.4,0-50.4-1.1-53.2-2.1   c-3.8-1.4-5.5,0.7-6.6,6.3l0,0c-1.1,7.7-2.5,8-34.8,9.1l-34.1,1.1V307l55.6,24.6V586l-55.6,21v42.4h23.9c33.4,0,45.5,2.8,45.5,10.7   c0,6.3,3.4,6.6,51.4,6.6c63.6,0,66.8-1.4,66.8-30.9C1182.1,608.8,1176.2,603.2,1148,605z"
        />
        <path
          {...this.props}
          d="M1148,605.9l-20.5,1.1V313.2h23.6c27.8,0,30.9-3.2,30.9-30.9c0-26.8-2.5-28.2-59.1-28.2   c-26.4,0-50.4-1.1-53.2-2.1c-3.8-1.4-5.5,0.7-6.6,6.3l0,0c-1.1,7.7-2.5,8-34.8,9.1l-34.1,1.1V308l55.6,24.6V587l-55.6,21v42.4h23.9   c33.4,0,45.5,2.8,45.5,10.7c0,6.3,3.4,6.6,51.4,6.6c63.6,0,66.8-1.4,66.8-30.9C1182.1,609.8,1176.2,604.2,1148,605.9z M1034,313   c0,1.4-1.4,2.4-2.7,1.9c-6.1-2.4-16.8-5.9-21.2-7.9c-7.3-3.4-8.7-6.3-8.7-18v-13.9h32.5L1034,313L1034,313z M1032.7,643.5h-30.6   v-23.1c0-4,2.4-7.8,6.2-9.5l22.7-10c0.9-0.4,1.8,0.3,1.8,1.2L1032.7,643.5L1032.7,643.5L1032.7,643.5z M1063.2,647.6   c-4.8-1.1-5.9-7-7-32c-0.7-19.1,0.4-30.5,2.5-30.2c2.1,0.4,3.8-43.1,4.5-123.7c0.4-68.5-0.4-123.7-2.1-122.7   c-1.8,1.1-3.2-12.9-3.8-30.9c-0.7-28.2,0-33,5.5-36.1c3.4-2.1,6.3-3.8,6.3-3.8v190.5l0,0C1069.1,635.5,1068.8,649.4,1063.2,647.6z"
        />
      </g>
    );
  }
}

let composed = BumperRear;
composed = SectionComponent({
  name: 'Rear Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Rear Bumper Damage',
    content: <Modal name="Rear Bumper" />,
  },
})(composed);

export default composed;
