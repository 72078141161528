import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofTwoDoorPickup4d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M585,319.3c-24.3,0-49.1-1.2-54.7-2.5c-9-2.1-10.4-1.2-12,9.1c-9,51.1-11.4,158.7-5.4,225.4c2,21,4,41.6,4.3,46.2  c0.1,1.2,0.1,2.3,0,3.2c2.7,1,5.5,2.1,8.2,3.2c7-2.1,21.5-3,51-3.6l213.2-1.2l-1-279.8H585z"
      />
    );
  }
}

let composed = RoofTwoDoorPickup4d;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
