import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackRight)}
        d="M898,260.4c-8.8,13.3-81.7,21.3-174.4,24.3c-0.8,0-10.7-68.7-12-82.7h210C919,214,911.6,239.7,898,260.4z"
      />
    );
  }
}

let composed = WindowBackRightHatchback;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
