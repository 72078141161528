import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/MirrorsModal/';

class WingMirrorLeft extends Component {
  render() {
    return (
      <g className={s.wingMirrorLeft}>
        <path
          {...this.props}
          d="M281.3,686.2c-12-6.4-1.6-27.2-34.3-27.8c-8.3-0.2-13,3.1-21.3,3.9c-4.1,0.4-7,1.3-9.9,2.5   c-3.6,1.5-7.6,3-11.6,6.3c-2,1.6-3.6,3.1-5.5,5.1c-4.2,4.5-4.6,9.3-6,14.4s-2.3,9.5,0.9,14.3c3.5,5,15.1,6,21.2,6.6   c15.6,1.6,44.8-2.3,44.8-2.3c4.1-2.1,7.3,0,9.4,1.3C274.1,701,276.6,695.9,281.3,686.2z"
        />
        <path
          {...this.props}
          d="M265.4,675c7.1,20.5,4.6,29.5-4.6,33.6c-7.7,3.8-38.4,4.1-47.5,3.5c-8.5-0.5-18.3-1.5-21.9-10.1   c-2.5-7.5-1.5-26,12.6-33.5c8-5.5,33.3-12.5,48.3-9.4C259.2,660.5,263.5,669.6,265.4,675z"
        />
        <path
          {...this.props}
          d="M252.6,665.7c6.7,4.1,10.3,19.5,11.3,24.7c2.9,14.6-9.4,14.8-15.4,15.7c-9.5,1-19.4,1.5-28.9,1.3   c-6.6-0.1-20.7,0-22.2-6.6c-1.2-5.2-1.3-10.3,0.9-17.4c2.1-7.6,12.9-12.8,24.5-16.2C234.8,663.8,247.7,662.4,252.6,665.7z"
        />
        <path
          {...this.props}
          d="M281.3,686.2c3.4,1.9,4,0.1,5.9,0.2c0.1,10,0.2,22,0.3,31.9c-4,0-9.1,0.5-12-1.6c-2.2-1.7-3.6-4.4-6.5-6.3   C274,701,276.5,695.8,281.3,686.2z"
        />
      </g>
    );
  }
}

let composed = WingMirrorLeft;
composed = SectionComponent({
  name: 'Passenger Door Mirror',
  category: 'Mirrors',
  highlighted: false,
  questionSets: ['Mirrors'],
  modalModel: {
    title: 'Register Passenger Mirror Damage',
    content: <Modal name="Passenger Mirror" />,
    actions: [],
  },
})(composed);

export default composed;
