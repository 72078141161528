import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontLeft4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M459.4,702.2c-0.8,0-10-0.1-10.7,2.2c-3.9,11.3-4.6,126.4-4.6,126.4l209.5,0c0,0.5,1.3-58.8,6.5-119.4  c0.3-3.1,0.5-6.2,0.8-9.2H459.4z"
      />
    );
  }
}

let composed = DoorFrontLeft4x4;
composed = SectionComponent({
  name: 'Front Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Passenger Door Damage',
    content: <Modal name="Front Passenger Door" />,
  },
})(composed);

export default composed;
