import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class Roof4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M904.8,578.3c4.4-35.2,4.4-203.3,0-238.4c-3.7-23.2-3.9-21.5-11.3-21.6c-31.6,1.5-226,2.2-324.7-2.8  c-28.8-3.2-42.3-3.4-43.3-0.7c-6.3,15.7-13.5,94.2-13.5,144.2c0,56.6,8.6,133.9,16.2,142.8c2.3,3.2,13.9,3.2,47.2,0  c103.7-0.2,282.5-0.2,317.9,1.4C901,603.2,901.2,601.6,904.8,578.3z"
      />
    );
  }
}

let composed = Roof4x4;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
