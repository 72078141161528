import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackLeft)}
        d="M775.6,707.3h-137c-0.6,0-1.1-0.5-1.1-1.1v-82.8c0-0.6,0.5-1.1,1.1-1.1h137c0.6,0,1.1,0.5,1.1,1.1v82.8  C776.7,706.8,776.2,707.3,775.6,707.3z"
      />
    );
  }
}

let composed = WindowBackLeftPickup;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
