import { Component } from 'react';

import s from './BusinessLineSelect.module.scss';
import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui/Menu';

class SubInsurerSelect extends Component {
  sortSubInsurers(unsorted) {
    return unsorted.sort((a, b) => {
      if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
        return -1;
      }

      if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
        return 1;
      }

      return 0;
    });
  }

  createItems = (items) => {
    let results = null;

    if (items) {
      results = items.map((item, index) => {
        return (
          <MenuItem key={index + 1} value={item.ID} primaryText={item.Name} />
        );
      });
    }
    return results;
  };

  render() {
    const menuItems =
      this.props.subInsurers && this.props.subInsurers.length > 0
        ? this.createItems(this.sortSubInsurers(this.props.subInsurers))
        : null;

    return (
      <div className={s.businessLineSelect}>
        <SelectField
          fullWidth={true}
          floatingLabelText="sub-insurer"
          value={this.props.selectedSubInsurer || null}
          onChange={this.props.onChangeHandler.bind(this)}
          errorText={
            this.props.selectedSubInsurer
              ? null
              : 'Choose a sub-insurer line to continue'
          }
          disabled={this.props.disabled}
        >
          {menuItems}
        </SelectField>
      </div>
    );
  }
}

let composed = SubInsurerSelect;

export default composed;
