const CarHireCostTotal = (props) => {
  const s = props.styles;
  const currentCase = props.currentCase;
  const totalCarHireCost =
    currentCase && currentCase.decision
      ? currentCase.decision.TotalCarHireCost || 0
      : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Total Car Hire Cost: </span>
      <strong className={s.cols_6}>
        £{parseFloat(totalCarHireCost).toFixed(2)}
      </strong>
    </li>
  );
};

export default CarHireCostTotal;
