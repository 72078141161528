import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeft4x4 extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackLeft)}>
        <path
          {...this.props}
          d="M720.3,623h-30.2c-0.5,0-0.9,2.2-1,2.7c0,0-5.6,28.9-8.6,69.9c-0.1,1.5,1.9,2.2,3,2.2l66.5-0.3c7.2,0,23.6-16.8,18.6-23.5   C763.7,667.3,727.7,623,720.3,623z"
        />
        <path
          {...this.props}
          d="M908.1,687.5c-8.1-11.1-55.4-63.8-60.7-63.8l-68.9,0.2c-1.3,0-8.3,0-4.7,3.7c0,0,55.8,62.3,64,62.3h69.1   C908.1,690,908.9,688.6,908.1,687.5z"
        />
      </g>
    );
  }
}

let composed = WindowBackLeft4x4;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
