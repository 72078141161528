import { Component } from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import { remoteDomain } from '../../config';
import emptyFunction from 'fbjs/lib/emptyFunction';
import withViewport from '../../decorators/withViewport';
import s from '../App/App.module.scss';
import AppStore from '../../stores/AppStore';
import AppActions from '../../actions/AppActions';
import TriageActions from '../../actions/TriageActions';
import Spinner from '../Spinner';
import Modal from '../ModalDialog/';
import TriageMainPage from '../TriagePage/MainPage/';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import ImgLogo from '../Logo.svg';
import ImgGrid from '../grid.svg';

const ORG_ERROR_MARKUP = (
  <div>
    <p>It was not possible to retrieve your organisation's settings.</p>
    <p>Please contact an Administrator.</p>
  </div>
);
class EmbedApp extends Component {
  static propTypes = {
    context: PropTypes.shape({
      onSetTitle: PropTypes.func,
      onSetMeta: PropTypes.func,
      onPageNotFound: PropTypes.func,
    }),
    error: PropTypes.object,
  };

  static childContextTypes = {
    onSetTitle: PropTypes.func.isRequired,
    onSetMeta: PropTypes.func.isRequired,
    onPageNotFound: PropTypes.func.isRequired,
  };

  state = {
    isLoggedIn: false,
    showSpinner: false,
    isCaseLoaded: false,
    user: {},
  };

  getChildContext() {
    const context = this.props.context;
    return {
      onSetTitle: context.onSetTitle || emptyFunction,
      onSetMeta: context.onSetMeta || emptyFunction,
      onPageNotFound: context.onPageNotFound || emptyFunction,
    };
  }

  updateState = () => {
    this.setState(
      {
        showSpinner: AppStore.getSpinnerState(),
        modalOptions: AppStore.getModalState(),
        user: AppStore.userData(),
      },
      () => {
        this.setState({
          isLoggedIn:
            this.state.user !== null && this.state.user.AppSettings !== null,
          modalOptions:
            this.state.user !== null && this.state.user.AppSettings === null
              ? {
                  open: true,
                  title: 'Error',
                  content: ORG_ERROR_MARKUP,
                  type: 'alert',
                  onConfirm: () => {
                    AppActions.logout();
                  },
                }
              : this.state.modalOptions,
        });
      }
    );
  };

  receiveMessage = (e) => {
    console.log('Origin for whitelist:', e.origin);
    if (remoteDomain.indexOf(e.origin) < 0) return; //Stop any origin not explicitly added to whitelist from calling embedded functions
    const data = JSON.parse(e.data);
    if (data.action === 'loading') {
      AppActions.addSpinner();
      return;
    }
    if (data.action === 'lookupError') {
      AppActions.removeSpinner();
      AppActions.createModal({
        open: true,
        title: 'Error creating Triage case',
        type: 'alert',
        content: (
          <div>
            <p>It was not possible to create a Triage case at this time.</p>
            <p>Please ensure you have the correct permissions.</p>
            <p>If the problem persists, please contact an Administrator.</p>
          </div>
        ),
      });
    }
    if (data.authentication) {
      AppActions.remoteLogin(JSON.parse(e.data).authentication);
      AppActions.removeSpinner();
    }
    if (data.progressState) {
      TriageActions.setProgressState(data.progressState);
    }
    if (data.triageCase) {
      if (data.triageCase !== null) {
        TriageActions.loadRemoteCase(
          Object.assign(data.triageCase, {
            registration: data.lookup.registration,
            mileage: data.lookup.mileage,
            businessLine: data.lookup.selectedBusinessLine || null,
          }),
          () => {
            this.setState({ isCaseLoaded: true }, AppActions.removeSpinner());
          }
        );
      }
    }
    if ((data.action = 'requestHtmlReport')) {
      TriageActions.updateCase({ shouldReturnEmbedReport: true });
    }
    if ((data.action = 'requestJSONReport')) {
      TriageActions.updateCase({ shouldReturnEmbedReport: false });
    }
  };

  UNSAFE_componentWillMount() {
    this.setState({
      showSpinner: false,
    });
    TriageActions.updateCase({ isEmbed: true });

    AppStore.addChangeListener(this.updateState);
  }

  componentDidMount() {
    if (canUseDOM)
      window.addEventListener('message', this.receiveMessage, false);
    if (canUseDOM)
      window.parent.postMessage(JSON.stringify({ action: 'loaded' }), '*');
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this.updateState);
  }

  getMainElement = () => {
    if (!this.state.isLoggedIn) {
      return (
        <div className={s.embedWarning}>
          Please enter your User name and Password
        </div>
      );
    }
    if (!this.state.isCaseLoaded) {
      return (
        <div className={s.embedWarning}>Please enter the vehicle details</div>
      );
    }

    return <TriageMainPage stage={0} />; //only get here if we are both logged in, and a case has been loaded
  };

  render() {
    const inProgress = this.state.showSpinner ? <Spinner /> : null;
    const mainElement = this.getMainElement();

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
        <div>
          <img
            style={{ height: 0 }}
            className={s.appImage}
            src={ImgLogo}
            alt=""
          />
          <img
            className={s.appImage}
            src={ImgGrid}
            height="100%"
            width="100%"
            style={{ opacity: 0.3 }}
            alt=""
          />
          <div className={s.root} style={{ opacity: '0' }}>
            <div className={s.app} style={{ paddingTop: 0 }}>
              {mainElement}
            </div>
            <Modal options={this.state.modalOptions} />
            {inProgress}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const composed = withViewport(EmbedApp);

export default composed;
