import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontRight4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontRight)}
        d="M660.5,217.1H451.5c70.6,79.5,85.9,74.3,95.1,77c20,3.3,124.1,1.5,123.9,1.5L660.5,217.1z"
      />
    );
  }
}

let composed = WindowFrontRight4x4;
composed = SectionComponent({
  name: 'Front Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
