import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackRightPV extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          className={s.tyre}
          d="M905.5,77.5c0-42.6-32.8-77.4-73-77.4s-73,34.6-73,77.4c0,42.6,32.7,77.4,73,77.4C872.8,155,905.6,120.5,905.5,77.5z   M827.2,30.5c1.8-0.2,3.5-0.4,5.3-0.4c18.1,0,33.5,11.3,40.5,27.7c2.7,6.1,4.1,12.7,4.1,19.9c0,5.6-0.9,11-2.6,16  c-5.3,15.9-18.1,27.8-34,30.8c-2.6,0.6-5.3,0.8-8.1,0.8c-24.8,0-44.6-21.3-44.6-47.5C787.9,53.3,804.9,33.3,827.2,30.5z"
        />
        <path
          {...this.props}
          d="M792.7,63.9c-1.4,4.3-2.1,9-2.1,13.8c0,8.8,2.3,16.9,6.4,23.8c7.4,12.5,20.4,20.8,35.5,20.8c0.5,0,0.9,0,1.4,0  c22.7-0.8,40.6-20.7,40.5-44.6c0-24.3-17.8-43.8-40.4-44.6c-0.6,0-1.1,0-1.5,0C813.9,33,798,45.9,792.7,63.9z M808.7,73.7h-1.5  c1.8-13.2,12.4-23.4,25.3-23.4c0.5,0,0.8,0.1,1.3,0.1c12.5,0.6,22.4,10.4,24.8,23.2h-0.5H857c-1.2-7.4-5.3-13.7-11-17.6  c-3.9-2.7-8.4-4.2-13.2-4.2c-4.3,0-8.3,1.2-11.8,3.2C814.5,58.8,809.8,65.6,808.7,73.7z M852.3,94.3c-4.4,6-11.1,10-18.7,10.5  c-0.6,0.1-1.1,0.1-1.5,0.1c-13,0-23.6-10.4-25.3-23.6h1.5c1.2,9.2,6.8,16.6,14.4,20.1c3,1.3,6.3,2.1,9.8,2.1c4.2,0,8.1-1.2,11.4-3  c6.8-3.9,11.6-10.9,12.7-19.2h0.8C856.9,86.2,855,90.6,852.3,94.3z"
        />
      </g>
    );
  }
}

let composed = WheelBackRightPV;
composed = SectionComponent({
  name: 'Back Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Driver Wheel Damage',
    content: <Modal name="Back Driver Wheel" />,
  },
})(composed);

export default composed;
