import { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';
import s from './style.module.scss';

export default class GriddleCheckBox extends Component {
  state = {
    checked: this.props.data ? this.props.data : null,
  };

  render() {
    return (
      <Checkbox
        className={s.checkbox}
        checked={this.state.checked}
        disabled={true}
      />
    );
  }
}
