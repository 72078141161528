import { FC } from 'react';
import styles from './Header.module.scss';
import Link from '../Link/Link';
import Navigation from '../Navigation/Navigation';
import ImgLogoSmall from './logo-small.svg';

interface Props {
  user: any;
  isLoggedIn: boolean;
}

const Header: FC<Props> = ({ user, isLoggedIn }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Navigation
          isLoggedIn={isLoggedIn}
          user={user}
          className={styles.nav}
        />

        <a className={styles.brand} href="/" onClick={Link.handleClick}>
          <img src={ImgLogoSmall} height={40} alt="Inter-est" />
          <span className={styles.brandTxt}>Inter-cept 2.0</span>
        </a>
      </div>
    </div>
  );
};

export default Header;
