import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './LoginPage.module.scss';
import withViewport from '../../decorators/withViewport';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import AppActions from '../../actions/AppActions';
import AppStore from '../../stores/AppStore';
import validator from 'pure-validator';
import { loginExpiry } from '../../config';

const title = 'Inter-cept | Log In';

class LoginPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    stage: 0,
    errors: [],
    showForgot: null,
    loaded: null,
    resetUserName: '',
    isResetNameValid: null,
    isResetVerified: null,
  };

  UNSAFE_componentWillMount() {
    this.context.onSetTitle(title);
    AppActions.removeSpinner();
    this.setState({
      showForgot: false,
      loaded: false,
      isResetNameValid: false,
      isResetVerified: false,
    });

    AppStore.addChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      isResetVerified: true,
    });
  };

  componentWillUnmount() {
    AppActions.destroyModal();
    AppStore.removeChangeListener(this.updateState);
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    const username = this.refs.userName.input.value;
    const password = this.refs.password.input.value;

    this.state.errors = [];
    if (!validator.isNotEmptyString(username)) {
      this.state.errors.push('username is required');
    }

    if (!validator.isNotEmptyString(password)) {
      this.state.errors.push('password is required');
    }

    this.forceUpdate();
    if (this.state.errors.length < 1) {
      let hasResponded = false;
      setTimeout(() => {
        if (hasResponded === false) {
          AppActions.removeSpinner();
          this.state.errors.push('the request timed out');
          this.forceUpdate();
        }
      }, 10000);
      AppActions.addSpinner();
      AppActions.login({
        username: username,
        password: password,
        callback: (response) => {
          hasResponded = true;
          AppActions.removeSpinner();
          if (response.status == 200) {
            AppActions.setExpiry({
              date: new Date(),
              minutes: loginExpiry,
            });
            return;
          }
          if (response.status == 401) {
            this.state.errors.push('invalid username or password');
            this.forceUpdate();
            return;
          }
          this.state.errors.push(
            'An error occurred. Please contact an administrator.'
          );
        },
      });
    }
  };

  handleReminderRequest = (e) => {
    e.preventDefault();

    AppActions.createModal({
      open: true,
      title: 'Reset User Password',
      type: 'confirm',
      disableConfirm: false,
      content: (
        <div>
          <p>
            Your password will be reset. An email containing a reset link will
            be sent to you.
          </p>
          <p>Are you sure you want to do this?</p>
        </div>
      ),
      onConfirm: () => {
        AppActions.resetPassword({
          userName: this.state.resetUserName,
          callback: (response) => {
            response.data === true
              ? this.handleConfirmationPopup('A Reset Email has been sent.')
              : this.handleConfirmationPopup(
                  'An Error occurred: please contact an Administrator.'
                );
          },
        });
      },
    });
  };

  handleConfirmationPopup = (msg) => {
    AppActions.updateModal({
      open: true,
      type: 'alert',
      content: (
        <div>
          <p>{msg}</p>
        </div>
      ),
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      showForgot: this.state.showForgot ? false : true,
    });
  };

  changeUserName = (e) => {
    this.setState(
      {
        resetUserName: e.target.value,
      },
      () => {
        this.setState({
          isResetNameValid: this.state.resetUserName !== '',
        });
      }
    );
  };

  render() {
    const { resetUserName, isResetNameValid, errors, loaded, showForgot } =
      this.state;

    const form = loaded ? (
      showForgot ? (
        <form onSubmit={this.handleReminderRequest} className={s.loginForm}>
          <h2>Reset Password</h2>
          <div className="form-group">
            <div>
              <TextField
                id="userNameReset"
                value={resetUserName}
                onChange={this.changeUserName}
                placeholder="Enter Username"
                autoComplete="off"
              />
            </div>
            <div>
              <RaisedButton
                disabled={!isResetNameValid}
                className={s.submitBtn}
                type="submit"
              >
                Reset Password
              </RaisedButton>
            </div>
          </div>

          <a className={s.forgotLink} href="/login" onClick={this.handleClick}>
            Log in
          </a>
        </form>
      ) : (
        <form onSubmit={this.handleLogin} className={s.loginForm}>
          <h2>Log in</h2>
          <div className="form-group">
            <div>
              <TextField
                id="userName"
                ref="userName"
                type="text"
                placeholder="Username"
              />
            </div>
            <div>
              <TextField
                id="password"
                ref="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div>
              <RaisedButton className={s.submitBtn} type="submit">
                Log in
              </RaisedButton>
            </div>
          </div>
          <ul className={s.validateErrors}>
            {errors.map((x, i) => (
              <li className={s.errorMsg} key={i}>
                {x}
              </li>
            ))}
          </ul>
          <a className={s.forgotLink} href="/forgot" onClick={this.handleClick}>
            Forgot Your Password?
          </a>
        </form>
      )
    ) : (
      <div className={s.loader}>
        <h1>loading... please wait...</h1>
      </div>
    );

    return (
      <div
        className={s.container}
        style={{
          height: this.props.viewport
            ? this.props.viewport.height - 135
            : 'auto',
        }}
      >
        {form}
      </div>
    );
  }
}

let composed = withViewport(LoginPage);

export default composed;
