import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackLeft4x45d extends Component {
  render() {
    return (
      <g className={s.wheelBackLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M806.5,786.4c-34.5,0-62.5,29.8-62.5,66.3c0,36.7,28.1,66.3,62.5,66.3c34.5,0,62.5-29.8,62.5-66.3  C869.1,815.9,841.1,786.4,806.5,786.4z M806.5,893.2c-21.2,0-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7s38.2,18.2,38.2,40.7  S827.5,893.2,806.5,893.2z"
        />
        <path
          {...this.props}
          d="M806.5,814.4c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2c19.9,0,35.9-17,35.9-38.2C842.5,831.7,826.5,814.4,806.5,814.4z   M806.5,829.2c11.1,0,20.2,8.9,21.7,20.2h-1.3c-1.5-10.6-10.1-19-20.7-19s-19.2,8.1-20.7,19h-1.3  C786.1,838.1,795.5,829.2,806.5,829.2z M778.9,852.6h2.5H778.9z M806.5,876c-11.1,0-20.2-8.7-21.7-20h1.3  c1.5,10.6,10.1,18.7,20.7,18.7c10.3,0,19-8.1,20.7-18.7h1.3C826.8,867.4,817.6,876,806.5,876z M831.7,852.6h1.5H831.7z"
        />
      </g>
    );
  }
}

let composed = WheelBackLeft4x45d;
composed = SectionComponent({
  name: 'Back Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Passenger Wheel Damage',
    content: <Modal name="Back Passenger Wheel" />,
  },
})(composed);

export default composed;
