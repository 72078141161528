import { Component } from 'react';
import s from './Review.module.scss';
import cx from 'classnames';

import TriageActions from '../../../actions/TriageActions';
import TriageStore from '../../../stores/TriageStore';
import triageAPI from '../../../api/triageAPI';
import AppStore from '../../../stores/AppStore';
import AppActions from '../../../actions/AppActions';
import DecisionDialog from '../DecisionDialog/';
import DecisionPrompt from './DecisionPrompt/';
import RaisedButton from 'material-ui/RaisedButton';
import VariantSelection from './VariantSelection';
import Summary from './Summary/';
import CashInLieu from './CashInLieu';
import QuestionsList from '../DamageTool/QuestionsList';
import PrevIcon from 'material-ui/svg-icons/image/navigate-before';
import FinishIcon from 'material-ui/svg-icons/action/done';
import ErrorIcon from 'material-ui/svg-icons/content/block';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import ReportPage from '../ReportPage';
import VehicleHistory from './VehicleHistory';
import pdfStyles from '../ReportPage/PDFStyles';

const DECISION_TYPES = {
  0: 'Unknown',
  1: 'Cosmetic',
  2: 'Mobile',
  3: 'Fast Track',
  4: 'Traditional',
  5: 'Total Loss',
  6: 'Possible Total Loss',
  7: 'Potential Total Loss',
  8: 'Cash in Lieu',
};

class Review extends Component {
  state = {
    openModal: null,
    decision: {},
    defaultCarHireData: {},
    promptAnswered: null,
    openPrompt: null,
    allowFinish: null,
    pdfMarkup: null,
    inputRerendered: false,
  };

  UNSAFE_componentWillMount() {
    this.setState(
      {
        openModal: false,
        userData: AppStore.userData(),
        currentCase: TriageStore.getCase(),
        openPrompt: false,
        allowFinish: false,
      },
      () => {
        if (this.state.currentCase.caseId === '') {
          TriageActions.resetProgress();
          TriageActions.resetCase();
        }
      }
    );

    TriageStore.addChangeListener(this.updateState);
    TriageActions.makeDecision();
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    const decision = TriageStore.getDecision();
    this.setState({
      allowFinish: decision.Direction !== 0,
      userData: AppStore.userData(),
      currentCase: TriageStore.getCase(),
      decision: decision,
    });
    this.handlePossibleTotalLoss();
    this.handleCashInLieu();
  };

  sendEmbedReport = () => {
    if (this.state.currentCase.isEmbed) {
      if (this.state.currentCase.shouldReturnEmbedReport) {
        const markup = this.refs.report.innerHTML;
        parent.postMessage(JSON.stringify({ results: markup }), '*');
      } else {
        parent.postMessage(JSON.stringify({ action: 'requestJSON' }), '*');
      }
    }
  };

  handlePossibleTotalLoss = () => {
    const isPossibleTotalLoss =
      TriageStore.getDecision().Direction === 6 &&
      TriageStore.getDecision().RequestVariants;
    if (isPossibleTotalLoss) {
      let newState = TriageStore.getProgressState();
      newState.MAX_PERMITTED = 3;
      newState.CURRENT_STAGE = 3;
      this.props.recordTime();
      TriageActions.setProgressState(newState);
    }
  };

  handleSelectVariant = (i) => {
    if (i > 0) {
      AppActions.updateModal({
        disableConfirm: false,
      });
      TriageActions.selectVariantModel(i - 1, () => {
        triageAPI.getRetailValue((res) => {
          const currentCase = TriageStore.getCase();
          currentCase.vehicleDetails.Retail = res;
          currentCase.vehicleDetails.Variation =
            currentCase.variantModels[i - 1].Name;
          currentCase.vehicleDetails.VariantIndex = i - 1;
          TriageActions.updateCase(currentCase);
        });
      });
    } else {
      AppActions.updateModal({
        disableConfirm: true,
      });
    }
  };

  changeVariant = (i) => {
    AppActions.createModal({
      open: true,
      title: 'Please Select Variant Model',
      type: 'alert',
      disableConfirm: true,
      content: (
        <VariantSelection
          selectedIndex={this.state.currentCase.vehicleDetails.VariantIndex}
          data={this.state.currentCase.variantModels}
          selectHandler={this.handleSelectVariant}
        />
      ),
      onConfirm: () => {
        TriageActions.makeDecision();
      },
    });
  };

  handleCashInLieu = (override) => {
    const decision = TriageStore.getDecision();
    const isCashInLieu = decision.Direction === 8;
    const isAnswered = decision.CashInLieuDetails
      ? decision.CashInLieuDetails.CashInLieuAccepted !== null
      : false;

    if ((isCashInLieu && !isAnswered) || override === true) {
      AppActions.createModal({
        open: true,
        title: 'Accept Cash In Lieu?',
        type: 'confirm',
        disableConfirm: true,
        content: (
          <CashInLieu
            changeHandler={this.submitCashInLieu}
            decision={decision}
          />
        ),
        onConfirm: () => {
          TriageActions.makeDecision();
        },
        onCancel: () => {
          this.gotoPrevious();
        },
      });
    }
  };

  submitCashInLieu = (e, i) => {
    AppActions.updateModal({
      disableConfirm: false,
    });
    const cilDecision = i * 1 === 1;
    TriageActions.submitCashInLieuAnswer(cilDecision);
  };

  onCashInLieuChanged = () => {
    this.handleCashInLieu(true);
  };

  checkClaimValid = () => {
    const isClaimReferenceMandatory =
      this.state.currentCase.caseSettings.IsClaimReferenceMandatory ===
      undefined
        ? false
        : this.state.currentCase.caseSettings.IsClaimReferenceMandatory;
    return (
      !isClaimReferenceMandatory ||
      (this.state.currentCase.metaData.ClaimReferenceNumber !== undefined &&
        this.state.currentCase.metaData.ClaimReferenceNumber !== null &&
        this.state.currentCase.metaData.ClaimReferenceNumber.trim() !== '')
    );
  };

  checkSettleValid = () => {
    const showSettlementPrompt =
      this.state.currentCase.caseSettings.ShowSettlementPrompt === undefined
        ? false
        : this.state.currentCase.caseSettings.ShowSettlementPrompt;
    const isTotalLoss = this.state.currentCase.decision.Direction === 5;
    const isValid =
      this.state.currentCase.settlementAnswer !== undefined &&
      this.state.currentCase.settlementAnswer !== null;

    if (showSettlementPrompt && isTotalLoss) return isValid;
    return true;
  };

  checkPromptValid = () => {
    const claimValid = this.checkClaimValid();
    const settleValid = this.checkSettleValid();
    return claimValid && settleValid;
  };

  resetCarHire = () => {
    AppActions.createModal({
      open: true,
      title: 'Reset Car Hire Data?',
      type: 'confirm',
      content: (
        <div>
          <p>Click 'confirm' to reset the selected Car Hire Rate.</p>
          <p>
            The Car Hire Rate will be set to the recommended rate for this
            vehicle. You will then redirected back to Stage 4 ("Further
            Actions").
          </p>
        </div>
      ),
      onConfirm: () => {
        triageAPI.ResetCarHireRate(() => {
          let newState = TriageStore.getProgressState();
          newState.CURRENT_STAGE = 3;
          TriageActions.setProgressState(newState);
        });
      },
    });
  };

  handleAnswers = (valid, model) => {
    AppActions.updateModal({
      open: this.state.openPrompt,
      title: 'Extra Details Required',
      type: 'confirm',
      disableConfirm: !valid,
      content: (
        <DecisionPrompt state={this.state} onChange={this.handleAnswers} />
      ),
      onConfirm: () => {
        //optional, so submit even if not required
        if (model.referenceNumber !== null) {
          TriageActions.submitReferenceNumber(
            model.referenceNumber.toUpperCase()
          );
        }
        //if not required, should be hidden and not submitted
        if (model.settlementAnswer !== null && model.isSettleQuestionRequired) {
          TriageActions.submitSettlementAnswer(model.settlementAnswer);
          //optional, so submit even if not required
          if (model.referenceNumber !== null) {
            TriageActions.submitReferenceNumber(
              model.referenceNumber.toUpperCase(),
              () => {
                //if not required, should be hidden and not submitted
                if (
                  model.settlementAnswer !== null &&
                  model.isSettleQuestionRequired
                ) {
                  TriageActions.submitSettlementAnswer(model.settlementAnswer);
                }
              }
            );
          }
        }
      },
    });
  };

  getVehicleHistory = () => {
    AppActions.createModal({
      open: true,
      title: 'Query historical data?',
      type: 'confirm',
      content: (
        <div>
          <p>Are you sure you want to check the vehicle history?</p>
        </div>
      ),
      onConfirm: () => {
        TriageActions.getVehicleHistory(
          this.state.currentCase.vehicleDetails.Registration,
          this.state.currentCase.vehicleDetails.Mileage,
          (res) => {
            setTimeout(() => {
              AppActions.updateModal({
                open: true,
                title: 'Vehicle History',
                type: 'alert',
                content: (
                  <div style={{ paddingTop: 20 }}>
                    <VehicleHistory styles={s} data={res} />
                  </div>
                ),
              });
            }, 0);
          },
          (res) => {
            setTimeout(() => {
              AppActions.updateModal({
                open: true,
                title: 'Error retrieving data',
                type: 'alert',
                content: (
                  <div>
                    <p>There was an error retrieving this data. </p>
                    <p>
                      If the problem persists, please contact an administrator.{' '}
                    </p>
                  </div>
                ),
              });
            }, 0);
          }
        );
      },
    });
  };

  gotoPrevious = (e) => {
    if (e) e.preventDefault();
    let newState = TriageStore.getProgressState();

    const currentCase = TriageStore.getCase();
    const caseSettings = currentCase.caseSettings;
    const shouldShowFurtherActions =
      currentCase.decision.RequestVariants ||
      caseSettings.ShowImagesComponent ||
      caseSettings.ShowRepairerSelectionGroup ||
      (currentCase.decision.OfferMobileRepairChoice &&
        currentCase.mobileConfirmed === null &&
        caseSettings.ShowConfirmMobileGroup) ||
      (caseSettings.ShowCarHireGroup && currentCase.decision.ShowCarHire);

    if (shouldShowFurtherActions) {
      const index = newState.DISABLED_STAGES.indexOf(3);
      if (index > -1) newState.DISABLED_STAGES.splice(index, 1);
      newState.CURRENT_STAGE -= 1;
    } else {
      if (newState.DISABLED_STAGES.indexOf(3) === -1)
        newState.DISABLED_STAGES.push(3);
      if (currentCase.criticals.length < 1) {
        newState.CURRENT_STAGE -= 2;
      } else {
        newState.CURRENT_STAGE -= 3;
      }
    }

    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  openPrompt = (e) => {
    //e.preventDefault();
    this.setState(
      {
        openPrompt: true,
      },
      () => this.handleAnswers()
    );
  };

  makeFinalDecision = () => {
    if (!this.checkPromptValid()) {
      this.openPrompt();
      // this.setState({
      //   inputRerendered: true
      // });
    } else {
      AppActions.createModal({
        open: true,
        title: 'Submit Case for Final Decision',
        type: 'confirm',
        content: (
          <div className={s.content}>
            <p>
              This will submit the current case for a final decision. No further
              amendments will be possible.
            </p>
            <p>Are you sure?</p>
          </div>
        ),
        onConfirm: () => {
          this.sendEmbedReport();
          const data = Object.assign({}, this.state.decision);
          const pdfMarkup = this.shouldShowPdf()
            ? this.refs.report.innerHTML
            : null;
          const decision = {
            title: 'DECISION: ' + DECISION_TYPES[data.Direction].toUpperCase(),
            type: DECISION_TYPES[data.Direction],
            service: this.state.currentCase.vehicleDetails.ServiceUsed,
            vehicle: data.VehicleDetails,
            damage: data.DamageDescription,
            message: data.DrivabilityDescription,
            NB: data.supplemental,
            refNo: data.ReferenceNumber,
          };
          this.setState({
            pdfMarkup: pdfMarkup,
            decision: decision,
            openModal: true,
          });
          TriageActions.closeCase();
        },
      });
    }
  };

  handleFinish = (e) => {
    e.preventDefault();
    const isEmbed = TriageStore.getCase().isEmbed;
    this.props.recordTime();
    TriageActions.resetProgress();
    TriageActions.resetCase();
    isEmbed
      ? window.parent.postMessage('{ "action" : "finish" }', '*')
      : document.location.reload(true);
  };

  shouldShowPdf = () => {
    const currentCase = this.state.currentCase;

    return currentCase.caseSettings !== undefined &&
      currentCase.caseSettings !== null
      ? currentCase.caseSettings.AllowPDFDownload
      : false;
  };

  render() {
    const { currentCase } = this.state;

    if (!currentCase.caseSettings) {
      return null;
    }

    const vehicleHistory =
      currentCase.vehicleHistory &&
      currentCase.caseSettings.AllowVehicleHistoryQuery ? (
        <div className={s.vehicleHistory}>
          <h2>Vehicle History</h2>
          <VehicleHistory styles={s} data={currentCase.vehicleHistory} />
        </div>
      ) : null;

    const historyButtonTitle = 'Vehicle History';

    const historyButton = currentCase.caseSettings.AllowVehicleHistoryQuery ? (
      <RaisedButton
        disabled={currentCase.vehicleHistory}
        icon={<HistoryIcon />}
        labelPosition="after"
        label={historyButtonTitle}
        primary={true}
        className={s.historyButton}
        onClick={this.getVehicleHistory}
      />
    ) : null;

    // TODO: remove require?
    const mainImage =
      currentCase.SVG === undefined ? (
        currentCase.splat ? (
          <img
            className={s.img}
            src={require('./DefaultImages/' + currentCase.splat + '.png')}
            alt=""
          />
        ) : null
      ) : (
        <img className={s.img} src={currentCase.SVG} alt="" />
      );

    const showPDF = this.shouldShowPdf();

    const errorOverlay =
      currentCase.criticals.length > 0 && mainImage !== null ? (
        <div className={s.criticalDamage}>
          <div>
            {currentCase.criticals.map((item, i) => {
              return (
                <div className={s.criticalDamageText} key={i}>
                  {item} damage reported
                </div>
              );
            })}{' '}
          </div>
          <ErrorIcon className={s.criticalDamageIcon} />
        </div>
      ) : null;

    return (
      <div className={s.root}>
        {showPDF ? (
          <div style={{ display: 'none' }}>
            <div ref="report">
              <ReportPage
                case={this.state.currentCase}
                userData={this.state.userData}
              />
            </div>
          </div>
        ) : null}
        <form className={s.container}>
          <div className={s.box}>
            <h2>Review</h2>
            <div className={s.row}>
              <div className={s.cols_6}>
                <div className={cx(s.damageImage, s.root)}>
                  <h2>Registered Damage</h2>
                  {mainImage}
                  {errorOverlay}
                </div>
                <div className={s.QuestionsList}>
                  <QuestionsList allowEdit={false} />
                </div>
              </div>
              <div className={s.cols_6}>
                <div className={s.summary}>
                  <Summary
                    decisionTypes={DECISION_TYPES}
                    userData={this.state.userData}
                    onCashInLieuChanged={this.onCashInLieuChanged}
                    onVariantChangeHandler={this.changeVariant}
                    onPromptHandler={this.openPrompt}
                    onCarHireReset={this.resetCarHire}
                  />
                  {vehicleHistory}
                </div>
              </div>
            </div>

            <div className={s.footerDialogContainer}>
              <div className={s.footerDialog}>
                <div className={s.nextButtons}>
                  <div className={s.prevButton}>
                    <RaisedButton
                      icon={<PrevIcon />}
                      label="Previous"
                      onClick={this.gotoPrevious}
                    />
                  </div>
                  {historyButton}
                  <div className={s.nextButton}>
                    <RaisedButton
                      labelPosition="before"
                      label="Get Final Decision"
                      icon={<FinishIcon />}
                      primary={true}
                      disabled={!this.state.allowFinish}
                      onClick={this.makeFinalDecision}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <DecisionDialog
          decisionTypes={DECISION_TYPES}
          currentCase={this.state.currentCase}
          pdfMarkup={this.state.pdfMarkup}
          pdfStyles={pdfStyles}
          decision={this.state.decision}
          open={this.state.openModal}
          referenceNumber={(
            this.state.currentCase.metaData.ClaimReferenceNumber || ''
          ).trim()}
          showPDF={showPDF}
          showCost={currentCase.caseSettings.ShowRepairCost}
          onSubmitHandler={this.handleFinish}
        />
      </div>
    );
  }
}

let composed = Review;

export default composed;
