import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRight4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M660.8,215.3c-0.3-3-0.5-6-0.8-9.2c-5.1-60.6-6.5-119.9-6.5-119.4l-209.5,0c0,0,0.7,115.1,4.6,126.4  c0.8,2.3,9.9,2.2,10.7,2.2H660.8z"
      />
    );
  }
}

let composed = DoorFrontRight4x4;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
