import React from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

export default (props) => {
  const s = props.styles;
  const {
    selectedRate,
    customChangeHandler,
    submitCustomRateClickHandler,
    resetCustomRateClickHandler,
    customRateChanged,
  } = props;
  const disableButtons = !customRateChanged || selectedRate.GroupCode === '';
  //console.log(props.dropdownItems)
  return (
    <div className={s.manualBox}>
      <h2 style={{ padding: '7px 0 0 0' }}>Current Hire Rate</h2>
      <ul>
        <li>
          <strong>Group: &nbsp;</strong>
          <span>{selectedRate.GroupName}</span>
          <RaisedButton
            className={s.rightAlignButton}
            label="Submit"
            primary={true}
            disabled={disableButtons}
            onClick={submitCustomRateClickHandler}
            title={'Click here to submit this custom rate'}
          />
        </li>
        <li>
          <strong>Code: &nbsp;</strong>
          <span className={s.value}>{selectedRate.GroupCode}</span>
          <RaisedButton
            className={s.rightAlignButton}
            label="Reset"
            primary={true}
            disabled={disableButtons}
            onClick={resetCustomRateClickHandler}
            title={'Click here to reset the custom rate to what it was before'}
          />
        </li>
        <li>
          <strong>Rate: (&pound;)&nbsp;</strong>
          <TextField
            id="rateField"
            value={selectedRate.GroupCodeRate || '0'}
            disabled={selectedRate.GroupCode === ''}
            onChange={customChangeHandler}
          />
          {/* <RaisedButton label="Submit" primary={true} disabled={disableButtons} onClick={submitCustomRateClickHandler}
                  title={"Click here to submit this custom rate"}
                  />
               <RaisedButton label="Reset" primary={true} disabled={disableButtons} onClick={resetCustomRateClickHandler}
                  title={"Click here to reset the custom rate to what it was before"}
                  /> */}
        </li>
      </ul>
    </div>
  );
};
