import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class BootPanel extends Component {
  render() {
    return (
      <RadioButtonGroup
        className={this.props.style.radios}
        name="panel"
        defaultSelected={this.props.defaultSelected.toString()}
        labelPosition="left"
      >
        <RadioButton value="4x" label="Torn/Crumpled/More than one dent" />
        <RadioButton value="3" label="A single dent or gouge" />
        <RadioButton value="1" label="Scratched or scuffed" />
        <RadioButton value="0" label="There is no damage" />
      </RadioButtonGroup>
    );
  }
}
