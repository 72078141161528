import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeftPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M442.3,833.7l0.2,39l191.2-0.4v-38.7L442.3,833.7L442.3,833.7z"
      />
    );
  }
}

let composed = SillLeftPickup;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
