import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRearCoupe extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M933.6,313.7c0,0-137.1,5.8-138.8,6.1c3.5,0.9,4.6,4.7,7.6,21.2c5.1,34.9,5.1,202.2,0,237.2c-3.5,19.2-4.2,23.6-9.5,24.5  c1.9,0.5,140.9,4.2,140.9,4.2C953,546.7,957.8,390.1,933.6,313.7z"
      />
    );
  }
}

let composed = ScreenRearCoupe;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
