import { Component } from 'react';
import s from './SideModal.module.scss';

import AirbagsFrontQuestions from '../../QuestionSets/AirbagsFrontQuestions/';
import AirbagsSideQuestions from '../../QuestionSets/AirbagsSideQuestions/';
import SteeringQuestions from '../../QuestionSets/SteeringQuestions/';
import DoorsQuestions from '../../QuestionSets/DoorsQuestions/';
import LampsFrontQuestions from '../../QuestionSets/LampsFrontQuestions/';
import PanelQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'airbagsfront', component: <AirbagsFrontQuestions /> },
  { stage: 2, section: 'airbagsside', component: <AirbagsSideQuestions /> },
  { stage: 3, section: 'steering', component: <SteeringQuestions /> },
  { stage: 4, section: 'doors', component: <DoorsQuestions /> },
  { stage: 5, section: 'lampsfront', component: <LampsFrontQuestions /> },
  { stage: 6, section: 'panelStandard', component: <PanelQuestions /> },
];

const SUBTITLE = 'Side Questions';

class FrontSideModal extends Component {
  render() {
    const wheelWarning =
      QUESTION_SETS[this.props.modalModel.currentStage].section ===
      'panelStandard' ? (
        <div className={s.wheelWarning}>
          <h4>Check wheel damage</h4>
        </div>
      ) : null;

    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
            {wheelWarning}
          </div>
        </div>
      </div>
    );
  }
}

const composed = ModalComponent(QUESTION_SETS, SUBTITLE)(FrontSideModal);

export default composed;
