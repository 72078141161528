import { Component } from 'react';
import s from './CreateUserPage.module.scss';

import cx from 'classnames';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import withLoggedInUser from '../../../../decorators/withLoggedInUser';
import ManageUserComponent from '../_ManageUserComponent';
import UsersSelectControl from '../UsersSelectControl';
import UsersRolesList from '../UsersRolesList';

class CreateUser extends Component {
  render() {
    const submitData = this.props.submitData;
    const isLocked = this.props.isLocked;

    const insurersUI = this.props.showInsurer ? (
      <UsersSelectControl
        changeHandler={this.props.handleSelectChange}
        dataName="insurer"
        data={submitData.insurer}
        items={this.props.insurers}
        valMessage="Valid Insurer is required"
        label="Insurer"
        reference="insurerField"
        initialValueText="Select Insurer..."
        initialValue={0}
      />
    ) : null;

    const departmentsUI = this.props.showDepartment ? (
      <UsersSelectControl
        data={submitData.department}
        dataName="department"
        changeHandler={this.props.handleSelectChange}
        items={this.props.departments}
        valMessage="Valid Department is required"
        label="Department"
        reference="departmentField"
        initialValueText="Select Department..."
        initialValue={0}
      />
    ) : null;

    const validationMessage = this.props.showValidationMessage ? (
      <div className={s.validationMessage}>
        Please make sure all fields are valid before trying to save
      </div>
    ) : null;

    return (
      <div>
        <div className={cx(s.root, s.row)}>
          <div className={s.cols_4}>
            <TextField
              floatingLabelText="Username"
              onChange={this.props.handleDataChange.bind(
                this,
                'name',
                this.props.nameRegEx
              )}
              onBlur={this.props.checkUserNameExists}
              errorText={
                submitData.name.isValid
                  ? null
                  : 'Valid unique name is required [3-20 chars, alphanumeric]'
              }
            />
            <TextField
              floatingLabelText="Email"
              onChange={this.props.handleDataChange.bind(
                this,
                'email',
                this.props.emailRegEx
              )}
              errorText={
                submitData.email.isValid ? null : 'Valid email is required'
              }
            />
            {insurersUI}
            {departmentsUI}
          </div>
          <div className={s.cols_4}>
            <TextField
              floatingLabelText="Comment"
              value={submitData.comment.value}
              onChange={this.props.handleDataChange.bind(this, 'comment', null)}
              multiLine={true}
              disabled={false}
              rows={5}
            />
            <Checkbox
              style={{ margin: '20px 0' }}
              label="Locked out"
              labelPosition="left"
              checked={submitData.isLocked.value || false}
              onClick={this.props.handleCheckToggle.bind(this, 'isLocked')}
            />
          </div>
          <div className={s.cols_4}>
            <UsersRolesList
              allRoles={this.props.roles}
              userRoles={submitData.roles}
              currentlyLoggedInUser={this.props.currentlyLoggedInUser}
              handler={this.props.handleRoleChange}
              isLocked={isLocked}
              caller={this}
            />
          </div>
        </div>
        {validationMessage}
      </div>
    );
  }
}

let composed = withLoggedInUser(CreateUser);
composed = ManageUserComponent('CREATE')(composed);

export default composed;
