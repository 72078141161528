import RaisedButton from 'material-ui/RaisedButton';

const CarHireCost = (props) => {
  const s = props.styles;
  const currentCase = props.currentCase;
  const carHireCost =
    currentCase && currentCase.decision
      ? currentCase.decision.CarHireCost || 0
      : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Car Hire Cost: </span>
      <strong className={s.cols_6}>
        <span>£{parseFloat(carHireCost).toFixed(2)}</span>
        {props.changeHandler ? (
          <RaisedButton
            className={'summaryBtn'}
            label="Reset"
            primary={true}
            title={
              'Click here to reset your car hire data. This will take you back to stage 4.'
            }
            onClick={props.changeHandler}
          />
        ) : null}
      </strong>
    </li>
  );
};

export default CarHireCost;
