import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightHatchback5d extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackRight)}>
        <path
          {...this.props}
          d="M811.2,272.2c-9.9,9.8-95.2,12.3-122.5,12.6l-9.8-84.3h166.7C845.6,200.5,816.2,267.5,811.2,272.2z"
        />
        <path
          {...this.props}
          d="M819.3,266.8c0.5-1.8,30-66.4,30-66.4h38.1c5,0,7.2,6.2,3.4,9.4C876.1,222,847.1,245.5,819.3,266.8z"
        />
      </g>
    );
  }
}

let composed = WindowBackRightHatchback5d;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
