import axios from 'axios';
import { apiServer, queryTimeout } from '../config.js';
import AppAPI from './appAPI.js';
import _ from 'underscore';

const defaultProgressState = {
  MAX_STAGE: 5,
  MIN_PERMITTED: 0,
  MAX_PERMITTED: 0,
  CURRENT_STAGE: 0,
  DISABLED_STAGES: [],
};

const DEFAULT_DECISION = {
  type: 'UNKNOWN',
  title: 'UNKNOWN',
  service: 'UNKNOWN',
  vehicle: 'UNKNOWN',
  damage: 'UNKNOWN',
  message: 'UNKNOWN',
  NB: 'UNKNOWN',
  refNo: 'UNKNOWN',
};

const DEFAULT_CASE = {
  caseId: '',
  splat: '',
  criticals: [],
  damagedParts: [],
  answeredQuestionSets: [],
  sectionDamage: [],
  userSelectedVehicle: null,
  vehicleDetails: {},
  variantModels: [],
  furtherActionsModel: {},
  mobileConfirmed: null,
  endpoints: [],
  selectedEndpoint: {},
  decision: DEFAULT_DECISION,
  notes: '',
  errorCode: 0,
  vehicleStatus: '',
  carHireData: null,
  relatedCases: [],
  isHistoryModalClosed: null,
  metaData: {},
  businessLine: null,
  isEmbed: false,
  subInsurerID: null,
  caseSettings: null,
  atFaultStatus: null,
  postcode: null,
};

let currentCaseRepo = DEFAULT_CASE;

const correctName = {
  Front: 'Front Bumper',
  PassengersFrontDoor: 'Front Passenger Door',
  PassengersFrontWing: 'Front Passenger Wing',
  PassengersQuarterPanel: 'Back Passenger Wing',
  PassengersRearDoor: 'Back Passenger Door',
  Rear: 'Rear Bumper',
  RearDoors: 'Back doors',
  LH_RearDoor: 'Left Back Door',
  RH_RearDoor: 'Right Back Door',
  Roof: 'Roof',
  Tailgate: 'Tailgate',
  RH_VanSlidingSideDoor: 'Right sliding door',
  LH_VanSlidingSideDoor: 'Left Sliding Door',
  RH_Sill: 'Driver Sill',
  LH_Sill: 'Passenger Sill',
  BackDoor: 'Hatch Back Door',
  Bonnet: 'Bonnet',
  Boot: 'Boot',
  Flatbed: 'Flatbed',
  DriversFrontDoor: 'Front Driver Door',
  DriversFrontWing: 'Front Driver Wing',
  DriversQuarterPanel: 'Back Driver Wing',
  DriversRearDoor: 'Back Driver Door',
  Windscreen: 'Wind Screen',
  RearScreen: 'Rear Screen',
  LH_FrontGlass: 'Front Passenger Window',
  LH_RearGlass: 'Back Passenger Window',
  RH_FrontGlass: 'Front Driver Window',
  RH_RearGlass: 'Back Driver Window',
  LH_FrontWheel: 'Front Passenger Wheel',
  LH_RearWheel: 'Back Passenger Wheel',
  RH_FrontWheel: 'Front Driver Wheel',
  RH_RearWheel: 'Back Driver Wheel',
  RH_Mirror: 'Driver Door Mirror',
  LH_Mirror: 'Passenger Door Mirror',
};

const triageAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },

  getCurrentCaseFromLocal() {
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      let currentCase = JSON.parse(global.sessionStorage.currentCase);
      currentCaseRepo = currentCase;
      return currentCaseRepo;
    }
    currentCaseRepo = DEFAULT_CASE;
    return currentCaseRepo;
  },

  getCaseFromServer(data, cb, errorCb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      url: 'triage/getCase/' + data.caseId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        const data = {
          caseId: response.data.CaseId,
          vehicleDetails: response.data.VehicleDetails,
          variantModels: response.data.VariantModels,
          damagedParts: this.populateDamageParts(
            response.data.DamagedParts.DamagedPanels
          ),
          answeredQuestionSets: response.data.DamagedParts.AnsweredQuestions,
          splat: response.data.SplatFileName,
          decision: this.populateSalvageCategoryDescription(
            response.data.Decision
          ),
          criticals: response.data.DamagedParts.CriticalDamageQuestions,
          notes: response.data.Notes,
          vehicleStatus: response.data.VehicleStatus,
          relatedCases: [],
          errorCode: response.data.ErrorState,
          sectionDamage: response.data.DamagedParts.SectionQuestions,
          userSelectedVehicle: null,
          furtherActionsModel: response.data.FollowOnModel,
          mobileConfirmed: response.data.IsMobileConfirmed,
          endpoints: [],
          selectedEndpoint: {},
          carHireData: null,
          isHistoryModalClosed: false,
          metaData: response.data.MetaData,
          settlementAnswer: response.data.TotalLossSettlementAnswer,
          businessLine: response.data.InsurerBusinessLineID,
          vehicleHistory: response.data.FollowOnModel
            ? response.data.FollowOnModel.VehicleHistoryModel
            : null,
          subInsurerID: response.data.SubInsurerID,
          caseSettings: response.data.CaseSettings,
          atFaultStatus: response.data.AtFaultStatus,
          postcode: response.data.Postcode,
        };

        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo = Object.assign(currentCaseRepo, data);
          if (global.sessionStorage) {
            global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
            setTimeout(function () {
              if (cb) cb();
            });
          }
        }
        //return currentCaseRepo;
      })
      .catch(function (response) {
        if (errorCb) errorCb(response);
        //currentCaseRepo = DEFAULT_CASE;
        //if(cb) cb(currentCaseRepo);
      });
  },

  createNewCase(
    registration,
    mileage,
    override,
    businessLine,
    referenceNumber,
    subInsurer,
    cb,
    errorCb
  ) {
    axios({
      method: 'post',
      baseURL: apiServer,
      url: 'triage/createCase/',
      data: {
        Registration: registration,
        Mileage: mileage,
        Override: override,
        BusinessLine: businessLine,
        ReferenceNumber: referenceNumber,
        SubInsurerID: subInsurer,
      },
      timeout: queryTimeout,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        currentCaseRepo.caseId = response.data.CaseId;
        currentCaseRepo.splat = response.data.SplatFileName;
        currentCaseRepo.vehicleDetails = response.data.VehicleDetails;
        currentCaseRepo.variantModels = response.data.VariantModels;
        currentCaseRepo.notes = response.data.Notes;
        currentCaseRepo.vehicleStatus = response.data.VehicleStatus;
        currentCaseRepo.errorCode = response.data.ErrorState;
        currentCaseRepo.relatedCases = response.data.RelatedCases;
        currentCaseRepo.metaData = response.data.MetaData;
        currentCaseRepo.furtherActionsModel = response.data.FollowOnModel;
        currentCaseRepo.businessLine = businessLine; //Take this from the data that was passed in, as it is not returned from the API
        currentCaseRepo.subInsurerID = subInsurer; //Take this from the data that was passed in, as it is not returned from the API
        currentCaseRepo.caseSettings = response.data.CaseSettings;
        currentCaseRepo.atFaultStatus = response.data.AtFaultStatus;
        currentCaseRepo.postcode = response.data.Postcode;

        if (global.sessionStorage) {
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb(response);
      })
      .catch((response) => {
        if (errorCb) errorCb(response);
        /*
          AppActions.removeSpinner();
          AppActions.logout();
          this.resetCase();
          Location.pushState("/login");
          */
      });
  },

  updateCompletedQuestionSetsOnLocal(data) {
    currentCaseRepo.answeredQuestionSets = data;
    if (global.sessionStorage) {
      global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
    }
  },

  updateCompletedQuestionSets(data, cb) {
    axios({
      method: 'post',
      baseURL: apiServer,
      url: 'triage/SetCompletedQuestionSets/',
      data: { CaseId: currentCaseRepo.caseId, AnsweredQuestionSets: data },
      timeout: queryTimeout,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (!response.data) return;
        currentCaseRepo.answeredQuestionSets = data;
        if (global.sessionStorage) {
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb(response);
      })
      .catch((response) => {});
  },

  updateCase(data, cb) {
    if (global.sessionStorage) {
      currentCaseRepo = Object.assign(currentCaseRepo, data);
      if (global.sessionStorage) {
        global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        setTimeout(function () {
          if (cb) cb();
        });
      }
    }
  },

  loadNewCaseRemotely(data, cb) {
    if (global.sessionStorage) {
      currentCaseRepo.isEmbed = true;
      currentCaseRepo.caseId = data.CaseId;
      currentCaseRepo.splat = data.SplatFileName;
      currentCaseRepo.vehicleDetails = data.VehicleDetails;
      currentCaseRepo.variantModels = data.VariantModels;
      currentCaseRepo.notes = data.Notes;
      currentCaseRepo.vehicleStatus = data.VehicleStatus;
      currentCaseRepo.errorCode = data.ErrorState;
      currentCaseRepo.relatedCases = data.RelatedCases;
      currentCaseRepo.metaData = data.MetaData;
      currentCaseRepo.furtherActionsModel = data.FollowOnModel;
      currentCaseRepo.businessLine = data.InsurerBusinessLineID;
      currentCaseRepo.subInsurerID = data.SubInsurerID;
      currentCaseRepo.caseSettings = data.CaseSettings;
      currentCaseRepo.atFaultStatus = data.AtFaultStatus;
      currentCaseRepo.postcode = data.Postcode;

      if (global.sessionStorage) {
        global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        setTimeout(function () {
          if (cb) cb();
        });
      }
    }
  },

  getCaseReviews(registration, cb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/getCaseReviews/' + registration,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(function () {});
  },

  getCaseReview(data, cb, errorCb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/getCaseReview/' + data.id + '/' + data.altId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb)
          cb({
            caseId: response.data.CaseId,
            vehicleDetails: response.data.VehicleDetails,
            variantModels: response.data.VariantModels,
            damagedParts: response.data.DamagedParts.DamagedParts,
            answeredQuestionSets: response.data.DamagedParts.AnsweredQuestions,
            splat: response.data.SplatFileName,
            decision: this.populateSalvageCategoryDescription(
              response.data.Decision
            ),
            criticals: response.data.DamagedParts.CriticalDamageQuestions,
            notes: response.data.Notes,
            vehicleStatus: response.data.VehicleStatus,
            relatedCases: [],
            errorCode: response.data.ErrorState,
            sectionDamage: response.data.DamagedParts.SectionQuestions,
            userSelectedVehicle: null,
            furtherActionsModel: response.data.FollowOnModel,
            mobileConfirmed: response.data.IsMobileConfirmed,
            endpoints: [],
            selectedEndpoint: {},
            carHireData: null,
            settlementAnswer: response.data.TotalLossSettlementAnswer,
            isHistoryModalClosed: false,
            metaData: response.data.MetaData,
            businessLine: response.data.InsurerBusinessLineID,
            images: response.data.Images,
            vehicleHistory: response.data.FollowOnModel
              ? response.data.FollowOnModel.VehicleHistoryModel
              : null,
            subInsurerID: response.data.SubInsurerID,
            caseSettings: response.data.CaseSettings,
            atFaultStatus: response.data.AtFaultStatus,
            postcode: response.data.Postcode,
          });
      })
      .catch(function (e) {
        if (errorCb) errorCb(e);
      });
  },

  getPartDamage(cb) {
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      return JSON.parse(global.sessionStorage.currentCase).damagedParts;
    }
  },

  updatePartDamage(name, severity, hasDangerousDamage, update, cb) {
    let data = {
      CaseId: currentCaseRepo.caseId,
      PartName: name,
      DamageSeverity: severity,
      HasDangerousDamage: hasDangerousDamage,
    };

    if (!update) return;
    axios({
      method: 'post',
      data: data,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/UpdatePartDamage',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        let item = currentCaseRepo.damagedParts.find((i) => {
          return i.PartName === name;
        });
        if (item !== undefined) {
          item.DamageSeverity = severity;
          item.HasDangerousDamage = hasDangerousDamage;
        } else {
          if (severity !== 0) currentCaseRepo.damagedParts.push(data);
        }
        currentCaseRepo.damagedParts = currentCaseRepo.damagedParts.filter(
          (item) => {
            return item.DamageSeverity !== 0;
          }
        );
        if (global.sessionStorage) {
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb(response.data);
      })
      .catch(function () {});
  },

  resetDamage(cb) {
    const currentCase = Object.assign(currentCaseRepo, {
      damagedParts: [],
      sectionDamage: [],
    });
    currentCase.SVG = '';
    if (global.sessionStorage) {
      global.sessionStorage.currentCase = JSON.stringify(currentCase);
    }

    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/resetDamage/' + currentCase.caseId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb();
      })
      .catch((err) => {});
  },

  resetDamageFromLocal(cb) {
    const currentCase = Object.assign(currentCaseRepo, {
      damagedParts: [],
      sectionDamage: [],
    });
    currentCase.SVG = '';
    if (global.sessionStorage) {
      global.sessionStorage.currentCase = JSON.stringify(currentCase);
    }
    if (cb) cb();
  },

  updateSectionDamage(data, cb) {
    const dataAsArray = [data];
    this.updateMultipleSectionDamage(dataAsArray, cb);
  },

  updateMultipleSectionDamage(data, cb) {
    data.map((item) => {
      item.CaseId = currentCaseRepo.caseId;
      if (item.SubQuestion !== undefined) {
        item.SubQuestion.CaseId = currentCaseRepo.caseId;
      }
      return item;
    });

    axios({
      method: 'post',
      data: data,
      baseURL: apiServer,
      url: 'triage/UpdateSectionDamage',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        const newSectionNames = data.map((newSectionDamage) => {
          return newSectionDamage.Name;
        });

        const sectionDamage =
          _.reject(currentCaseRepo.sectionDamage, (sectionDamage) => {
            return _.contains(newSectionNames, sectionDamage.Name);
          }) || [];

        sectionDamage.push(...data);
        currentCaseRepo.sectionDamage = sectionDamage;
        if (global.sessionStorage) {
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb(response);
      })
      .catch((err) => {});
  },

  getSectionDamage(id, cb, errorCb) {
    axios({
      method: 'get',
      baseURL: apiServer,
      url: 'triage/getSectionDamage/' + id,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((response) => {
        if (errorCb) errorCb(response);
      });
  },

  getSectionDamageFromLocal(cb) {
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      const sectionDamage = JSON.parse(
        global.sessionStorage.currentCase
      ).sectionDamage;
      if (cb) cb(sectionDamage);
    }
  },

  setVehicleModel(name, update, cb) {
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      currentCaseRepo.userSelectedVehicle = name;
      global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
    }
    if (!update) {
      if (cb) cb();
      return;
    }

    const model = {
      Splat: name,
      CaseId: currentCaseRepo.caseId,
    };
    axios({
      method: 'post',
      data: model,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/UpdateVehicleSplat',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb();
      })
      .catch(() => {});
  },

  setCriticals(data) {
    //save locally
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      currentCaseRepo.criticals = data.criticals;
      global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
    }
    //save to server
    axios({
      method: 'post',
      data: { CaseId: currentCaseRepo.caseId, Criticals: data.criticals },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/UpdateCriticalDamage/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (data.callback) data.callback(response);
        return response;
      })
      .catch(() => {});
  },

  updateFurtherActionsModel(d) {
    const data = d.data;

    const model = {
      CaseId: currentCaseRepo.caseId,
      EmailAddress: data.EmailAddress || '',
      CarRequired: data.CarRequired || false,
      DailyRate: data.DailyRate || 0,
      HireType: data.HireType || '',
      AllocationEndpoint: {},
    };
    //save locally
    if (global.sessionStorage && global.sessionStorage.currentCase) {
      currentCaseRepo.furtherActionsModel = model;
      global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
    }

    axios({
      method: 'post',
      data: model,
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/UpdateFollowOn/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (d.callback) d.callback();
      })
      .catch(() => {
        if (d.errorCb) d.errorCb();
      });
  },

  getEndpoints(data) {
    axios({
      method: 'get',
      baseURL: apiServer,
      timeout: queryTimeout,
      url: 'triage/getEndpoints/' + data.postcode,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (data.callback) data.callback(response);
        //save locally
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.selectedEndpoint = {};
          currentCaseRepo.endpoints = response.data;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
      })
      .catch(() => {});
  },

  submitClaimReference(ref, cb) {
    if (currentCaseRepo.caseId === '') return;
    const data = ref ? ref.trim() : '';
    axios({
      method: 'post',
      baseURL: apiServer,
      data: { CaseId: currentCaseRepo.caseId, ReferenceNumber: data },
      timeout: queryTimeout,
      url: 'triage/UpdateRefNumber',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.metaData = Object.assign(
            {},
            currentCaseRepo.metaData,
            { ClaimReferenceNumber: data }
          );
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        //if(decisionHandler) decisionHandler(json);
        if (cb) cb();
      })
      .catch(() => {});
  },

  submitSettlementAnswer(answer, cb) {
    if (currentCaseRepo.caseId === '') return;
    axios({
      method: 'post',
      baseURL: apiServer,
      data: { CaseId: currentCaseRepo.caseId, Answer: answer },
      timeout: queryTimeout,
      url: 'triage/SetSettlementAnswer',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.settlementAnswer = answer;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb();
      })
      .catch(() => {});
  },

  setEndpoint(data) {
    axios({
      method: 'post',
      baseURL: apiServer,
      data: data,
      timeout: queryTimeout,
      url: 'triage/setEndpoint',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        //save locally
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.selectedEndpoint = {};
          const garages = currentCaseRepo.endpoints;
          for (let i in garages) {
            if (garages[i].Address === data.Address)
              currentCaseRepo.selectedEndpoint = data;
          }
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (data.callback) data.callback(response);
      })
      .catch(() => {});
  },

  makeDecision(cb) {
    if (currentCaseRepo.caseId === '') return;
    fetch(apiServer + '/triage/getDecision/' + currentCaseRepo.caseId, {
      method: 'GET',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.decision =
            this.populateSalvageCategoryDescription(json);
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        //if(decisionHandler) decisionHandler(json);
        if (cb) cb();
      })
      .catch(() => {
        if (cb) cb();
      });
  },

  setCashInLieuAnswer(answer, cb) {
    if (currentCaseRepo.caseId === '') return;
    axios({
      method: 'post',
      baseURL: apiServer,
      data: { CaseId: currentCaseRepo.caseId, Answer: answer },
      timeout: queryTimeout,
      url: 'triage/SetCashInLieuAnswer',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb();
      })
      .catch(() => {});
  },

  generatePDF(data, cb) {
    const content =
      '<body>' +
      data.pdfMarkup +
      '<style>' +
      data.pdfStyles +
      '</style></body>';

    axios({
      method: 'post',
      baseURL: apiServer,
      data: [content],
      timeout: 60000,
      url: 'triage/generatePDFFromHTML/',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        var binaryString = window.atob(response.data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        if (data.callback) data.callback(new Blob([bytes]));
        if (cb) cb();
      })
      .catch(() => {});
  },

  getPostcodes(partial, cb) {
    //if(!this.isTokenValid()) return;
    axios({
      method: 'get',
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/getPostcodes/' + partial,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
        return response;
      })
      .catch(() => {});
  },

  resetCase(cb) {
    //if(!this.isTokenValid()) return;

    if (global.sessionStorage && global.sessionStorage.currentCase) {
      global.sessionStorage.currentCase = JSON.stringify(DEFAULT_CASE);
    }
    if (cb) cb();
  },

  closeCase(cb) {
    axios({
      method: 'post',
      data: { caseId: currentCaseRepo.caseId },
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/CloseTriageCase',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb();
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          global.sessionStorage.currentCase = JSON.stringify(DEFAULT_CASE);
        }
        return response.data;
      })
      .catch(() => {
        /*
      AppActions.logout();
      Location.pushState("/login");*/
      });
  },

  recordTime(duration, token, cb, errorCb) {
    if (currentCaseRepo.caseId === '') return;
    axios({
      method: 'post',
      data: {
        CaseId: currentCaseRepo.caseId,
        Duration: duration,
      },
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/RecordTime',
      headers: {
        Auth: token === undefined || token === null ? this.getToken() : token,
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  setSelectedVariant(i, cb) {
    axios({
      method: 'post',
      data: { caseId: currentCaseRepo.caseId, variantIndex: i },
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/SetSelectedVariant',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch(() => {});
  },

  updateCarHireData(data, cb, errorCb) {
    const dataModel = {
      CaseId: currentCaseRepo.caseId,
      SelectedGroupCode: data.GroupCode || '',
      SelectedGroupDescription: data.GroupName || '',
      SelectedRate: data.GroupCodeRate || '0',
    };

    axios({
      method: 'post',
      timeout: queryTimeout,
      data: dataModel,
      baseURL: apiServer,
      url: 'triage/UpdateCarHireData',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((response) => {
        if (errorCb) errorCb(response);
      });
  },

  getCarHireDataById(cb) {
    axios({
      method: 'get',
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/GetCarHireSelectionModel/' + currentCaseRepo.caseId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(() => {});
  },

  getCarHireRatesForGroupName(groupName, cb) {
    axios({
      method: 'get',
      timeout: queryTimeout,
      baseURL: apiServer,
      url: `triage/GetCarHireRatesForGroupName/${currentCaseRepo.caseId}/${groupName}`,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch(() => {});
  },

  ResetCarHireRate(cb) {
    axios({
      method: 'post',
      timeout: queryTimeout,
      baseURL: apiServer,
      url: `triage/ResetCarHireRate/${currentCaseRepo.caseId}`,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch(() => {});
  },

  confirmMobile(i, cb) {
    axios({
      method: 'post',
      data: { CaseId: currentCaseRepo.caseId, MobileConfirmed: i },
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/setMobileConfimation',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.mobileConfirmed = i;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb();
      })
      .catch(() => {});
  },

  //PROGRESS BAR API
  setProgressState(data) {
    if (global.sessionStorage) {
      const currentData = global.sessionStorage.triageStageData
        ? JSON.parse(global.sessionStorage.triageStageData)
        : defaultProgressState;
      const newData = Object.assign(currentData, data);
      if (data.CURRENT_STAGE <= data.MAX_PERMITTED) {
        global.sessionStorage.setItem(
          'triageStageData',
          JSON.stringify(newData)
        );
      } else {
        console.log('CURRENT_STAGE > MAX_PERMITTED - ProgressState not saved');
      }
    }
  },

  getProgressState() {
    if (global.sessionStorage && global.sessionStorage.triageStageData) {
      return JSON.parse(global.sessionStorage.triageStageData);
    }
    return defaultProgressState;
  },

  resetProgress() {
    this.setProgressState(defaultProgressState);
  },

  setBusinessLine(data, cb) {
    axios({
      method: 'post',
      data: data,
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/UpdateBusinessLine',
      headers: {
        Auth: this.getToken(),
      },
    }).then((response) => {
      if (global.sessionStorage && global.sessionStorage.currentCase) {
        currentCaseRepo = Object.assign(currentCaseRepo, {
          businessLine: data.businessLineID,
        });
        if (global.sessionStorage) {
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
      }
      if (cb) cb();
    });
  },

  getRetailValue(cb, errorCb) {
    axios({
      method: 'get',
      timeout: queryTimeout,
      baseURL: apiServer,
      url: 'triage/getRetailValue/' + currentCaseRepo.caseId,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  populateSalvageCategoryDescription(decision) {
    if (!decision) return decision;

    var finalDecision = decision;

    if (decision.SalvageCat) {
      const salvageCategories = [
        { id: 0, description: '' },
        { id: 1, description: 'A' },
        { id: 2, description: 'B' },
        { id: 3, description: 'C' },
        { id: 4, description: 'D' },
        { id: 5, description: 'A/B' },
        { id: 6, description: 'C/D' },
      ];

      const category = salvageCategories.filter((item) => {
        return item.id === decision.SalvageCat;
      });

      finalDecision =
        category.length > 0 && category[0].description !== ''
          ? Object.assign(decision, { SalvageCat: category[0].description })
          : decision;
    }

    if (decision.SalvageCat2017) {
      const salvageCategories = [
        { id: 0, description: '' },
        { id: 1, description: 'A' },
        { id: 2, description: 'B' },
        { id: 3, description: 'S' },
        { id: 4, description: 'N' },
      ];

      const category = salvageCategories.filter((item) => {
        return item.id === decision.SalvageCat2017;
      });

      finalDecision =
        category.length > 0 && category[0].description !== ''
          ? Object.assign(finalDecision, {
              SalvageCat2017: category[0].description,
            })
          : finalDecision;
    }

    return finalDecision;
  },

  populateDamageParts(panels) {
    const damagedParts =
      panels.map((item) => {
        item.PartName = correctName[item.PartName];
        if (
          item.PartName.toLowerCase().includes('mirror') ||
          item.PartName.toLowerCase().includes('wheel')
        )
          return item;
        //correct for historical cases
        if (item.Action === 1) item.DamageSeverity = 2;
        if (item.Action === 2) item.DamageSeverity = 4;
        return item;
      }) || [];

    return damagedParts;
  },

  getVehicleHistory(data, cb, errorCb) {
    axios({
      method: 'get',
      timeout: queryTimeout,
      baseURL: apiServer,
      url:
        'triage/getVehicleHistory/caseid/' +
        currentCaseRepo.caseId +
        '/reg/' +
        data.registration +
        '?mileage=' +
        data.mileage,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        const vehicleHistory = JSON.parse(response.data);
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo = Object.assign(currentCaseRepo, {
            vehicleHistory: vehicleHistory,
          });
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb(vehicleHistory);
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  setCustomRetailValue(value, cb, errorCb) {
    axios({
      method: 'post',
      timeout: queryTimeout,
      baseURL: apiServer,
      data: { CaseID: currentCaseRepo.caseId, Value: value },
      url: 'triage/setCustomRetailValue',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (
          response.data == true &&
          global.sessionStorage &&
          global.sessionStorage.currentCase
        ) {
          currentCaseRepo.vehicleDetails.Retail = value;
          currentCaseRepo.vehicleDetails.VariantIndex = -1;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb();
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  closeAllITGCases() {
    return new Promise((resolve, reject) => {
      fetch(apiServer + '/triage/closeITGCases', {
        method: 'POST',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject(Error('closeITGCases failed'));
        });
    });
  },

  setAtFaultStatus(status, cb, errorCb) {
    axios({
      method: 'post',
      timeout: queryTimeout,
      baseURL: apiServer,
      data: { CaseID: currentCaseRepo.caseId, AtFaultStatus: status },
      url: 'triage/setAtFaultStatus',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then(() => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.atFaultStatus = status;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb();
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  setTriagePostcode(postcode, cb, errorCb) {
    axios({
      method: 'post',
      timeout: queryTimeout,
      baseURL: apiServer,
      data: { CaseID: currentCaseRepo.caseId, Location: postcode },
      url: 'triage/setVehicleLocation',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then(() => {
        if (global.sessionStorage && global.sessionStorage.currentCase) {
          currentCaseRepo.postcode = postcode;
          global.sessionStorage.currentCase = JSON.stringify(currentCaseRepo);
        }
        if (cb) cb();
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },

  updateAndResendImageEmail(caseId, newEmailAddress, cb, errorCb) {
    axios({
      method: 'post',
      timeout: queryTimeout,
      baseURL: apiServer,
      data: { newEmailAddress },
      url: `triage/UpdateAndResendImageEmail/${caseId}`,
      headers: {
        Auth: this.getToken(),
      },
    })
      .then(() => {
        if (cb) cb();
      })
      .catch((response) => {
        if (errorCb) errorCb(response.data);
      });
  },
};

export default triageAPI;
