const ReserveValue = (props) => {
  const s = props.styles;
  const reserveValue = props.reserveValue ? props.reserveValue || 0 : 0;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Reserve Value: </span>
      <strong className={s.cols_6}>
        £{parseFloat(reserveValue).toFixed(reserveValue % 1 != 0 ? 2 : 0)}
      </strong>
    </li>
  );
};

export default ReserveValue;
