import { Component } from 'react';
import TriageStore from '../../../../stores/TriageStore';
import TwoDoorSaloon from '../VehicleTypes/TwoDoorSaloon/';
import FourDoorSaloon from '../VehicleTypes/FourDoorSaloon/';
import TwoDoorConvertible from '../VehicleTypes/TwoDoorConvertible/';
import ThreeDoorHatchback from '../VehicleTypes/ThreeDoorHatchback/';
import CarDerivedVan from '../VehicleTypes/CarDerivedVan/';
import Coupe from '../VehicleTypes/Coupe/';
import TwoDoorPickup from '../VehicleTypes/TwoDoorPickup/';
import ThreeDoor4x4 from '../VehicleTypes/ThreeDoor4x4/';
import FiveDoor4x4 from '../VehicleTypes/FiveDoor4x4/';
import FiveDoorEstate from '../VehicleTypes/FiveDoorEstate/';
import FourDoorPickup from '../VehicleTypes/FourDoorPickup/';
import FiveDoorHatchback from '../VehicleTypes/FiveDoorHatchback/';
import LightVan from '../VehicleTypes/LightVan/';
import PanelVan from '../VehicleTypes/PanelVan/';
import MPV from '../VehicleTypes/MPV/';

class SelectedVehicle extends Component {
  state = {
    vehicles: {},
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      vehicles: [
        { name: '2 Door Saloon', elem: <TwoDoorSaloon {...this.props} /> },
        { name: '4 Door Saloon', elem: <FourDoorSaloon {...this.props} /> },
        {
          name: '2 Door Convertible',
          elem: <TwoDoorConvertible {...this.props} />,
        },
        {
          name: '3 Door Hatchback',
          elem: <ThreeDoorHatchback {...this.props} />,
        },
        { name: 'Car Derived Van', elem: <CarDerivedVan {...this.props} /> },
        { name: '2 Door Coupe', elem: <Coupe {...this.props} /> },
        { name: '2 Door Pickup', elem: <TwoDoorPickup {...this.props} /> },
        { name: '3 Door 4x4', elem: <ThreeDoor4x4 {...this.props} /> },
        { name: '5 Door 4x4', elem: <FiveDoor4x4 {...this.props} /> },
        { name: '4 Door Pickup', elem: <FourDoorPickup {...this.props} /> },
        {
          name: '5 Door Hatchback',
          elem: <FiveDoorHatchback {...this.props} />,
        },
        { name: '5 Door Estate', elem: <FiveDoorEstate {...this.props} /> },
        { name: 'Light Van', elem: <LightVan {...this.props} /> },
        { name: 'Panel Van', elem: <PanelVan {...this.props} /> },
        { name: '5 Door MPV', elem: <MPV {...this.props} /> },
      ],
    });
  };

  getVehicle = () => {
    for (let i in this.state.vehicles) {
      if (
        this.state.vehicles[i].name.toLowerCase() ===
        this.props.vehicleName.toLowerCase()
      ) {
        return this.state.vehicles[i].elem;
      }
    }
    return null;
  };

  render() {
    const vehicle = this.getVehicle();
    return <div>{vehicle}</div>;
  }
}

let composed = SelectedVehicle;

export default composed;
