import React from 'react';

class BusinessLinesMI extends React.Component {
  render() {
    const display = this.props.display;

    return (
      <div>
        <h1>BusinessLinesMI</h1>
        {display}
      </div>
    );
  }
}

export default BusinessLinesMI;
