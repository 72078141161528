import { Component } from 'react';
import s from './OwnerImages.module.scss';
import cx from 'classnames';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import TriageActions from '../../../../actions/TriageActions';
import TriageStore from '../../../../stores/TriageStore';

const EMAIL_REGEX =
  "^[-a-z0-9~!$%^&*_=+}{'?]+(.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(.[-a-z0-9_]+)*.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,5})?$";

class OwnerImages extends Component {
  state = {
    showError: null,
    showEmailer: null,
    allowSubmit: null,
    isSubmitted: null,
    disableRadio: null,
    defaultSelected: null,
    email: '',
  };

  UNSAFE_componentWillMount() {
    const model = TriageStore.getCase().furtherActionsModel || {
      EmailAddress: '',
    };
    const isEmpty =
      model.EmailAddress === undefined ||
      model.EmailAddress === null ||
      model.EmailAddress === '';
    this.setState({
      showError: false,
      showEmailer: isEmpty ? false : true,
      allowSubmit: false,
      disableRadio: false,
      isSubmitted: isEmpty ? false : true,
      defaultSelected: isEmpty ? 'n' : 'y',
      email: isEmpty ? '' : model.EmailAddress,
    });
  }

  changeHandler = (e, value) => {
    if (e.target.value === 'y') {
      this.setState({
        showEmailer: true,
      });
      this.props.setContinueState(false);
    } else {
      this.setState({
        showEmailer: false,
      });
    }
    if (e.target.value === 'n') {
      this.props.setContinueState(true);
    }
  };

  changeEmailHandler = (e) => {
    var regex = new RegExp(EMAIL_REGEX, 'gi');

    this.setState(
      {
        email: e.target.value,
      },
      () => {
        let isValid =
          this.state.email !== '' && this.state.email.match(regex) !== null;

        this.setState(
          {
            showError: !isValid,
            allowSubmit: isValid,
          },
          () => {
            this.props.setContinueState(false);
          }
        );
      }
    );
  };

  resetEmailHandler = (e) => {
    this.setState(
      {
        email: '',
        showError: false,
        allowSubmit: false,
        disableRadio: false,
        isSubmitted: false,
      },
      () => {
        this.props.setContinueState(false);
      }
    );
    const model = TriageStore.getCase().furtherActionsModel || {
      EmailAddress: '',
    };
    model.EmailAddress = '';
    TriageActions.updateFurtherActionsModel(model);
  };

  submitEmailHandler = (e) => {
    e.preventDefault();
    var regex = new RegExp(EMAIL_REGEX, 'gi');
    let isValid = this.state.email.match(regex) !== null;

    if (isValid) {
      this.setState({
        showError: false,
        allowSubmit: false,
        disableRadio: true,
        isSubmitted: true,
      });
      this.props.setContinueState(true);
    } else {
      this.setState({
        showError: true,
      });
    }
    const model = TriageStore.getCase().furtherActionsModel || {
      EmailAddress: '',
    };
    model.EmailAddress = this.state.email;
    TriageActions.updateFurtherActionsModel(model);
  };

  render() {
    const textField = !this.state.isSubmitted ? (
      <div className={s.emailWrap}>
        <TextField
          className={cx(s.email)}
          autoComplete="off"
          onChange={this.changeEmailHandler}
          onBlur={this.changeEmailHandler}
          value={this.state.email}
          hintText={'enter a valid email address'}
          underlineShow={true}
        />
      </div>
    ) : (
      <div className={s.confirm}>
        <strong>Contact address given by owner: </strong>
        <span>{this.state.email}</span>
      </div>
    );

    const email = this.state.showEmailer ? (
      <div className={cx(s.emailBox)}>
        <h4>Email Address</h4>
        {textField}
        <div className={s.submitEmail}>
          <RaisedButton label="Reset" onClick={this.resetEmailHandler} />
          <span className={s.space}></span>
          <RaisedButton
            label="Submit Email"
            disabled={!this.state.allowSubmit}
            primary={true}
            onClick={this.submitEmailHandler}
            type="submit"
          />
        </div>
      </div>
    ) : null;

    return (
      <div className={s.root}>
        <h2>Owner Images</h2>
        <div className={s.row}>
          <div className={s.owner}>
            <h4>Is the owner willing to send images of the vehicle?</h4>
            <RadioButtonGroup
              onChange={this.changeHandler}
              className={cx(s.radios)}
              name="ownerImages"
              defaultSelected={this.state.defaultSelected}
              labelPosition="left"
            >
              <RadioButton
                disabled={this.state.disableRadio}
                value="y"
                label="Yes"
              />
              <RadioButton
                disabled={this.state.disableRadio}
                value="n"
                label="No"
              />
            </RadioButtonGroup>
          </div>
          {email}
        </div>
      </div>
    );
  }
}

export default OwnerImages;
