import { Component } from 'react';
import PropTypes from 'prop-types';
import Griddle from 'griddle-react';
import OrgAPI from '../../../../api/organisationAPI.js';
import withLoggedInUser from '../../../../decorators/withLoggedInUser';
import TableConfig from './TableConfig';
import AppActions from '../../../../actions/AppActions';
import OrgStore from '../../../../stores/OrganisationStore';

class AppSettingsTable extends Component {
  static propTypes = {
    editHandler: PropTypes.func,
  };

  static defaultProps = {
    editHandler: () => {},
  };

  state = {
    data: [],
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    OrgStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    OrgStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    AppActions.addSpinner();
    OrgAPI.getAllRulesets()
      .then((result) => {
        //fix to griddle bug with booleans. resolved in later versions.
        result.map((item) => {
          item = Object.keys(item).map((k) => {
            if (k === 'InsurerAndDepIDs') item[k] = JSON.stringify(item[k]);
            if (item[k] === true) item[k] = 'Yes';
            if (item[k] === false) item[k] = 'No';
          });
        });
        this.setState(
          {
            data: result,
          },
          () => AppActions.removeSpinner()
        );
      })
      .catch(() => {
        AppActions.removeSpinner();
        throw 'getAllRulesets failed';
      });
  };

  render() {
    const { appColumns, appIgnores } = TableConfig;
    appColumns.forEach((item) => {
      if (item.displayName.toLowerCase() === '[edit]') {
        item.editHandler = this.props.editHandler;
      }
    });

    const s = this.props.styles;

    return this.props.currentUser.isAdmin ? (
      <div className={s.gridWrap}>
        <Griddle
          className={s.grid}
          results={this.state.data}
          useFixedHeader={true}
          tableClassName={s.table}
          showFilter={true}
          resultsPerPage={100}
          columnMetadata={appColumns}
          metadataColumns={appIgnores}
        />
      </div>
    ) : (
      <div>You do not have permission to view this page</div>
    );
  }
}

const composed = withLoggedInUser(AppSettingsTable);

export default composed;
