import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontLeftVan extends Component {
  render() {
    return (
      <g className={s.wingFrontLeft}>
        <path
          {...this.props}
          d="M572.7,667.9l7.3-38.5c0.2-0.9-0.5-1.7-1.4-1.7c-7.6-0.2-39.5,0-72.1,14.7c-23.3,10.5-73.3,38.3-82.9,43.3     c-36.1,18.6-79.8,48.1-91.8,49.4c-19.1,2-112.3,16.4-149.2,36.5c-18.2,9.9-23.4,27.7-25,38.3c-0.2,1.4-1.1,1.7-1.9,1.8     c-0.7,0.1-1.3,0.7-1.3,1.4v39.1c0,0.7,0.5,1.3,1.2,1.4l0.8,0.1c0.7,0.1,1.2,0.7,1.2,1.4l0.1,11.5c0,0.8-0.6,1.4-1.4,1.5l0,0     c-1.1,0-1.7,1.2-1.2,2.2c2.5,4.3,8.3,4.2,11.8,4.3l69.5,0.4c0.9,0,1.6-0.8,1.4-1.7c-0.7-4.7-2.8-18.2-2.5-24.2     c0.5-12.9,4.5-29,12.4-39.8c21.9-30.2,54.8-31.7,75.1-25.7c0.1,0,0.1,0,0.2,0.1c60.5,25.1,49.6,77.4,46.8,87.7     c-0.2,0.9,0.4,1.8,1.4,1.8H406c0.9,0,1.6-0.9,1.4-1.8c-4.6-19-7.9-118.3,0.3-134.3c0.1-0.1,0.1-0.2,0.2-0.3     c21-22.2,47.8-37.1,75.4-50.4h0.1c14.9-6,35.6-15.6,52.4-16.4c21-1,22.8-0.9,35.5-1C572,669,572.6,668.5,572.7,667.9z"
        />
        <path
          {...this.props}
          d="M578.7,627.7c-7.4-0.2-39.4,0-72.2,14.7c-9,4.1-22,10.7-35.1,17.6l16.9,13.7c-35.2,15-73.7,39.2-97.2,61h-57.4   c-0.7,0.2-1.4,0.3-2,0.4c-19.1,2-112.3,16.4-149.2,36.5c-18.2,9.9-23.4,27.7-25,38.3c-0.2,1.4-1.2,1.8-2,1.8   c-0.7,0-1.2,0.6-1.2,1.3v39.3c0,0.7,0.5,1.2,1.2,1.3l0.9,0.1c0.7,0.1,1.2,0.7,1.2,1.3l0.1,11.7c0,0.7-0.6,1.3-1.3,1.4h-0.2   c-1,0-1.6,1.1-1.1,2c2.4,4.4,8.3,4.3,11.8,4.5l69.6,0.4c0.8,0,1.5-0.7,1.3-1.6c-0.7-4.6-2.8-18.2-2.5-24.3   c0.5-12.9,4.5-29,12.4-39.8c21.9-30.2,54.8-31.7,75.1-25.7c0.1,0,0.1,0,0.2,0.1c60.7,25.2,49.6,77.8,46.8,87.9   c-0.2,0.9,0.4,1.7,1.3,1.7h35c0.9,0,1.5-0.8,1.3-1.7c-4.6-18.8-8-118.4,0.3-134.4c0.1-0.1,0.1-0.2,0.2-0.3   c21-22.2,47.8-37.1,75.4-50.4h0.1c14.9-6,35.6-15.6,52.4-16.4c21.1-1,22.8-0.9,35.6-1c0.6,0,1.2-0.5,1.3-1.1l7.3-38.7   C580.2,628.5,579.6,627.7,578.7,627.7z M181.7,778.3c7-4.6,18.8-6.1,29.1-7.8c-3.4,11.9-5.9,20.3-6.7,36c-0.1,0-40.8,0.1-41,0.1   C166,796.4,171.9,784.8,181.7,778.3z M241.9,811.8h-80.3l0.5-3h81.5L241.9,811.8z"
        />
      </g>
    );
  }
}

let composed = WingFrontLeftVan;
composed = SectionComponent({
  name: 'Front Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Passenger Wing Damage',
    content: <Modal name="Front Passenger Wing" />,
  },
})(composed);

export default composed;
