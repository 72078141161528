import RaisedButton from 'material-ui/RaisedButton';

const ClaimReference = (props) => {
  const s = props.styles;
  const claimRefExists =
    props.claimReferenceNumber &&
    props.claimReferenceNumber !== null &&
    props.claimReferenceNumber !== '';
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Claim Reference Number: </span>
      <strong className={s.cols_6}>
        <span>
          {claimRefExists ? props.claimReferenceNumber : '- - - - - - - '}
        </span>
        {props.onPromptHandler ? (
          <RaisedButton
            className={'summaryBtn'}
            label="Edit"
            primary={true}
            onClick={props.onPromptHandler}
          />
        ) : null}
      </strong>
    </li>
  );
};

export default ClaimReference;
