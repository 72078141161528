import { Component } from 'react';

class GriddleDateCell extends Component {
  state = {
    data: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: this.props.data,
    });
  }

  padLeadingZeros = (value) => {
    var valAsString = value.toString();
    var len = valAsString.length;
    return len == 1 ? '0' + valAsString : valAsString;
  };

  getFormattedDate = () => {
    var d = new Date(this.state.data);

    var results =
      [
        this.padLeadingZeros(d.getDate()),
        this.padLeadingZeros(d.getMonth() + 1),
        d.getFullYear(),
      ].join('/') +
      ' ' +
      [
        this.padLeadingZeros(d.getHours()),
        this.padLeadingZeros(d.getMinutes()),
        this.padLeadingZeros(d.getSeconds()),
      ].join(':');

    return results;
  };

  render = () => {
    const formattedDate = this.getFormattedDate();

    return <div>{formattedDate}</div>;
  };
}

export default GriddleDateCell;
