import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackRightMPV extends Component {
  render() {
    return (
      <path
        className={s.doorBackRight}
        {...this.props}
        d="M792.7,227.1H610.5c-6.7-64-7.6-111-10.5-140c46,0,90,0,136,0c0,0,1.9,40.6,40.3,75   c16.4,14.6,25.7,27.5,22.1,42.9C796.6,212.6,794.7,220.1,792.7,227.1z"
      />
    );
  }
}

let composed = DoorBackRightMPV;
composed = SectionComponent({
  name: 'Back Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Driver Door Damage',
    content: <Modal name="Back Driver Door" />,
  },
})(composed);

export default composed;
