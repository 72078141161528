import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontLeftPickup extends Component {
  render() {
    return (
      <g className={s.wingFrontLeft}>
        <path
          {...this.props}
          d="M620.6,608.7L620.6,608.7l-73.3,0.3c-13-0.3-29.4,6.2-52.2,26c-13.6,11.8-41.6,36.2-52,44.6c-7.2,6.3-19.2,16-23.8,18.5  l-102.4,17.8c0,0-60.4,6.1-64.9,38.3c-1,7-13.2,8.2-13.2,17.5c0,0,0,27.6,0,29.3c0.5,4.4-2.8,40.1,33.2,41.2l19.4,0.3  c11-56,53-64.2,78-61c23.7,3,63.7,33.3,52.3,91.3L440,873c0,0-9-148.7,0.7-169.9c5.6-12.3,62.1-61.9,66.6-65.9  c15.5-13.6,31.1-18.2,39.6-18.5h73.7v212.2h13.6V618.5v-9.8H620.6L620.6,608.7z"
        />
        <path
          {...this.props}
          d="M620.6,608.7L620.6,608.7l-73.3,0.3c-13-0.3-29.4,6.2-52.2,26c-13.6,11.8-41.6,36.2-52,44.6   c-7.2,6.3-19.2,16-23.8,18.5l-102.4,17.8c0,0-60.4,6.1-64.9,38.3c-1,7-13.2,8.2-13.2,17.5c0,0,0,27.6,0,29.3   c0.5,4.4-2.8,40.1,33.2,41.2h3.8c0.8-45.7,37.5-82.7,82.5-82.7c37.4,0,69.1,25.3,79.2,60.3c-1.7-44.9-2.6-103.9,3.2-116.6   c5.6-12.3,62.1-61.9,66.6-65.9c15.5-13.6,31.1-18.2,39.6-18.5h73.7V831h13.6V618.5v-9.8H620.6L620.6,608.7z M290.3,737.8   c-2.3,3.3-3.8,7.4-5.1,11.1c-1.9,5.4-2.3,10.5-1.9,16.2h-25.5c0,0-0.4-8.5,1.2-13.9c1.7-5.9,6.7-11.1,10-13.7   c3.8-2.9,14.7-6.4,26.3-6.2C293.7,733.2,291.9,735.5,290.3,737.8z"
        />
      </g>
    );
  }
}

let composed = WingFrontLeftPickup;
composed = SectionComponent({
  name: 'Front Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Passenger Wing Damage',
    content: <Modal name="Front Passenger Wing" />,
  },
})(composed);

export default composed;
