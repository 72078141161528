import { Component } from 'react';
import PropTypes from 'prop-types';
import VehicleLookup from '../VehicleLookup';
import TriageFooter from '../TriageFooter';
import MajorDamage from '../MajorDamage';
import DamageTool from '../DamageTool';
import FurtherActions from '../FurtherActions/';
import Review from '../Review/';
import s from './MainPage.module.scss';
import withTriageSession from '../Decorators/withTriageSession';
import ProgressBar from '../ProgressBar/';
import TriageStore from '../../../stores/TriageStore';
import Location from '../../../core/Location';
import { baseUrl } from '../../../config';

class MainPage extends Component {
  state = {
    currentStage: null,
    currentCase: {},
    stages: {
      0: {
        name: 'Vehicle Lookup',
        elem: <VehicleLookup {...this.props} />,
        path: baseUrl + '/triage/lookup',
      },
      1: {
        name: 'Quick Damage Checks',
        elem: <MajorDamage {...this.props} />,
        path: baseUrl + '/triage/quickChecks',
      },
      2: {
        name: 'Assess Damage',
        elem: <DamageTool {...this.props} />,
        path: baseUrl + '/triage/assess',
      },
      3: {
        name: 'Further Actions',
        elem: <FurtherActions {...this.props} />,
        path: baseUrl + '/triage/furtherActions',
      },
      4: {
        name: 'Review',
        elem: <Review {...this.props} />,
        path: baseUrl + '/triage/review',
      },
    },
  };

  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  updateState = () => {
    const { stages } = this.state;

    let progress = TriageStore.getProgressState();
    let currentCase = TriageStore.getCase();
    this.context.onSetTitle(
      'Inter-cept | ' + stages[progress.CURRENT_STAGE].name
    );

    try {
      // Note - hack to get around that irritating promises error that's an
      //artifact of the way Location is used in the original boilerplate

      if (window)
        window.history.pushState(
          null,
          null,
          stages[progress.CURRENT_STAGE].path
        );
    } catch (error) {
      //default to the original, as it has polyfills for older browsers
      Location.push(stages[progress.CURRENT_STAGE].path);
    }

    this.setState({
      currentStage: stages[progress.CURRENT_STAGE].elem,
      currentCase: currentCase,
    });
  };

  UNSAFE_componentWillMount() {
    try {
      if (!this.props.isTriageUser) Location.pushState('/');
    } catch (e) {}
    this.setState({
      currentStage: this.state.stages[0],
    });
    TriageStore.addChangeListener(this.updateState);
    this.updateState();
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  render() {
    return this.props.isTriageUser ? (
      <div className={s.mainPage}>
        <ProgressBar
          stages={this.state.stages}
          recordTime={this.props.recordTime}
        />
        <div className={s.content}>{this.state.currentStage}</div>
        <TriageFooter case={this.state.currentCase} />
      </div>
    ) : (
      <div className={s.mainPage}>
        <h1>You are not permitted to view this page.</h1>
      </div>
    );
  }
}

let composed = MainPage;
composed = withTriageSession(composed);

export default composed;
