import React from 'react';
import s from './MIPieChart.module.scss';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

class MIPieChart extends React.Component {
  render() {
    const totalCases = this.props.totalCases;
    const pieChartData = this.props.pieChartData;

    const legendData = pieChartData.map((i, x) => {
      const percentageText =
        i.value > 0
          ? ' (' + Math.round(100 * (i.value / totalCases), 1) + '%)'
          : ' (0%)';
      return {
        value: i.name + ': ' + i.value + percentageText,
        color: this.props.colours[x],
        label: i.name,
      };
    });

    return (
      <div className={s.pieChart}>
        <PieChart width={400} height={240}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={100}
            innerRadius={40}
            outerRadius={80}
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={index}
                fill={this.props.colours[index % this.props.colours.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            payload={legendData}
            wrapperStyle={{
              paddingLeft: 20,
              color: 'white',
              fontSize: 'smaller',
              textTransform: 'capitalize',
            }}
          />
        </PieChart>
      </div>
    );
  }
}

export default MIPieChart;
