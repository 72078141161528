import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeft4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M445,833.6l1.8,19.1h292.6c0,0,0.3-2.4,1-11.6c0.7-9.2,0.7-7.6,0.7-7.6l-292.8,0.1H445z"
      />
    );
  }
}

let composed = SillLeft4x45d;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
