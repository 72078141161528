import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRight4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M447.2,84.8l257.1,0.1c0,0-1.3-2-1.1-4.5L703,65.7H445.8L444,84.8H447.2z"
      />
    );
  }
}

let composed = SillRight4x4;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
