import { Component } from 'react';

import s from './TriageReviewPage.module.scss';

class CaseStatus extends Component {
  render() {
    return (
      <div className={this.props.data === true ? s.closed : s.open}>
        {this.props.data === true ? 'CLOSED' : 'OPEN'}
      </div>
    );
  }
}

let composed = CaseStatus;

export default composed;
