import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenWind extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenWind)}
        d="M386.8,288.9c-23.2,36.8-29.5,73.7-29.5,173.8c0,88.2,5.2,125.5,21.6,158.9c5.9,11.8,12.9,23.6,15.7,25.7  c3.8,3.2,15,1.4,50.7-8.7c58-16.6,70.5-21.6,69.1-26.8c-12.9-53.2-12.2-252.3,1.1-300.3c1.8-5.9-90.4-28.6-114.7-37.3  C397.9,273.1,391.3,281.6,386.8,288.9z"
      />
    );
  }
}

let composed = ScreenWind;
composed = SectionComponent({
  name: 'Wind Screen',
  category: 'Screens',
  highlighted: false,
})(composed);

export default composed;
