import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontRightPickup extends Component {
  render() {
    return (
      <g className={s.wingFrontRight}>
        <path
          {...this.props}
          d="M620.5,309.5h13.6v-9.8V87.3h-13.6v212.2h-73.7c-8.5-0.3-24.1-4.9-39.6-18.5c-4.5-4-61-53.6-66.6-65.9  c-9.7-21.2-0.7-169.9-0.7-169.9l-18.3,0.2c11.4,58-28.6,88.3-52.3,91.3c-25,3.2-67-5-78-61L271.9,76c-36,1.1-32.7,36.8-33.2,41.2  c0,1.7,0,29.3,0,29.3c0,9.3,12.2,10.5,13.2,17.5c4.5,32.2,64.9,38.3,64.9,38.3l102.4,17.8c4.6,2.5,16.6,12.2,23.8,18.5  c10.4,8.4,38.4,32.8,52,44.6c22.8,19.8,39.2,26.3,52.2,26L620.5,309.5L620.5,309.5L620.5,309.5z"
        />
        <path
          {...this.props}
          d="M620.5,309.5h13.6v-9.8V87.2h-13.6v212.2h-73.7c-8.5-0.3-24.1-4.9-39.6-18.5c-4.5-4-61-53.6-66.6-65.9   c-5.8-12.7-4.9-71.7-3.2-116.6c-10.1,35-41.8,60.3-79.2,60.3c-45,0-81.7-37-82.5-82.7h-3.8c-36,1.1-32.7,36.8-33.2,41.2   c0,1.7,0,29.3,0,29.3c0,9.3,12.2,10.5,13.2,17.5c4.5,32.2,64.9,38.3,64.9,38.3l102.4,17.8c4.6,2.5,16.6,12.2,23.8,18.5   c10.4,8.4,38.4,32.8,52,44.6c22.8,19.8,39.2,26.3,52.2,26L620.5,309.5L620.5,309.5L620.5,309.5z M295.2,186.9   c-11.6,0.2-22.5-3.3-26.3-6.2c-3.3-2.6-8.3-7.8-10-13.7c-1.6-5.4-1.2-13.9-1.2-13.9h25.5c-0.4,5.7,0,10.8,1.9,16.2   c1.3,3.7,2.8,7.8,5.1,11.1C291.8,182.7,293.6,185,295.2,186.9z"
        />
      </g>
    );
  }
}

let composed = WingFrontRightPickup;
composed = SectionComponent({
  name: 'Front Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Driver Wing Damage',
    content: <Modal name="Front Driver Wing" />,
  },
})(composed);

export default composed;
