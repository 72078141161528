import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './TriageReviewPage.module.scss';
import Link from '../Link';
import Griddle from 'griddle-react';
import CaseSearch from './CaseSearch';
import withValidation from '../TriagePage/VehicleLookup/_withValidation.js';
import triageAPI from '../../api/triageAPI';
import CasesReviewConfig from './CasesReviewConfig';
import AppActions from '../../actions/AppActions';
import OrganisationActions from '../../actions/OrganisationActions';
import AppStore from '../../stores/AppStore';
import dateFormat from 'dateformat';

const title = 'Inter-cept | Review Triage Cases';

class TriageReviewPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  state = {
    registration: '',
    isRegValid: false,
    isNonUKReg: false,
    errorText: '',
    caseData: [],
  };

  UNSAFE_componentWillMount() {
    OrganisationActions.getBusinessLinesForInsurer(
      AppStore.userData().User.InsurerId
    );
    this.context.onSetTitle(title);
  }

  onRegistrationChange = (e) => {
    this.setState(
      {
        registration: e.target.value.replace(/ /g, '').toUpperCase(),
      },
      () => {
        this.props.validateRegistration(this);
      }
    );
  };

  onRegistrationSearch = () => {
    AppActions.addSpinner();
    triageAPI.getCaseReviews(this.state.registration, (response) => {
      response.map((item) => {
        if (item.DateCreated) {
          item.DateCreated = dateFormat(item.DateCreated, 'fullDate');
        }

        item.CaseData = JSON.stringify({
          id: item.CaseId,
          altId: item.AltId,
          status: item.IsComplete === true ? 'CLOSED' : 'OPEN',
        });
      });
      this.setState({
        caseData: response,
      });
      AppActions.removeSpinner();
    });
  };

  onToggleUKPlate = () => {
    this.setState({
      isNonUKReg: this.state.isNonUKReg ? false : true,
    });
  };

  render() {
    const registrationError =
      this.state.registration === '' || this.state.isRegValid
        ? null
        : 'invalid uk license plate';

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.box}>
            <ul className={s.backLinks}>
              <li>
                <a href="/dashboard" onClick={Link.handleClick}>
                  Back to Dashboard
                </a>
              </li>
            </ul>
            <h1 className={s.heading}>Review Triage Cases</h1>
            <CaseSearch
              styles={s}
              isRegValid={this.state.isRegValid}
              registration={this.state.registration}
              errorText={registrationError}
              isNonUKReg={this.state.isNonUKReg}
              onRegistrationChange={this.onRegistrationChange}
              onToggleUKPlate={this.onToggleUKPlate}
              onSearch={this.onRegistrationSearch}
            />
            <h2 style={{ marginTop: '-20px' }}>
              Results: {this.state.caseData.length} found
            </h2>
            <Griddle
              className={s.grid}
              results={this.state.caseData}
              useFixedHeader={true}
              resultsPerPage={10}
              tableClassName={s.table}
              showFilter={true}
              enableInfiniteScroll={false}
              columnMetadata={CasesReviewConfig.columnMetadata}
              metadataColumns={CasesReviewConfig.ignoreColumns}
              showSettings={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

let composed = TriageReviewPage;
composed = withValidation(composed);

export default composed;
