import { Component } from 'react';
import PropTypes from 'prop-types';
import s from '../PermissionsPage.module.scss';

import Griddle from 'griddle-react';
import Link from '../../Link';
import UserStore from '../../../stores/UserStore';
import AppActions from '../../../actions/AppActions';
import withLoggedInUser from '../../../decorators/withLoggedInUser';

const title = 'Inter-cept | Permissions | Roles';

class RolesPage extends Component {
  state = {
    data: [],
    currentUser: null,
  };

  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.setState(
      {
        currentUser: this.props.currentUser,
      },
      () => {
        if (!this.state.currentUser.isAdmin) {
          Location.pushState('/');
        }
      }
    );
    this.context.onSetTitle(title);
    AppActions.addSpinner();
    UserStore.getAllRoleData((response) => {
      const roleData = Object.keys(response).map((item, i) => {
        return { Role: item, 'User Count': response[item] };
      });
      roleData.shift();
      this.setState(
        {
          data: roleData,
        },
        () => AppActions.removeSpinner()
      );
    });
  }

  render() {
    return (
      <div className={s.box}>
        <ul className={s.backLinks}>
          <li>
            <a href="/dashboard" onClick={Link.handleClick}>
              Back to Dashboard
            </a>
          </li>
        </ul>
        <h1 className={s.heading}>Roles</h1>
        <div className={s.grid}>
          <Griddle
            className={s.grid}
            results={this.state.data}
            useFixedHeader={true}
            resultsPerPage={100}
            tableClassName={s.table}
            enableInfiniteScroll={false}
            columns={['Role', 'User Count']}
          />
        </div>
      </div>
    );
  }
}

let composed = withLoggedInUser(RolesPage);

export default composed;
