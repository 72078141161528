import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeft4x45d extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M993.1,723.4c-1-15-3.1-19.2-5.6-24.5c-5.3-10.7-51.9-68.6-70.1-83.7c-2.5-2.1-11.3-7-14.5-7.1  c-30.3-0.5-165.6-0.3-182.1-0.3c-0.8,0-42.7,0.1-42.7,0.1l-2.4,10.5l83.6,1.3c10.8,0.7,15.3,11.5,26.2,29.6  c-0.8-1.6,31.2,53.2,32.1,58c0.5,2.9-0.5,19.4-1,28.9c-0.6,10.4-1.9,21.4-2,22.7c0,0-7.6-0.3-15,0.1c-37.9,2.2-67.1,34.1-76.6,72.1  h17.9c0.8,0,1.5-1.5,1.7-2.2c10.9-29.7,30.5-41.8,48.1-45.8h0.1c8.5-3,17-3.5,25.1-2.3c27.8,5.2,52.7,29,58.2,59.2  c0.2,0.9,1,1.4,1.9,1.3c11.9-0.8,76.3-5.2,76.3-5.2s33.6-2.5,40.8-47.7c0-0.3,0.1-0.6,0.1-0.9v-64  C993,723.4,993.1,723.4,993.1,723.4z M950.7,693h-75.6c-8.9,0-70-68.6-70-68.6c-3.9-4.1,3.8-4.2,5.2-4.2l75.3-0.3  c5.8,0,57.6,57.9,66.5,70.1C953,691.2,952.2,693,950.7,693z"
        />
        <path
          {...this.props}
          d="M994.1,723.3c-1-15-3.6-19.2-6.1-24.5c-0.7-1.5-2.3-4-4.5-7.1c0,0,0,0.1-0.1,0.1c-1.8-2.6-4.1-5.7-6.6-9.1  c0,0,0-0.1,0.1-0.1c-16.4-21.6-45.6-56.3-59.1-67.5c-2.5-2.1-11.5-7-14.7-7.1c-30.3-0.5-165.7-0.3-182.2-0.3  c-0.8,0-42.8,0.1-42.8,0.1l-2.4,10.5l83.6,1.3c8.8,0.1,14.8,9.8,26.2,29.6c0.6,1.3,31.3,53.3,32.1,58c0.5,2.9-0.5,19.4-1,28.9  c-0.6,10.4-1.9,21.4-2,22.7c0,0-7.6-0.3-15,0.1c-37.9,2.2-67.1,34.1-76.6,72.1h17.9c0.8,0,1.5-1.5,1.7-2.2  c10.9-29.7,30.5-41.8,48.1-45.8h0.1c8.5-3,17-3.5,25.1-2.3c27.8,5.2,52.7,29,58.2,59.2c0.2,0.9,1,1.4,1.9,1.3  c4.3-0.3,15.6-1.1,28.1-2c0-0.1,0-0.2,0-0.3c3.3-0.2,6.7-0.5,10.1-0.7c0,0.1,0,0.1,0,0.2c19.2-1.3,38.1-2.6,38.1-2.6  s34.6-2.5,41.8-47.7c0-0.3,1.1-0.5,1.1-0.8v-64C995,723.3,994.1,723.3,994.1,723.3z M990.7,713.7c0.6,2.1,0.9,7.9,1.1,10.9  c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0.7,0.1,1.1,0.1,1.2v26.5c0,0.7-0.6,2.7-1.1,3.2c-0.3,0.3-1,1.9-1,1.9H971  c0,0,0-2.8,0-2.9v-34.7c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0l7.6-1.9c2.8-0.8,9.9-2.8,11.6-5.3c0-0.1,0.1-0.1,0.1-0.1  C990.5,713.2,990.6,713.4,990.7,713.7z M819.7,640.3c-8.3-8.9-14-15.8-14-15.8c-3.9-4.1,3.8-4.3,5.2-4.3l60.1-0.3l14.7-0.1  c5.8,0,57.6,58,66.5,70.2c0.9,1.1,0.1,3-1.4,3h-75.6C869,693,838.4,660.3,819.7,640.3z M994,780v-1H870v-2h124v-1.7  C994,776.8,994,778.6,994,780z"
        />
      </g>
    );
  }
}

let composed = WingBackLeft4x45d;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
