import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRightPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M617.6,95.1l0,114.2l-175.7,0.2c-2.6-10.4-1.6-21.9-2-36.8c-0.6-25.5-0.2-41.8,0.5-58.7c0.3-7,1.7-26.3,1.7-26.3h175.5  C617.6,87.7,617.5,91,617.6,95.1z"
      />
    );
  }
}

let composed = DoorFrontRightPickup;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
