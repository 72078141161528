import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRightVan extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M692.6,16.9H374c-2,0-6.9,2-6.9,4.6L366,42.9h335.4l-1.5-22.3C699.9,18.3,694.6,16.9,692.6,16.9z"
      />
    );
  }
}

let composed = SillRightVan;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
