import { Component } from 'react';
import s from './ModalActions.module.scss';
import TriageStore from '../../../../stores/TriageStore';

class ModalActions extends Component {
  state = {
    title: '',
    length: 0,
    modalModel: {},
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      title: this.props.title,
      length: this.props.length,
      modalModel: TriageStore.getCurrentModal(),
    });
  };

  render() {
    const content =
      this.state.modalModel.currentStage + 1 !== this.state.length
        ? this.state.title
        : 'Part Damage Questions';

    return <div className={s.actionTitle}>{content}</div>;
  }
}

export default ModalActions;
