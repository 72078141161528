import dateFormat from 'dateformat';
import { listViewCSS } from './_config';

export const getPDFData = (refs, dateRange) => {
  const title =
    'TRIAGE STATISTICS | ' +
    dateFormat(dateRange.minDate, 'fullDate') +
    '-' +
    dateFormat(dateRange.maxDate, 'fullDate');
  const data = {
    HtmlMarkup: refs.report.innerHTML,
    Name: title,
    Title: title,
    CssStyles: listViewCSS,
  };
  return data;
};

const getCSVTableArray = (dateRange, arr) => {
  return arr.map((item) => {
    let data = {
      Category: item.key,
      'Start Date': dateFormat(dateRange.minDate),
      'End Date': dateFormat(dateRange.maxDate),
    };
    Object.keys(item.values).map((key) => {
      data[key] = item.values[key];
    });
    return data;
  });
};

export const getExcelData = (categories, props, state) => {
  const { dateRange, displayMode } = state;
  let displayData;
  if (displayMode === 'list') {
    displayData = getCSVTableArray(
      dateRange,
      props.listViewData.filter((i) => {
        return categories.includes(i.key);
      })
    );
    //console.log("displayData",displayData)
  } else {
    displayData = props.itemViewData[state.selectedCategory];
  }
  const data = {
    data: displayData,
    name:
      'TRIAGE STATISTICS | ' +
      dateFormat(dateRange.minDate, 'fullDate') +
      '-' +
      dateFormat(dateRange.maxDate, 'fullDate'),
  };
  //console.log(data)
  return data;
};
