import { Component } from 'react';
import s from './DoorsQuestions.module.scss';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import QuestionComponent from '../_questionsComponent/';
import TriageActions from '../../../../../actions/TriageActions';

const CURRENT_QUESTION = 'doors';

class DoorsQuestions extends Component {
  state = {
    radio: null,
  };

  UNSAFE_componentWillMount() {
    const { sectionDamage } = this.props;
    this.setState({
      radio: sectionDamage ? sectionDamage.Options['0'] : 0,
    });
  }

  onChangeHandler = (e) => {
    TriageActions.registerSectionDamage({
      Name: CURRENT_QUESTION,
      Options: [e.target.value],
    });
  };

  render() {
    const { radio } = this.state;
    const mainAnswer = radio === null ? '' : radio.toString();

    return (
      <form className={s.formItem} onChange={this.onChangeHandler}>
        <h4>
          Are you having difficulty closing the doors, bonnet, boot or tailgate?
        </h4>
        <div>
          <RadioButtonGroup
            className={s.radios}
            name="doors"
            defaultSelected={mainAnswer}
            labelPosition="left"
          >
            <RadioButton value="1" label="Yes" />
            <RadioButton value="0" label="No" />
          </RadioButtonGroup>
        </div>
      </form>
    );
  }
}

let composed = DoorsQuestions;
composed = QuestionComponent(CURRENT_QUESTION)(composed);

export default composed;
