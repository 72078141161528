import { Component } from 'react';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

export default class CaseSearch extends Component {
  render() {
    const nonUKBtn =
      this.props.isNonUKReg === true
        ? this.props.styles.selectedNonUKBtn
        : this.props.styles.nonUKBtn;
    const invalidSearch =
      (this.props.registration === '' || this.props.isRegValid === false) &&
      this.props.isNonUKReg === false;
    return (
      <div className={this.props.styles.caseSearch}>
        <div className={this.props.styles.searchRow}>
          <div style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            Search Cases by Registration:
          </div>
          <div>
            <TextField
              className={this.props.styles.textBox}
              floatingLabelText="enter registration"
              value={this.props.registration}
              autoComplete="off"
              errorText={this.props.errorText}
              onChange={this.props.onRegistrationChange}
            />
          </div>
          <div>
            <FlatButton
              primary={true}
              className={nonUKBtn}
              label="not a UK licence plate"
              onClick={this.props.onToggleUKPlate}
            />
          </div>
          <div>
            <RaisedButton
              disabled={invalidSearch}
              primary={true}
              className={this.props.styles.searchBtn}
              label="search"
              onClick={this.props.onSearch}
            />
          </div>
        </div>
      </div>
    );
  }
}
