import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackRightMPV extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackRight)}>
        <path
          {...this.props}
          d="M791.5,231.1c-8.9,31-19.7,57.7-31.9,88.9l-136.1,1.1c-5.6-32.8-9.6-63-12.5-90H791.5z"
        />
        <path
          {...this.props}
          d="M880,245l-27.6,67.6c-2.7,7-10.1,9.5-17.2,9.5h-56.6l32.4-89h28.1h32.6C878.1,233.1,882.4,238.7,880,245z"
        />
      </g>
    );
  }
}

let composed = WindowBackRightMPV;
composed = SectionComponent({
  name: 'Back Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
