import { Component } from 'react';
import s from './ExhaustDamageQuestions.module.scss';
import QuestionComponent from '../_questionsComponent/';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TriageActions from '../../../../../actions/TriageActions';

const CURRENT_QUESTION = 'exhaustdamage';

class ExhaustDamageQuestions extends Component {
  state = {
    radio: null,
  };

  UNSAFE_componentWillMount() {
    const { sectionDamage } = this.props;
    this.setState({
      radio: sectionDamage ? sectionDamage.Options['0'] : 0,
    });
  }

  onChangeHandler = (e) => {
    TriageActions.registerSectionDamage({
      Name: CURRENT_QUESTION,
      Options: [e.target.value],
    });
  };

  render() {
    const { radio } = this.state;
    const mainAnswer = radio === null ? '' : radio.toString();

    return (
      <form className={s.formItem} onChange={this.onChangeHandler}>
        <h4>Is the exhaust damaged / detached from the vehicle?</h4>
        <div>
          <RadioButtonGroup
            className={s.radios}
            name={CURRENT_QUESTION}
            defaultSelected={mainAnswer}
            labelPosition="left"
          >
            <RadioButton value="1" label="Yes" />
            <RadioButton value="0" label="No" />
          </RadioButtonGroup>
        </div>
      </form>
    );
  }
}

let composed = ExhaustDamageQuestions;
composed = QuestionComponent(CURRENT_QUESTION)(composed);

export default composed;
