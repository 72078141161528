import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontRightVan extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontRight}
        d="M559.5,246c-1.9-11.7-8.1-49.4-11.2-63.1c-0.5-2.1-2.3-3.6-4.5-3.6L410.1,179c-2.4,0-4.4-1.9-4.6-4.3   c-2-33.6-2.4-99.2,2.4-125.5c0.4-2.2,2.3-3.8,4.5-3.8l140.9,0c2.5,0,4.5,2,4.6,4.5c0.5,20.4,2.8,104.4,4.1,123.4   c1.8,26.3,2.6,47,7.3,72.6H559.5z"
      />
    );
  }
}

let composed = DoorFrontRightVan;
composed = SectionComponent({
  name: 'Front Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Driver Door Damage',
    content: <Modal name="Front Driver Door" />,
  },
})(composed);

export default composed;
