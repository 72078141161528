const Valuation = (props) => {
  const s = props.styles;
  const title = props.isCustom ? 'Custom Retail Value' : 'Typical Retail Value';
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>{title}: </span>
      <strong className={s.cols_6}>
        £{parseInt(props.retailPrice || 0).toLocaleString()}
      </strong>
    </li>
  );
};

export default Valuation;
