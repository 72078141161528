import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeft4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M647.7,622.7c-8.7,0.2-69-0.4-101.3,0.4c0,0-9.6,0.9-14.3,4.5c0,0-8.3,5.1-8.4,5.2c-8.7,6-67.8,59.6-75.6,72.5  c-0.4,0.6-0.1,1.4,0.6,1.4h189.9c0.4,0,0.7-0.3,0.8-0.7l8.3-80.6C647.7,624.9,648.2,622.7,647.7,622.7z"
      />
    );
  }
}

let composed = WindowFrontLeft4x45d;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
