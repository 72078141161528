import { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import s from './QuickDamageCheckAirbags.module.scss';
import TriageActions from '../../../../actions/TriageActions';
import AppActions from '../../../../actions/AppActions';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Checkbox from 'material-ui/Checkbox';

class QuickDamageCheckAirbags extends Component {
  state = {
    showAirbagCheckboxes: false,
    disableSubmitButton: true,
    fDriversTicked: false,
    fPassengersTicked: false,
    sDriversTicked: false,
    sPassengersTicked: false,
  };

  UNSAFE_componentWillMount() {
    const { existingAirbagData } = this.props;
    if (existingAirbagData && existingAirbagData.length > 0) {
      this.setState({ showAirbagCheckboxes: true });
      existingAirbagData.forEach((section) => {
        if (section.area === 'side') {
          this.setState({
            sDriversTicked: section.drivers,
            sPassengersTicked: section.passengers,
          });
        }
        if (section.area === 'front') {
          this.setState({
            fDriversTicked: section.drivers,
            fPassengersTicked: section.passengers,
          });
        }
      });
    }
  }

  handleAirbagsChange = (e) => {
    this.setState(
      { showAirbagCheckboxes: e.target.value === 'yes' ? true : false },
      () => {
        const { showAirbagCheckboxes } = this.state;
        this.props.onYesNoChange(showAirbagCheckboxes);
        if (!showAirbagCheckboxes) {
          //No airbags, reset them all in the backend
          AppActions.addSpinner();

          const payload = [
            this.createSectionDataForUpload('airbagsfront', false, false),
            this.createSectionDataForUpload('airbagsside', false, false),
          ];

          TriageActions.registerMultipleSectionDamage(
            payload.map((item) => {
              item.Options = ['0']; //Set the model to not be pending
              return item;
            }),
            (response) => {
              this.setState(
                {
                  fDriversTicked: false,
                  fPassengersTicked: false,
                  sDriversTicked: false,
                  sPassengersTicked: false,
                },
                () => {
                  TriageActions.makeDecision(() => {
                    TriageActions.addCompletedQuestionSets(
                      ['airbagsfront', 'airbagsside'],
                      () => {
                        AppActions.removeSpinner();
                      }
                    );
                  });
                }
              );
            }
          );
        }
      }
    );
  };

  handleAirbagSelection = (e) => {
    const isTicked = this.state[e.target.name + 'Ticked'];
    this.setState(
      {
        [e.target.name + 'Ticked']: !isTicked,
        disableSubmitButton: false,
      },
      () => {
        this.props.setContinue(false);
      }
    );
  };

  createSectionDataForUpload = (sectionName, driversSide, passengersSide) => {
    const airbagsSelected = driversSide || passengersSide;

    if (airbagsSelected) {
      let options = [];
      if (driversSide) options.push('1');
      if (passengersSide) options.push('0');

      return {
        Name: sectionName,
        Options: ['1'], //TODO: Should this always be 1?
        SubQuestion: {
          Name: sectionName,
          Options: options,
        },
      };
    } else {
      return {
        Name: sectionName,
        Options: ['0'], //leave the question open/pending
        SubQuestion: undefined,
      };
    }
  };

  submitAirbags = () => {
    const {
      fDriversTicked,
      fPassengersTicked,
      sDriversTicked,
      sPassengersTicked,
    } = this.state;

    const payload = [
      this.createSectionDataForUpload(
        'airbagsfront',
        fDriversTicked,
        fPassengersTicked
      ),
      this.createSectionDataForUpload(
        'airbagsside',
        sDriversTicked,
        sPassengersTicked
      ),
    ];
    AppActions.addSpinner();
    this.setState({ disableSubmitButton: true }, () => {
      TriageActions.registerMultipleSectionDamage(payload, () => {
        TriageActions.makeDecision(() => {
          this.props.setContinue(
            fDriversTicked ||
              fPassengersTicked ||
              sDriversTicked ||
              sPassengersTicked
          );
          AppActions.removeSpinner();
        });
      });
    });
  };

  render() {
    const {
      showAirbagCheckboxes,
      fDriversTicked,
      fPassengersTicked,
      sDriversTicked,
      sPassengersTicked,
      disableSubmitButton,
    } = this.state;
    const radioButtonChoice = showAirbagCheckboxes ? 'yes' : 'no';
    const airbagCheckBoxes = showAirbagCheckboxes ? (
      <div className={s.inlineCheckboxContainer}>
        <Checkbox
          className={s.inlineCheckbox}
          label="Driver's Front Airbag"
          checked={fDriversTicked}
          name="fDrivers"
          onClick={this.handleAirbagSelection.bind(this)}
        />
        <Checkbox
          className={s.inlineCheckbox}
          label="Passenger's Front Airbag"
          checked={fPassengersTicked}
          name="fPassengers"
          onClick={this.handleAirbagSelection.bind(this)}
        />
        <Checkbox
          className={s.inlineCheckbox}
          label="Driver's Side/Curtain Airbag"
          checked={sDriversTicked}
          name="sDrivers"
          onClick={this.handleAirbagSelection.bind(this)}
        />
        <Checkbox
          className={s.inlineCheckbox}
          label="Passenger's Side/Curtain Airbag"
          checked={sPassengersTicked}
          name="sPassengers"
          onClick={this.handleAirbagSelection.bind(this)}
        />
        <RaisedButton
          label="Submit"
          disabled={disableSubmitButton}
          primary={true}
          onClick={this.submitAirbags}
          type="submit"
        />
      </div>
    ) : null;

    return (
      <div>
        <div className={s.radiosInlineOuterContainer}>
          <div className={s.radiosInlineLabel}>
            <strong>Have any airbags deployed?</strong>
          </div>
          <div className={s.radiosInlineInnerContainer}>
            <RadioButtonGroup
              className={s.radiosInline}
              ref="airbags"
              name="airbags"
              id="airbags"
              onChange={this.handleAirbagsChange}
              defaultSelected={radioButtonChoice.toString()}
            >
              <RadioButton
                name="yes"
                value="yes"
                label="Yes"
                id="yes"
                style={{ display: 'inline-block' }}
              />
              <RadioButton
                name="no"
                value="no"
                label="No"
                id="no"
                style={{ display: 'inline-block' }}
              />
            </RadioButtonGroup>
          </div>
        </div>
        {airbagCheckBoxes}
      </div>
    );
  }
}

export default QuickDamageCheckAirbags;
