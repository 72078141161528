import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftMPV extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M960.6,756.4l-0.6-0.3l1.6-58.6c0.3-11-2-21.9-6.6-31.9l-28.3-61.2c-4.3-9.3-13.6-15.3-23.9-15.3L536,589l0,5.5  l144.2,0.4c3.8,0,63.6,0,63.6,0l15.8,0.1c16.4,40.3,28.4,76.8,38.9,119.3c3.8,15.6-7.4,30.8-19.8,43c-4.4,4.4-32,23.2-42,70.8  c-0.3,1.2-0.6,2.5-0.6,3.8l0,0.1h11.5c5-46.8,39.6-77.1,82.3-75c9,0.4,18.2,2.5,26.2,6c27.6,11.5,49.4,38.4,50.1,73.5  c0.1,4.3-0.3,8.4-0.9,12.5h2.1l0,0.4l34.8-4.3c9.4-1.2,17.7-6.6,22.5-14.8c0.9-1.5,1.4-3.3,1.4-5.1V765  C966,761.3,963.9,758,960.6,756.4z M868.9,688.1H804v-0.2l-33-92.8h61.7c7.5,0,14.2,4.5,17,11.4l28,68.5  C880.2,681.3,875.6,688.1,868.9,688.1z"
        />
        <path
          {...this.props}
          d="M960.6,756.4l-0.6-0.3l1.6-58.6c0.3-11-2-21.9-6.6-31.9l-28.3-61.2c-4.3-9.3-13.6-15.3-23.9-15.3L536,589l0,5.5  l144.2,0.4c3.8,0,63.6,0,63.6,0l15.8,0.1c16.4,40.3,28.4,76.8,38.9,119.3c3.8,15.6-7.4,30.8-19.8,43c-4.4,4.4-32,23.2-42,70.8  c-0.3,1.2-0.6,2.5-0.6,3.8l0,0.1h11.5c5-46.8,39.6-77.1,82.3-75c9,0.4,18.2,2.5,26.2,6v-0.9h109.5  C964.7,760.1,963,757.6,960.6,756.4z M868.9,688.1H804v-0.2l-33-92.8h61.7c7.5,0,14.2,4.5,17,11.4l28,68.5  C880.2,681.3,875.6,688.1,868.9,688.1z M955,751.1h-23v-49l20.5,2.9c2.6,0.4,4.4,2.6,4.3,5.2L955,751.1z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftMPV;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
