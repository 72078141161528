import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontRight4x4 extends Component {
  render() {
    return (
      <g className={s.wingFrontRight}>
        <path
          {...this.props}
          d="M671,298.3L550,298.8c-8.5-0.3-24.1-4.9-39.6-18.5c-4.5-4-61.1-53.5-66.6-65.9c-9.4-21.1-3.7-149.7-3.7-149.7L423.9,65  c-1.7,42.3-30.7,64.2-49.5,68.5c-25,5.8-71.9-2.2-82.9-58.2H275c-36,1.1-32.7,36.8-33.2,41.2c0,1.7,0,29.3,0,29.3  c0,9.3,12.2,10.5,13.2,17.5c4.5,32.2,64.9,38.3,64.9,38.3l102.4,17.8c4.6,2.5,16.6,12.2,23.8,18.5c10.4,8.4,38.4,32.8,52,44.6  c22.8,19.8,39.2,26.3,52.2,26l123.4,0.3L671,298.3z"
        />
        <path
          {...this.props}
          d="M671.1,298.4l-121.2,0.4c-8.5-0.3-24.1-4.9-39.6-18.5c-4.5-4-61-53.6-66.6-65.9c-5.8-12.7-5.2-71.9-4.3-117   c-10.1,35-40.7,60.7-78.1,60.7c-45,0-81.7-37-82.5-82.7H275c-36,1.1-32.7,36.8-33.2,41.2c0,1.7,0,29.3,0,29.3   c0,9.3,12.2,10.5,13.2,17.5c4.5,32.2,64.9,38.3,64.9,38.3l102.4,17.8c4.6,2.5,16.6,12.2,23.8,18.5c10.4,8.4,38.4,32.8,52,44.6   c22.8,19.8,39.2,26.3,52.2,26l123.5,0.3L671.1,298.4z M298.3,186.2c-11.6,0.2-22.5-3.3-26.3-6.2c-3.3-2.6-8.3-7.8-10-13.7   c-1.6-5.4-1.2-13.9-1.2-13.9h25.5c-0.4,5.7,0,10.8,1.9,16.2c1.3,3.7,2.8,7.8,5.1,11.1C294.9,182,296.7,184.3,298.3,186.2z"
        />
      </g>
    );
  }
}

let composed = WingFrontRight4x4;
composed = SectionComponent({
  name: 'Front Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Driver Wing Damage',
    content: <Modal name="Front Driver Wing" />,
  },
})(composed);

export default composed;
