import React from 'react';
import s from '../TriageStatsPage.module.scss';
import Griddle from 'griddle-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const KEY = 'Date';

const ResultView = (props) => {
  return (
    <div className={s.resultView}>
      <a href="/triageStats" onClick={(e) => props.clickHandler(e, null)}>
        Back to List View
      </a>
      <h2>
        {props.selectedCategory} -{' '}
        {props.productLines[props.filterIndex] || 'All'}
      </h2>
      <BarChart
        width={props.graphWidth}
        height={props.graphHeight}
        data={props.data}
        barSize={props.graphWidth > 600 ? 10 : 2}
        margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
      >
        <XAxis dataKey={KEY} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {Object.keys(props.data ? props.data[0] || [] : [])
          .filter((item) => {
            return item !== KEY;
          })
          .map((item, i) => {
            return (
              <Bar
                isAnimationActive={props.isAnimationActive || false}
                animationDuration={300}
                key={i}
                dataKey={item}
                unit={props.unit || null}
                fillOpacity={
                  props.canFilter === false
                    ? 0.9
                    : props.filterIndex === null
                    ? 0.9
                    : props.filterIndex === i
                    ? 0.9
                    : 0.1
                }
                fill={props.colors ? props.colors[i] || '#ccc' : '#ccc'}
              />
            );
          })}
      </BarChart>
      <Griddle
        className={s.grid}
        results={props.data}
        columnMetadata={props.griddleConfig}
        useFixedHeader={true}
        tableClassName={s.table}
        showFilter={false}
        fullWidth={true}
        enableInfiniteScroll={false}
        showSettings={false}
        resultsPerPage={12}
      />
    </div>
  );
};

export default ResultView;
