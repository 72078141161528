import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M707,635.1c0.2,0-91.9-4.7-117,2.8c-9.7,3-26.4,7.7-114.5,81.1h221L707,635.1z"
      />
    );
  }
}

let composed = WindowFrontLeftConvertible;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
