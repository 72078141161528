import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightPV extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M966.6,162.7L966,163l1.6,58.6c0.3,11-2,21.9-6.6,31.9l-28.3,61.2c-4.3,9.3-13.6,15.3-23.9,15.3L542,330.1v-5.5  l144.2-0.4c3.8,0,6.8-3.1,6.8-6.8V87h0.1H732h21.5c5,46.8,39.6,77.1,82.3,75c38.6-1.9,75.4-33.7,76.4-79.5c0.1-4.3-0.3-8.4-0.9-12.5  h2.1v-0.4l34.8,4.3c9.4,1.2,17.7,6.6,22.5,14.8c0.9,1.5,1.4,3.3,1.4,5.1v60.3C972,157.8,969.9,161.1,966.6,162.7z"
        />
        <path
          {...this.props}
          d="M971.5,157H862v-0.9c-8.4,3.5-17.2,5.5-26.2,6c-42.7,2.1-77.3-28-82.3-75H732h-38.9H693v230.3  c0,3.8-3.1,6.8-6.8,6.8L542,324.6v5.5l366.8-0.1c10.3,0,19.6-6,23.9-15.3l28.3-61.2c4.6-10,6.9-20.9,6.6-31.9L966,163l0.6-0.3  C969,161.5,970.7,159,971.5,157z M961,213.5l-23,3.5v-49h23V213.5z"
        />
      </g>
    );
  }
}

let composed = WingBackRightPV;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
