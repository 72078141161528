import { Component } from 'react';
import s from './DamageTool.module.scss';

import withViewport from '../../../decorators/withViewport';
import QuestionsList from './QuestionsList/';
import SelectedVehicle from './SelectedVehicle/';
import VehicleSelector from './VehicleSelector/';
import SelectionList from './SelectionList/';
import { Tabs, Tab } from 'material-ui/Tabs';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import TriageStore from '../../../stores/TriageStore';
import TriageActions from '../../../actions/TriageActions';
import VehicleData from './VehicleData';
import AppActions from '../../../actions/AppActions';
import LockActions from '../../../actions/LockActions';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import PrevIcon from 'material-ui/svg-icons/image/navigate-before';
import ResetIcon from 'material-ui/svg-icons/content/clear';
import ReactDOM from 'react-dom';
import canvg from 'canvg-browser';
import AdditionalCaseInformation from '../MajorDamage/AdditionalCaseInformation';

class DamageTool extends Component {
  state = {
    toolKey: 0,
    partsList: [],
    damageModel: [],
    modalModel: {},
    decisionModel: {},
    vehicleName: '',
    disableNext: true,
    selectedIndex: -1,
    direction: 0,
    waitingForServer: [],
  };

  gotoPrevious = (e) => {
    e.preventDefault();
    let newState = TriageStore.getProgressState();
    newState.CURRENT_STAGE = 1;
    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  UNSAFE_componentWillMount() {
    //TriageActions.resetParts();
    const currentCase = TriageStore.getCase();
    //Disallow incoming requests to page if there are criticals flagged and redirect
    if (currentCase.criticals.length > 0) {
      let newState = TriageStore.getProgressState();
      newState.CURRENT_STAGE = 1;
      TriageActions.setProgressState(newState);
      return;
    }
    if (currentCase.splat != '')
      TriageActions.setVehicleModel(currentCase.splat);

    const vehicleName = TriageStore.getVehicleModel();
    this.setState({
      damageModel: TriageStore.getPartDamage(),
      showManualSelector:
        vehicleName === '' || vehicleName === null ? true : false,
      openConfirm: false,
      disableNext: true,
      showErrorPrompt:
        vehicleName === '' || vehicleName === null ? true : false,
    });

    TriageActions.makeDecision();
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
    TriageStore.addHoverListener(this.updateHover);
  }

  componentWillUnmount() {
    this.saveImage();
    TriageStore.removeChangeListener(this.updateState);
    TriageStore.removeHoverListener(this.updateHover);
  }

  updateState = () => {
    this.setState(
      {
        damageModel: TriageStore.getPartDamage(),
        partsList: TriageStore.getAllParts() || [],
        modalModel: TriageStore.getCurrentModal(),
        disableNext: true,
        decisionModel: {
          open: false,
          decision: {},
        },
        vehicles: VehicleData,
        vehicleName: TriageStore.getVehicleModel()
          ? TriageStore.getVehicleModel()
          : this.state.vehicleName,
      },
      () => {
        const decision = TriageStore.getDecision();
        const direction = decision === null ? 0 : decision.Direction;
        this.setState({
          disableNext:
            direction === undefined ||
            direction === 0 ||
            this.state.showManualSelector,
        });
      }
    );
  };

  updateHover = () => {
    this.setState({
      partsList: TriageStore.getAllParts() || [],
    });
  };

  handleSelect = (key) => {
    if (this.state.selectedIndex === key) {
      return;
    }

    this.setState(
      {
        selectedIndex: key,
      },
      () => {
        TriageActions.resetParts();
        this.setState({
          vehicleName: this.getCurrentVehicle().name,
        });
      }
    );
  };

  handleSubmit = () => {
    this.setState({
      openConfirm: true,
    });
  };

  handleNoneOfTheAbove = () => {
    AppActions.createModal({
      open: true,
      title: 'Body Type Not Supported',
      type: 'confirm',
      content: (
        <div>
          <p>
            You have selected an unsupported body type, you cannot continue with
            this case.
          </p>
          <p>
            Are you sure you want to close the case? Click Cancel to select
            another body type.
          </p>
        </div>
      ),
      onConfirm: () => {
        const currentCase = TriageStore.getCase();
        const thisCase = Object.assign(
          { caseId: null },
          { caseId: currentCase.caseId }
        );
        const isEmbed = currentCase.isEmbed;
        TriageActions.resetCompletedQuestionSets();
        TriageActions.resetProgress();
        TriageActions.resetCase();
        LockActions.unlockCase(thisCase);
        AppActions.addSpinner();
        setTimeout(() => {
          isEmbed
            ? window.parent.postMessage('{ "action" : "reset" }', '*')
            : document.location.reload(true);
        }, 600);
      },
    });
  };

  confirmSelection = () => {
    this.setState({
      openConfirm: false,
      showManualSelector: false,
    });
    TriageActions.setVehicleModel(this.getCurrentVehicle().name, true);
  };

  getCurrentVehicle = () => {
    for (let i in VehicleData) {
      if (VehicleData[i].key === this.state.selectedIndex) {
        return VehicleData[i];
      }
    }
  };

  resetTool = (e) => {
    e.preventDefault();
    AppActions.createModal({
      open: true,
      title: 'Reset Registered damage',
      type: 'confirm',
      content: (
        <div>
          <p>This will reset all registered damage for this vehicle.</p>
          <p>Are you sure?</p>
        </div>
      ),
      onConfirm: () => {
        TriageActions.resetDamage();
        TriageActions.resetCompletedQuestionSets();
        const currentCase = TriageStore.getCase();
        currentCase.decision.Direction = 0;
        currentCase.decision.DirectionText = 'Unknown';
        TriageActions.updateCase(currentCase);
        let newState = TriageStore.getProgressState();
        newState.MAX_PERMITTED = 1;
        newState.CURRENT_STAGE = 1;
        TriageActions.setProgressState(newState);
        // haaaaaaack! required to bust react caching bug
        AppActions.addSpinner();
        setTimeout(() => {
          currentCase.isEmbed
            ? window.parent.postMessage('{ "action" : "reload" }', '*')
            : document.location.reload(true);
        }, 1000);
      },
    });
  };

  closeModalHandler = (e) => {
    e.preventDefault();
    this.setState({
      decisionModel: { open: false },
      openConfirm: false,
      showErrorPrompt: false,
    });
  };

  saveImage = () => {
    const currentCase = TriageStore.getCase();
    const container = ReactDOM.findDOMNode(this.refs.svg).firstChild;
    if (container === undefined || container === null) return;
    const svg = container.lastChild.cloneNode(true);
    svg.style.opacity = '0.618';
    svg.style.width = '1200px';
    svg.style.height = '900px';
    Array.from(svg.getElementsByTagName('path')).map((el) => {
      el.style['stroke-width'] = '3';
      el.style['fill-opacity'] = '0.618';
      el.style['fill'] = el.style['stroke'];
      el.style['stroke'] = '#333333';
    });
    const svgHtml = new XMLSerializer().serializeToString(svg);
    let canvas = ReactDOM.findDOMNode(this.refs.canvas);
    canvg(canvas, svgHtml, {
      log: false,
    });
    let encodedData = canvas.toDataURL('image/png');
    currentCase.SVG = encodedData;
    TriageActions.updateCase(currentCase);
  };

  handleLatency = (mode, item) => {
    let timeout;
    if (mode === 'add') {
      AppActions.addSpinner();
      timeout = setTimeout(() => {
        AppActions.removeSpinner();
      }, 10000);
    }
    if (mode === 'remove') {
      if (timeout) clearTimeout(timeout);
      AppActions.removeSpinner();
    }
  };

  handleSubmitAndProgress = () => {
    let newState = TriageStore.getProgressState();
    const currentCase = TriageStore.getCase();
    const caseSettings = currentCase.caseSettings;

    if (
      currentCase.decision.RequestVariants ||
      caseSettings.ShowImagesComponent ||
      caseSettings.ShowRepairerSelectionGroup ||
      (currentCase.decision.OfferMobileRepairChoice &&
        currentCase.mobileConfirmed === null &&
        caseSettings.ShowConfirmMobileGroup) ||
      (caseSettings.ShowCarHireGroup && currentCase.decision.ShowCarHire)
    ) {
      const index = newState.DISABLED_STAGES.indexOf(3);
      newState.DISABLED_STAGES.splice(index, 1);
      newState.MAX_PERMITTED = 3;
      newState.CURRENT_STAGE = 3;
    } else {
      if (newState.DISABLED_STAGES.indexOf(3) === -1)
        newState.DISABLED_STAGES.push(3);
      newState.MAX_PERMITTED = 4;
      newState.CURRENT_STAGE = 4;
    }

    this.props.recordTime();
    TriageActions.setProgressState(newState);
  };

  render() {
    const direction = TriageStore.getCase().decision.Direction;
    const waitingForServer =
      this.state.waitingForServer && this.state.waitingForServer.length > 0;
    const modalModel = this.state.modalModel;
    const waitingOverlay = waitingForServer ? <div>Please Wait...</div> : null;
    const rightDisplay = !this.state.showManualSelector ? (
      <Tabs>
        <Tab
          className={s.tab}
          label="Panel Damage"
          title="Click to View Panel Damage"
        >
          <SelectionList
            handleLatency={this.handleLatency}
            disabled={waitingForServer}
            partsList={this.state.partsList}
            modalModel={this.state.modalModel}
          />
        </Tab>
        <Tab
          className={s.tab}
          label="Review Questions"
          title="Click to View Answered Section Questions"
        >
          <QuestionsList allowEdit={true} />
        </Tab>
      </Tabs>
    ) : (
      <VehicleSelector
        damageModel={this.state.damageModel}
        vehicles={this.state.vehicles}
        handleSelect={this.handleSelect}
        handleSubmit={this.handleSubmit}
        handleNoneOfTheAbove={this.handleNoneOfTheAbove}
        selectedIndex={this.state.selectedIndex}
      />
    );

    return (
      <div>
        <div className={s.mainPage}>
          <AdditionalCaseInformation />

          <div className={s.svgContainer}>
            <h2 style={{ marginBottom: 20 }}>Assess Damage</h2>
            <canvas ref="canvas" style={{ display: 'none' }} />
            <SelectedVehicle
              ref="svg"
              damageModel={this.state.damageModel}
              disabled={this.state.showManualSelector || waitingForServer}
              vehicleName={this.state.vehicleName || ''}
              partsList={this.state.partsList}
              style={{ opacity: '0' }}
              className={s.svgLoaded}
              handleLatency={this.handleLatency}
            />
            {waitingOverlay}
          </div>
          <div className={s.selectionToolContainer}>{rightDisplay}</div>
        </div>
        <div className={s.footerDialogContainer}>
          <div className={s.footerDialog}>
            <div className={s.nextButtons}>
              <div className={s.prevButton}>
                <RaisedButton
                  label="previous"
                  icon={<PrevIcon />}
                  onClick={this.gotoPrevious}
                />
              </div>
              <div className={s.resetButton}>
                <RaisedButton
                  labelPosition="before"
                  label="Reset Damage Model"
                  icon={<ResetIcon />}
                  disabled={direction == 0}
                  onClick={this.resetTool}
                />
              </div>
              <div className={s.nextButton}>
                <RaisedButton
                  labelPosition="before"
                  icon={<NextIcon />}
                  primary={true}
                  label="Continue"
                  onClick={this.handleSubmitAndProgress}
                  disabled={this.state.disableNext}
                />
              </div>
            </div>
          </div>
        </div>

        <Dialog
          className={s.modal}
          title={modalModel.title}
          autoDetectWindowHeight={true}
          repositionOnUpdate={true}
          actions={modalModel.actions}
          open={modalModel.open}
          autoScrollBodyContent={true}
        >
          {modalModel.content}
        </Dialog>

        <Dialog
          className={s.modal}
          title={'Select Vehicle Body Type'}
          autoDetectWindowHeight={true}
          repositionOnUpdate={true}
          open={this.state.showErrorPrompt}
          autoScrollBodyContent={true}
        >
          {' '}
          <p>
            It was not possible to retrieve the vehicle model. Please select the
            correct vehicle body type.
          </p>
          <RaisedButton
            style={{ float: 'right' }}
            onClick={this.closeModalHandler}
          >
            Continue
          </RaisedButton>
        </Dialog>

        <Dialog
          className={s.modal}
          title={'Confirm selection: ' + this.state.vehicleName}
          autoDetectWindowHeight={true}
          repositionOnUpdate={true}
          open={this.state.openConfirm}
          autoScrollBodyContent={true}
        >
          {' '}
          <p>Are you sure?</p>
          <RaisedButton
            primary={true}
            style={{ float: 'right', margin: '0 10px' }}
            onClick={this.confirmSelection}
          >
            Confirm
          </RaisedButton>
          <RaisedButton
            style={{ float: 'right' }}
            onClick={this.closeModalHandler}
          >
            Cancel
          </RaisedButton>
        </Dialog>
      </div>
    );
  }
}

let composed = withViewport(DamageTool);

export default composed;
