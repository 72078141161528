import { Component } from 'react';
import UserStore from '../../../stores/UserStore';
import UserActions from '../../../actions/UserActions';
import AppActions from '../../../actions/AppActions';

const NAME_REGEX = '^([a-zA-Z0-9_-]){3,20}$';
const EMAIL_REGEX =
  "^[-a-z0-9~!$%^&*_=+}{'?]+(.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(.[-a-z0-9_]+)*.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,5})?$";

function ManageUserComponent(TYPE) {
  return (ComposedComponent) =>
    class ManageUserComponent extends Component {
      state = {
        submitData: {
          id: { value: '', isValid: false, exists: false },
          name: { value: '', isValid: false, exists: false },
          email: { value: '', isValid: false },
          insurer: { value: '', isValid: false },
          department: { value: '', isValid: false },
          comment: { value: '', isValid: false },
          isApproved: { value: false, isValid: true },
          isLocked: { value: false, isValid: true },
          roles: [],
          isValid: false,
        },
        insurers: [],
        roles: [],
        showInsurer: false,
        showDepartment: false,
        isLocked: false,
        showValidationMessage: false,
        spinnerCount: 0,
      };

      fnc = null;

      UNSAFE_componentWillMount() {
        const showInsurer = this.props.currentlyLoggedInUser
          ? this.props.currentlyLoggedInUser.isAdmin
          : false;
        const showDepartment = this.props.currentlyLoggedInUser
          ? this.props.currentlyLoggedInUser.isAdmin ||
            this.props.currentlyLoggedInUser.isUsersCompanyManager
          : false;

        UserStore.addInvalidUserListener(this.showInvalidFields);

        UserStore.getAllRoles((data) => {
          this.setState({ roles: data }); //, () => {console.log("Roles Set - source, state: ", data, this.state.roles);}  );
          this.checkToRemoveSpinner(); // Inform we have got data for roles
        });

        if (showInsurer) {
          UserStore.getAllOrganisationNamesAndIds((data) => {
            this.setState({ insurers: data });
            this.checkToRemoveSpinner(); // Inform we have got data for insurers
          });
        }

        this.setState({ showInsurer: showInsurer });

        switch (TYPE) {
          case 'EDIT':
            this.fnc = () => {
              const user = this.createUserFromSubmitData(this.state.submitData);
              if (user) {
                UserActions.setCurrentUser(user);
              } else {
                UserActions.setCurrentUser(null);
              }
            };

            UserActions.setLockState({
              ItemId: this.props.userId,
              UserId: this.props.currentlyLoggedInUser.Id,
              ShouldLock: true,
            });
            UserStore.addLockListener(this.getUserLockState);

            UserStore.getUserDataById(this.props.userId, (data) => {
              this.checkToRemoveSpinner(); //Log that we have got user data
              UserActions.setCurrentUser(data); // Inform we have got data for user
              let currentUser = this.createSubmitDataFromUser(data);

              if (
                showDepartment &&
                data.DepartmentId &&
                data.DepartmentId > 0
              ) {
                // If the user has rights to change department, and there is a department for the current user, populate the departments dropdown list
                this.populateDepartments(data.InsurerId);
              } else {
                if (showDepartment) {
                  // We only get here if the user is UCM or Admin but have no department ID, i.e. their company has no departments
                  // If this is the case, we need to make sure the spinner doesn't block users
                  this.checkToRemoveSpinner();
                }
                this.setState({ showDepartment: false });
              }

              this.setState({ submitData: currentUser }, () => {});
            });
            break;
          case 'CREATE':
            this.fnc = () => {
              const user = this.createUserFromSubmitData(this.state.submitData);
              if (user) {
                UserActions.setNewUserDetails(user);
              } else {
                UserActions.setNewUserDetails(null);
              }
            };

            if (
              showDepartment &&
              this.props.currentlyLoggedInUser.DepartmentId &&
              this.props.currentlyLoggedInUser.DepartmentId > 0
            ) {
              // If the user has rights to change department, and there is a department for the logged in user, populate the departments dropdown list
              this.populateDepartments(
                this.props.currentlyLoggedInUser.InsurerId
              );
            } else {
              if (showDepartment) {
                // We only get here if the user is UCM or Admin but have no department ID, i.e. their company has no departments
                // If this is the case, we need to make sure the spinner doesn't block users
                this.checkToRemoveSpinner();
              }
              this.setState({ showDepartment: false });
            }
            break;
          case 'BULKUPLOAD':
            this.checkToRemoveSpinner();
            break;

          default:
        }

        //AppActions.removeSpinner();
      }

      componentWillUnmount() {
        UserStore.removeInvalidUserListener(this.showInvalidFields);

        if (TYPE === 'EDIT') {
          UserActions.setLockState({
            ItemId: this.props.userId,
            UserId: this.props.currentlyLoggedInUser.Id,
            ShouldLock: false,
          });
          UserStore.removeLockListener(this.getUserLockState);
        }
      }

      getUserLockState = () => {
        UserStore.getUserLockState(
          this.props.userId,
          this.props.currentlyLoggedInUser.Id,
          (response) => {
            this.setState({
              isLocked: response,
            });
          }
        );
      };

      checkToRemoveSpinner() {
        const tmpSpinnerCount = this.state.spinnerCount + 1;
        const populateInsurerIfAdminModifier = this.props.currentlyLoggedInUser
          .isAdmin
          ? 1
          : 0; //Add one on if the CLIU is an admin, as we will also make a call to get all the insurers
        const populateDepartmentsIfNeededModifier =
          this.props.currentlyLoggedInUser.isAdmin ||
          this.props.currentlyLoggedInUser.isUsersCompanyManager
            ? 1
            : 0; //Add one on if the CLIU is an admin or UCM, as we will also make a call to get the departments

        switch (TYPE) {
          case 'EDIT':
            if (
              tmpSpinnerCount >=
              2 +
                populateInsurerIfAdminModifier +
                populateDepartmentsIfNeededModifier
            ) {
              //Always have to get roles & userbyid, might have to get insurers & depts too
              AppActions.removeSpinner();
            }
            break;

          case 'CREATE':
            if (
              tmpSpinnerCount >=
              1 +
                populateInsurerIfAdminModifier +
                populateDepartmentsIfNeededModifier
            ) {
              //Always have to get roles, might have to get insurers & depts too
              AppActions.removeSpinner();
            }
            break;

          case 'BULKUPLOAD':
            if (
              tmpSpinnerCount >=
              1 +
                populateInsurerIfAdminModifier +
                populateDepartmentsIfNeededModifier
            ) {
              //Always have to get roles, might have to get insurers & depts too
              AppActions.removeSpinner();
            }

            break;
          default:
        }

        this.setState({ spinnerCount: tmpSpinnerCount });
      }

      handleDataChange = (name, rgx, e) => {
        const regex = new RegExp(rgx, 'gi');
        let data = Object.assign({}, this.state.submitData);
        if (!data[name]) return;
        let value = e.currentTarget.value;
        data[name].value = value;
        data[name].isValid =
          rgx === null || (value !== '' && value.match(regex) !== null);
        this.setState(
          { submitData: data, showValidationMessage: false },
          () => {
            if (this.fnc) {
              this.fnc();
            }
          }
        );
      };

      handleSelectChange = (name, invalidValue, e, index, value) => {
        let data = Object.assign({}, this.state.submitData);
        if (!data[name]) {
          return;
        }

        data[name].value = value === invalidValue ? null : value;
        const isSelectValid = value !== null && value !== 0;
        data[name].isValid = isSelectValid;

        this.setState(
          {
            submitData: data,
            showValidationMessage: false,
          },
          () => {
            if (name === 'insurer') {
              this.populateDepartments(value, (deptData) => {
                let newData = Object.assign({}, this.state.submitData);
                if (deptData && deptData.length > 0) {
                  newData['department'].isValid = false;
                } else {
                  newData['department'].isValid = true; // if there's no data, don't flag a lack of department as a val error
                }
                newData['department'].value = null;
                this.setState({ submitData: data }, () => {
                  if (this.fnc) {
                    this.fnc();
                  }
                });
              });
            } else {
              if (this.fnc) {
                this.fnc();
              }
            }
          }
        );
      };

      handleCheckToggle = (name) => {
        const data = Object.assign({}, this.state.submitData);
        if (!data[name]) return;
        data[name].value = data[name].value === true ? false : true;
        data[name].isValid = true;
        this.setState(
          { submitData: data, showValidationMessage: false },
          () => {
            if (this.fnc) {
              this.fnc();
            }
          }
        );
      };

      handleRoleChange = (e) => {
        let tempRoles = this.state.submitData.roles;
        let tmpRolePosition = tempRoles.lastIndexOf(e.target.value);
        if (tmpRolePosition < 0) {
          tempRoles.push(e.target.value);
        } else {
          tempRoles.splice(tmpRolePosition, 1);
        }
        let data = Object.assign({}, this.state.submitData);
        data.roles = tempRoles;
        this.setState(
          { submitData: data, showValidationMessage: false },
          () => {
            if (this.fnc) {
              this.fnc();
            }
          }
        );
      };

      populateDepartments = (insurerId, cb) => {
        //Get all departments for
        let tmpShowDepartments = this.props.currentUser
          ? this.props.currentUser.isAdmin ||
            this.props.currentUser.isUsersCompanyManager
          : false;
        let tmpDepartments = null; //[]
        //    console.log("populateDepartments - tmpShowDepartments: ", tmpShowDepartments);
        if (insurerId && tmpShowDepartments) {
          UserStore.getDepartmentsByInsurerId(insurerId, (data) => {
            //    let newSubmitData = Object.assign({}, this.state.submitData);
            //    let tmpShowDepartments = false;

            if (data && data.length > 0) {
              //There is data, so now we want a validation error if a value has not been chosen from departments
              // if (resetSubmitData) {
              //   //Nullify the submitData so that the department dropdown always resets if insurer changes - don't want to do this on first load for an existing user
              //   newSubmitData["department"].value = null;
              //   newSubmitData["department"].isValid = false;
              // }
              tmpDepartments = data;
              //        console.log("populateDepartments - tmpDepartments: ", tmpDepartments);
            } else {
              tmpShowDepartments = false;
            }
            // else {
            //   const tmpDepartments = [];
            //   const tmpShowDepartments = false;
            //   //   newSubmitData["department"].value = null;
            //   //   newSubmitData["department"].isValid = true;
            //   }
            this.setState(
              {
                departments: tmpDepartments,
                showDepartment: tmpShowDepartments,
              },
              () => {
                if (cb) {
                  //    console.log("Setting department property of submit data via CB");
                  cb(tmpDepartments);
                }
              }
            );
            this.checkToRemoveSpinner();
          });
        } else {
          // For when there is no insurer ID (invalid insurer ID) - still want to save the fact there are no departments!
          this.setState(
            { departments: tmpDepartments, showDepartment: tmpShowDepartments },
            () => {
              if (cb) {
                //    console.log("Setting department property of submit data via CB - empty insurerID");
                cb(tmpDepartments);
              }
              this.checkToRemoveSpinner();
            }
          );
        }
        // else {
        //   let newSubmitData = Object.assign({}, this.state.submitData);
        //   newSubmitData["department"].value = null; //No Department data, nullify the submitData so the previous departmentId (if there is one) is not saved
        //   newSubmitData["department"].isValid = true; // Make sure the lack of departments doesn't stop validation
        //   this.setState({departments: [], showDepartment: false, submitData: newSubmitData});
        // }
      };

      showInvalidFields = () => {
        this.setState({ showValidationMessage: true });
      };

      createUserFromSubmitData = (userDataWithValidation) => {
        let result = null;

        //TODO: Set defaults for UCM and UDM here before the checks and the population - maybe just do the isValid setting based on that here, then set the values to the UCM or UDM user's values within the standard setting code
        if (
          !this.props.currentlyLoggedInUser.isAdmin &&
          (this.props.currentlyLoggedInUser.isUsersCompanyManager ||
            this.props.currentlyLoggedInUser.isUsersDepartmentManager)
        ) {
          userDataWithValidation.insurer.value =
            this.props.currentlyLoggedInUser.InsurerId;
          userDataWithValidation.insurer.isValid = true;

          if (
            this.props.currentlyLoggedInUser.isUsersCompanyManager &&
            this.props.currentlyLoggedInUser.DepartmentId === null
          ) {
            //UCM with no departments - if an empty department is not set as valid, the user cannot be validated, but the error message will not be visible as the departments control is not shown
            // NB if we have gone into the outer if as a UCM with departments, then the departments dropdown will be shown, and we should fail validation for empty departments
            userDataWithValidation.department.value = null;
            userDataWithValidation.department.isValid = true;
          }
        } else {
        }

        if (
          !this.props.currentlyLoggedInUser.isAdmin &&
          this.props.currentlyLoggedInUser.isUsersDepartmentManager
        ) {
          userDataWithValidation.department.value =
            this.props.currentlyLoggedInUser.DepartmentId;
          userDataWithValidation.department.isValid = true;
        } else {
        }

        const isUserValid =
          userDataWithValidation.name.isValid &&
          userDataWithValidation.email.isValid &&
          userDataWithValidation.insurer.isValid &&
          userDataWithValidation.department.isValid;

        if (isUserValid) {
          result = {
            Id: userDataWithValidation.id
              ? userDataWithValidation.id.value
              : null,
            UserName: userDataWithValidation.name.value,
            //"InsurerName": "ITG Group",
            InsurerId: userDataWithValidation.insurer.value,
            //"DepartmentName" : undefined,
            DepartmentId: userDataWithValidation.department.value,
            Email: userDataWithValidation.email.value,
            IsApproved: userDataWithValidation.isApproved.value,
            IsLocked: userDataWithValidation.isLocked.value,
            //"Created":"01/06/2016 12:18:26", //"2016-06-01 12:18:26.510",
            //"LastLogin": "03/06/2016 12:18:26",
            Comments: userDataWithValidation.comment.value,
            roles: userDataWithValidation.roles,
          };
        }
        return result;
      };

      createSubmitDataFromUser = (user) => {
        let result = null;
        result = {
          id: { value: user.Id, isValid: true },
          name: { value: user.UserName, isValid: true, exists: false },
          email: { value: user.Email, isValid: true },
          insurer: { value: user.InsurerId, isValid: true },
          department: { value: user.DepartmentId, isValid: true },
          comment: { value: user.Comments, isValid: true },
          isApproved: { value: user.IsApproved, isValid: true },
          isLocked: { value: user.IsLocked, isValid: true },
          roles: user.Roles,
          isValid: true,
        };
        return result;
      };

      checkUserNameExists = () => {
        if (
          !this.state.submitData.name.isValid ||
          this.state.submitData.name.value == ''
        )
          return;
        UserActions.checkUserNameExists({
          name: this.state.submitData.name.value,
          callback: (response) => {
            this.state.submitData.name.isValid = !response;
          },
        });
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            submitData={this.state.submitData}
            insurers={this.state.insurers}
            roles={this.state.roles}
            handleSelectChange={this.handleSelectChange}
            handleDataChange={this.handleDataChange}
            handleRoleChange={this.handleRoleChange}
            handleCheckToggle={this.handleCheckToggle}
            departments={this.state.departments}
            emailRegEx={EMAIL_REGEX}
            nameRegEx={NAME_REGEX}
            populateDepartments={this.populateDepartments}
            isLocked={this.state.isLocked}
            showInsurer={this.state.showInsurer}
            showDepartment={
              this.state.showDepartment &&
              this.state.departments &&
              this.state.departments.length > 0
            }
            showValidationMessage={this.state.showValidationMessage}
            checkUserNameExists={this.checkUserNameExists}
          ></ComposedComponent>
        );
      }
    };
}
export default ManageUserComponent;
