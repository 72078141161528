import { Component } from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import PrevIcon from 'material-ui/svg-icons/content/clear';
import s from './VehicleLookup.module.scss';

class NextButtons extends Component {
  render() {
    const { vehicleDetails, progressDisabled, resetHandler, submitHandler } =
      this.props;

    return vehicleDetails.Registration !== null &&
      vehicleDetails.Registration !== undefined ? (
      <div className={s.nextButtons}>
        <div className={s.prevButton}>
          <RaisedButton
            label="Close Case"
            onClick={resetHandler}
            icon={<PrevIcon />}
          />
        </div>
        <div className={s.nextButton}>
          <RaisedButton
            label="Continue"
            primary={true}
            onClick={submitHandler}
            labelPosition="before"
            disabled={progressDisabled}
            icon={<NextIcon />}
          />
        </div>
      </div>
    ) : null;
  }
}

let composed = NextButtons;

export default composed;
