import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowBackLeft)}
        d="M897,659.5c-8.8-13.3-81.7-21.2-174.4-24.3c-0.8,0-10.7,68.8-12,82.8h210C918,706,910.6,680.2,897,659.5z"
      />
    );
  }
}

let composed = WindowBackLeftHatchback;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
