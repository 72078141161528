import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './PermissionsPage.module.scss';

import Link from '../Link';
import RolesPage from './RolesPage';
import OrganisationsPage from './OrganisationsPage';
import UsersPage from './UsersPage';
import Location from '../../core/Location';

const PAGE_TYPES = {
  default: null,
  roles: <RolesPage />,
  users: <UsersPage />,
  organisations: <OrganisationsPage />,
};

const title = 'Inter-cept | Permissions';

class PermissionsPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.context.onSetTitle(title);
  }

  render() {
    const pageType = PAGE_TYPES[this.props.type || 'default'];
    if (pageType === undefined) Location.pushState('/dashboard');
    const pageComponent =
      pageType !== null ? (
        pageType
      ) : (
        <div className={s.box}>
          <ul className={s.backLinks}>
            <li>
              <a href="/dashboard" onClick={Link.handleClick}>
                Back to Dashboard
              </a>
            </li>
          </ul>
          <h1 className={s.heading}>Permissions</h1>

          <ul className={s.links}>
            <li>
              <a href="/permissions/users" onClick={Link.handleClick}>
                Manage Users
              </a>
            </li>
            <li>
              <a href="/permissions/organisations" onClick={Link.handleClick}>
                Manage Organisations
              </a>
            </li>
            <li>
              <a href="/permissions/roles" onClick={Link.handleClick}>
                Manage Roles
              </a>
            </li>
          </ul>
        </div>
      );

    return (
      <div className={s.root}>
        <div className={s.container}>{pageComponent}</div>
      </div>
    );
  }
}

export default PermissionsPage;
