import React from 'react';
import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui/Menu';
import AtFaultStatuses from '../../../../../constants/AtFaultStatusTypes';

const AtFaultQuestion = (props) => {
  const menuItems = AtFaultStatuses.map((item, index) => {
    return <MenuItem key={index} value={index + 1} primaryText={item} />;
  });

  return (
    <div>
      <p>Fault Status:</p>
      <div className={props.styles.row}>
        <SelectField
          fullWidth={true}
          floatingLabelText="Choose the most appropriate fault status"
          value={props.atFaultSelection > 0 ? props.atFaultSelection : null}
          onChange={props.atFaultStatusChangeHandler}
          floatingLabelFixed={true}
        >
          {menuItems}
        </SelectField>
      </div>
    </div>
  );
};

export default AtFaultQuestion;
