import React from 'react';

const createList = (data) => {
  const list = Object.keys(data).map((item, i) => {
    const content = data[item];

    const listItem =
      content === null ||
      !content.Value ||
      content.Value === null ||
      content.Value.constructor === Array ||
      item === '$id' ? null : (
        <li key={i}>
          <span>{content.Title}</span>
          <strong>{content.Value}</strong>
        </li>
      );
    return listItem;
  });
  return list;
};

const VehicleHistory = (props) => {
  const s = props.styles;
  const data = props.data;
  //console.log(data)
  return (
    <div>
      <h3 style={{ paddingBottom: 10 }}>Vehicle Details</h3>
      <ul className={s.historyList}>{createList(data)}</ul>
      {Object.keys(data).map((item, i) => {
        const content = data[item];
        //if(content !== null && content.constructor === Array) console.log("array item", item, content)
        const listItem =
          content &&
          content !== null &&
          content.Value &&
          content.Value.constructor === Array ? (
            <div key={i} className={s.vehicleHistoryList}>
              <h3 style={{ paddingBottom: 10 }}>{content.Title}</h3>
              <div className={s.historySubLists}>
                {content.Value.length > 0 ? (
                  content.Value.map((subItem, j) => {
                    const list = createList(subItem);
                    return (
                      <ul key={j} className={s.historyList}>
                        {list}
                      </ul>
                    );
                  })
                ) : (
                  <ul className={s.historyList}>
                    <li>No records</li>
                  </ul>
                )}
              </div>
            </div>
          ) : null;
        return listItem;
      })}
    </div>
  );
};

export default VehicleHistory;
