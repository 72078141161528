const SummaryNote = (props) => {
  const s = props.styles;

  const warningStyle = {
    backgroundColor: 'rgba(255, 66, 66, 0.618)',
    padding: 5,
  };

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Warning: </span>
      <strong className={s.cols_6} style={warningStyle}>
        {props.note || null}
      </strong>
    </li>
  );
};

export default SummaryNote;
