import { Component } from 'react';
import s from './DashBoardPage.module.scss';
import TriageStore from '../../stores/TriageStore';
import TriageActions from '../../actions/TriageActions';
import LockActions from '../../actions/LockActions';
import AppActions from '../../actions/AppActions';
import AppStore from '../../stores/AppStore';
import RaisedButton from 'material-ui/RaisedButton';
import Link from '../Link';
import Location from '../../core/Location';
import ResetIcon from 'material-ui/svg-icons/action/delete';
import TriageIcon from 'material-ui/svg-icons/image/healing';
import AddIcon from 'material-ui/svg-icons/action/note-add';
import UsersIcon from 'material-ui/svg-icons/hardware/security';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import LoggingIcon from 'material-ui/svg-icons/alert/error';
import withLoggedInUser from '../../decorators/withLoggedInUser';
import withViewport from '../../decorators/withViewport';

class DashboardPage extends Component {
  state = {
    currentCase: null,
    currentUser: null,
  };

  componentDidMount() {
    if (document) document.title = 'Inter-cept | Dashboard';
  }

  clearCurrentCase = (e) => {
    e.preventDefault();
    AppActions.createModal({
      open: true,
      title: 'Exit Case',
      type: 'confirm',
      content: (
        <div>
          <p>
            This will exit your current case. The current case will remain open
            and not be completed.
          </p>
          <p>Are you sure?</p>
        </div>
      ),
      onConfirm: () => {
        TriageActions.resetCompletedQuestionSets();
        TriageActions.resetProgress();
        TriageActions.resetCase();
        LockActions.unlockCase({ caseId: this.state.currentCase.caseId });
        AppActions.addSpinner();
        setTimeout(() => {
          document.location.reload(true);
        }, 1000);
      },
    });
  };

  updateState = () => {
    this.setState({
      currentCase: TriageStore.getCase(),
      currentUser: this.props.currentUser, // Now taken from withLoggedInUser decorator
    });
    //console.log("state updating: ", this.state, TriageStore.getCase());
  };

  UNSAFE_componentWillMount() {
    TriageStore.addChangeListener(this.updateState);
    AppStore.addChangeListener(this.updateState);
    this.updateState();
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
    AppStore.removeChangeListener(this.updateState);
  }

  render() {
    const { currentCase, currentUser } = this.state;

    if (currentCase.relatedCases === null) currentCase.relatedCases = [];
    const details = currentCase ? currentCase.vehicleDetails : {};
    //console.log(currentCase, currentUser);
    const logging = currentUser.isAdmin ? (
      <div className={s.dashboardItem}>
        <h2 className={s.itemHeading}>
          Event Logging <LoggingIcon className={s.icon} />
        </h2>
        <div className={s.itemContent}>
          <a href="/eventLogging" onClick={Link.handleClick}>
            View Event Logs
          </a>
        </div>
      </div>
    ) : null;

    const permissionsSub = currentUser.isAdmin ? (
      <div>
        <a href="/permissions/organisations" onClick={Link.handleClick}>
          Manage Organisations
        </a>
        <a href="/permissions/roles" onClick={Link.handleClick}>
          View Roles
        </a>
      </div>
    ) : null;

    const permissions =
      currentUser.isAdmin ||
      currentUser.isUsersCompanyManager ||
      currentUser.isUsersDepartmentManager ? (
        <div className={s.dashboardItem}>
          <h2 className={s.itemHeading}>
            Permissions <UsersIcon className={s.icon} />
          </h2>
          <div className={s.itemContent}>
            <a href="/permissions/users" onClick={Link.handleClick}>
              Manage Users
            </a>
            {permissionsSub}
          </div>
        </div>
      ) : null;

    const statistics =
      currentUser.isAdmin ||
      currentUser.isMICompanyManager ||
      currentUser.isMIDepartmentManager ? (
        <a href="/triageStats" onClick={Link.handleClick}>
          Search Triage Statistics
        </a>
      ) : null;

    const triageLink =
      currentUser.isAdmin || currentUser.isTriageUser ? (
        details !== undefined && Object.keys(details).length !== 0 ? (
          <div className={s.triageLink}>
            <strong>You have an active case </strong>
            <div className={s.linkBtns}>
              <RaisedButton
                primary={true}
                className={s.linkBtn}
                style={{ minWidth: 155 }}
                label="Exit Case"
                labelPosition="before"
                icon={<ResetIcon />}
                onClick={this.clearCurrentCase}
              />
              <RaisedButton
                primary={true}
                className={s.linkBtn}
                style={{ minWidth: 190 }}
                label="Go To Active Case"
                labelPosition="before"
                icon={<TriageIcon />}
                onClick={() => {
                  Location.push('/triage/lookup');
                }}
              />
            </div>
          </div>
        ) : (
          <div className={s.triageLink}>
            <div
              className={s.linkBtns}
              style={{ maxWidth: 440, margin: 'auto' }}
            >
              <strong>You have no open cases </strong>
              <RaisedButton
                className={s.linkBtn}
                primary={true}
                label="Open a Case"
                labelPosition="before"
                icon={<AddIcon />}
                onClick={() => {
                  Location.push('/triage/lookup');
                }}
              />
            </div>
          </div>
        )
      ) : (
        <p>You do not have permission to create a Triage case.</p>
      );

    return (
      <div className={s.root}>
        <div
          className={s.container}
          style={{ minHeight: this.props.viewport.height - 232 }}
        >
          <div className={s.content}>
            <h1 className={s.heading}>Inter-cept 2.0</h1>
            <div className={s.row}>
              <div className={s.dashboardItem} style={{ flexGrow: 3 }}>
                <h2 className={s.itemHeading}>
                  Triage <TriageIcon className={s.icon} />
                </h2>
                <div className={s.itemContent}>{triageLink}</div>
              </div>
              <div className={s.dashboardItem}>
                <h2 className={s.itemHeading}>
                  Case History <HistoryIcon className={s.icon} />
                </h2>
                <div className={s.itemContent}>
                  {statistics}
                  {currentUser.Roles.length > 0 ? (
                    <a href="/triageReview" onClick={Link.handleClick}>
                      Review a Case
                    </a>
                  ) : (
                    <p>You do not have permission to view case history</p>
                  )}
                </div>
              </div>
              {permissions}
              {logging}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let composed = withViewport(DashboardPage);
composed = withLoggedInUser(composed);

export default composed;
