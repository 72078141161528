import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontRight4x45d extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontRight)}
        d="M649.7,293.3l-8.3-80.6c0-0.4-0.4-0.7-0.8-0.7H450.7c-0.7,0-0.9,0.8-0.6,1.4c7.8,12.9,66.9,66.6,75.6,72.5  c0,0,8.3,5.1,8.4,5.2c4.7,3.6,14.3,4.5,14.3,4.5c32.3,0.8,92.6,0.1,101.3,0.4C650.2,295.9,649.7,293.8,649.7,293.3z"
      />
    );
  }
}

let composed = WindowFrontRight4x45d;
composed = SectionComponent({
  name: 'Front Driver Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
