import keyMirror from 'fbjs/lib/keyMirror';

export default keyMirror({
  ADMINISTRATOR: 'ADMINISTRATOR',
  IMAGEUSER: 'IMAGEUSER',
  MANAGER: 'MANAGER',
  MICOMPANYMANAGER: 'MICOMPANYMANAGER',
  MIDEPARTMENTMANAGER: 'MIDEPARTMENTMANAGER',
  TRIAGEUSER: 'TRIAGEUSER',
  USERSCOMPANYMANAGER: 'USERSCOMPANYMANAGER',
  USERSDEPARTMENTMANAGER: 'USERSDEPARTMENTMANAGER',
});
