import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class FrontWingPanel extends Component {
  state = {
    defaultSelected: null,
    showExtraQuestion: null,
    dentedOrGougedValue: 2,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      defaultSelected: this.props.defaultSelected.toString() || '0',
      showExtraQuestion:
        this.props.defaultSelected == '2' || this.props.defaultSelected == '3',
      dentedOrGougedValue: this.props.defaultSelected == 3 ? '3' : '2',
    });
  }

  dentHandler(value) {
    this.setState({
      showExtraQuestion: value,
    });
  }

  render() {
    const subQuestion = this.state.showExtraQuestion ? (
      <div className={this.props.style.subQuestions}>
        <div className={this.props.style.formGroup}>
          <span>
            Is the damaged area equal to or larger than two outspread hands?
          </span>
          <RadioButtonGroup
            className={this.props.style.radios}
            name="panel"
            defaultSelected={this.state.dentedOrGougedValue == '3' ? '3' : '2'}
            labelPosition="left"
          >
            <RadioButton value="3" label="Yes" />
            <RadioButton value="2" label="No" />
          </RadioButtonGroup>
        </div>
      </div>
    ) : null;

    return (
      <div>
        <RadioButtonGroup
          className={this.props.style.radios}
          name="panel"
          defaultSelected={this.state.defaultSelected}
          labelPosition="left"
        >
          <RadioButton
            value="4x"
            label="Torn/Crumpled/More than one dent"
            onClick={this.dentHandler.bind(this, false)}
          />
          <RadioButton
            value={this.state.dentedOrGougedValue}
            label="A single dent or gouge"
            onClick={this.dentHandler.bind(this, true)}
          />
          <RadioButton
            value="1"
            label="Scratched or scuffed"
            onClick={this.dentHandler.bind(this, false)}
          />
          <RadioButton
            value="0"
            label="There is no damage"
            onClick={this.dentHandler.bind(this, false)}
          />
        </RadioButtonGroup>
        {subQuestion}
      </div>
    );
  }
}
