import { Component } from 'react';
import s from './VehicleLookup.module.scss';
import OpenCasesConfig from './OpenCasesConfig';
import Griddle from 'griddle-react';
import AppStore from '../../../stores/AppStore';
import LockStore from '../../../stores/LockStore';
import LockActions from '../../../actions/LockActions';
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';

let socket = null;
// TODO
const DEBUG = false;

class OpenCases extends Component {
  static defaultProps = {
    openCases: [],
    registration: '',
    casesArraySize: 0,
  };

  state = {
    filteredCases: [],
  };

  setupLocking = () => {
    socket.on('open', () => {
      socket.join(AppStore.userData().User.InsurerName);
    });
    socket.on('change', () => {
      LockActions.refreshLocks({
        Insurer: AppStore.userData().User.InsurerName,
      });
    });
  };

  updateLocking = () => {
    const lockedCases = LockStore.getLockedCases();
    const filtered = this.props.openCases.map((item) => {
      let data = JSON.parse(item.Edit);

      if (
        lockedCases.filter((i) => {
          return i.CaseId === data.caseId;
        }).length > 0
      ) {
        data.isLocked = true;
      } else {
        data.isLocked = false;
      }
      item.Edit = JSON.stringify(data);
      return item;
    });
    this.setState({
      filteredCases: lockedCases.length > 0 ? filtered : this.props.openCases,
    });
    try {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    } catch (e) {}
  };

  UNSAFE_componentWillMount() {
    LockStore.setWSClient(
      DEBUG ? 'ws://localhost:5000' : 'wss://' + window.location.hostname
    );
    socket = LockStore.getWSClient();
    this.setState({
      filteredCases: this.props.openCases,
    });
    LockStore.addChangeListener(this.updateLocking);
    LockActions.refreshLocks({
      Insurer: AppStore.userData().User.InsurerName,
    });
    // 30 sec hail mary catch all
    setInterval(() => {
      if (AppStore.userData() !== null) {
        LockActions.refreshLocks({
          Insurer: AppStore.userData().User.InsurerName,
        });
      }
    }, 30000);

    this.setupLocking();
  }

  componentWillUnmount() {
    if (canUseDOM && socket !== null) {
      socket.close();
    }
    LockStore.removeChangeListener(this.updateLocking);
  }

  render() {
    const { casesArraySize, registration } = this.props;

    return (
      <div className={s.root} style={{ margin: 0 }}>
        <p>
          You already have {casesArraySize} open cases with the registration{' '}
          {registration}. Click "load" to open an existing case.
        </p>
        <Griddle
          className={s.grid}
          results={this.state.filteredCases || []}
          columnMetadata={OpenCasesConfig.columnMetadata}
          metadataColumns={OpenCasesConfig.ignoreColumns}
          useFixedHeader={true}
          resultsPerPage={5}
          tableClassName={s.tableNarrow}
          showFilter={true}
          enableInfiniteScroll={false}
          showSettings={false}
        />
        <p>Or click "Create New Case" to open a new case.</p>
      </div>
    );
  }
}

export default OpenCases;
