import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRight4x45d extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M995,195.2v-64c0-0.3,0-0.6-0.1-0.9c-7.1-45.2-40.8-47.7-40.8-47.7s-64.3-4.4-76.3-5.2c-0.9-0.1-1.7,0.4-1.9,1.3  c-5.4,30.2-30.4,54.2-58.2,59.4c-8.1,1.2-16.6,0.9-25.1-2.1h-0.1c-17.6-4-37.2-16.1-48.1-45.8c-0.2-0.7-0.9-2.2-1.7-2.2h-17.9  c9.5,38,38.7,69.7,76.6,71.9c7.4,0.4,15,0,15,0c0.2,1.3,1.5,12.2,2,22.6c0.5,9.4,1.5,25.9,1,28.8c-0.9,4.8-32.8,59.5-32.1,58  c-10.9,18.1-15.4,28.9-26.2,29.6l-83.6,1.3l2.4,10.5c0,0,41.9,0.1,42.7,0.1c16.5,0,151.8,0.2,182.1-0.3c3.2-0.1,12-5,14.5-7.1  c18.2-15.1,64.8-73,70.1-83.7c2.6-5.3,4.6-9.5,5.6-24.5C995.1,195.2,995,195.2,995,195.2z M954.1,228.7c-8.9,12.2-60.7,70-66.5,70  l-75.3-0.3c-1.4,0-9.1,0.1-5.2-4c0,0,61.1-68.4,70-68.4h75.6C954.2,226,955,227.6,954.1,228.7z"
        />
        <path
          {...this.props}
          d="M997,195.3v-64c0-0.3-1-0.5-1.1-0.8c-7.1-45.2-41.8-47.7-41.8-47.7s-18.9-1.3-38.1-2.6c0,0.1,0,0.1,0,0.2  c-3.4-0.2-6.8-0.5-10.1-0.7c0-0.1,0-0.2,0-0.3c-12.6-0.8-23.8-1.7-28.1-2c-0.9-0.1-1.7,0.4-1.9,1.3c-5.4,30.2-30.4,54.2-58.2,59.4  c-8.1,1.2-16.6,0.9-25.1-2.1h-0.1c-17.6-4-37.2-16.1-48.1-45.8c-0.2-0.7-0.9-2.2-1.7-2.2h-17.9c9.5,38,38.7,69.8,76.6,72  c7.4,0.4,15,0,15,0c0.2,1.3,1.5,12.2,2,22.6c0.5,9.4,1.5,25.9,1,28.8c-0.8,4.7-31.5,56.7-32.1,58c-11.4,19.9-17.4,29.5-26.2,29.6  l-83.6,1.3l2.4,10.5c0,0,42,0.1,42.8,0.1c16.5,0,151.9,0.2,182.2-0.3c3.2-0.1,12.2-5,14.7-7.1c13.5-11.2,42.8-46,59.1-67.5  c0,0,0-0.1-0.1-0.1c2.6-3.4,4.8-6.5,6.6-9.1c0,0,0,0.1,0.1,0.1c2.2-3.1,3.8-5.6,4.5-7.1c2.6-5.3,5.1-9.5,6.1-24.5  C996.1,195.3,997,195.3,997,195.3z M992.3,205.7c0,0-0.1,0-0.1-0.1c-1.7-2.5-8.8-4.5-11.6-5.3l-7.6-1.9c0,0,0,0,0,0c0,0,0-0.1,0-0.2  v-34.7c0-0.1,0-2.5,0-2.5h18.9c0,0,0.7,1.5,1,1.8c0.5,0.5,1.1,2.4,1.1,3.1v26.5c0,0-0.1,0.5-0.1,1.2c0,0.1-0.1,0.1-0.1,0.2  c0,0.1,0,0.3,0,0.4c-0.2,3-0.5,8.8-1.1,10.9C992.6,205.2,992.5,205.5,992.3,205.7z M877.1,226h75.6c1.5,0,2.3,1.8,1.4,2.9  c-8.9,12.2-60.7,70.1-66.5,70.1l-14.7-0.1l-60.1-0.4c-1.4,0-9.1-0.2-5.2-4.3c0,0,5.8-6.7,14-15.6C840.4,258.5,871,226,877.1,226z   M996,143.3V142H872v-2h124v-1.4C996,140,996,141.8,996,143.3z"
        />
      </g>
    );
  }
}

let composed = WingBackRight4x45d;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
