import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowFrontLeftPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.windowFrontLeft)}
        d="M612.4,622.2c-17.8-0.1-63.5,0-63.8,0c-6.1,0.3-21.7,3.5-32,12.4c-0.1,0-69.6,58.9-72.3,68.9c0,0-1.8,2.8,0.1,3.5   c0,0,62.6,0.2,89.7,0.2h84l-0.1-81C617.9,621.8,616.9,622.2,612.4,622.2z"
      />
    );
  }
}

let composed = WindowFrontLeftPickup;
composed = SectionComponent({
  name: 'Front Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
