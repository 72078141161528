import { Component } from 'react';
import s from '../_vehicleComponent/VehicleTypes.module.scss';

import BumperFront from '../../VehicleSections/BumperFront/BumperFrontPV/';
import BumperRear from '../../VehicleSections/BumperRear/BumperRearMPV/';
import Bonnet from '../../VehicleSections/Bonnet/BonnetPV/';
import DoorBackLeft from '../../VehicleSections/DoorBackLeft/DoorBackLeftMPV/';
import DoorBackRight from '../../VehicleSections/DoorBackRight/DoorBackRightMPV/';
import DoorFrontLeft from '../../VehicleSections/DoorFrontLeft/DoorFrontLeftMPV/';
import DoorFrontRight from '../../VehicleSections/DoorFrontRight/DoorFrontRightMPV/';
import Roof from '../../VehicleSections/Roof/RoofPV/';
import ScreenRear from '../../VehicleSections/ScreenRear/ScreenRearPV/';
import ScreenWind from '../../VehicleSections/ScreenWind/ScreenWindPV/';
import SillLeft from '../../VehicleSections/SillLeft/SillLeftPV/';
import SillRight from '../../VehicleSections/SillRight/SillRightPV/';
import Tailgate from '../../VehicleSections/Tailgate/TailGateMPV/';
import WheelFrontLeft from '../../VehicleSections/WheelFrontLeft/WheelFrontLeftPV/';
import WheelFrontRight from '../../VehicleSections/WheelFrontRight/WheelFrontRightPV/';
import WheelBackLeft from '../../VehicleSections/WheelBackLeft/WheelBackLeftPV/';
import WheelBackRight from '../../VehicleSections/WheelBackRight/WheelBackRightPV/';
import WindowBackLeft from '../../VehicleSections/WindowBackLeft/WindowBackLeftMPV/';
import WindowBackRight from '../../VehicleSections/WindowBackRight/WindowBackRightMPV/';
import WindowFrontLeft from '../../VehicleSections/WindowFrontLeft/WindowFrontLeftMPV/';
import WindowFrontRight from '../../VehicleSections/WindowFrontRight/WindowFrontRightMPV/';
import WingFrontLeft from '../../VehicleSections/WingFrontLeft/WingFrontLeftPV/';
import WingFrontRight from '../../VehicleSections/WingFrontRight/WingFrontRightPV/';
import WingBackLeft from '../../VehicleSections/WingBackLeft/WingBackLeftMPV/';
import WingBackRight from '../../VehicleSections/WingBackRight/WingBackRightMPV/';
import WingMirrorLeft from '../../VehicleSections/WingMirrorLeft/WingMirrorLeftMPV';
import WingMirrorRight from '../../VehicleSections/WingMirrorRight/WingMirrorRightMPV';
import VehicleComponent from '../_vehicleComponent/';

class MPV extends Component {
  render() {
    return (
      <div className={s.svgWrapper}>
        <canvas className={s.svgCanvas} height={93} width={120}></canvas>
        <svg className={s.svg} {...this.props}>
          <text
            transform="translate(0,24)"
            style={{ fill: 'rgb(0,188,212)', fontSize: '24px' }}
          >
            MPV
          </text>
          <BumperFront {...this.props} />
          <BumperRear {...this.props} />

          <Bonnet {...this.props} />
          <Tailgate {...this.props} />

          <Roof {...this.props} />

          <ScreenWind {...this.props} />
          <ScreenRear {...this.props} />

          <DoorFrontLeft {...this.props} />
          <DoorFrontRight {...this.props} />

          <DoorBackLeft {...this.props} />
          <DoorBackRight {...this.props} />

          <SillLeft {...this.props} />
          <SillRight {...this.props} />

          <WingFrontRight {...this.props} />
          <WingFrontLeft {...this.props} />
          <WingBackLeft {...this.props} />
          <WingBackRight {...this.props} />

          <WindowBackRight {...this.props} />
          <WindowBackLeft {...this.props} />
          <WindowFrontRight {...this.props} />
          <WindowFrontLeft {...this.props} />

          <WheelFrontRight {...this.props} />
          <WheelFrontLeft {...this.props} />
          <WheelBackLeft {...this.props} />
          <WheelBackRight {...this.props} />

          <WingMirrorLeft {...this.props} />
          <WingMirrorRight {...this.props} />
        </svg>
      </div>
    );
  }
}

let composed = MPV;
composed = VehicleComponent(composed);

export default composed;
