import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightCoupe extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M1005.3,97.5c0-12.5-2.9-31.3-4.5-40.4c-0.6-3.2-3.2-5.7-6.4-6.1c-6.7-0.8-20.1-1.9-43.7-2.6l-27-0.5c-1.8,0-3.2,1.3-3.4,3  l0.7,17.9V69c0,26-13.2,43.5-31.2,56.8c-24.9,18.2-66.6,10.8-86-21.2c-6.8-11-10.5-19.2-11.5-56.6l0.2-29c-0.3-1.7-1.6-2.9-3.3-2.9  h-97.3c-0.9,0-1.6,0.7-1.6,1.6l5.3,115.4c3.9,65.3,5.4,79.5,11.5,118.7c3.2,20.9,6.7,44.6,7.6,50.9c0.1,0.9,0.9,1.5,1.8,1.4  c30.9,0,64.6-6.3,64.6-6.3c46-6.7,188.4-90.8,188.7-90.9l16.8,0.1c3.2,0,5.8-2.6,5.8-5.8c0-18.3,0.1-63.7-0.1-63.1  c0,0,4.1-5.5,5.9-8.6C998.1,129.3,1004.9,117.3,1005.3,97.5z M719.7,285c-0.9,0-1.6-3.2-1.7-4c-1.1-9.6-7.9-63.9-9.6-80.8  c-0.1-1,0.7-2,1.8-1.9l201.4-0.6C913.6,197.6,832.1,285,719.7,285z"
        />
        <path
          {...this.props}
          d="M1006,97.2c0-12.5-2.9-31.3-4.5-40.4c-0.6-3.2-3.2-5.7-6.4-6.1c-6.7-0.8-20.1-1.9-43.6-2.6l-26.9-0.5  c-1.8,0-3.2,1.3-3.4,3l0.8,17.9v0.1c0,26-13.3,43.5-31.3,56.8c-24.9,18.2-66.8,10.8-86.1-21.2c-6.8-11-10.5-19.2-11.5-56.6l0.2-28.9  c-0.3-1.7-1.6-2.8-3.3-2.8h-97.3c-0.9,0-1.6,0.6-1.6,1.5l5.3,115.2c3.9,65.3,5.4,79.5,11.5,118.7c3.2,20.9,6.7,44.6,7.6,50.9  c0.1,0.9,0.9,1.5,1.8,1.4c30.9,0,64.6-6.3,64.6-6.3c46-6.7,188.4-90.8,188.7-90.9l16.8,0.1c3.2,0,5.8-2.6,5.8-5.8  c0-18.3,0.1-63.7-0.1-63.1c0,0,4.1-5.5,5.9-8.6C998.9,129,1005.7,117,1006,97.2z M990,191.5c0,2-1.6,3.5-3.5,3.5h-21.9  c-2,0-3.5-1.6-3.5-3.5v-28.9c0-2,1.6-3.5,3.5-3.5h21.9c2,0,3.5,1.6,3.5,3.5V191.5z M912,197.8c2,0-79.5,87.4-191.9,87.4  c-0.9,0-1.6-3.2-1.7-4c-1.1-9.6-7.9-63.9-9.6-80.8c-0.1-1,0.7-2,1.8-1.9L912,197.8z M993.8,124.7c-1.3,1.3-2,2.3-3.7,2.3h-85.6  c-15.9,0-4.2-3.7-2.2-5.7c1.3-1.3,3-2.3,4.7-2.3h84.6C997.5,119,995.9,122.8,993.8,124.7z"
        />
      </g>
    );
  }
}

let composed = WingBackRightCoupe;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
