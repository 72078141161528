import { Component } from 'react';
import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class DoorBackRightVan extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          d="M1074,459V331l-179.2,10.2c0,0,0,64.8,0,117.8H1074z M1021.5,443H913v-86.9l107.6-5.6L1021.5,443z"
        />
        <path
          {...this.props}
          d="M1036.5,348.7l22-1c0.3,0,0.5,0.2,0.5,0.5v93.6c0,0.3-0.2,0.5-0.5,0.5h-22c-0.3,0-0.5-0.2-0.5-0.5v-92.7   C1036,348.9,1036.2,348.7,1036.5,348.7z"
        />
        <path
          {...this.props}
          d="M916,439.9v-80c0-0.6,0.5-1.1,1.1-1.1l99-4.9c0.6,0,1.2,0.5,1.2,1.1l1,84.9c0,0.6-0.5,1.1-1.1,1.1h-100  C916.5,441,916,440.5,916,439.9z"
        />
      </g>
    );
  }
}

let composed = DoorBackRightVan;
composed = SectionComponent({
  name: 'Right Back Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Right Back Door Damage',
    content: <Modal name="Right Back Door" />,
  },
})(composed);

export default composed;
