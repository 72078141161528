import { Component } from 'react';
import s from '../Review.module.scss';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class VariantSelection extends Component {
  state = {
    variants: [],
    selected: null,
    selectHandler: () => {},
  };

  UNSAFE_componentWillMount() {
    const variants = this.props.data;
    const arr = variants.map((item, i) => {
      return { key: item.ID, value: item.Name };
    });

    //console.log(filteredArr);
    this.setState({
      selected: this.props.selectedIndex + 1,
      variants: arr,
      selectHandler: this.props.selectHandler
        ? this.props.selectHandler
        : () => {},
    });
  }

  handleSelect = (e, i, v) => {
    this.setState({
      selected: v,
    });
    this.state.selectHandler(i);
  };

  render() {
    return (
      <div className={s.root}>
        <p className={s.subtitle}>
          {this.state.variants.length - 1} Available Variants (select to
          continue)
        </p>
        <SelectField
          maxHeight={200}
          fullWidth={true}
          value={this.state.selected}
          floatingLabelFixed={true}
          floatingLabelText="Please Select a Variant"
          onChange={this.handleSelect}
        >
          <MenuItem key={0} value={null} primaryText="" />
          {this.state.variants.map((item, i) => {
            return (
              <MenuItem key={i + 1} value={i + 1} primaryText={item.value} />
            );
          })}
        </SelectField>
      </div>
    );
  }
}

let composed = VariantSelection;

export default composed;
