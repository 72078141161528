import { Component } from 'react';
import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class DoorBackLeftVan extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          d="M894.8,464c0,53,0,117.6,0,117.6l179.2,10V464H894.8z M1020.6,572.3L913,566.5V480h108.5L1020.6,572.3z"
        />
        <path
          {...this.props}
          d="M1036.5,573.9l22,1c0.3,0,0.5-0.2,0.5-0.5v-93.6c0-0.3-0.2-0.5-0.5-0.5h-22c-0.3,0-0.5,0.2-0.5,0.5v92.7   C1036,573.6,1036.2,573.9,1036.5,573.9z"
        />
        <path
          {...this.props}
          d="M916,483v79.9c0,0.5,0.4,1,0.9,1l99.2,5.1c0.6,0,1-0.4,1.1-1l1-84.9c0-0.6-0.4-1-1-1H917C916.4,482,916,482.4,916,483z"
        />
      </g>
    );
  }
}

let composed = DoorBackLeftVan;
composed = SectionComponent({
  name: 'Left Back Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Left Back Door Damage',
    content: <Modal name="Left Back Door" />,
  },
})(composed);

export default composed;
