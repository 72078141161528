import Dispatcher from '../core/Dispatcher.js';
import ActionTypes from '../constants/ActionTypes.js';

const LockActions = {
  unlockCase(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_UNLOCK,
      data: data,
    });
  },
  updateLock(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_LOCK_UPDATE,
      data: data,
    });
  },

  refreshLocks(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CASE_LOCKS_REFRESH,
      data: data,
    });
  },
};

export default LockActions;
