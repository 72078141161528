import { Component } from 'react';
import s from './Summary.module.scss';
import TriageStore from '../../../../stores/TriageStore';
import AppStore from '../../../../stores/AppStore';
import OrganisationStore from '../../../../stores/OrganisationStore';
import OrganisationActions from '../../../../actions/OrganisationActions';
import TriageActions from '../../../../actions/TriageActions';
import AppActions from '../../../../actions/AppActions';
import MobileRepair from '../../FurtherActions/MobileRepair';
import SummaryList from './Components/SummaryList';
import AtFaultQuestion from '../../MajorDamage/AdditionalCaseInformation/AtFaultQuestion';
import PostcodeQuestion from '../../MajorDamage/AdditionalCaseInformation/PostcodeQuestion';

class Summary extends Component {
  static defaultProps = {
    onMobileHandler: () => {},
    onPromptHandler: () => {},
    onVariantChangeHandler: () => {},
    onCashInLieuChanged: () => {},
    onCarHireReset: () => {},
  };

  state = {
    currentCase: null,
    vehicleDetails: {},
    userData: null,
    decision: {},
    displaySettleAnswer: false,
    claimReferenceNumber: null,
    businessLineName: '',
    confirmedPostcode: null,
    currentPostcode: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      userData: AppStore.userData(),
    });
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
    OrganisationActions.getBusinessLinesForInsurer(
      AppStore.userData().User.InsurerId
    );
  }

  updateState = () => {
    const currentCase = this.props.case
      ? this.props.case
      : TriageStore.getCase();
    this.setState(
      {
        currentCase: currentCase,
        vehicleDetails: currentCase.vehicleDetails,
        decision: currentCase.decision,
        claimReferenceNumber:
          currentCase.metaData && currentCase.metaData.ClaimReferenceNumber
            ? currentCase.metaData.ClaimReferenceNumber === null
              ? null
              : currentCase.metaData.ClaimReferenceNumber.trim()
            : null,
        confirmedPostcode: currentCase.postcode ? currentCase.postcode : '',
        currentPostcode: currentCase.postcode ? currentCase.postcode : '',
      },
      () => {
        this.setState({
          displaySettleAnswer:
            this.state.decision &&
            this.state.decision.Direction === 5 &&
            currentCase.caseSettings.ShowSettlementPrompt,
        });
      }
    );

    if (
      currentCase.caseSettings &&
      currentCase.caseSettings.EnforceBusinessLines &&
      !currentCase.caseSettings.AllowSubInsurers
    ) {
      const businessLines = OrganisationStore.getBusinessLines();
      let businessLine = null;
      if (
        businessLines &&
        businessLines.length > 0 &&
        currentCase.businessLine
      ) {
        businessLine = businessLines.filter((item) => {
          return item.ID === currentCase.businessLine;
        });
        if (businessLine.length > 0) {
          this.setState({ businessLineName: businessLine[0].Name });
        }
      }
    }
  };

  handleMobileSelection = (e) => {
    const currentCase = this.props.case
      ? this.props.case
      : TriageStore.getCase();

    const answer = e.target.value * 1 === 1;

    TriageActions.confirmMobile(answer);
    AppActions.updateModal({
      disableConfirm: false,
      onConfirm: () => {
        AppActions.addSpinner();
        TriageActions.makeDecision(() => {
          if (!answer) {
            const decision = TriageStore.getDecision();
            if (decision.Direction === 0) {
              //Has come back with direction of unknown - redirect to damage tool
              let newState = TriageStore.getProgressState();
              newState.MAX_PERMITTED = 2;
              newState.CURRENT_STAGE = 2;
              TriageActions.setProgressState(newState);
            } else if (currentCase.caseSettings.ShowCarHireGroup) {
              let newState = TriageStore.getProgressState();
              newState.CURRENT_STAGE = 3;
              TriageActions.setProgressState(newState);
            }
            AppActions.removeSpinner();
          } else {
            TriageActions.updateCarHireData(
              {
                selectedGroupCode: '',
                selectedGroupDescription: '',
                carHireCost: 0,
              },
              () => {
                TriageActions.makeDecision(() => {
                  AppActions.removeSpinner();
                });
              }
            );
          }
        });
      },
    });
  };

  onMobileHandler = () => {
    const currentCase = this.props.case
      ? this.props.case
      : TriageStore.getCase();
    const carHireMessage = currentCase.caseSettings.ShowCarHireGroup ? (
      <p style={{ padding: '0 0 0 10px' }}>
        Selecting "NO" will send you back to Stage 4 | Further Actions to select
        a Car Hire rate.
      </p>
    ) : null;

    AppActions.createModal({
      open: true,
      title: 'Edit Mobile repair answer',
      type: 'alert',
      disableConfirm: false,
      content: (
        <div>
          <MobileRepair
            changeHandler={this.handleMobileSelection}
            defaultSelected={this.state.currentCase.mobileConfirmed}
          />
          {carHireMessage}
        </div>
      ),
    });
  };

  onAtFaultHandler = () => {
    const currentCase = this.props.case
      ? this.props.case
      : TriageStore.getCase();
    const atFaultStatus = currentCase.atFaultStatus;
    AppActions.createModal({
      open: true,
      title: 'Change At Fault Status',
      type: 'alert',
      disableConfirm: false,
      content: (
        <div>
          <p>
            If the At Fault status is changed, you will need to confirm the case
            details
          </p>
          <AtFaultQuestion
            atFaultStatusChangeHandler={this.handleAtFaultConfirm}
            atFaultSelection={atFaultStatus}
          ></AtFaultQuestion>
        </div>
      ),
    });
  };

  handleAtFaultConfirm = (e, index, value) => {
    const currentCase = this.props.case
      ? this.props.case
      : TriageStore.getCase();

    if (value === currentCase.atFaultStatus) {
      return;
    }

    AppActions.addSpinner();
    this.setState({ atFaultSelection: value }, () => {
      TriageActions.setAtFaultStatus(value, () => {
        TriageActions.makeDecision(() => {
          AppActions.removeSpinner();
        });
        AppActions.updateModal({
          disableConfirm: this.state.atFaultSelection === 0,
          onConfirm: () => {
            AppActions.addSpinner();
            TriageActions.makeDecision(() => {
              AppActions.removeSpinner();
            });
            let newState = TriageStore.getProgressState();
            newState.CURRENT_STAGE = 1;
            newState.MAX_PERMITTED = 1;
            TriageActions.setProgressState(newState);
          },
          content: (
            <div>
              <p>
                The At Fault status has been changed - click OK to confirm the
                case details
              </p>
              <AtFaultQuestion
                atFaultStatusChangeHandler={this.handleAtFaultConfirm}
                atFaultSelection={this.state.atFaultSelection}
              />
            </div>
          ),
        });
      });
    });
  };

  getPostcodeModalContent = () => {
    return (
      <div>
        <PostcodeQuestion
          postcodeChangeHandler={this.postcodeChangeHandler}
          postcodeSaveHandler={this.postcodeSaveHandler}
          currentValue={this.state.currentPostcode}
          submittedValue={this.state.confirmedPostcode}
          styles={s}
        />
      </div>
    );
  };

  onPostcodeHandler = () => {
    AppActions.createModal({
      open: true,
      title: 'Edit Postcode',
      type: 'alert',
      disableConfirm: false,
      content: this.getPostcodeModalContent(),
      onConfirm: this.handlePostcodeConfirmed,
    });
  };

  postcodeChangeHandler = (postcode) => {
    this.setState({ currentPostcode: postcode }, () => {
      AppActions.updateModal({
        disableConfirm:
          this.state.currentPostcode !== this.state.confirmedPostcode,
        content: this.getPostcodeModalContent(),
      });
    });
  };

  postcodeSaveHandler = () => {
    AppActions.addSpinner();
    this.setState({ confirmedPostcode: this.state.currentPostcode }, () => {
      TriageActions.setTriagePostcode(this.state.confirmedPostcode, () => {
        TriageActions.makeDecision();
        AppActions.updateModal({
          disableConfirm:
            this.state.currentPostcode !== this.state.confirmedPostcode,
          content: this.getPostcodeModalContent(),
        });
        AppActions.removeSpinner();
      });
    });
  };

  handlePostcodeConfirmed = (e) => {
    AppActions.addSpinner();
    TriageActions.makeDecision(() => {
      AppActions.removeSpinner();
    });
  };

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  render() {
    const {
      vehicleDetails,
      decision,
      currentCase,
      businessLineName,
      userData,
      displaySettleAnswer,
      claimReferenceNumber,
    } = this.state;
    return (
      <SummaryList
        styles={s}
        businessLineName={businessLineName}
        currentCase={currentCase}
        vehicleDetails={vehicleDetails}
        decision={decision}
        userData={userData}
        onPromptHandler={this.props.onPromptHandler}
        displaySettleAnswer={displaySettleAnswer}
        claimReferenceNumber={claimReferenceNumber}
        variantChangeHandler={this.props.onVariantChangeHandler}
        onMobileHandler={this.onMobileHandler}
        cashInLieuChangeHandler={this.props.onCashInLieuChanged}
        carHireResetHandler={this.props.onCarHireReset}
        onAtFaultHandler={this.onAtFaultHandler}
        onPostcodeHandler={this.onPostcodeHandler}
      />
    );
  }
}

let composed = Summary;

export default composed;
