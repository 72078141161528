import { Component } from 'react';
import s from './LoginPage.module.scss';

import withViewport from '../../decorators/withViewport';
import Link from '../Link';
import AppApi from '../../api/appAPI';
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';

class ResetPage extends Component {
  state = {
    resetToken: null,
    isMounted: false,
    serverError: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      resetToken: this.props.resetToken,
      serverError: false,
    });
    if (!canUseDOM) return;
    AppApi.confirmReset(this.props.resetToken, (response) => {
      this.setState({
        serverError: !response.data,
      });
    });
  }

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  render() {
    const containerHeight = this.props.viewport
      ? this.props.viewport.height - 135
      : 0;
    const content = this.state.isMounted ? (
      !this.state.serverError ? (
        <div className={s.loginForm}>
          <h1>Your password has been reset</h1>
          <p>An email has been sent to you with new login details.</p>
          <p>
            <a className={s.forgotLink} onClick={Link.handleClick} href="/">
              Return to Login
            </a>
          </p>
        </div>
      ) : (
        <div className={s.loginForm}>
          <h1>There was an error with this request</h1>
          <p>It was not possible to complete this request.</p>
          <p>If the problem persists, please contact an Administrator.</p>
          <p>
            <a className={s.forgotLink} onClick={Link.handleClick} href="/">
              Return to Login
            </a>
          </p>
        </div>
      )
    ) : (
      <div className={s.loginForm}>Verifying...</div>
    );
    return (
      <div className={s.container} style={{ height: containerHeight }}>
        {content}
      </div>
    );
  }
}

let composed = withViewport(ResetPage);

export default composed;
