import { Component } from 'react';
import s from './ModalDialog.module.scss';

import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import AppActions from '../../actions/AppActions';

class ModalDialog extends Component {
  static defaultProps = {
    options: {
      type: 'alert',
      title: '',
      content: null,
      open: false,
      disableConfirm: false,
      customStyle: null,
    },
  };

  state = {
    type: null,
    disableConfirm: this.props.disableConfirm,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      type: this.props.options.type,
      disableConfirm: this.props.disableConfirm,
    });
  }

  getCorrectActions = () => {
    const opts = this.props.options;
    if (opts.actions !== undefined) return opts.actions;

    const ACTIONS_CONFIRM = [
      <RaisedButton
        key={0}
        style={{ marginRight: 10 }}
        title="click to cancel"
        label="cancel"
        primary={true}
        onClick={this.onCancel}
      />,
      <RaisedButton
        key={1}
        title="click to confirm"
        label="confirm"
        primary={true}
        disabled={opts.disableConfirm || false}
        onClick={this.onConfirm}
      />,
    ];

    const ACTIONS_RESET = [
      <RaisedButton
        key={0}
        style={{ marginRight: 10 }}
        title="click to reset"
        label="reset/cancel"
        primary={true}
        onClick={this.onCancel}
      />,
      <RaisedButton
        key={1}
        title="click to confirm"
        label="confirm"
        primary={true}
        disabled={false}
        onClick={this.onConfirm}
      />,
    ];

    const ACTIONS_ALERT = [
      <RaisedButton
        key={0}
        title="click to close"
        label="Ok"
        primary={true}
        disabled={opts.disableConfirm || false}
        onClick={this.onConfirm}
      />,
    ];

    const actions = {
      alert: () => {
        return ACTIONS_ALERT;
      },
      confirm: () => {
        return ACTIONS_CONFIRM;
      },
      reset: () => {
        return ACTIONS_RESET;
      },
    };
    const type =
      this.props.options.type === undefined ? 'alert' : this.props.options.type;
    //console.log("test", actions[type]());
    return actions[type]();
  };

  onCancel = () => {
    if (this.props.options.onCancel) this.props.options.onCancel();
    AppActions.destroyModal();
  };
  onConfirm = () => {
    if (this.props.options.onConfirm) this.props.options.onConfirm();
    AppActions.destroyModal();
  };

  render() {
    return (
      <Dialog
        title={this.props.options.title}
        className={s.modal}
        open={this.props.options.open || false}
        actions={this.getCorrectActions()}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        contentStyle={this.props.options.customStyle}
        repositionOnUpdate={true}
        disableConfirm={this.state.disableConfirm}
      >
        {this.props.options.content}
      </Dialog>
    );
  }
}

export default ModalDialog;
