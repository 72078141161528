import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackLeftVan extends Component {
  render() {
    return (
      <g className={s.wheelBackLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M772.7,786.1c-35,0-63.4,29.8-63.4,66.3c0,36.7,28.5,66.3,63.4,66.3c35,0,63.4-29.8,63.4-66.3  C836.2,815.6,807.8,786.1,772.7,786.1z M772.7,892.9c-21.5,0-38.8-18.2-38.8-40.7s17.3-40.7,38.8-40.7s38.8,18.2,38.8,40.7  S794,892.9,772.7,892.9z"
        />
        <path
          {...this.props}
          d="M772.7,814.1c-20.3,0-36.4,17-36.4,38.2s16.3,38.2,36.4,38.2c20.2,0,36.4-17,36.4-38.2  C809.2,831.4,793,814.1,772.7,814.1z M772.7,828.9c11.3,0,20.5,8.9,22,20.2h-1.3c-1.5-10.6-10.2-19-21-19c-10.8,0-19.5,8.1-21,19  h-1.3C752,837.8,761.5,828.9,772.7,828.9z M744.7,852.3h2.5H744.7z M772.7,875.7c-11.3,0-20.5-8.7-22-20h1.3  c1.5,10.6,10.2,18.7,21,18.7c10.5,0,19.3-8.1,21-18.7h1.3C793.3,867.1,783.9,875.7,772.7,875.7z M798.3,852.3h1.5H798.3z"
        />
      </g>
    );
  }
}

let composed = WheelBackLeftVan;
composed = SectionComponent({
  name: 'Back Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Passenger Wheel Damage',
    content: <Modal name="Back Passenger Wheel" />,
  },
})(composed);

export default composed;
