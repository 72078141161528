import { Component } from 'react';
import s from './TriageFooter.module.scss';
import cx from 'classnames';

import TriageStore from '../../../stores/TriageStore';

const STYLE_CLASSES = [
  { type: 0, value: s.standard }, //Unknown
  { type: 1, value: s.cosmetic }, //Cosmetic
  { type: 2, value: s.cosmetic }, //Mobile
  { type: 3, value: s.traditional }, //Fast Track
  { type: 4, value: s.traditional }, //Traditional
  { type: 6, value: s.possibleLoss }, //Possible Total Loss
  { type: 7, value: s.possibleLoss }, //Potential Total Loss
  { type: 5, value: s.totalLoss }, //Total Loss
  { type: 8, value: s.traditional }, //Cash in Lieu
];

class TriageFooter extends Component {
  state = {
    currentCase: {},
  };

  updateState = () => {
    this.setState({
      currentCase: TriageStore.getCase(),
    });
  };

  UNSAFE_componentWillMount() {
    TriageStore.addChangeListener(this.updateState);
    this.updateState();
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  render() {
    const { currentCase } = this.state;
    //console.log("tf state", this.state.currentCase.vehicleDetails);
    const details =
      currentCase === undefined ? null : currentCase.vehicleDetails;
    const decision =
      currentCase.decision == undefined ? {} : currentCase.decision;
    const style = STYLE_CLASSES.find((i) => {
      return i.type == currentCase.decision.Direction;
    });
    const appliedStyle = style === undefined ? s.standard : style.value;
    return (
      <div className={s.root}>
        <ul className={cx(appliedStyle, s.container)}>
          <li>
            <span className={s.contentWrap}>
              <span className={s.subTitle}>Registration:</span>
              <span className={cx(s.registration, s.content)}>
                {details.Registration}
              </span>
            </span>
          </li>
          <li>
            <span className={s.contentWrap}>
              <span className={s.subTitle}>Service:</span>
              <span className={s.content}>{details.ServiceUsed}</span>
            </span>
          </li>
          <li>
            <span className={s.contentWrap}>
              <span className={s.subTitle}>Additional:</span>
              <span className={s.content}>
                {decision.DrivabilityDescription}
              </span>
            </span>
          </li>
          <li>
            <span className={s.contentWrap}>
              <span className={s.subTitle}>Decision:</span>
              <span
                className={s.content}
                style={{ textTransform: 'capitalize' }}
              >
                {decision.DirectionText
                  ? decision.DirectionText.toLowerCase()
                  : ''}
              </span>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

let composed = TriageFooter;

export default composed;
