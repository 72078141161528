import React from 'react';
import styles from './Spinner.module.scss';
import MoonLoader from 'react-spinners/MoonLoader';

const Spinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.overlay}></div>
      <MoonLoader color="#00BCD4" size={150} />
    </div>
  );
};

export default Spinner;
