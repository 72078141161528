import RaisedButton from 'material-ui/RaisedButton';

const VariantSelection = (props) => {
  const s = props.styles;

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Selected Variant Model: </span>
      <strong className={s.cols_6}>
        <span>{props.selected || null}</span>
        {props.variantChangeHandler ? (
          <RaisedButton
            className={'summaryBtn'}
            label="Edit"
            primary={true}
            onClick={props.variantChangeHandler}
          />
        ) : null}
      </strong>
    </li>
  );
};

export default VariantSelection;
