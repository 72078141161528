import { Component } from 'react';

import s from './AgeEstimator.module.scss';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import numberPlates from 'uk-numberplates';

class AgeEstimator extends Component {
  state = {
    registration: null,
    submitHandler: null,
    disableEstimate: null,
    likelyAge: null,
    age: null,
    isError: false,
    errorMsg: null,
  };

  UNSAFE_componentWillMount() {
    this.setState(
      {
        registration: this.props.registration,
        submitHandler: this.props.submitHandler,
        disableEstimate: this.props.disableEstimate,
        age: 0,
      },
      () => {
        if (
          this.state.registration === undefined ||
          this.state.registration === null ||
          this.state.disableEstimate
        )
          return;

        numberPlates.validate(this.state.registration, (err, data) => {
          const likelyAge =
            data.year_of_issue === null || data.year_of_issue === 'DAT'
              ? 0
              : new Date().getFullYear() - data.year_of_issue;
          this.setState({
            age: likelyAge,
            likelyAge: likelyAge,
            isError: err || data.year_of_issue === 'DAT',
            errorMsg: this.getErrorMessage(err),
          });
          this.props.submitHandler(likelyAge * 10000);
        });
      }
    );
  }

  onDecrease = () => {
    if (!(this.state.age > 1)) return;
    this.setState(
      {
        age: this.state.age - 1,
      },
      () => {
        this.props.submitHandler(this.state.age * 10000);
      }
    );
  };

  onIncrease = () => {
    this.setState(
      {
        age: this.state.age + 1,
      },
      () => {
        this.props.submitHandler(this.state.age * 10000);
      }
    );
  };

  getErrorMessage = (err) => {
    if (err == false || err == null) return null;
    let errors = {
      1: 'This is an unknown UK registration format.',
      2: 'The registration contains invalid characters. Only A-Z and 0-9 are valid.',
      3: 'The string cannot contain an asterisk.',
      4: "This is an unknown UK registration format (contains 'Q' in wrong format).",
      5: "This is an unknown UK registration format (contains 'I' but not in Irish format).",
      6: "This is an unknown UK registration format (contains 'Z' but not in Irish format).",
      7: "This is an unknown UK registration format (contains 'Z' in first two characters).",
      8: 'This is an unknown UK registration format (contains a leading zero).',
    };
    return errors[err];
  };

  render() {
    //console.log(this.state)
    const errorText = this.state.isError ? (
      <p>
        <span
          style={{
            color: 'rgb(249, 46, 46)',
            textShadow: 'rgb(206, 28, 28) 1px 0px 0px',
          }}
        >
          We were not able to calculate the age of your vehicle based upon your
          registration.
        </span>
        <em style={{ color: 'rgb(249, 46, 46)' }}>{this.state.errorMsg}</em>
      </p>
    ) : null;

    const helpText =
      this.state.disableEstimate ||
      !this.state.registration ||
      this.state.isError ? (
        <p>Please manually enter the age of your vehicle:</p>
      ) : (
        <div>
          <p>
            Based on your registration, we estimate the age of your vehicle to
            be less than <strong>{this.state.likelyAge + 1}</strong> years old.
          </p>
          <p>Please amend this figure if incorrect:</p>
        </div>
      );

    const warningText =
      this.state.age > this.state.likelyAge &&
      !this.props.disableEstimate &&
      this.state.registration &&
      !this.state.isError ? (
        <div className={s.warningText}>
          <p>
            Warning: it is <strong>highly unlikely</strong> that the vehicle is
            more than {this.state.likelyAge} years old.
          </p>
          <hr></hr>
        </div>
      ) : null;

    return (
      <div className={s.ageEstimator}>
        {errorText}
        {helpText}
        <hr></hr>
        <div className={s.widget}>
          <div>
            <FloatingActionButton onClick={this.onDecrease} mini={true}>
              <ContentRemove />
            </FloatingActionButton>
          </div>
          <div className={s.ageValue}>{this.state.age}</div>
          <div>
            <FloatingActionButton onClick={this.onIncrease} mini={true}>
              <ContentAdd />
            </FloatingActionButton>
          </div>
        </div>
        <hr></hr>
        {warningText}
      </div>
    );
  }
}

let composed = AgeEstimator;

export default composed;
