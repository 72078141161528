import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightMPV extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M971,154.2V93.9c0-1.8-0.5-3.5-1.4-5.1c-4.8-8.1-13.1-13.6-22.5-14.8l-34.8-4.3l0,0.4h-2.1  c0.6,4,1,8.3,0.9,12.5c-0.7,35.2-22.6,62.1-50.1,73.5c-8,3.5-17.2,5.5-26.2,6c-42.7,2.1-77.3-28-82.3-75H741l0,0.1  c0,1.3,0.4,2.5,0.6,3.8c10,47.6,37.6,66.4,42,70.8c12.4,12.3,23.6,27.4,19.8,43c-10.4,42.5-22.4,79-38.9,119.3l-15.8,0.1  c0,0-59.8,0-63.6,0L541,324.7l0,5.5l366.8-0.1c10.3,0,19.6-6,23.9-15.3l28.3-61.2c4.6-10,6.9-20.9,6.6-31.9l-1.6-58.6l0.6-0.3  C968.9,161.2,971,157.8,971,154.2z M882.7,244.1l-28,68.5c-2.8,6.9-9.6,11.4-17,11.4H776l33-92.8v-0.2h64.9  C880.6,231.1,885.2,237.9,882.7,244.1z"
        />
        <path
          {...this.props}
          d="M970.5,157.1H861v-0.9c-8,3.5-17.2,5.5-26.2,6c-42.7,2.1-77.3-28-82.3-75H741l0,0.1c0,1.3,0.4,2.5,0.6,3.8  c10,47.6,37.6,66.4,42,70.8c12.4,12.3,23.6,27.4,19.8,43c-10.4,42.5-22.4,79-38.9,119.3l-15.8,0.1c0,0-59.8,0-63.6,0L541,324.7  l0,5.5l366.8-0.1c10.3,0,19.6-6,23.9-15.3l28.3-61.2c4.6-10,6.9-20.9,6.6-31.9l-1.6-58.6l0.6-0.3C968,161.6,969.7,159.1,970.5,157.1  z M882.7,244.1l-28,68.5c-2.8,6.9-9.6,11.4-17,11.4H776l33-92.8v-0.2h64.9C880.6,231.1,885.2,237.9,882.7,244.1z M961.8,209  c0.1,2.6-1.8,4.8-4.3,5.2l-20.5,2.9v-49h23L961.8,209z"
        />
      </g>
    );
  }
}

let composed = WingBackRightMPV;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
