import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightLightVan extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M897.5,60.5l-60.4-11l-1.3,33c-1.8,25.3-28.7,55.3-66.8,55c-47.4-0.4-63.2-41.4-63.1-41.5   c-5.8-16.7-4.3-50.5-4.3-50.5s-129.8-0.1-139.6,0c0,0,1.3,166,12.7,215.9c2.5,10.7,5.8,29.9,10.7,45.2c2.4,7.4,9.4,12.5,17.3,12.5   l274.8,1.2c7,0,12.9-5,13.9-11.9c6.7-46,7.3-192.2,7.3-192.2l7.2-7.7c2.4-2.6,3.7-5.9,3.7-9.4V74.8   C909.6,67.8,904.5,61.8,897.5,60.5z"
        />
        <path
          {...this.props}
          d="M909.6,99.2V74.8c0-7-5.1-13-12.1-14.3l-60.4-11l-1.3,33c-1.8,25.3-28.7,55.3-66.8,55   c-47.4-0.4-63.2-41.4-63.1-41.5c-5.8-16.7-4.3-50.5-4.3-50.5s-129.8-0.1-139.6,0c0,0,1.3,166,12.7,215.9   c2.5,10.7,5.8,29.9,10.7,45.2c2.4,7.4,9.4,12.5,17.3,12.5l274.8,1.2c7,0,12.9-5,13.9-11.9c6.7-46,7.3-192.2,7.3-192.2l7.2-7.7   C908.3,106,909.6,102.7,909.6,99.2z M601,296.6c-3.1,0-3.8-1.4-4.1-1.7c-0.1-0.1-0.1,0-0.1-0.1c-4.7-15.5-11.1-83.1-11.3-115.1h4   c0.3,30,4.8,90.5,12,116.1C601.5,296.2,601.3,296.6,601,296.6z M905.2,104.5H834l1.7-4.3h71L905.2,104.5z"
        />
      </g>
    );
  }
}

let composed = WingBackRightLightVan;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
