import GriddleActionCell from '../../GriddleActionCell';
import GriddleCheckBox from '../../GriddleCheckBox';
import GriddleDateCell from '../../GriddleDateCell';

const UserPageConfig = {
  fakeData: [
    {
      Id: 'B181B699-E61C-444B-81F1-1D4ADFC35FE6',
      UserName: 'mparkinson',
      InsurerName: 'ITG Group',
      InsurerId: 2,
      DepartmentName: undefined,
      DepartmentId: undefined,
      Email: 'mparkinson@inter-est.net',
      IsApproved: true,
      IsLocked: false,
      Created: '01/06/2016 12:18:26', //"2016-06-01 12:18:26.510",
      LastLogin: '03/06/2016 12:18:26',
      Comments: 'Admin',
    },
  ],

  columnMetadata: [
    {
      columnName: 'Id',
      order: 11,
      customComponent: GriddleActionCell,
      displayName: 'Actions',
    },
    {
      columnName: 'UserName',
      locked: false,
      order: 2,
      displayName: 'Username',
    },
    {
      columnName: 'InsurerName',
      locked: false,
      order: 3,
      displayName: 'Insurer',
    },
    {
      columnName: 'DepartmentName',
      locked: false,
      order: 4,
      displayName: 'Department',
    },
    {
      columnName: 'Email',
      locked: false,
      order: 5,
    },
    {
      columnName: 'IsLocked',
      locked: false,
      order: 7,
      displayName: 'Locked',
      customComponent: GriddleCheckBox,
      cssClassName: 'smallerTableColumn',
    },
    {
      columnName: 'Comments',
      locked: false,
      order: 8,
      displayName: 'Notes',
    },
    {
      columnName: 'Created',
      locked: false,
      order: 9,
      customComponent: GriddleDateCell,
      displayName: 'Created', //,
    },
    {
      columnName: 'LastLogin',
      locked: false,
      order: 10,
      customComponent: GriddleDateCell,
      displayName: 'Last Accessed', //,
    },
  ],
  ignoreColumns: [
    'InsurerId',
    'LastLogin',
    'DepartmentId',
    'IsApproved',
    'Roles',
    '$id',
    'IsAdmin',
    'IsTriageUser',
    'IsImageUser',
    'IsMICompanyManager',
    'IsMIDepartmentManager',
    'IsUsersCompanyManager',
    'IsUsersDepartmentManager',
  ],
};

export default UserPageConfig;
