import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelFrontRight extends Component {
  render() {
    return (
      <g className={s.wheelFrontRight}>
        <path
          {...this.props}
          className={s.tyre}
          d="M419.9,67.3c0-36.7-28.1-66.3-62.5-66.3s-62.5,29.8-62.5,66.3c0,36.7,28.1,66.3,62.5,66.3  C391.8,133.6,419.9,103.7,419.9,67.3z M395.5,67.3c0,22.5-17,40.7-38.2,40.7s-38.2-18.3-38.2-40.7s17-40.7,38.2-40.7  S395.5,44.8,395.5,67.3z"
        />
        <path
          {...this.props}
          d="M393.3,67.3c0-21.2-16.1-38.2-35.9-38.2c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2C377.2,105.5,393.3,88.3,393.3,67.3z   M379,63.5h-1.3c-1.5-10.6-10.1-18.7-20.7-18.7c-10.3,0-19,8.1-20.7,18.7h-1.2c1.7-11.3,10.6-20,21.7-20  C368.5,43.8,377.5,52.5,379,63.5z M379.3,70.5c-1.5,11.4-10.6,20.2-21.7,20.2c-11.1,0-20.2-8.9-21.7-20.2h1.3  c1.5,10.6,10.1,19,20.7,19c10.6,0,19.2-8.1,20.7-19H379.3z"
        />
      </g>
    );
  }
}

let composed = WheelFrontRight;
composed = SectionComponent({
  name: 'Front Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Front Driver Wheel Damage',
    content: <Modal name="Front Driver Wheel" />,
  },
})(composed);

export default composed;
