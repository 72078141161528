import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackRight extends Component {
  render() {
    return (
      <g>
        <path
          {...this.props}
          className={s.tyre}
          d="M920,66.3C920,29.8,892,0,857.5,0C823.1,0,795,29.6,795,66.3c0,36.5,28,66.3,62.5,66.3C892.1,132.6,920.1,103.1,920,66.3z   M895.7,66.5c0,22.5-17,40.7-38.2,40.7S819.3,89,819.3,66.5s17-40.7,38.2-40.7C878.5,25.8,895.7,44,895.7,66.5z"
        />
        <path
          {...this.props}
          d="M893.4,66.4c0-21.2-16-38.2-35.9-38.2c-19.8,0-35.9,17-35.9,38.2s15.9,38.2,35.9,38.2S893.5,87.3,893.4,66.4z M835.2,69.6  h1.3c1.5,10.9,10.1,19,20.7,19s19.2-8.4,20.7-19h1.3c-1.5,11.3-10.6,20.2-21.7,20.2C846.5,89.8,837.1,80.9,835.2,69.6z M832.4,66.4  h-2.5H832.4z M879.8,63h-1.3c-1.7-10.6-10.4-18.7-20.7-18.7c-10.6,0-19.2,8.1-20.7,18.7h-1.3c1.5-11.3,10.6-20,21.7-20  S877.8,51.6,879.8,63z M884.2,66.4h-1.5H884.2z"
        />
      </g>
    );
  }
}

let composed = WheelBackRight;
composed = SectionComponent({
  name: 'Back Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Driver Wheel Damage',
    content: <Modal name="Back Driver Wheel" />,
  },
})(composed);

export default composed;
