import { Component } from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

const CustomTooltip = (props) => {
  const { active } = props;
  if (active) {
    const { payload } = props;
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: 10,
          border: '1px solid silver',
        }}
      >
        <div>{`${payload[0].name}`}</div>
        <div style={{ color: '#333' }}>
          {`${payload[0].value}`} ({`${Math.floor(payload[0].percent * 100)}`})%
        </div>
      </div>
    );
  }
  return null;
};

export default class CustomPieChart extends Component {
  static defaultProps = {
    data: [],
    activeIndex: null,
    isAnimationActive: false,
    colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
  };

  render() {
    let total = 0.001;
    const data = Object.keys(this.props.data).map((item) => {
      const val = this.props.data[item];
      total += val;
      return { name: item, value: val };
    });
    const legendData = data.map((i, x) => {
      let d = {
        value:
          i.name +
          ': ' +
          i.value +
          ' (' +
          Math.floor(100 * (i.value / total)) +
          '%)',
        color: this.props.colors[x],
        label: i.name,
      };
      return d;
    });

    const isAnimationActive = this.props.isAnimationActive;
    const children = data.map((entry, index) => (
      <Cell
        key={index}
        fillOpacity={
          this.props.canFilter === false
            ? 0.9
            : this.props.activeIndex === null
            ? 0.9
            : this.props.activeIndex === index
            ? 0.9
            : 0.1
        }
        fill={this.props.colors[index % this.props.colors.length]}
      />
    ));
    return (
      <PieChart width={this.props.maxGraphWidth || 300} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="40%"
          innerRadius={40}
          outerRadius={100}
          animationDuration={300}
          labelLine={false}
          isAnimationActive={isAnimationActive}
          fill="rgb(0,188,212)"
        >
          {children}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          payload={legendData}
          wrapperStyle={{
            paddingLeft: 20,
            color: 'white',
          }}
        />
      </PieChart>
    );
  }
}
