import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const CarHireRefusal = (props) => {
  const s = props.styles;

  return (
    <div className={s.refusalBox}>
      <p className={s.colorPrimary}>
        If you do not wish to hire a car, please click here. Your car hire rate
        will be set to zero.
      </p>
      <div style={{ whiteSpace: 'nowrap', padding: 10 }}>
        <RaisedButton
          label="I do not wish to hire a car"
          primary={true}
          onClick={props.selectZeroHandler}
        />
      </div>
    </div>
  );
};

export default CarHireRefusal;
