import { Component } from 'react';
import s from './RateComponent.module.scss';
import AppActions from '../../../../actions/AppActions';
import TriageActions from '../../../../actions/TriageActions';
import TriageStore from '../../../../stores/TriageStore';
import SampleVehicles from './components/SampleVehicles';
import TabbedRateDropdowns from './components/TabbedRateDropdowns';
import CarHireRefusal from './components/CarHireRefusal';
import SuggestedRate from './components/SuggestedRate';
import SelectedRate from './components/SelectedRate';
import NoSuggestedRate from './components/NoSuggestedRate';

const EMPTY_CAR_HIRE = {
  GroupCode: '',
  GroupName: '',
  GroupCodeRate: 0,
  SampleVehicles: '',
};

class RateComponent extends Component {
  static defaultProps = {
    vehicleDetails: {},
  };

  state = {
    allGroupNames: [],
    selectedGroupNameCodes: [],
    defaultSuggestedRate: EMPTY_CAR_HIRE,
    allSuggestedRates: [],
    selectedRate: EMPTY_CAR_HIRE,
    duration: 0,
    tabIndex: 0,
    selectedGroupName: '',
    customRate: EMPTY_CAR_HIRE,
    customRateChanged: false,
  };

  UNSAFE_componentWillMount() {
    this.setupCarHirePage();
  }

  setupCarHirePage = () => {
    AppActions.addSpinner();
    TriageStore.getCarHireData((data) => {
      this.setState(
        {
          allGroupNames: data.AllGroupNames || [],
          selectedGroupNameCodes: data.SelectedGroupNameCodes
            ? this.orderGroupCodes(data.SelectedGroupNameCodes)
            : [],
          defaultSuggestedRate: data.DefaultSuggestedRate || null,
          allSuggestedRates: data.AllSuggestedRates || [],
          selectedRate: data.SelectedRate || EMPTY_CAR_HIRE,
          duration: data.Duration,
          selectedGroupName: data.SelectedRate
            ? data.SelectedRate.GroupName || ''
            : '',
          customRate: data.SelectedRate || EMPTY_CAR_HIRE,
        },
        () => {
          TriageActions.makeDecision(() => {
            AppActions.removeSpinner();
          });
        }
      );
    });
  };

  submitRate = (cb) => {
    TriageActions.updateCarHireData(this.state.selectedRate, () => {
      AppActions.addSpinner();
      TriageActions.makeDecision(() => {
        if (cb) cb();
        AppActions.removeSpinner();
      });
    });
  };

  customChangeHandler = (event, value) => {
    const newCustomRate = Object.assign(
      {},
      this.state.customRate,
      !isNaN(value) ? { GroupCodeRate: value } : {}
    );
    if (newCustomRate !== this.state.customRate) {
      this.setState(
        {
          customRate: newCustomRate,
          customRateChanged: true,
        },
        () => {
          this.props.setContinueState(false);
        }
      );
    } else {
      this.setState({
        customRateChanged: false,
      });
    }
  };

  resetCustomRateClickHandler = (event, value) => {
    this.setState(
      {
        customRate: this.state.selectedRate,
        customRateChanged: false,
      },
      () => {
        this.props.setContinueState(true);
      }
    );
  };

  submitCustomRateClickHandler = (event, value) => {
    this.setState(
      {
        selectedRate: this.state.customRate,
        customRateChanged: false,
      },
      () => {
        this.submitRate(() => {
          this.props.setContinueState(true);
        });
      }
    );
  };

  rateChangeHandler = (event, index, value) => {
    this.setState(
      {
        selectedRate: value,
        customRate: value,
        selectedGroupName: value.GroupName,
      },
      () => {
        this.submitRate();
      }
    );
  };

  suggestedRateChangeHandler = (event, index, value) => {
    if (value.GroupName !== this.state.selectedGroupName) {
      AppActions.addSpinner();
      //TODO: Don't know if there could be some code re-use here, but trying to avoid multiple setStates for performance
      TriageStore.getCarHireRatesForGroupName(value.GroupName, (data) => {
        this.setState(
          {
            selectedRate: value,
            customRate: value,
            selectedGroupName: value.GroupName,
            selectedGroupNameCodes: this.orderGroupCodes(data),
          },
          () => {
            this.submitRate();
          }
        );
      });
    } else {
      this.setState(
        {
          selectedRate: value,
          customRate: value,
          selectedGroupName: value.GroupName,
        },
        () => {
          this.submitRate();
        }
      );
    }
  };

  selectGroupNameHandler = (event, index, groupName) => {
    AppActions.addSpinner();
    TriageStore.getCarHireRatesForGroupName(groupName, (data) => {
      this.setState(
        {
          selectedGroupNameCodes: this.orderGroupCodes(data),
          selectedGroupName: groupName,
        },
        () => {
          AppActions.removeSpinner();
        }
      );
    });
  };

  selectZeroHandler = (event, index, value) => {
    AppActions.createModal({
      open: true,
      title: 'Confirm Car Hire Refusal',
      type: 'confirm',
      disableConfirm: false,
      content: <p>Your car hire rate will be set to zero.</p>,
      onConfirm: () => {
        this.rateChangeHandler(event, index, EMPTY_CAR_HIRE);
      },
    });
  };

  handleTabChange = (v) => {
    this.setState({
      tabIndex: v,
    });
  };

  orderGroupCodes = (groupCodes) => {
    groupCodes.map((option) => {
      let thenum = option.GroupCode.replace(/^\D+/g, '');
      option.orderNum = thenum;
    });
    groupCodes.sort(function (a, b) {
      return parseInt(a.orderNum) - parseInt(b.orderNum);
    });
    return groupCodes;
  };

  render() {
    const {
      allGroupNames,
      selectedGroupNameCodes,
      defaultSuggestedRate,
      allSuggestedRates,
      selectedGroupName,
      selectedRate,
      duration,
      tabIndex,
      customRate,
      customRateChanged,
    } = this.state;
    const { vehicleDetails } = this.props;
    const suggestedRateDisplay =
      defaultSuggestedRate && defaultSuggestedRate.GroupCodeRate != 0 ? (
        <SuggestedRate styles={s} defaultSuggestedRate={defaultSuggestedRate} />
      ) : (
        <NoSuggestedRate styles={s} />
      );

    return (
      <div className={s.root}>
        <h2>Car Hire</h2>

        <h3>
          <span className={s.durationBox}>
            Required for {duration} days.&nbsp;
          </span>
          {suggestedRateDisplay}
        </h3>
        <div className={s.flex}>
          <p className={s.colorPrimary}>Current vehicle:&nbsp;</p>
          <p style={{ flexGrow: 2 }}>
            {vehicleDetails.Manufacturer} {vehicleDetails.Model} (
            {vehicleDetails.Body})
          </p>
          <CarHireRefusal
            styles={s}
            selectZeroHandler={this.selectZeroHandler}
          />
        </div>
        <div className={s.flex}>
          <TabbedRateDropdowns
            styles={s}
            allGroupNames={allGroupNames}
            selectedGroupNameCodes={selectedGroupNameCodes}
            selectedGroupName={selectedGroupName}
            selectedRate={selectedRate}
            suggested={allSuggestedRates}
            tabIndex={tabIndex}
            handleTabChange={this.handleTabChange}
            selectGroupNameHandler={this.selectGroupNameHandler}
            rateChangeHandler={this.rateChangeHandler}
            suggestedRateChangeHandler={this.suggestedRateChangeHandler}
          />
          <SelectedRate
            styles={s}
            selectedRate={customRate}
            customChangeHandler={this.customChangeHandler}
            customRateChanged={customRateChanged}
            resetCustomRateClickHandler={this.resetCustomRateClickHandler}
            submitCustomRateClickHandler={this.submitCustomRateClickHandler}
          />
        </div>
        <SampleVehicles
          styles={s}
          sampleVehicleList={selectedRate.SampleVehicles}
        />
      </div>
    );
  }
}

let composed = RateComponent;

export default composed;
