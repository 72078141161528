import Dispatcher from '../core/Dispatcher.js';
import EventEmitter from 'eventemitter3';
import ActionTypes from '../constants/ActionTypes.js';
import lockAPI from '../api/lockAPI.js';
import EvSocket from '../core/evsocket-client';

let _socket;
let _lockedUsers = [];
const CHANGE_EVENT = 'change';

const LockStore = Object.assign({}, EventEmitter.prototype, {
  isCaseLocked(id) {
    return (
      _lockedUsers.filter((item) => {
        return item.caseId === id;
      }) !== undefined
    );
  },
  getLockedCases(cb) {
    return _lockedUsers;
  },
  setWSClient(data) {
    _socket = new EvSocket(data);
  },
  getWSClient(data) {
    return _socket;
  },
  emitChange() {
    this.emit(CHANGE_EVENT);
  },
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
});

Dispatcher.register(function (action) {
  var data = action.data;
  switch (action.actionType) {
    case ActionTypes.CASE_UNLOCK:
      if (data && data !== {}) {
        lockAPI.unlockCase(data, (success) => {
          if (success) {
            const amendedUsers = _lockedUsers.filter((item) => {
              return item.caseId !== data.caseId;
            });
            _lockedUsers = amendedUsers || [];
          } else {
            if (data.onFail) data.onFail();
          }
          if (data.onComplete) data.onComplete(success);
          LockStore.emitChange();
        });
      }
      break;

    case ActionTypes.CASE_LOCK_UPDATE:
      if (data && data !== {}) {
        lockAPI.updateLock(data, (success) => {
          //refresh only local
          if (!success) {
            if (data.onFail) data.onFail();
          }
          if (data.onComplete) data.onComplete(success);
          LockStore.emitChange();
        });
      }
      break;

    case ActionTypes.CASE_LOCKS_REFRESH:
      if (data && data !== {}) {
        if (data.Insurer && data.Insurer !== null) {
          lockAPI.getLockedCases(data.Insurer, (response) => {
            _lockedUsers = response;
            if (data.onComplete) data.onComplete(success);
            LockStore.emitChange();
          });
        }
      }
      break;
    default:
  }

  return true;
});

export default LockStore;
