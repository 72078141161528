import axios from 'axios';
import { apiServer, loginTimeout, queryTimeout } from '../config';

let hasLocalStorage = 'localStorage' in global;
if (hasLocalStorage) {
  const testKey = 'react-localstorage.mixin.test-key';
  try {
    // Access to global `localStorage` property must be guarded as it
    // fails under iOS private session mode.
    let ls = global.localStorage;
    ls.setItem(testKey, 'foo');
    ls.removeItem(testKey);
  } catch (e) {
    hasLocalStorage = false;
  }
}

const appAPI = {
  getToken() {
    return this.getIdentity() === null
      ? null
      : JSON.parse(this.getIdentity()).Token;
  },

  login(username, password, callback) {
    //callback = arguments[arguments.length - 1];
    var data = {
      Username: username,
      Password: password,
    };
    if (data.Username && data.Password) {
      axios({
        method: 'post',
        baseURL: apiServer,
        url: '/account/login',
        timeout: loginTimeout,
        data: data,
      })
        .then((response) => {
          this.setIdentity(response.data);
          if (callback) callback(response);
        })
        .catch((response) => {
          if (callback) callback(response);
        });
    }
  },

  setExpiry(dt, mins) {
    if (global.localStorage && global.localStorage.identity) {
      let identity = JSON.parse(global.localStorage.identity);

      if (identity.User) {
        identity.User.LoginExpiry = new Date(dt.getTime() + mins * 60000);
        global.localStorage.identity = JSON.stringify(identity);
      }
    }
  },

  register(username, password, confirm, callback) {
    callback = arguments[arguments.length - 1];
    var data = {
      username: username,
      password: password,
      confirm: confirm,
    };

    axios({
      method: 'post',
      baseURL: apiServer,
      url: '/account/register',
      timeout: queryTimeout,
      data: data,
    })
      .then((response) => {
        if (callback) callback(response);
      })
      .catch((err) => {});
  },

  getIdentity() {
    return global.localStorage && global.localStorage.identity
      ? global.localStorage.identity
      : null;
  },

  setIdentity(data, cb) {
    if (global.localStorage) {
      global.localStorage.removeItem('identity');
      global.localStorage.setItem('identity', JSON.stringify(data));
    }
    if (cb) cb();
  },

  logout(cb) {
    if (global.localStorage && global.localStorage.identity)
      delete global.localStorage.identity;
    if (cb) cb();
  },

  isLoggedIn(cb) {
    cb = arguments[arguments.length - 1];

    const identity = this.getIdentity();

    if (identity) {
      const token = JSON.parse(identity).Token;
      if (!token) {
        return false;
      }

      let hasResponded = false;
      setTimeout(() => {
        if (hasResponded === false) {
          if (cb) cb(false);
        }
      }, loginTimeout);

      axios({
        method: 'get',
        baseURL: apiServer,
        timeout: queryTimeout,
        url: '/account/verify/' + token,
      })
        .then((response) => {
          hasResponded = true;
          if (cb) cb(response.data);

          return response.data;
        })
        .catch((err) => {
          hasResponded = true;

          return false;
        });
    } else {
      return false;
    }
  },

  resetUser(username, cb) {
    axios({
      method: 'post',
      data: { username: username },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: '/account/reset/',
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((err) => {});
  },

  confirmReset(token, cb) {
    axios({
      method: 'post',
      data: { token: token },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: '/account/confirmReset/',
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((err) => {});
  },

  evaluatePassword(pwd, cb) {
    axios({
      method: 'post',
      data: {
        password: pwd,
      },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: '/account/evaluatePassword',
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((err) => {});
  },

  changePassword(data, cb) {
    axios({
      method: 'post',
      data: {
        UserName: data.username,
        OldPassword: data.oldPassword,
        NewPassword: data.newPassword,
      },
      baseURL: apiServer,
      timeout: queryTimeout,
      url: '/account/changePassword',
      headers: {
        Auth: this.getToken(),
      },
    })
      .then((response) => {
        if (cb) cb(response);
      })
      .catch((err) => {});
  },
};

export default appAPI;
