import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class BumperPanel extends Component {
  state = {
    showSideQuestion: null,
    showSizeQuestion: null,
    dentedOrGougedValue: null,
    tornOrRippedValue: null,
  };

  UNSAFE_componentWillMount() {
    this.updateState(this.props.defaultSelected || '0');
  }

  updateState = (value) => {
    switch (value) {
      case '4x':
        this.setState({
          showSideQuestion: true,
          showSizeQuestion: false,
          dentedOrGougedValue: '3',
          tornOrRippedValue: '4x',
        });
        break;
      case '3x':
        this.setState({
          showSideQuestion: true,
          showSizeQuestion: false,
          dentedOrGougedValue: '3',
          tornOrRippedValue: '3x',
        });
        break;
      case '3':
        this.setState({
          showSideQuestion: false,
          showSizeQuestion: true,
          dentedOrGougedValue: '3',
          tornOrRippedValue: '4x',
        });
        break;
      case '2':
        this.setState({
          showSideQuestion: false,
          showSizeQuestion: true,
          dentedOrGougedValue: '2',
          tornOrRippedValue: '4x',
        });
        break;
      default:
        this.setState({
          showSideQuestion: false,
          showSizeQuestion: false,
          dentedOrGougedValue: '3',
          tornOrRippedValue: '4x',
        });
    }
  };

  selectHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    this.updateState(value);
  };

  render() {
    const {
      showSideQuestion,
      showSizeQuestion,
      dentedOrGougedValue,
      tornOrRippedValue,
    } = this.state;
    const sideQuestion = showSideQuestion ? (
      <div className={this.props.style.subQuestions}>
        <div>
          <span>Is the damage only on the side?</span>
          <RadioButtonGroup
            className={this.props.style.radios}
            name="panel"
            defaultSelected={tornOrRippedValue}
            labelPosition="left"
          >
            <RadioButton value="3x" label="Yes" />
            <RadioButton value="4x" label="No" />
          </RadioButtonGroup>
        </div>
      </div>
    ) : null;

    const sizeQuestion = showSizeQuestion ? (
      <div className={this.props.style.subQuestions}>
        <div>
          <span>Is the damage smaller than your hand and not a crack?</span>
          <RadioButtonGroup
            className={this.props.style.radios}
            name="panel"
            defaultSelected={dentedOrGougedValue}
            labelPosition="left"
          >
            <RadioButton value="2" label="Yes" />
            <RadioButton value="3" label="No" />
          </RadioButtonGroup>
        </div>
      </div>
    ) : null;

    return (
      <div>
        <RadioButtonGroup
          className={this.props.style.radios}
          name="panel"
          onChange={this.selectHandler.bind(this)}
          defaultSelected={this.props.defaultSelected.toString()}
          labelPosition="left"
        >
          <RadioButton
            value={tornOrRippedValue}
            label="Torn/Crumpled/More than one dent or crack"
          />
          <RadioButton
            value={dentedOrGougedValue}
            label="A single dent, gouge or crack"
          />
          <RadioButton value="1" label="Scratched or scuffed" />
          <RadioButton value="0" label="There is no damage" />
        </RadioButtonGroup>
        {sideQuestion}
        {sizeQuestion}
      </div>
    );
  }
}
