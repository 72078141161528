import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofTwoDoorConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M819.6,578.1c3.4-35.2,3.4-203.3,0-238.4c-2.8-23.2-3-21.5-8.7-21.6c-24.4,1.5-174.5,2.2-250.6-2.8  c-22.3-3.2-32.7-3.4-33.4-0.7c-4.8,15.7-10.4,94.2-10.4,144.2c0,56.6,6.6,133.9,12.5,142.8c1.8,3.2,10.7,3.2,36.4,0  c80-0.2,218-0.2,245.4,1.4C816.7,603,816.8,601.4,819.6,578.1z"
      />
    );
  }
}

let composed = RoofTwoDoorConvertible;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
