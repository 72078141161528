import keyMirror from 'fbjs/lib/keyMirror';

export default keyMirror({
  //AUTH ACTIONS
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_REMOTE: 'LOGIN_USER_REMOTE',
  LOGOUT_USER: 'LOGOUT_USER',
  SET_EXPIRY: 'SET_EXPIRY',
  RESET_USER: 'RESET_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  AMEND_PASSWORD: 'AMEND_PASSWORD',
  SPINNER_ADD: 'SPINNER_ADD',
  SPINNER_REMOVE: 'SPINNER_REMOVE',
  MODAL_ADD: 'MODAL_ADD',
  MODAL_UPDATE: 'MODAL_UPDATE',
  MODAL_REMOVE: 'MODAL_REMOVE',
  //PROGRESS BAR ACTIONS
  PROGRESS_SET: 'PROGRESS_SET',
  PROGRESS_RESET: 'PROGRESS_RESET',
  SELECT_VARIANT: 'SELECT_VARIANT',
  //TRIAGE GENERIC ACTIONS
  CASE_CREATE: 'CASE_CREATE',
  CASE_DESTROY: 'CASE_DESTROY',
  CASE_CLEAR: 'CASE_CLEAR',
  CASE_UPDATE: 'CASE_UPDATE',
  CASE_LOAD: 'CASE_LOAD',
  CASE_LOAD_REMOTE: 'CASE_LOAD_REMOTE',
  CASE_LOCK: 'CASE_LOCK',
  CASE_UNLOCK: 'CASE_UNLOCK',
  CASE_LOCK_UPDATE: 'CASE_LOCK_UPDATE',
  CASE_LOCKS_REFRESH: 'CASE_LOCKS_REFRESH',
  CASE_MAKE_DECISION: 'CASE_MAKE_DECISION',
  CASE_SET_CRITS: 'CASE_SET_CRITS',
  CASE_FOLLOWON_UPDATE: 'CASE_FOLLOWON_UPDATE',
  CASE_SET_ENDPOINT: 'CASE_SET_ENDPOINT',
  CASE_GET_PDF: 'CASE_GET_PDF',
  CASE_SUBMIT_REF: 'CASE_SUBMIT_REF',
  CASE_ANSWER_SETTLEMENT: 'CASE_ANSWER_SETTLEMENT',
  CASE_ANSWER_CASH_IN_LIEU: 'CASE_ANSWER_CASH_IN_LIEU',
  CASE_GET_VEHICLE_HISTORY: 'CASE_GET_VEHICLE_HISTORY',
  CASE_SET_BL: 'CASE_SET_BL',
  CASE_SET_RETAIL: 'CASE_SET_RETAIL',
  CAR_HIRE_UPDATE: 'CAR_HIRE_UPDATE',
  CASE_SET_ATFAULT_STATUS: 'CASE_SET_ATFAULT_STATUS',
  CASE_SET_POSTCODE: 'CASE_SET_POSTCODE',
  //TRIAGE DAMAGE TOOL ACTIONS
  PARTS_ITEM_ADD: 'PARTS_ITEM_ADD',
  PARTS_QUESTIONSET_MARKCOMPLETE: 'PARTS_QUESTIONSET_MARKCOMPLETE',
  PARTS_QUESTIONSET_RESET: 'PARTS_QUESTIONSET_RESET',
  PARTS_ITEM_UPDATE: 'PARTS_ITEM_UPDATE',
  PARTS_QUESTIONSET_UPDATE: 'PARTS_QUESTIONSET_UPDATE',
  PARTS_ITEMS_GET: 'PARTS_ITEMS_GET',
  PARTS_RESET_ALL: 'PARTS_RESET_ALL',
  PARTS_RESET_DMG: 'PARTS_RESET_DMG',
  PARTS_SET_MODAL: 'PARTS_SET_MODAL',
  PARTS_GET_DAMAGE: 'PARTS_GET_DAMAGE',
  PARTS_RESET_LOCAL_DMG: 'PARTS_RESET_LOCAL_DMG',
  VEHICLE_SET_MODEL: 'VEHICLE_SET_MODEL',
  PARTS_REG_DMG: 'PARTS_REG_DMG',
  PARTS_REG_MULTIPLE_DMG: 'PARTS_REG_MULTIPLE_DMG',
  CONFIRM_MOBILE: 'CONFIRM_MOBILE',

  //USER ACTIONS
  USER_CREATE: 'USER_CREATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_SET_CURRENT: 'USER_SET_CURRENT',
  USER_SET_LOCK: 'USER_SET_LOCK',
  USER_CHECK_EXISTS: 'USER_CHECK_EXISTS',
  USER_UPDATE_NEW_USER_DETAILS: 'USER_UPDATE_NEW_USER_DETAILS',
  USER_DELETE_USER_DETAILS: 'USER_DELETE_USER_DETAILS',
  USER_RESET_EMAIL: 'USER_RESET_EMAIL',
  USER_INVALID_USER: 'USER_INVALID_USER',
  //ORG ACTIONS
  ORG_SETTINGS_UPDATE: 'ORG_SETTINGS_UPDATE',
  APP_SETTINGS_UPDATE: 'APP_SETTINGS_UPDATE',
  //REPORT ACTIONS
  REPORT_GET_OVERVIEW_REPORT: 'REPORT_GET_OVERVIEW_REPORT',
  REPORT_GET_DAILY_REPORT: 'REPORT_GET_OVERVIEW_REPORT',
  REPORT_GET_MONTHLY_REPORT: 'REPORT_GET_MONTHLY_REPORT',
  REPORT_GET_DEPARTMENT_REPORT: 'REPORT_GET_DEPARTMENT_REPORT',
  REPORT_GET_RAW_REPORT: 'REPORT_GET_RAW_REPORT',
  //ORG ACTIONS
  BUSINESS_LINES_GET: 'BUSINESS_LINES_GET',
});
