import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackRightVan extends Component {
  render() {
    return (
      <g className={s.wheelBackRight}>
        <path
          {...this.props}
          d="M833.1,66.7c0-36.5-28.4-66.3-63.4-66.3c-34.9,0-63.4,29.6-63.4,66.3c0,36.5,28.4,66.3,63.4,66.3  C804.7,133,833.2,103.5,833.1,66.7z M808.4,66.9c0,22.5-17.3,40.7-38.8,40.7c-21.5,0-38.8-18.2-38.8-40.7s17.3-40.7,38.8-40.7  C790.9,26.2,808.4,44.4,808.4,66.9z"
        />
        <path
          {...this.props}
          d="M769.6,28.2c-20.3,0-36.4,17-36.4,38.2s16.3,38.2,36.4,38.2c20.2,0,36.4-17,36.4-38.2  C806.2,45.5,789.9,28.2,769.6,28.2z M769.6,43c11.3,0,20.5,8.9,22,20.2h-1.3c-1.5-10.6-10.2-19-21-19c-10.8,0-19.5,8.1-21,19H747  C748.9,51.9,758.5,43,769.6,43z M741.6,66.4h2.5H741.6z M769.6,89.8c-11.3,0-20.5-8.7-22-20h1.3c1.5,10.6,10.2,18.7,21,18.7  c10.5,0,19.3-8.1,21-18.7h1.3C790.2,81.2,780.9,89.8,769.6,89.8z M795.2,66.4h1.5H795.2z"
        />
      </g>
    );
  }
}

let composed = WheelBackRightVan;
composed = SectionComponent({
  name: 'Back Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Driver Wheel Damage',
    content: <Modal name="Back Driver Wheel" />,
  },
})(composed);

export default composed;
