import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeftMPV extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackLeft)}>
        <path
          {...this.props}
          d="M786.5,688.1c-8.9-30.5-19.7-57.7-31.9-88.9l-136.1-1.1c-5.6,32.8-9.6,62.8-12.5,90H786.5z"
        />
        <path
          {...this.props}
          d="M875,674.2l-27.6-67.6c-2.7-7-10.1-9.5-17.2-9.5h-56.6l32.4,89h28.1h32.6C873.1,686.1,877.4,680.5,875,674.2z"
        />
      </g>
    );
  }
}

let composed = WindowBackLeftMPV;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
