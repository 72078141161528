import { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import { MenuItem } from 'material-ui/Menu';

class UsersSelectControl extends Component {
  createItems = (items, initialValue, initialValueText) => {
    let results = null;

    if (items) {
      results = items.map((item, index, list) => {
        return (
          <MenuItem key={index + 1} value={item.ID} primaryText={item.Name} />
        );
      });
      results.unshift(
        <MenuItem key={0} value={initialValue} primaryText={initialValueText} />
      );
    }
    return results;
  };

  render = () => {
    const isLocked = this.props.isLocked || false;
    const menuItems = this.props.items
      ? this.createItems(
          this.props.items,
          this.props.initialValue,
          this.props.initialValueText
        )
      : null;
    //console.log(menuItems);
    return (
      <SelectField
        disabled={isLocked}
        fullWidth={this.props.fullWidth || false}
        floatingLabelText={this.props.label || ''}
        ref={this.props.reference}
        value={this.props.data.value || 0}
        onChange={this.props.changeHandler.bind(
          this,
          this.props.dataName || '',
          this.props.initialValue
        )}
        errorText={this.props.data.isValid ? null : this.props.valMessage}
      >
        {menuItems}
      </SelectField>
    );
  };
}

export default UsersSelectControl;
