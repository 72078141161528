import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackLeft extends Component {
  render() {
    return (
      <g className={s.wheelBackLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M856.5,787.1c-34.5,0-62.5,29.8-62.5,66.3c0,36.7,28.1,66.3,62.5,66.3c34.5,0,62.5-29.8,62.5-66.3  C919.1,816.6,891.1,787.1,856.5,787.1z M856.5,893.9c-21.2,0-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7s38.2,18.2,38.2,40.7  S877.5,893.9,856.5,893.9z"
        />
        <path
          {...this.props}
          d="M856.5,815.1c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2c19.9,0,35.9-17,35.9-38.2C892.5,832.4,876.5,815.1,856.5,815.1z   M856.5,829.9c11.1,0,20.2,8.9,21.7,20.2h-1.3c-1.5-10.6-10.1-19-20.7-19s-19.2,8.1-20.7,19h-1.3  C836.1,838.8,845.5,829.9,856.5,829.9z M828.9,853.3h2.5H828.9z M856.5,876.7c-11.1,0-20.2-8.7-21.7-20h1.3  c1.5,10.6,10.1,18.7,20.7,18.7c10.3,0,19-8.1,20.7-18.7h1.3C876.8,868.1,867.6,876.7,856.5,876.7z M881.7,853.3h1.5H881.7z"
        />
      </g>
    );
  }
}

let composed = WheelBackLeft;
composed = SectionComponent({
  name: 'Back Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Passenger Wheel Damage',
    content: <Modal name="Back Passenger Wheel" />,
  },
})(composed);

export default composed;
