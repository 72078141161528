import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenWindPV extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenWind)}
        d="M318,310.5c12,0.3,93.7,18.7,104.5,20.5c0,0-17.4,56.5-18.4,124c-1.2,80.5,18.4,133,18.4,133   c-11.5,2.4-104.4,23.8-105,23c-22.5-74.1-23.1-191-1.4-297.6"
      />
    );
  }
}

let composed = ScreenWindPV;
composed = SectionComponent({
  name: 'Wind Screen',
  category: 'Screens',
  highlighted: false,
})(composed);

export default composed;
