import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackRightPV extends Component {
  render() {
    return (
      <g className={s.doorBackRight}>
        <path
          {...this.props}
          d="M680.9,321c4.1,0,7.4-3.3,7.4-7.4V202.7V87h-73h-73l-1,112.2C541,260.9,542,321,542,321H680.9z"
        />
        <path
          {...this.props}
          d="M675.8,315.4c6.6-0.1,8.6-3.1,8.6-12V91.5H542.3c0.2-0.6-1.3,118.3-1,134.2l0.7,89.8H675.8z M595,216.3  l-19.3,0.6c-10.3,0.6-20.5-1.9-22.9-5c-6-10.5,5.4-11.8,24.2-3.7L595,216.3z"
        />
      </g>
    );
  }
}

let composed = DoorBackRightPV;
composed = SectionComponent({
  name: 'Right Sliding Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Right Sliding Door Damage',
    content: <Modal name="Right Sliding Door" />,
  },
})(composed);

export default composed;
