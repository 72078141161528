import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftHatchback extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M974.7,774.3l-3.7-38.2c-3.4-22.5-48.2-92.9-53.4-94.7c0,0-7-15.2-201.7-26.6c-0.9-0.1-1.7,1.5-1.8,2.4  c-0.9,6.3-4.4,30-7.5,50.9c-6.1,39.2-7.5,53.5-11.3,118.8L690,902.2c0,0.9,0.7,1.7,1.6,1.7h95.8c1.7,0,2.9-1.3,3.2-3l-0.2-29.1  c1-37.4,4.6-45.6,11.3-56.6c19-32,59.3-37.8,86.9-22.2c24,14,39.5,48.7,30.3,78.8l27.5-0.5c36.5-1,44-2.8,44-10.6  c0-3.4,2.4-8.1,4.8-10.3c3.5-3,4.8-11.1,4.8-28.1C1001,786.8,979.9,780.4,974.7,774.3z M707.3,721.1c1.4-14.2,11.6-89.4,12.4-89.4  c96.1,3.3,171.6,12.9,180.7,27.5c14.1,22.6,21.8,49.2,24.5,61.9H707.3L707.3,721.1z"
        />
        <path
          {...this.props}
          d="M974.7,774.6l-3.7-38.2c-3.4-22.5-48.2-92.9-53.4-94.7c0,0-7-15.2-201.7-26.6c-0.9-0.1-1.7,1.5-1.8,2.4  c-0.9,6.3-4.4,30-7.5,50.9c-6.1,39.2-7.5,53.5-11.3,118.8L690,902.4c0,0.9,0.7,1.6,1.6,1.6h95.8c1.7,0,2.9-1.2,3.2-2.9l-0.2-29  c1-37.4,4.6-45.6,11.3-56.6c19-32,59.3-37.8,86.9-22.2c24,14,39.5,48.7,30.3,78.8l27.5-0.5c36.5-1,44-2.8,44-10.6  c0-3.4,2.4-8.1,4.8-10.3c3.5-3,4.8-11.1,4.8-28.1C1001,787.1,979.9,780.7,974.7,774.6z M936,738.2c0-2.7,20.8-4.6,27.1-5.2  c1-0.1,1.8,0.6,1.9,1.6l3.4,34c0.1,1.1-0.7,2.1-1.8,2.1l-28.8,0.2c0,0,0,0-0.1,0c-1,0-1.7-1.1-1.7-2.1V738.2z M707.3,721  c1.4-14,11.6-89.2,12.4-89.2c96.1,3.3,171.6,12.8,180.7,27.4c14.1,22.6,21.8,49.8,24.5,61.8H707.3z M987.3,795h-80.9  c-6.6,0-8.2-0.5-9.5-1.8c-1.9-2-13.2-6.2,2.1-5.2h81c1.7,0,3.2,0.5,4.4,1.8C986.3,791.8,987.9,795,987.3,795z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftHatchback;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
