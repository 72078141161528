import RaisedButton from 'material-ui/RaisedButton';

const MobileConfirmed = (props) => {
  const s = props.styles;
  const currentCase = props.currentCase;
  const mobileConfirmed =
    currentCase.mobileConfirmed !== null &&
    currentCase.mobileConfirmed !== undefined
      ? currentCase.mobileConfirmed
        ? 'Yes'
        : 'No'
      : null;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Mobile Repair Required?</span>
      <strong className={s.cols_6}>
        <span>{mobileConfirmed}</span>
        <RaisedButton
          className={'summaryBtn'}
          label="Edit"
          primary={true}
          onClick={props.onMobileHandler}
        />
      </strong>
    </li>
  );
};

export default MobileConfirmed;
