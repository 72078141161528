import { Component } from 'react';
import PropTypes from 'prop-types';
import s from './AboutPage.module.scss';
import withViewport from '../../decorators/withViewport';

class AboutPage extends Component {
  static contextTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  render() {
    const versionNumber = '1.49.0';

    return (
      <div className={s.container}>
        <div className={s.aboutText}>
          <h2>Information</h2>
          <p>
            <span>Inter-cept 2.0</span> is an FNOL/Triage tool. Follow the
            wizard on the <a href="/triage/lookup">Triage page</a> to help you
            decide where an incident should be directed.
          </p>
          <p>
            You will be given a recommendation as to whether or not the vehicle
            is driveable.
          </p>
          <hr />

          <h2>Supported Desktop Browsers</h2>

          <div className={s.browsersContainer}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://raw.githubusercontent.com/alrra/browser-logos/master/src/chrome/chrome_32x32.png"
                      alt="Chrome logo"
                    />
                  </td>
                  <td>GOOGLE CHROME</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="https://raw.githubusercontent.com/alrra/browser-logos/master/src/firefox/firefox_32x32.png"
                      alt="Firefox logo"
                    />
                  </td>
                  <td>MOZILLA FIREFOX</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="https://raw.githubusercontent.com/alrra/browser-logos/master/src/edge/edge_32x32.png"
                      alt="Edge logo"
                    />
                  </td>
                  <td>MICROSOFT EDGE</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <h2>Version</h2>
          <p>v{versionNumber}</p>
        </div>
      </div>
    );
  }
}

let composed = withViewport(AboutPage);

export default composed;
