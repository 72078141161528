import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenWindVan extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenWind)}
        d="M357.1,313.4c12,0.3,80.6,20.3,91.4,22.1l0.8,242.9c-11.5,2.4-91,28.4-91.6,27.6   C310.8,543,311.1,397.3,357.1,313.4"
      />
    );
  }
}

let composed = ScreenWindVan;
composed = SectionComponent({
  name: 'Wind Screen',
  category: 'Screens',
  highlighted: false,
})(composed);

export default composed;
