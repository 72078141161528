import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class BumperRearVan extends Component {
  render() {
    return (
      <g className={s.bumperRear}>
        <path
          {...this.props}
          d="M1151.1,344.4c26.7,0,29.6-2.5,29.6-24.6c0-0.2,0-0.3,0-0.6c0-0.1,0-0.2,0-0.3c0-21.3-2.4-22.5-56.7-22.5  c-25.3,0-27.5,0.1-27.5,0.1c-0.1,0,0.5,329.8,0.5,329.9c0,0,19.4,0,19.5,0c61,0,64.1-1.1,64.1-24.6c0-0.8,0-1.4,0-2.1  c0-20.3-5.9-24.6-32.4-23.2l-8.5,0.8v-233H1151.1z"
        />
        <path
          {...this.props}
          d="M1119.7,626h-23.2V296.5h23.2c7.3,0,13.2,5.9,13.2,13.2v303C1133,620.1,1127.1,626,1119.7,626z"
        />
        <path
          {...this.props}
          d="M1093.3,296.4c-14-1.1-26.3,3.1-28,3.6c-0.1,0-0.3,0.2-0.4,0.2c-20.9-0.6-171,9.9-170.1,33.8c0,0.1,0,2,0,4h0.2l182.9-11.8   c0.7,0,1.1,0.2,1.1,0.9v268.7c0,0.7-0.4,1-1.1,1L895,585h-0.2c0,2,0,3.7,0,3.8c-0.9,23.9,149.1,34.4,170.1,33.7   c0.1,0,0.3,0.1,0.4,0.1c1.7,0.6,14,4.4,28,3.3C1093.3,622.1,1093.3,300.3,1093.3,296.4z"
        />
        <path
          {...this.props}
          d="M1093.3,461c0-3.4,0-6,0-10c0,0,0,0,0,0c0-81-0.1-158.9-0.1-154.5c-14-1.1-26.3,3-28,3.5  c-0.1,0-0.3,0.1-0.4,0.1c-20.9-0.6-171,9.6-170.1,33.5c0,0.1,0,1.4,0,3.4h0.2l182.9-11.3c0.7,0,1.1,0.7,1.1,1.4v125.4v17.6v125.4  c0,0.7-0.4,1.1-1.1,1.1L894.9,585h-0.2c0,2,0,3.6,0,3.7c-0.9,23.9,149.1,34.3,170.1,33.7c0.1,0,0.3,0,0.4,0.1c1.7,0.6,14,4.4,28,3.3  c0,4.3,0-72.8,0.1-154.8c0,0,0,0,0,0C1093.3,468,1093.3,464.4,1093.3,461z M1060,323.2c0,0.6-0.3,1.2-1,1.2l-31.6,1.7  c-0.7,0-1.4-0.5-1.4-1.2v-18.4c0-0.6,0.7-1.2,1.3-1.2l31.5-1.9c0.7,0,1.2,0.5,1.2,1.2V323.2z M1060,617.9c0,0.7-0.4,1.3-1.1,1.2  l-31.6-1.9c-0.6,0-1.2-0.6-1.2-1.2v-18.4c0-0.7,0.7-1.3,1.4-1.2l31.5,1.7c0.6,0,1.1,0.6,1.1,1.2V617.9z"
        />
      </g>
    );
  }
}

let composed = BumperRearVan;
composed = SectionComponent({
  name: 'Rear Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Rear Bumper Damage',
    content: <Modal name="Rear Bumper" />,
  },
})(composed);

export default composed;
