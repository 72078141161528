import React from 'react';

export default (props) => {
  const s = props.styles;

  return (
    <ul className={s.rateDisplay}>
      <li>Suggested Hire Rate: </li>
      <li className={s.rateError}>
        No match found <span>Please choose an appropriate rate below</span>
      </li>
    </ul>
  );
};
