import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRear4x4 extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M972.5,312.2L972.5,312.2c-0.8,0-61.6,5.5-61.6,5.5s0.5,283.2,0.5,283c0,0,60.8,3.5,60.8,3.5l0.2-0.5  C986,542.5,989.3,381.5,972.5,312.2z"
      />
    );
  }
}

let composed = ScreenRear4x4;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
