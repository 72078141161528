import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RoofModal/';

class RoofTwoDoorSaloon extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.roof}
        d="M799.4,579.1c3.2-35.2,3.2-203.3,0-238.4c-2.7-23.2-2.9-21.5-8.3-21.6c-23.2,1.5-166.1,2.2-238.6-2.8  c-21.2-3.2-31.1-3.4-31.8-0.7c-4.6,15.7-9.9,94.2-9.9,144.2c0,56.6,6.3,133.9,11.9,142.8c1.7,3.2,10.2,3.2,34.7,0  c76.2-0.2,207.6-0.2,233.6,1.4C796.6,604,796.7,602.4,799.4,579.1z"
      />
    );
  }
}

let composed = RoofTwoDoorSaloon;
composed = SectionComponent({
  name: 'Roof',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Roof'],
  modalModel: {
    title: 'Register Roof Damage',
    content: <Modal name="Roof" />,
  },
})(composed);

export default composed;
