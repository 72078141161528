import { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';

export default class SettingsDialog extends Component {
  static defaultProps = {
    editSubmitModel: () => {},
    editErrorState: () => {},
    processSettings: () => {},
    styles: null,
  };

  state = {
    displayData: null,
    rawData: null,
    editData: null,
    errorList: [],
    editable: [],
  };

  UNSAFE_componentWillMount() {
    const result = this.props.data;
    this.setState({
      rawData: Object.assign({}, result),
      editData: Object.assign({}, result),
      displayData: this.props.processSettings(Object.assign({}, result)),
    });
  }

  toggleEdit = (key) => {
    const editList = this.state.editable;
    const indexPos = this.state.editable.indexOf(key);

    if (indexPos < 0) {
      editList.push(key);
    } else {
      editList.splice(indexPos, 1);
    }

    this.setState({
      editable: editList,
    });
  };

  handleChange = (e, key) => {
    const newValue = e.target.value;

    const indexPos = this.state.errorList.indexOf(key);
    if (!this.checkIsValid(key, newValue)) {
      if (indexPos < 0) {
        this.state.errorList.push(key);
        this.props.editErrorState(this.props.table, this.state.errorList);
      }
    } else {
      this.state.errorList.splice(indexPos, 1);
      this.props.editErrorState(this.props.table, this.state.errorList);
    }

    this.setState({
      displayData: this.props.processSettings(this.state.editData),
    });

    this.props.editSubmitModel(this.props.table, key, newValue);
  };

  resetEditedValue = (key) => {
    this.state.editData[key] = this.state.rawData[key];
    this.setState({
      displayData: this.props.processSettings(this.state.editData),
    });
    this.toggleEdit(key);
    this.props.editSubmitModel(this.props.table, key, this.state.rawData[key]);

    const indexPos = this.state.errorList.indexOf(key);
    this.state.errorList.splice(indexPos, 1);
    this.props.editErrorState(this.props.table, this.state.errorList);
  };

  checkIsValid = (key, newValue) => {
    if (this.state.displayData[key].regex === null) return true;
    const regex = new RegExp(this.state.displayData[key].regex, 'gi');
    const editData = newValue + ''; //type forcing to allow regex match
    const match = editData.match(regex);
    const isValid = match !== null && editData == match[0];
    return isValid;
  };

  createDefaultBool = (key) => {
    this.state.editData[key] = false;
    this.setState({
      displayData: this.props.processSettings(this.state.editData),
    });
    this.props.editSubmitModel(this.props.table, key, false);
  };

  toggleBoolean = (key) => {
    let editData = Object.assign({}, this.state.editData);

    if (editData[key] === false) {
      editData[key] = true;
    } else {
      editData[key] = false;
    }
    this.setState(
      {
        editData: editData,
      },
      () => {
        this.setState({
          displayData: this.props.processSettings(this.state.editData),
        });

        this.props.editSubmitModel(
          this.props.table,
          key,
          this.state.editData[key]
        );
      }
    );
  };

  renderToggledItem = (key, i, s) => {
    const data = this.state.displayData;
    if (this.state.editable.indexOf(key) !== -1) {
      return (
        <li key={i}>
          <span className={s.name}>{data[key].name}</span>
          <span className={s.value}>
            <TextField
              style={{ maxWidth: 105 }}
              name="edit"
              errorText={
                this.state.errorList.indexOf(key) < 0 ? null : 'invalid'
              }
              onChange={(e) => {
                this.handleChange(e, key);
              }}
              defaultValue={data[key].data}
            />
          </span>
          <span>
            <RaisedButton
              label="Reset"
              primary={true}
              className={s.btn}
              onClick={() => {
                this.resetEditedValue(key);
              }}
            />
          </span>
        </li>
      );
    }

    return (
      <li key={i}>
        <span className={s.name}>{data[key].name}</span>
        <span className={s.value}>{data[key].displayValue || 'null'}</span>
        <span>
          <RaisedButton
            label="Edit"
            primary={true}
            className={s.btn}
            onClick={() => {
              this.toggleEdit(key);
            }}
          />
        </span>
      </li>
    );
  };

  renderBooleanItem = (key, i, s) => {
    const data = this.state.displayData;
    return (
      <li key={i}>
        <span className={s.name}>{data[key].name}</span>
        <span className={s.value}>
          {data[key].displayValue === null
            ? 'No entry in config'
            : data[key].displayValue
            ? 'Yes'
            : 'No'}
        </span>
        {data[key].displayValue === null ? (
          <span>
            <RaisedButton
              label="Create"
              primary={true}
              className={s.btn}
              onClick={() => {
                this.createDefaultBool(key);
              }}
            />
          </span>
        ) : (
          <span className={s.toggleWrap}>
            <Toggle
              onToggle={() => {
                this.toggleBoolean(key);
              }}
              thumbStyle={{ backgroundColor: 'rgba(208, 208, 208, 0.9)' }}
              defaultToggled={data[key].displayValue}
            />
          </span>
        )}
      </li>
    );
  };

  render() {
    const s = this.props.styles;

    const content = Object.keys(this.state.displayData).map((key, i) => {
      return this.state.displayData[key].isBool
        ? this.renderBooleanItem(key, i, s)
        : this.renderToggledItem(key, i, s);
    });

    return (
      <div className={s.dialogResult}>
        <ul>{content}</ul>
      </div>
    );
  }
}
