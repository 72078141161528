import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackRight4x4 extends Component {
  render() {
    return (
      <g className={s.wheelBackRight}>
        <path
          {...this.props}
          className={s.tyre}
          d="M769.5,28c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2c19.9,0,35.9-17,35.9-38.2C805.5,45.3,789.5,28,769.5,28z   M769.5,42.8c11.1,0,20.2,8.9,21.7,20.2h-1.3c-1.5-10.6-10.1-19-20.7-19s-19.2,8.1-20.7,19h-1.3C749.1,51.7,758.5,42.8,769.5,42.8z   M741.9,66.2h2.5H741.9z M769.5,89.6c-11.1,0-20.2-8.7-21.7-20h1.3c1.5,10.6,10.1,18.7,20.7,18.7c10.3,0,19-8.1,20.7-18.7h1.3  C789.8,81,780.6,89.6,769.5,89.6z M794.7,66.2h1.5H794.7z"
        />
        <path
          {...this.props}
          d="M769.5,0C735,0,707,29.8,707,66.3c0,36.7,28.1,66.3,62.5,66.3c34.5,0,62.5-29.8,62.5-66.3C832.1,29.5,804.1,0,769.5,0z   M769.5,106.8c-21.2,0-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7s38.2,18.2,38.2,40.7S790.5,106.8,769.5,106.8z"
        />
      </g>
    );
  }
}

let composed = WheelBackRight4x4;
composed = SectionComponent({
  name: 'Back Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Driver Wheel Damage',
    content: <Modal name="Back Driver Wheel" />,
  },
})(composed);

export default composed;
