import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRearConvertible extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M847.9,317.6c-20.2,4.5-19.5,0.7-12.5,66.1c6.3,60.2,4.1,138-5.2,195.7c-2.5,17,1.8,20.5,26.4,22.6  c15.7,1.1,15.7,1.1,23.6-15c26.4-53.5,25.7-214.4-1.8-261C870.8,312.8,869.8,312.5,847.9,317.6L847.9,317.6z"
      />
    );
  }
}

let composed = ScreenRearConvertible;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
