import EditButton from '../EditButton.js';

const TableConfig = {
  orgColumns: [
    {
      columnName: 'ID',
      order: 111,
      customComponent: EditButton,
      displayName: '[edit]',
    },
    {
      columnName: 'Name',
      locked: false,
      order: 2,
      displayName: 'Insurer Name',
    },
    {
      columnName: 'PreferredService',
      locked: false,
      order: 3,
      displayName: 'Preferred Service ',
    },
    {
      columnName: 'AlternativeServices',
      locked: false,
      order: 4,
      displayName: 'Alternative Services',
    },
    {
      columnName: 'CherisedVehicleAge',
      locked: false,
      order: 5,
      displayName: 'Cherished Vehicle Age',
    },
    {
      columnName: 'CherishedVehicleAnnualMileage',
      locked: false,
      order: 6,
      displayName: 'Cherished Vehicle Annual Mileage',
    },
    {
      columnName: 'ClassicVehicleAge',
      locked: false,
      order: 7,
      displayName: 'Classic Vehicle Age',
    },
    {
      columnName: 'MaximumValuation',
      locked: false,
      order: 8,
      displayName: 'Maximum Valuation',
    },
    {
      columnName: 'NewForOldAge',
      locked: false,
      order: 9,
      displayName: 'New For Old Age',
    },
    {
      columnName: 'PremiumVehiclePrice',
      locked: false,
      order: 10,
      displayName: 'Premium Vehicle Price',
    },
    {
      columnName: 'TradeLabourRate',
      locked: false,
      order: 11,
      displayName: 'Trade Labour Rate',
    },
    {
      columnName: 'TradePaintDiscount',
      locked: false,
      order: 12,
      displayName: 'Trade Paint Discount',
    },
    {
      columnName: 'TradePartsDiscount',
      locked: false,
      order: 13,
      displayName: 'Trade Parts Discount',
    },
    {
      columnName: 'WriteOffMultiplier',
      locked: false,
      order: 14,
      displayName: 'WriteOff Multiplier',
    },
  ],
  appColumns: [
    {
      columnName: 'InsurerAndDepIDs',
      order: 111,
      customComponent: EditButton,
      displayName: '[edit]',
    },
    {
      columnName: 'InsurerName',
      locked: false,
      order: 2,
      displayName: 'Insurer Name',
    },
    {
      columnName: 'DepartmentName',
      locked: false,
      order: 3,
      displayName: 'Department Name ',
    },
    {
      columnName: 'ShowRepairerSelectionGroup',
      locked: false,
      order: 4,
      displayName: 'Show Repairer Selection Group',
    },
    {
      columnName: 'ShowCarHireGroup',
      locked: false,
      order: 5,
      displayName: 'Show CarHire Group',
    },
    {
      columnName: 'ShowConfirmMobileGroup',
      locked: false,
      order: 6,
      displayName: 'Show Confirm Mobile Group',
    },
    {
      columnName: 'ShowImagesComponent',
      locked: false,
      order: 7,
      displayName: 'Show Images Component',
    },
    {
      columnName: 'AllowPDFDownload',
      locked: false,
      order: 8,
      displayName: 'Allow PDF Download',
    },
    {
      columnName: 'ShowValuation',
      locked: false,
      order: 9,
      displayName: 'Show Valuation',
    },
    {
      columnName: 'ShowRepairCost',
      locked: false,
      order: 10,
      displayName: 'Show Repair Cost',
    },
    {
      columnName: 'ShowDamageDescription',
      locked: false,
      order: 11,
      displayName: 'Show Damage Description',
    },
    {
      columnName: 'IsClaimReferenceMandatory',
      locked: false,
      order: 12,
      displayName: 'Is Claim Reference Mandatory',
    },
    {
      columnName: 'ClaimReferenceNumberRegex',
      locked: false,
      order: 13,
      displayName: 'Reference Number Regex',
    },
    {
      columnName: 'ShowSettlementPrompt',
      locked: false,
      order: 14,
      displayName: 'Show Settlement Prompt',
    },
    {
      columnName: 'ShowPercentageWriteOff',
      locked: false,
      order: 15,
      displayName: 'Show Percentage WriteOff',
    },
    {
      columnName: 'EnforceBusinessLines',
      locked: false,
      order: 16,
      displayName: 'Enforce Business Lines',
    },
    {
      columnName: 'ShowTotalLossCategory',
      locked: false,
      order: 17,
      displayName: 'Show Total Loss Category',
    },
    {
      columnName: 'ShowPAV',
      locked: false,
      order: 18,
      displayName: 'Show PAV',
    },
    {
      columnName: 'ShowReserveValue',
      locked: false,
      order: 19,
      displayName: 'Show Reserve Value',
    },
    {
      columnName: 'AllowVehicleHistoryQuery',
      locked: false,
      order: 20,
      displayName: 'Allow Vehicle History Query',
    },
  ],
  orgIgnores: [
    '$id',
    'InsightHandlerCode',
    'InsightInsurerCode',
    'InsightRepairerCode',
    'GTARatesCode',
  ],
  appIgnores: ['$id'],
};

export default TableConfig;
