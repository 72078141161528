import { Component } from 'react';
import s from './VariantSelection.module.scss';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TriageActions from '../../../../actions/TriageActions';
import TriageStore from '../../../../stores/TriageStore';
import triageAPI from '../../../../api/triageAPI';

class VariantSelection extends Component {
  state = {
    variants: [],
    selected: null,
    selectHandler: () => {},
  };

  UNSAFE_componentWillMount() {
    const variants = this.props.data;
    const arr = variants.map((item, i) => {
      const value =
        item.Name.trim().toLowerCase() === 'unknown'
          ? 'Variant is unknown'
          : item.Name;
      return { key: item.ID, value: value };
    });
    //console.log(filteredArr);
    this.setState({
      selected: 0,
      variants: arr,
      selectHandler: this.props.selectHandler
        ? this.props.selectHandler
        : () => {},
    });
  }

  handleSelect = (e, i, v) => {
    //console.log(e, i, v)
    if (i > 0) {
      TriageActions.selectVariantModel(i - 1, () => {
        triageAPI.getRetailValue((res) => {
          const currentCase = TriageStore.getCase();
          currentCase.vehicleDetails.Retail = res;
          currentCase.vehicleDetails.Variation =
            currentCase.variantModels[i - 1].Name;
          currentCase.vehicleDetails.VariantIndex = i - 1;
          TriageActions.updateCase(currentCase);
          TriageActions.makeDecision();
          this.props.setContinueState(true);
          //this.props.variantSatisfied = true;
        });
      });
    }
  };

  render() {
    //console.log(TriageStore.getCase())
    return (
      <div className={s.root}>
        <div className={s.row}>
          <div className={s.cols_6}>
            <div className={s.variantCopy}>
              <h2>Select a vehicle variant</h2>
              <p>
                To make the most accurate decision possible, please select from
                the following vehicle model variants.
              </p>
              <p>You can amend this answer later.</p>
            </div>
          </div>
          <div className={s.cols_6}>
            <p className={s.subtitle}>
              {this.state.variants.length - 1} Available Variants (select to
              continue)
            </p>
            <SelectField
              maxHeight={200}
              fullWidth={true}
              value={null}
              floatingLabelText="Select Variant Model"
              hintText="Please Select your Model"
              onChange={this.handleSelect}
            >
              <MenuItem key={0} value={null} primaryText="" />
              {this.state.variants.map((item, i) => {
                return (
                  <MenuItem
                    key={i + 1}
                    value={item.key}
                    primaryText={item.value}
                  />
                );
              })}
            </SelectField>
          </div>
        </div>
      </div>
    );
  }
}

let composed = VariantSelection;

export default composed;
