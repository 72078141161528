import React from 'react';
import { Tabs, Tab } from 'material-ui/Tabs';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

function getListOfGroupNameMenuItems(categories) {
  return categories
    ? categories.map((value, index, array) => {
        return <MenuItem key={index} value={value} primaryText={value} />;
      })
    : null;
}

function getListOfGroupCodeMenuItems(codes) {
  return (
    codes.map((data, i) => {
      const text =
        'GTA Group: ' +
        data.GroupName +
        ' | Code : ' +
        data.GroupCode +
        ' | Rate : £' +
        parseFloat(data.GroupCodeRate).toFixed(2);
      return <MenuItem key={i} value={data} primaryText={text} />;
    }) || null
  );
}

function getSelectedRateUIItem(array, selectedRate) {
  if (array === null) return selectedRate;

  const selectedElement = array.find((currentElement) => {
    return (
      currentElement.props.value.GroupCode === selectedRate.GroupCode &&
      currentElement.props.value.GroupCodeRate == selectedRate.GroupCodeRate
    );
  });

  return selectedElement ? selectedElement.props.value : selectedRate;
}

const TabbedRateDropdown = (props) => {
  const s = props.styles;

  const {
    tabIndex,
    handleTabChange,
    selectedGroupName,
    selectGroupNameHandler,
    rateChangeHandler,
    selectedRate,
    selectedGroupNameCodes,
    suggestedRateChangeHandler,
    allGroupNames,
    suggested,
  } = props;

  const suggestedRatesUI = getListOfGroupCodeMenuItems(suggested);
  const groupNameRatesUI = getListOfGroupCodeMenuItems(selectedGroupNameCodes);
  const groupNamesUI = getListOfGroupNameMenuItems(allGroupNames);

  const selectedSuggestedRateUI = suggestedRatesUI
    ? getSelectedRateUIItem(suggestedRatesUI, selectedRate)
    : selectedRate;
  const selectedGroupNameRatesUI = groupNameRatesUI
    ? getSelectedRateUIItem(groupNameRatesUI, selectedRate)
    : selectedRate;

  return (
    <Tabs
      className={s.tabbedRateDropdowns}
      value={tabIndex}
      onChange={handleTabChange}
    >
      <Tab className={s.tab} label="Find Vehicle Rate Manually" value={0}>
        <div className={s.flex}>
          <div
            className={s.flex}
            style={{ padding: '10px 10px 0 5px', flexGrow: 2 }}
          >
            <div style={{ width: '50%', paddingRight: 5 }}>
              <h3>Select Group</h3>
              <SelectField
                value={selectedGroupName}
                fullWidth={true}
                floatingLabelText="Available Groups"
                onChange={selectGroupNameHandler}
              >
                {groupNamesUI}
              </SelectField>
            </div>
            <div style={{ width: '50%', paddingLeft: 5 }}>
              <h3>Select Category</h3>
              <SelectField
                value={selectedGroupNameRatesUI}
                fullWidth={true}
                floatingLabelText="Available Categories"
                onChange={rateChangeHandler}
              >
                {groupNameRatesUI}
              </SelectField>
            </div>
          </div>
        </div>
      </Tab>
      <Tab className={s.tab} label="Get Rate By Vehicle Group" value={1}>
        <div className={s.flex}>
          <div style={{ padding: '10px 10px 0 5px', flexGrow: 2 }}>
            <h3 style={{ paddingBottom: 13 }}>Select your rate</h3>
            <SelectField
              value={selectedSuggestedRateUI}
              fullWidth={true}
              floatingLabelText="Available Rates"
              onChange={suggestedRateChangeHandler}
            >
              {suggestedRatesUI}
            </SelectField>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
};

export default TabbedRateDropdown;
