import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const Postcode = (props) => {
  const s = props.styles;
  const button = (
    <RaisedButton
      className={'summaryBtn'}
      label="Edit"
      primary={true}
      onClick={props.onPostcodeHandler}
    />
  );

  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Postcode: </span>
      <strong className={s.cols_6}>
        <span>{props.postcode ? props.postcode : '(none entered)'}</span>
        {button}
      </strong>
    </li>
  );
};

export default Postcode;
