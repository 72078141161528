import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontModal/';

class BumperFrontVan extends Component {
  render() {
    return (
      <g className={s.bumperFront}>
        <path
          {...this.props}
          d="M143.7,306.7c-14.2,1.3-15.2,1.2-15.2-4.8c0-5.8-1.5-6.6-10.8-6c-6,0.2-32.4,1.2-58.5,1.3  c-56,1.2-59.2,2.6-59.2,23.8c0,19.2,5.7,23,34.8,23h22.7v227.9H34.4c-29.6,0-34,3-34,23c0,21.4,2.7,22.3,71.9,23.6l56.5,1.2v-6.2  c0-5.8,1.5-6.2,14.7-5.8l14.2,0.8l1-151.7l0.7-151.7L143.7,306.7L143.7,306.7z"
        />
        <path
          {...this.props}
          d="M143.7,306.7c-14.2,1.3-15.2,1.2-15.2-4.8c0-5.8-1.5-6.5-10.8-6c-6,0.2-32.4,1.2-58.5,1.3  C3.2,298.4,0,299.8,0,321c0,19.1,5.7,23,34.8,23h22.7v227.2H34.3c-29.6,0-34,3-34,23c0,21.3,2.7,22.3,71.9,23.6l56.5,1.2v-6.1  c0-5.8,1.5-6.1,14.7-5.8l14.2,0.8l1-151.3l0.7-151.3L143.7,306.7L143.7,306.7z M99.9,545.4c0,19.8-1.5,35.5-3.3,36.4  c-1.7,0.8-6.4,0.2-10-1.3c-6.4-2.6-6.7-5-6.7-114.1h3.2c0,98.7,1,111.3,5.5,112.5c3.2,0.8,6,1.6,6.7,1.6s1.5-55.9,1.5-124.5  s-1-124.5-2.4-124.1c-11,1-11.3,2.3-11.3,118.3h-3.3c0-107.4,0.2-116.2,6-118.3c3.2-1.6,7.5-2.6,9.1-1.9c1.9,0.2,3.8,15.3,4.3,36.3  l1,35.5l19.1,0.8l1.5,106.2l-20.9,1.6V545.4L99.9,545.4z M150.9,599.1l-14.2-0.8c-13.2-0.5-14.7,0-14.7,5.8v1.6  c-1.5-1.6-3.2-2.6-5-3.8l-1-39.7c-1.7-50.8-1.7-50.6,20.6-38.7l12.4,7.2c0.7,0.5,1.5,0.8,1.9,1.3L150.9,599.1z M149.4,381.3  l-12.4,7.2c-22.3,11.9-22.3,12.1-20.6-38.7l1-39.7c1.9-0.8,3.8-2.2,5-3.8v1.6c0,5.8,1.5,6.1,14.7,5.8l14.2-0.8l0.2,67.7  C150.9,380.1,150.2,380.5,149.4,381.3L149.4,381.3z"
        />
      </g>
    );
  }
}

let composed = BumperFrontVan;
composed = SectionComponent({
  name: 'Front Bumper',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front'],
  modalModel: {
    title: 'Register Front Bumper Damage',
    content: <Modal name="Front Bumper" />,
  },
})(composed);

export default composed;
