import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightConvertible extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M996.4,171.8c-3.4-34-3.4-36.4,3-43.1c5.7-6.6,6.9-12.6,6.9-32c0-17-1.3-25.1-4.9-28.1c-2.8-2.5-4.9-7.2-4.9-10.3  c0-8.1-7.7-9.7-45.1-10.6l-27.1-0.5c-1.7,0-3,1.3-3.3,3l1,18c-0.5,26.1-13.6,43.6-31.8,56.9c-25.1,18.5-67.5,10.8-87-21.2  c-6.9-11.3-10.6-19.2-11.6-56.6l0.2-29.1c0-1.7-1.5-3-3.3-3h-99.8l5.4,117.3c3.9,65.3,5.1,79.9,11.6,118.8  c3.6,23.9,7.9,51.5,7.9,52.5c0,0,50.5-3.9,75.9-7.7c13.3-1.7,30-3.9,36.7-4.7c19.5-2.5,31-9.7,62.8-40.4l28.1-27.1  c0.5-0.5,11.8-10.8,12.6-10.8h29.5c37.2,0,42.6,2.2,40.9-6.6C999.9,203.8,998.2,191.5,996.4,171.8L996.4,171.8z M800.8,229.7  c-1.7,5.4-7.4,18.5-7.7,19.2c-4.4,8.9-9.8,31-76.1,33.3c0,0-8.7-68.7-10.1-84.3l101.7,1.5C808.7,199.1,805.1,216.3,800.8,229.7  L800.8,229.7z"
        />
        <path
          {...this.props}
          d="M996.4,171.8c-3.4-34-3.4-36.4,3-43.1c5.7-6.6,6.9-12.6,6.9-32c0-17-1.3-25.1-4.9-28.1c-2.8-2.5-4.9-7.2-4.9-10.3  c0-8.1-7.7-9.7-45.1-10.6l-27.1-0.5c-1.7,0-3,1.3-3.3,3l1,18c-0.5,26.1-13.6,43.6-31.8,56.9c-25.1,18.5-67.5,10.8-87-21.2  c-6.9-11.3-10.6-19.2-11.6-56.6l0.2-29.1c0-1.7-1.5-3-3.3-3h-99.8l5.4,117.3c3.9,65.3,5.1,79.9,11.6,118.8  c3.6,23.9,7.9,51.5,7.9,52.5c0,0,50.5-3.9,75.9-7.7c13.3-1.7,30-3.9,36.7-4.7c19.5-2.5,31-9.7,62.8-40.4l28.1-27.1  c0.5-0.5,11.8-10.8,12.6-10.8h29.5c37.2,0,42.6,2.2,40.9-6.6C999.9,203.8,998.2,191.5,996.4,171.8L996.4,171.8z M800.8,229.7  c-1.7,5.4-7.4,18.5-7.7,19.2c-4.4,8.9-9.8,31-76.1,33.3c0,0-8.7-68.7-10.1-84.3l101.7,1.5C808.7,199.1,805.1,216.3,800.8,229.7  L800.8,229.7z M896.7,234.3c-36.2,35-35.8,38.7-55.9,39.4c-7.9,0.2-5.9-5.7,14.1-24.6c14.3-14.3,26.4-28.6,32.3-28.6h20.7  C910.5,220.5,898.9,232.3,896.7,234.3z M963.4,193.9c-3-0.2-5.4-2.5-5.4-5.4v-31.8c0,0,0.2-0.7,1.7-0.7h26.1  c3.3,28.6,5.1,37.9,5.1,37.9C981.5,193.6,970.1,193.4,963.4,193.9z M992.3,130.1c-1.3,1.3-3,2-4.7,2h-85.3  c-16.1,0.5-4.2-3.3-2.2-5.4c1.3-1.3,3-2,4.7-2h85.3C995.9,124.4,994.3,128.1,992.3,130.1z"
        />
      </g>
    );
  }
}

let composed = WingBackRightConvertible;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
