import { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import TriageStore from '../../../../../stores/TriageStore.js';
import TriageActions from '../../../../../actions/TriageActions.js';
import ModalActions from '../../ModalActions/';

function ModalComponent(QUESTION_SETS, SUBTITLE) {
  return (ComposedComponent) =>
    class ModalComponent extends Component {
      state = {
        questionSets: [],
        completed: [],
        modalModel: {},
      };

      updateState = () => {
        this.setState({
          modalModel: TriageStore.getCurrentModal(),
        });
      };

      buttonHandler = (isOpen) => {
        if (this.state.modalModel === undefined) return;
        const isIncomplete =
          this.state.modalModel.currentStage + 1 < QUESTION_SETS.length;
        //console.log(this.state.modalModel.currentStage, questionSets.length);
        if (isOpen) {
          if (isIncomplete) {
            this.state.completed.push(
              this.state.questionSets[this.state.modalModel.currentStage]
                .section
            );
            this.state.modalModel.currentStage += 1;
          } else {
            isOpen = false;
            TriageActions.makeDecision(() => {
              TriageActions.addCompletedQuestionSets(this.state.completed);
            });
          }
        } else {
          if (!isIncomplete) {
            TriageActions.makeDecision(() => {
              TriageActions.addCompletedQuestionSets(this.state.completed);
            });
          }
        }

        this.state.modalModel.open = isOpen;
        TriageActions.setModal(this.state.modalModel);
      };

      UNSAFE_componentWillMount() {
        this.setState({
          questionSets: QUESTION_SETS,
        });
        this.updateState();
        TriageStore.addChangeListener(this.updateState);
      }

      componentDidMount() {
        this.state.modalModel.actions = [
          <ModalActions
            key={0}
            title={SUBTITLE}
            length={QUESTION_SETS.length}
          />,
          <FlatButton
            key={1}
            label="Cancel"
            primary={true}
            onClick={this.buttonHandler.bind(this, false)}
          />,
          <FlatButton
            key={2}
            label="Save and Continue"
            primary={true}
            onClick={this.buttonHandler.bind(this, true)}
          />,
        ];
        TriageActions.setModal(this.state.modalModel);
      }

      componentWillUnmount() {
        TriageStore.removeChangeListener(this.updateState);
      }

      render() {
        //console.log(this.props);
        return (
          <ComposedComponent
            {...this.props}
            modalModel={this.state.modalModel}
          ></ComposedComponent>
        );
      }
    };
}

export default ModalComponent;
