import { Component } from 'react';
import s from './PanelQuestions.module.scss';

import QuestionComponent from '../_questionsComponent/';
import TriageActions from '../../../../../actions/TriageActions';
import TriageStore from '../../../../../stores/TriageStore';
import BootPanel from './BootPanel';
import FrontWingPanel from './FrontWingPanel';
import DoorPanel from './DoorPanel';
import GenericPanel from './GenericPanel';
import BumperPanel from './BumperPanel';
import Mirrors from './Mirrors';
import Wheels from './Wheels';

const CURRENT_QUESTION = '';

class PanelQuestions extends Component {
  state = {
    currentPart: null,
  };

  UNSAFE_componentWillMount() {
    const currentPart = TriageStore.getAllParts().find((x) => {
      return x.name === this.props.modalModel.name;
    });
    //console.log(currentPart);
    this.setState({
      currentPart: currentPart,
      modalModel: this.props.modalModel,
    });
  }

  componentDidMount() {
    this.state.modalModel.actions[1] = null;
  }

  onChangeHandler = (e) => {
    e.preventDefault();

    const isDangerousDamage = e.target.value.length == 2 ? true : false;
    const damageSeverity =
      e.target.value.length == 1 ? e.target.value : e.target.value.charAt(0);

    TriageActions.updatePart(
      this.state.modalModel.name,
      { damageSeverity: damageSeverity, hasDangerousDamage: isDangerousDamage },
      true,
      () => {
        window.dispatchEvent(new Event('resize'));
        if (this.state.modalModel.updateHandler)
          this.state.modalModel.updateHandler();
      }
    );
  };

  render() {
    const damageSeverity =
      this.state.currentPart !== undefined
        ? this.state.currentPart.damageSeverity +
          (this.state.currentPart.hasDangerousDamage ? 'x' : '')
        : 0;

    const panels = {
      Boot: <BootPanel style={s} defaultSelected={damageSeverity} />,
      Bonnet: <BootPanel style={s} defaultSelected={damageSeverity} />,
      'Front Passenger Wing': (
        <FrontWingPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Front Driver Wing': (
        <FrontWingPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Front Passenger Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Front Driver Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Back Passenger Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Back Driver Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Left Back Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Left Sliding Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Right Sliding Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Right Back Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Rear Right Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Rear Left Door': (
        <DoorPanel style={s} defaultSelected={damageSeverity} />
      ),
      Tailgate: <DoorPanel style={s} defaultSelected={damageSeverity} />,
      'Front Bumper': (
        <BumperPanel style={s} defaultSelected={damageSeverity} />
      ),
      'Rear Bumper': <BumperPanel style={s} defaultSelected={damageSeverity} />,
      'Passenger Door Mirror': (
        <Mirrors style={s} defaultSelected={damageSeverity} />
      ),
      'Driver Door Mirror': (
        <Mirrors style={s} defaultSelected={damageSeverity} />
      ),
      'Front Passenger Wheel': (
        <Wheels style={s} defaultSelected={damageSeverity} />
      ),
      'Back Passenger Wheel': (
        <Wheels style={s} defaultSelected={damageSeverity} />
      ),
      'Front Driver Wheel': (
        <Wheels style={s} defaultSelected={damageSeverity} />
      ),
      'Back Driver Wheel': (
        <Wheels style={s} defaultSelected={damageSeverity} />
      ),
      'Driver Sill': (
        <GenericPanel
          style={s}
          defaultSelected={damageSeverity}
          stepThreeQuestionText="Is the damaged area equal to or longer than two hands?"
        />
      ),
      'Passenger Sill': (
        <GenericPanel
          style={s}
          defaultSelected={damageSeverity}
          stepThreeQuestionText="Is the damaged area equal to or longer than two hands?"
        />
      ),
    };

    const correctPanel = panels[this.state.modalModel.name] || (
      <GenericPanel style={s} defaultSelected={damageSeverity} />
    );
    //console.log(this.state.modalModel.name)
    return (
      <form className={s.formItem} onChange={this.onChangeHandler}>
        <h4>Please describe the damage:</h4>
        <div>{correctPanel}</div>
      </form>
    );
  }
}

let composed = PanelQuestions;
composed = QuestionComponent(CURRENT_QUESTION)(composed);

export default composed;
