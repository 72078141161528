import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackRightPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M775.6,87.2h-137c-0.6,0-1.1,0.5-1.1,1.1V208c0,0.6,0.5,1.1,1.1,1.1h137c0.6,0,1.1-0.5,1.1-1.1V88.3  C776.7,87.7,776.2,87.2,775.6,87.2z"
      />
    );
  }
}

let composed = DoorBackRightPickup;
composed = SectionComponent({
  name: 'Back Driver Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Driver Door Damage',
    content: <Modal name="Back Driver Door" />,
  },
})(composed);

export default composed;
