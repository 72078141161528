import { Component } from 'react';

function VehicleComponent(ComposedComponent) {
  return class VehicleComponent extends Component {
    render() {
      return (
        <ComposedComponent
          {...this.props}
          viewBox="0 0 1182 918"
          partsList={this.props.partsList}
        />
      );
    }
  };
}

export default VehicleComponent;
