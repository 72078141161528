import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackLeftPickup extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M775.6,831.4h-137c-0.6,0-1.1-0.5-1.1-1.1V710.6c0-0.6,0.5-1.1,1.1-1.1h137c0.6,0,1.1,0.5,1.1,1.1v119.7  C776.7,830.9,776.2,831.4,775.6,831.4z"
      />
    );
  }
}

let composed = DoorBackLeftPickup;
composed = SectionComponent({
  name: 'Back Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Passenger Door Damage',
    content: <Modal name="Back Passenger Door" />,
  },
})(composed);

export default composed;
