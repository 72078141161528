import React from 'react';
import TextField from 'material-ui/TextField';

const CustomRetails = (props) => {
  const s = props.styles;

  return (
    <div style={{ padding: '15px 0 0' }}>
      <h3>Maximum allowed custom value: £10m</h3>
      <div className={s.customRetailModal}>
        £
        <TextField
          style={{ textAlign: 'center', fontSize: '30px', maxWidth: 125 }}
          name="retail"
          value={props.value}
          onChange={props.changeHandler}
        />
      </div>
      {props.warn ? (
        <div className={s.warning}>
          This valuation is high given the supplied vehicle. Are you sure?
        </div>
      ) : null}
    </div>
  );
};

export default CustomRetails;
