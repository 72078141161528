import { Component } from 'react';
import triageAPI from '../../../api/triageAPI';
import AppActions from '../../../actions/AppActions';
import dateFormat from 'dateformat';

function withFileGenerator(ComposedComponent) {
  return class withFileGenerator extends Component {
    constructor() {
      super();
      this.state = {
        options: {
          PDF: {
            removeClasses: false,
            removeInlineStyles: false,
            dataHandler: null,
          },
          EXCEL: {
            isJSONFormat: false,
            dataHandler: null,
          },
        },
        setOptions: () => {},
      };
    }

    UNSAFE_componentWillMount() {
      this.setState({
        setOptions: (obj) => {
          this.state.options = Object.assign(this.state.options, obj);
        },
      });
    }

    createFileFromBlob = (data, fileName, type) => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let out = new Blob([data], { type: type });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(out, fileName);
        AppActions.removeSpinner();
        return;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        //target IOS
        let reader = new FileReader();
        reader.onload = function (e) {
          window.open(reader.result, '_blank');
        };
        reader.readAsDataURL(out);
        return;
      }

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(out);
      link.download = fileName;
      link.click();
    };

    removeStyles = (data) => {
      const { options } = this.state;
      if (!(options.removeInlineStyles || options.removeClasses)) return;

      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = data.HtmlMarkup;

      const descendents = htmlObject.getElementsByTagName('*') || [];
      if (descendents.length > 0) {
        descendents.forEach((e) => {
          if (options.removeInlineStyles) e.removeAttribute('style');
          if (options.removeClasses) e.removeAttribute('class');
        });
      }

      data.HtmlMarkup = htmlObject.innerHTML;
      console.log('data.HtmlMarkup: ', data.HtmlMarkup);
    };

    generatePDFHandler = (data) => {
      const pdfData = {
        HtmlMarkup: data.HtmlMarkup || '<div></div>',
        CssStyles: data.CssStyles || '',
        Name: data.Name || 'PDF',
        Title: data.Title ? '<h1>' + data.Title + '</h1>' : '',
      };

      this.removeStyles(pdfData);
      if (this.state.options.PDF.dataHandler !== null)
        this.state.options.PDF.dataHandler(pdfData);

      AppActions.addSpinner();
      triageAPI.generatePDF({
        pdfMarkup: pdfData.Title + pdfData.HtmlMarkup,
        pdfStyles: pdfData.CssStyles,
        callback: (res) => {
          try {
            const fileName = 'report(' + pdfData.Name + ').pdf';
            this.createFileFromBlob(res, fileName, 'application/pdf');
            AppActions.removeSpinner();
          } catch (e) {
            console.log(e);
            AppActions.removeSpinner();
          }
        },
      });
    };

    sanitiseField = (fieldName, row) => {
      let data = row[fieldName] || '';
      if (fieldName.toString() === 'Created') {
        data = dateFormat(new Date(data), 'dd/mm/yyyy HH:MM');
      }
      return JSON.stringify(data);
    };

    generateFields = (header, row) => {
      let data = header.map((fieldName) => {
        return JSON.stringify(row[fieldName]); //this.sanitiseField(fieldName, row);
      });

      return data;
    };

    convertJSONToCSV = (json) => {
      const header = Object.keys(json[0]);
      let csv = json.map((row) => {
        return this.generateFields(header, row);
      });

      csv.unshift(header);
      let csvString = csv.join('\r\n');

      return csvString;
    };

    generateExcelHandler = (data) => {
      let csvData = {
        data: data.data || {},
        name: data.name || '',
      };
      csvData.data =
        this.state.options.EXCEL.dataHandler !== null
          ? this.state.options.EXCEL.dataHandler(csvData.data)
          : csvData.data;
      csvData.data = this.state.options.EXCEL.isJSONFormat
        ? this.convertJSONToCSV(csvData.data)
        : csvData.data;

      const fileName = csvData.name + '.csv';
      try {
        this.createFileFromBlob(csvData.data, fileName, 'text/csv');
      } catch (e) {
        console.log(e);
      }
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          fileOptions={this.state.options}
          setFileOptions={this.state.setOptions}
          generatePDFHandler={this.generatePDFHandler}
          generateExcelHandler={this.generateExcelHandler}
        />
      );
    }
  };
}

export default withFileGenerator;
