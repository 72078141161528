import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackLeft4x4 extends Component {
  render() {
    return (
      <g className={s.wheelBackLeft}>
        <path
          {...this.props}
          className={s.tyre}
          d="M769.5,814.3c-20,0-35.9,17-35.9,38.2s16.1,38.2,35.9,38.2c19.9,0,35.9-17,35.9-38.2C805.5,831.6,789.5,814.3,769.5,814.3z   M769.5,829.1c11.1,0,20.2,8.9,21.7,20.2h-1.3c-1.5-10.6-10.1-19-20.7-19s-19.2,8.1-20.7,19h-1.3C749.1,838,758.5,829.1,769.5,829.1  z M741.9,852.5h2.5H741.9z M769.5,875.9c-11.1,0-20.2-8.7-21.7-20h1.3c1.5,10.6,10.1,18.7,20.7,18.7c10.3,0,19-8.1,20.7-18.7h1.3  C789.8,867.3,780.6,875.9,769.5,875.9z M794.7,852.5h1.5H794.7z"
        />
        <path
          {...this.props}
          d="M769.5,786.3c-34.5,0-62.5,29.8-62.5,66.3c0,36.7,28.1,66.3,62.5,66.3c34.5,0,62.5-29.8,62.5-66.3  C832.1,815.8,804.1,786.3,769.5,786.3z M769.5,893.1c-21.2,0-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7s38.2,18.2,38.2,40.7  S790.5,893.1,769.5,893.1z"
        />
      </g>
    );
  }
}

let composed = WheelBackLeft4x4;
composed = SectionComponent({
  name: 'Back Passenger Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Passenger Wheel Damage',
    content: <Modal name="Back Passenger Wheel" />,
  },
})(composed);

export default composed;
