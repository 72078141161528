import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class Flatbed4d extends Component {
  render() {
    return (
      <g className={s.boot}>
        <path
          {...this.props}
          d="M1017.9,262.9H801c-3.8,0-6.9,5.1-6.9,11.5v368c0,6.4,3.1,11.5,6.9,11.5h216.9c3.8,0,6.9-5.1,6.9-11.5v-368  C1024.8,268,1021.7,262.9,1017.9,262.9z"
        />
      </g>
    );
  }
}

let composed = Flatbed4d;
composed = SectionComponent({
  name: 'Flatbed',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Flatbed Damage',
    content: <Modal name="Flatbed" />,
  },
})(composed);

export default composed;
