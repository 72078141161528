import React from 'react';
import s from './GenericMI.module.scss';

import MIPieChart from '../MIPieChart/MIPieChart';

class GenericMI extends React.Component {
  state = {
    chartData: [],
    tableData: [],
    unknowns: 0,
    totalCases: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.prepareDataArray();
    }
  }

  prepareDataArray = () => {
    const data = this.props.miSummaryData.data;
    const unknowns = data && data['Unknown'] ? data['Unknown'] : 0;
    const possTL =
      data && data['POSSIBLE TOTAL LOSS'] ? data['POSSIBLE TOTAL LOSS'] : 0;

    let chartData = [];
    let tableData = [];
    let totalCases = 0;

    for (var key in data) {
      if (
        key.startsWith('$') ||
        key === 'Unknown' ||
        key === 'POSSIBLE TOTAL LOSS'
      )
        continue;
      const item = { name: key.toLowerCase(), value: data[key] };
      if (data[key] !== 0) chartData.push(item);
      tableData.push(item);
      totalCases = totalCases + data[key];
    }

    tableData.unshift({ name: 'total', value: totalCases });

    this.setState({
      chartData,
      tableData,
      unknowns: unknowns + possTL,
      totalCases,
    });
  };

  render() {
    const { unknowns, chartData, tableData, totalCases } = this.state;
    const infoTextPrefix = unknowns > 1 ? <span>are</span> : <span>is</span>;
    const plural = unknowns > 1 ? <span>s</span> : null;
    const unknownUI =
      unknowns > 0 ? (
        <h3 className={s.dataInfo}>
          Please note, there {infoTextPrefix} {unknowns} incomplete case{plural}{' '}
          which {infoTextPrefix} not included in this data
        </h3>
      ) : null;

    const colours = [
      '#0088FE',
      '#00C49F',
      '#FFBB28',
      '#FF8042',
      '#9542F4',
      '#FFFFFF',
      '#F44141',
      '#2039F7',
    ];

    const tableHeaderRow =
      tableData.map((item, index) => {
        return <th key={index}>{item.name}</th>;
      }) || [];

    const tableDataRow =
      tableData.map((item, index) => {
        return <td key={index}>{item.value}</td>;
      }) || [];

    const tableUI =
      totalCases > 0 ? (
        <table className={s.dataTable}>
          <thead className={s.dataTableHeader}>
            <tr>{tableHeaderRow}</tr>
          </thead>
          <tbody>
            <tr>{tableDataRow}</tr>
          </tbody>
        </table>
      ) : null;

    const pieChartUI =
      totalCases > 0 ? (
        <div className={s.chart}>
          <MIPieChart
            pieChartData={chartData}
            colours={colours}
            totalCases={totalCases}
          />
        </div>
      ) : null;

    const noDataUI =
      totalCases === 0 ? (
        <p className={s.noDataMessage}>No records found for this date range</p>
      ) : null;

    return (
      <div className={s.genericMIComponent}>
        <div className={s.miItem}>
          <div className={s.data}>
            <div>
              <h1>Repair Direction</h1>

              {unknownUI}
            </div>
            {tableUI}
          </div>

          {pieChartUI}
        </div>
        {noDataUI}
      </div>
    );
  }
}

let composed = GenericMI;

export default composed;
