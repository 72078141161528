import { Component } from 'react';
import s from './../MajorDamage.module.scss';
import TriageActions from '../../../../actions/TriageActions';
import AppActions from '../../../../actions/AppActions';
import TriageStore from '../../../../stores/TriageStore';
import AtFaultQuestion from './AtFaultQuestion';
import PostcodeQuestion from './PostcodeQuestion';

class AdditionalCaseInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      atFaultAsked: false,
      atFaultSelection: 0,
      postcodeRequested: false,
      confirmedPostcode: null,
      currentPostcode: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.updateState(() => {
      const currentCase = TriageStore.getCase();
      this.setState(
        {
          postcodeRequested: currentCase.postcode !== null,
        },
        () => {
          this.checkAdditionalQuestions(currentCase);
        }
      );
    });
    TriageStore.addChangeListener(this.updateState);
  }

  componentWillUnmount() {
    TriageStore.removeChangeListener(this.updateState);
  }

  updateState = (cb) => {
    const currentCase = TriageStore.getCase();

    this.setState(
      {
        // show AF question if it has not been answered or confirmed by the user yet. Once we move off this page, outcome will have been calculated, and AF answer will be in the outcome, and so we know it has been confirmed. Same for a loaded case.
        // Not using whether it has been set on the datamodel (in the root of currentCase) since for Saga TP, the datamodel has a default set, and we still want to show it in that case, for confirmation
        atFaultAsked:
          currentCase.decision &&
          currentCase.decision.AtFaultStatus &&
          currentCase.decision.AtFaultStatus !== 0
            ? true
            : false,
        atFaultSelection: currentCase.atFaultStatus,
        confirmedPostcode: currentCase.postcode ? currentCase.postcode : '',
        currentPostcode: currentCase.postcode ? currentCase.postcode : '',
      },
      () => {
        if (cb) cb();
      }
    );
  };

  isAdditionalInfoConfirmDisabled = (currentCase) => {
    const atFaultStatusDisableConfirm =
      currentCase.caseSettings &&
      currentCase.caseSettings.AllowAtFaultStatusCapture
        ? this.state.atFaultSelection === 0
        : false; //using false as it's whether to disable it - this means as far as atFault is concerned, the button can be clicked
    const postcodeDisableConfirm =
      currentCase.caseSettings && currentCase.caseSettings.AllowPostcodeCapture
        ? this.state.currentPostcode !== this.state.confirmedPostcode
        : false; //using false as it's whether to disable it - this means as far as postcode is concerned, the button can be clicked

    return atFaultStatusDisableConfirm || postcodeDisableConfirm;
  };

  getPostcodeUI = (currentCase) => {
    if (
      currentCase.caseSettings &&
      currentCase.caseSettings.AllowPostcodeCapture &&
      !this.state.postcodeRequested
    ) {
      return (
        <PostcodeQuestion
          postcodeChangeHandler={this.postcodeChangeHandler}
          postcodeSaveHandler={this.postcodeSaveHandler}
          currentValue={this.state.currentPostcode}
          submittedValue={this.state.confirmedPostcode}
          styles={s}
        />
      );
    }
    return null;
  };

  getAtFaultUI = (currentCase) => {
    if (
      currentCase.caseSettings &&
      currentCase.caseSettings.AllowAtFaultStatusCapture &&
      !this.state.atFaultAsked
    ) {
      return (
        <AtFaultQuestion
          atFaultStatusChangeHandler={this.submitAtFaultStatus}
          atFaultSelection={this.state.atFaultSelection}
          styles={s}
        />
      );
    }
    return null;
  };

  refreshAdditionalInfoModal = () => {
    const currentCase = TriageStore.getCase();
    const atFaultUI = this.getAtFaultUI(currentCase);
    const postcodeUI = this.getPostcodeUI(currentCase);
    AppActions.updateModal({
      disableConfirm: this.isAdditionalInfoConfirmDisabled(currentCase),
      content: (
        <div>
          {atFaultUI}
          {atFaultUI && postcodeUI ? <br /> : null}
          {postcodeUI}
        </div>
      ),
    });
  };

  checkAdditionalQuestions = (currentCase) => {
    const atFaultUI = this.getAtFaultUI(currentCase);
    const postcodeUI = this.getPostcodeUI(currentCase);

    if (!atFaultUI && !postcodeUI) {
      //if neither of them have been populated, don't show anything
      return;
    }

    AppActions.createModal({
      open: true,
      title: 'Additional Case Information',
      type: 'alert',
      disableConfirm: this.isAdditionalInfoConfirmDisabled(currentCase),
      onConfirm: () => {
        AppActions.addSpinner();
        TriageActions.makeDecision(() => {
          const currentCase = TriageStore.getCase();
          if (
            currentCase.caseSettings &&
            currentCase.caseSettings.AllowPostcodeCapture &&
            !this.state.postcodeRequested &&
            !currentCase.postcode
          ) {
            TriageActions.setTriagePostcode(
              this.state.confirmedPostcode,
              () => {
                //set the postcode to an empty string (as opposed to null) so we know the questions has been offered - but only if it should have been asked!
                AppActions.removeSpinner();
              }
            );
          } else {
            AppActions.removeSpinner();
          }
        });
      },
      content: (
        <div>
          {atFaultUI}
          {atFaultUI && postcodeUI ? <br /> : null}
          {postcodeUI}
        </div>
      ),
    });
  };

  submitAtFaultStatus = (e, index, value) => {
    AppActions.addSpinner();
    this.setState(
      {
        atFaultSelection: value,
      },
      () => {
        TriageActions.setAtFaultStatus(value, () => {
          AppActions.removeSpinner();
          this.refreshAdditionalInfoModal();
        });
      }
    );
  };

  postcodeChangeHandler = (postcode) => {
    this.setState({ currentPostcode: postcode }, () => {
      this.refreshAdditionalInfoModal();
    });
  };

  postcodeSaveHandler = () => {
    AppActions.addSpinner();
    this.setState({ confirmedPostcode: this.state.currentPostcode }, () => {
      TriageActions.setTriagePostcode(this.state.confirmedPostcode, () => {
        this.refreshAdditionalInfoModal();
        AppActions.removeSpinner();
      });
    });
  };

  render() {
    return null;
  }
}

export default AdditionalCaseInformation;
