import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelFrontRightVan extends Component {
  render() {
    return (
      <g className={s.wheelFrontRight}>
        <path
          {...this.props}
          className={s.tyre}
          d="M363.8,66.3c0-36.5-28.5-66.3-63.4-66.3S237,29.6,237,66.3c0,36.5,28.4,66.3,63.4,66.3S363.9,103.1,363.8,66.3z   M339.2,66.5c0,22.5-17.3,40.7-38.8,40.7S261.6,89,261.6,66.5s17.3-40.7,38.8-40.7S339.2,44,339.2,66.5z"
        />
        <path
          {...this.props}
          d="M336.8,66.3c0-21.2-16.2-38.2-36.4-38.2c-20.1,0-36.4,17-36.4,38.2s16.1,38.2,36.4,38.2S336.9,87.2,336.8,66.3z   M277.8,69.5h1.3c1.5,10.9,10.2,19,21,19c10.8,0,19.5-8.4,21-19h1.3c-1.5,11.3-10.8,20.2-22,20.2C289.2,89.7,279.7,80.8,277.8,69.5z   M274.9,66.3h-2.5H274.9z M323,62.9h-1.3c-1.7-10.6-10.6-18.7-21-18.7c-10.8,0-19.5,8.1-21,18.7h-1.3c1.5-11.3,10.8-20,22-20  S321,51.5,323,62.9z M327.5,66.3H326H327.5z"
        />
      </g>
    );
  }
}

let composed = WheelFrontRightVan;
composed = SectionComponent({
  name: 'Front Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Front Driver Wheel Damage',
    content: <Modal name="Front Driver Wheel" />,
  },
})(composed);

export default composed;
