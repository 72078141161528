import Dispatcher from '../core/Dispatcher.js';
import EventEmitter from 'eventemitter3';
import ActionTypes from '../constants/ActionTypes.js';
import AppAPI from '../api/appAPI.js';

const CHANGE_EVENT = 'changeAuth';
let _inProgress = false;
let _modalModel = {};
let _passwordChangeModel = {};

const AppStore = Object.assign({}, EventEmitter.prototype, {
  verifyUser(cb) {
    return AppAPI.isLoggedIn(cb);
  },

  userData() {
    return JSON.parse(AppAPI.getIdentity());
  },

  isUserExpired() {
    const identity = JSON.parse(AppAPI.getIdentity());
    //if(identity) console.log("identity", new Date(identity.User.LoginExpiry));
    if (identity) {
      if (new Date() > new Date(identity.User.LoginExpiry)) {
        return true;
      }
    }

    return false;
  },

  getSpinnerState() {
    return _inProgress;
  },

  getModalState() {
    return _modalModel;
  },

  getPasswordChangeState() {
    return _passwordChangeModel;
  },

  emitChange() {
    //console.log(CHANGE_EVENT);
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(callback) {
    //console.log(callback);
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    //console.log(callback);
    this.removeListener(CHANGE_EVENT, callback);
  },
});

Dispatcher.register(function (action) {
  switch (action.actionType) {
    case ActionTypes.LOGIN_USER:
      AppAPI.login(action.data.username, action.data.password, (response) => {
        action.data.callback(response);
        AppStore.emitChange();
      });

      break;

    case ActionTypes.LOGIN_USER_REMOTE:
      AppAPI.setIdentity(action.data, () => {
        AppStore.emitChange();
      });

      break;

    case ActionTypes.RESET_USER:
      AppAPI.resetUser(action.data.userName, (res) => {
        action.data.callback(res);
        AppStore.emitChange();
      });

      break;

    case ActionTypes.SET_EXPIRY:
      AppAPI.setExpiry(action.data.date, action.data.minutes);
      AppStore.emitChange();
      break;

    case ActionTypes.CHANGE_PASSWORD:
      AppAPI.changePassword(action.data, (response) => {
        action.data.callback(response, _passwordChangeModel);
        AppStore.emitChange();
      });
      break;

    case ActionTypes.EVALUATE_PASSWORD:
      AppAPI.evaluatePassword(action.data.password, (response) => {
        action.data.callback(response, _passwordChangeModel);
        AppStore.emitChange();
      });

      break;

    case ActionTypes.LOGOUT_USER:
      AppAPI.logout(() => {
        AppStore.emitChange();
      });

      break;

    case ActionTypes.SPINNER_ADD:
      _inProgress = true;
      AppStore.emitChange();
      break;

    case ActionTypes.SPINNER_REMOVE:
      _inProgress = false;
      AppStore.emitChange();
      break;

    case ActionTypes.MODAL_ADD:
      _modalModel = action.data;
      AppStore.emitChange();
      break;

    case ActionTypes.MODAL_UPDATE:
      _modalModel = Object.assign(_modalModel, action.data);
      AppStore.emitChange();
      break;

    case ActionTypes.MODAL_REMOVE:
      _modalModel = {};
      AppStore.emitChange();
      break;

    default:
  }
  return true;
});

export default AppStore;
