import { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export default class FrontDoorPanel extends Component {
  state = {
    defaultSelected: null,
    showStepTwoQuestion: null,
    showStepThreeQuestion: null,
    dentedOrGougedValue: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      defaultSelected: this.props.defaultSelected.toString() || '0',
      showStepTwoQuestion:
        this.props.defaultSelected == 2 ||
        this.props.defaultSelected == 3 ||
        this.props.defaultSelected == 4, // if 3, loaded damage was a single dent or gouge > 25%, not on the edge
      showStepThreeQuestion:
        this.props.defaultSelected == 3 || this.props.defaultSelected == 4, // if 4 and not 4x, loaded damage was not torn/crumpled/more than one dent but a single dent or gouge on the edge
      dentedOrGougedValue:
        this.props.defaultSelected == 3 || this.props.defaultSelected == 4
          ? this.props.defaultSelected.toString()
          : '2',
    });
  }

  dentStepOneHandler(value) {
    this.setState({
      showStepTwoQuestion: value,
      showStepThreeQuestion: false,
    });
  }

  dentStepTwoHandler(value) {
    this.setState({
      showStepThreeQuestion: value,
    });
  }

  render() {
    const subQuestion2 = this.state.showStepThreeQuestion ? (
      <div className={this.props.style.subQuestions}>
        <div className={this.props.style.formGroup}>
          <span>Is the damage on the edge?</span>
          <RadioButtonGroup
            className={this.props.style.radios}
            name="panel"
            defaultSelected={this.state.defaultSelected == 4 ? '4' : '3'}
            labelPosition="left"
          >
            <RadioButton value="4" label="Yes" />
            <RadioButton value="3" label="No" />
          </RadioButtonGroup>
        </div>
      </div>
    ) : null;

    const subQuestion1 = this.state.showStepTwoQuestion ? (
      <div className={this.props.style.subQuestions}>
        <div className={this.props.style.formGroup}>
          <span>
            Is the damaged area equal to or larger than two outspread hands?
          </span>
          <RadioButtonGroup
            className={this.props.style.radios}
            name="panel"
            defaultSelected={
              this.state.defaultSelected == 3 || this.state.defaultSelected == 4
                ? '3'
                : '2'
            }
            labelPosition="left"
          >
            <RadioButton
              value="3"
              label="Yes"
              onClick={this.dentStepTwoHandler.bind(this, true)}
            />
            <RadioButton
              value="2"
              label="No"
              onClick={this.dentStepTwoHandler.bind(this, false)}
            />
          </RadioButtonGroup>
        </div>
      </div>
    ) : null;

    return (
      <div>
        <RadioButtonGroup
          className={this.props.style.radios}
          name="panel"
          defaultSelected={this.state.defaultSelected}
          labelPosition="left"
        >
          <RadioButton
            value="4x"
            label="Torn/Crumpled/More than one dent"
            onClick={this.dentStepOneHandler.bind(this, false)}
          />
          <RadioButton
            value={this.state.dentedOrGougedValue}
            label="A single dent or gouge"
            onClick={this.dentStepOneHandler.bind(this, true)}
          />
          <RadioButton
            value="1"
            label="Scratched or scuffed"
            onClick={this.dentStepOneHandler.bind(this, false)}
          />
          <RadioButton
            value="0"
            label="There is no damage"
            onClick={this.dentStepOneHandler.bind(this, false)}
          />
        </RadioButtonGroup>
        {subQuestion1}
        {subQuestion2}
      </div>
    );
  }
}
