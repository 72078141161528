import { Component } from 'react';
import cx from 'classnames';
import s from './DecisionDialog.module.scss';
import TriageActions from '../../../actions/TriageActions';
import TriageStore from '../../../stores/TriageStore';
import AppActions from '../../../actions/AppActions';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import dateFormat from 'dateformat';

const STYLE_CLASSES = [
  { type: 0, value: s.standard }, //Unknown
  { type: 1, value: s.cosmetic }, //Cosmetic
  { type: 2, value: s.cosmetic }, //Mobile
  { type: 3, value: s.traditional }, //Fast Track
  { type: 4, value: s.traditional }, //Traditional
  { type: 6, value: s.possibleLoss }, //Possible Total Loss
  { type: 7, value: s.possibleLoss }, //Potential Total Loss
  { type: 5, value: s.totalLoss }, //Total Loss
  { type: 8, value: s.traditional }, //Cash in Lieu
];

class DecisionDialog extends Component {
  state = {
    pdfInProgress: false,
  };

  defaultProps = {
    onCancelHandler: () => {},
    onSubmitHandler: () => {},
    open: false,
    decision: {},
    currentCase: {},
    pdfMarkup: null,
    pdfStyles: null,
    showPDF: false,
    showCost: false,
    referenceNumber: '',
  };

  UNSAFE_componentWillMount() {
    this.updateState();
    TriageStore.addChangeListener(this.updateState);
  }

  updateState = () => {
    this.setState({
      decision: this.props.decision,
      currentCase: this.props.currentCase,
      pdfMarkup: this.props.pdfMarkup,
      pdfStyles: this.props.pdfStyles,
      showPDF: this.props.showPDF,
      referenceNumber: this.props.referenceNumber,
    });
  };

  componentWillUnmount = () => {
    TriageStore.removeChangeListener(this.updateState);
  };

  requestPDFHandler = (e) => {
    e.preventDefault();
    AppActions.addSpinner();
    TriageActions.generatePDF({
      pdfMarkup: this.props.pdfMarkup,
      pdfStyles: this.props.pdfStyles,
      callback: (data) => {
        try {
          const userAgent =
            navigator.userAgent || navigator.vendor || window.opera;
          const fileName =
            'Intercept_Report(' +
            this.props.currentCase.vehicleDetails.Registration +
            '_' +
            dateFormat(new Date(), 'yyyy_mm_dd') +
            ').pdf';
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(data, fileName);
            AppActions.removeSpinner();
            return;
          }
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //target IOS
            let reader = new FileReader();
            let out = new Blob([data], { type: 'application/pdf' });
            reader.onload = function (e) {
              window.open(reader.result, '_blank');
            };
            reader.readAsDataURL(out);
            AppActions.removeSpinner();
            return;
          }

          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = fileName;
          link.click();

          AppActions.removeSpinner();
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  render() {
    const pdfLabel = this.state.pdfInProgress
      ? 'GENERATING PDF REPORT...'
      : 'Download PDF';
    const pdfTitle = this.state.pdfInProgress
      ? 'pdf generation in progress'
      : 'click to download a pdf of this assessment';
    const pdf = this.state.showPDF ? (
      <li>
        <span className={s.pdf}>
          <RaisedButton
            title={pdfTitle}
            disabled={this.state.pdfInProgress}
            onClick={this.requestPDFHandler}
            label={pdfLabel}
            primary={true}
          />
        </span>
      </li>
    ) : null;
    const { decision, currentCase } = this.state;
    const style = STYLE_CLASSES.find((i) => {
      return i.type == decision.Direction;
    });
    const appliedStyle = style === undefined ? s.standard : style.value;
    const details = currentCase.vehicleDetails;

    const additionalInfo =
      details.Retail === undefined || details.Retail === 0 ? (
        <li>
          <strong>Additional Info: </strong>
          <span>
            <p>
              Be advised that a vehicle without valuation cannot have an
              accurate decision.
            </p>
            <p>Therefore an engineer should further inspect the vehicle.</p>
          </span>
        </li>
      ) : null;

    const totalCost =
      this.props.showCost && currentCase.decision.CarHireCost * 1 > 0 ? (
        <li>
          <strong>Total Cost: </strong>
          <span>£{parseFloat(currentCase.decision.TotalCost).toFixed(2)}</span>
        </li>
      ) : null;

    const damageDescription = this.props.currentCase.caseSettings
      .ShowDamageDescription ? (
      <li>
        <strong>Damage: </strong>
        <span>{decision.DamageDescription}</span>
      </li>
    ) : null;

    return (
      <div className={s.root}>
        <Dialog
          className={cx(appliedStyle)}
          autoScrollBodyContent={true}
          actions={[
            <RaisedButton
              key={0}
              title="click to complete this assessment"
              label="Finish"
              primary={true}
              onClick={this.props.onSubmitHandler}
            />,
          ]}
          title={'DECISION: ' + decision.DirectionText}
          open={this.props.open}
        >
          <ul className={s.lossDetails}>
            <li>
              <strong>Vehicle Details: </strong>
              <span>
                {details.Registration ? details.Registration.toUpperCase() : ''}{' '}
                - {details.Manufacturer} {details.Model}
              </span>
            </li>
            {this.props.currentCase.caseSettings.ShowPAV === true ? (
              <li>
                <strong>PAV: </strong>
                <span>£{parseInt(details.Retail).toLocaleString()}</span>
              </li>
            ) : null}
            {this.props.currentCase.caseSettings.ShowPAV === false &&
            this.props.currentCase.caseSettings.ShowValuation === true ? (
              <li>
                <strong>Typical Retail Value: </strong>
                <span>£{parseInt(details.Retail).toLocaleString()}</span>
              </li>
            ) : null}
            {damageDescription}
            <li>
              <strong>Drivability: </strong>
              <span>{decision.DrivabilityDescription}</span>
            </li>
            <li>
              <strong>Service Used: </strong>
              <span>{details.ServiceUsed}</span>
            </li>
            {additionalInfo}
            {totalCost}
            <li>
              <strong>Claim Reference Number: </strong>
              <span>
                {this.props.referenceNumber
                  ? this.props.referenceNumber.trim()
                  : ''}
              </span>
            </li>
            {pdf}
          </ul>
        </Dialog>
      </div>
    );
  }
}

export default DecisionDialog;
