const RepairCost = (props) => {
  const s = props.styles;
  const repairCost = props.decision ? props.decision.RepairCost || 0 : 0;
  return (
    <li className={s.detList__item}>
      <span className={s.cols_6}>Cost to Repair: </span>
      <strong className={s.cols_6}>
        £{parseFloat(repairCost).toFixed(repairCost % 1 != 0 ? 2 : 0)}
      </strong>
    </li>
  );
};

export default RepairCost;
