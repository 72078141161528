import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/FrontSideModal/';

class WingFrontRightConvertible extends Component {
  render() {
    return (
      <g className={s.wingFrontRight}>
        <path
          {...this.props}
          d="M710.1,304.9l-3.3-19.9c-18.5,2-105.8,6.2-120.1,0c0,0-13.5-4.8-23.2-11c-6.4-4-29.2-20.3-29.2-20.3S516,241.1,471.1,202  c-7.4-6.4-13.1-23.5-15.7-84l-1.2-98.2c0-3.2,0.4-3.2-3.3-3.3h-26.7c-1.9,0-3.3,1.5-3.3,3.3l1.1,39.1c-0.9,41.7-18,63.8-45,73.8  c-17.6,6.5-77.6,8.1-85.9-64.6c0-0.2,0-0.3,0-0.4l0.5-30.3c0-1.9-1.6-3.3-3.4-3.3l-33,1.9c-20.2,1.1-41.4,4.1-46.6,7  c-9.4,4.1-9.8,5.5-7.7,19.5c1.8,11.8,1.1,15.3-3.2,17c-9.8,3.8-7,33.4,5.2,58c15.7,31.3,21.6,36.1,56.6,47.3  c37.7,11.9,108.4,28.3,122.9,28.3c7,0,31.6,12.9,76.1,39.6c59.9,36.1,81.6,41.4,83.7,41.8c0.1,0,0.2,0,0.4,0  C589.3,309.8,650.3,308.2,710.1,304.9L710.1,304.9z"
        />
        <path
          {...this.props}
          d="M710.1,304.8l-3.3-19.9c-18.5,2-105.8,6.2-120.1,0c0,0-13.5-4.8-23.2-11c-6.4-4-29.2-20.3-29.2-20.3  S516,241,471.1,201.9c-7.4-6.4-13.1-23.5-15.7-84l-1.2-98.2c0-3.2,0.4-3.2-3.3-3.3h-26.7c-1.9,0-3.3,1.5-3.3,3.3l1.1,39.1  c-0.7,41.8-17.9,63.9-44.9,73.9c-17.6,6.5-77.6,8.1-85.9-64.6c0-0.2,0-0.3,0-0.4l0.5-30.3c0-1.9-1.6-3.3-3.4-3.3l-33,1.9  c-20.2,1.1-41.4,4.1-46.6,7c-9.4,4.1-9.8,5.5-7.7,19.5c1.8,11.8,1.1,15.3-3.2,17c-9.8,3.8-7,33.4,5.2,58  c15.8,31.3,21.7,36.2,56.7,47.3c37.5,11.8,108.1,28.2,122.7,28.2c7,0,31.6,12.9,76.1,39.6c59.9,36.1,81.6,41.4,83.7,41.8  c0.1,0,0.2,0,0.4,0C589.3,309.7,650.3,308.1,710.1,304.8L710.1,304.8z M221.2,160.3c-0.2-0.2-0.3-0.4-0.5-0.6  c-3.4-5.1-14.4-25.4-14.4-27.5c0-0.7,6.6-0.7,15.1-0.7c0.9,0,1.7,0.3,2.2,0.9c5,4.7,29.1,27.5,30,27.6  C268.1,170,226.9,165.4,221.2,160.3L221.2,160.3z M503.6,245.8c22.9,16.3,51.7,34.5,63.3,37.9h-33.1c-10.7-0.9-13.2-3.5-13.8-3.7  c-19.3-7.5-107.7-60.8-107.7-60.8c-7.1-4.6-8.9-10.3-5.7-10.6c13.5-1.2,44.2-2,51.1,1.5C463,212.5,482.1,230.4,503.6,245.8z"
        />
      </g>
    );
  }
}

let composed = WingFrontRightConvertible;
composed = SectionComponent({
  name: 'Front Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Front', 'Side'],
  modalModel: {
    title: 'Register Front Driver Wing Damage',
    content: <Modal name="Front Driver Wing" />,
  },
})(composed);

export default composed;
