import { Component } from 'react';
import s from '../_vehicleComponent/VehicleTypes.module.scss';

import BumperFront from '../../VehicleSections/BumperFront/BumperFrontVan/';
import BumperRear from '../../VehicleSections/BumperRear/BumperRearVan/';
import Bonnet from '../../VehicleSections/Bonnet/BonnetVan/';
import DoorFrontLeft from '../../VehicleSections/DoorFrontLeft/DoorFrontLeftVan/';
import DoorFrontRight from '../../VehicleSections/DoorFrontRight/DoorFrontRightVan/';
import DoorBackLeft from '../../VehicleSections/DoorBackLeft/DoorBackLeftVan/';
import DoorBackRight from '../../VehicleSections/DoorBackRight/DoorBackRightVan/';
import Roof from '../../VehicleSections/Roof/RoofLightVan/';
import ScreenWind from '../../VehicleSections/ScreenWind/ScreenWindVan/';
import SillLeft from '../../VehicleSections/SillLeft/SillLeftVan/';
import SillRight from '../../VehicleSections/SillRight/SillRightVan/';
import WheelFrontLeft from '../../VehicleSections/WheelFrontLeft/WheelFrontLeftVan/';
import WheelFrontRight from '../../VehicleSections/WheelFrontRight/WheelFrontRightVan/';
import WheelBackLeft from '../../VehicleSections/WheelBackLeft/WheelBackLeftVan/';
import WheelBackRight from '../../VehicleSections/WheelBackRight/WheelBackRightVan/';
import WindowFrontLeft from '../../VehicleSections/WindowFrontLeft/WindowFrontLeftVan/';
import WindowFrontRight from '../../VehicleSections/WindowFrontRight/WindowFrontRightVan/';
import WingFrontLeft from '../../VehicleSections/WingFrontLeft/WingFrontLeftVan/';
import WingFrontRight from '../../VehicleSections/WingFrontRight/WingFrontRightVan/';
import WingBackLeft from '../../VehicleSections/WingBackLeft/WingBackLeftLightVan/';
import WingBackRight from '../../VehicleSections/WingBackRight/WingBackRightLightVan/';
import WingMirrorLeft from '../../VehicleSections/WingMirrorLeft/WingMirrorLeft';
import WingMirrorRight from '../../VehicleSections/WingMirrorRight/WingMirrorRight';
import VehicleComponent from '../_vehicleComponent/';

class LightVan extends Component {
  render() {
    return (
      <div className={s.svgWrapper}>
        <canvas className={s.svgCanvas} height={93} width={120}></canvas>
        <svg className={s.svg} {...this.props}>
          <text
            transform="translate(0,24)"
            style={{ fill: 'rgb(0,188,212)', fontSize: '24px' }}
          >
            Light Van
          </text>
          <BumperFront {...this.props} />
          <BumperRear {...this.props} />

          <Bonnet {...this.props} />

          <Roof {...this.props} />

          <ScreenWind {...this.props} />

          <DoorFrontLeft {...this.props} />
          <DoorFrontRight {...this.props} />
          <DoorBackLeft {...this.props} />
          <DoorBackRight {...this.props} />

          <SillLeft {...this.props} />
          <SillRight {...this.props} />

          <WingFrontRight {...this.props} />
          <WingFrontLeft {...this.props} />
          <WingBackLeft {...this.props} />
          <WingBackRight {...this.props} />

          <WindowFrontRight {...this.props} />
          <WindowFrontLeft {...this.props} />

          <WheelFrontRight {...this.props} />
          <WheelFrontLeft {...this.props} />
          <WheelBackLeft {...this.props} />
          <WheelBackRight {...this.props} />

          <WingMirrorLeft {...this.props} />
          <WingMirrorRight {...this.props} />
        </svg>
      </div>
    );
  }
}

let composed = LightVan;
composed = VehicleComponent(composed);

export default composed;
