import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillRightHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillRight}
        d="M457.9,56.6h333.5l-1.5-40.7H457.1L457.9,56.6L457.9,56.6z"
      />
    );
  }
}

let composed = SillRightHatchback;
composed = SectionComponent({
  name: 'Driver Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Driver Sill Damage',
    content: <Modal name="Driver Sill" />,
  },
})(composed);

export default composed;
