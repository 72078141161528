import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class WindowBackLeft4x45d extends Component {
  render() {
    return (
      <g className={cx(s.glass, s.windowBackLeft)}>
        <path
          {...this.props}
          d="M755,624.8c-13-1.4-38.8-1.5-101.3-1.9l-8.9,83.1H755V624.8z"
        />
        <path
          {...this.props}
          d="M793.2,672.2c-10.7-18.5-23.1-40.5-25.6-42.9c-2.2-1.9-4.6-3.2-9.6-4V706h15.6C791,706,801.9,687.3,793.2,672.2z"
        />
        <path
          {...this.props}
          d="M818.8,774.7c0.9,0.1,1.9,0.3,2.8,0.5C820.7,774.9,819.8,774.8,818.8,774.7z M946.1,691h-69.8c-8.2,0-64.6-64.2-64.6-64.2  c-3.6-3.9,3.5-3.6,4.8-3.6l69.5-0.1c5.4,0,53.2,54.2,61.4,65.8C948.2,689.9,947.5,691,946.1,691z"
        />
      </g>
    );
  }
}

let composed = WindowBackLeft4x45d;
composed = SectionComponent({
  name: 'Back Passenger Window',
  category: 'Windows',
  highlighted: false,
})(composed);

export default composed;
