import React from 'react';

function parseSampleVehiclesText(sampleVehicles) {
  if (sampleVehicles === undefined || sampleVehicles == null) return [];
  const vehicleListUI = sampleVehicles.split(',').map((item, index) => {
    return <li key={index}>{item}</li>;
  });
  return vehicleListUI || [];
}

export default (props) => {
  const s = props.styles;
  const { sampleVehicleList } = props;
  const vehicleListUI = parseSampleVehiclesText(sampleVehicleList);

  return (
    <div className={s.vehiclesBox}>
      <h2>Example Vehicles</h2>
      <ul className={s.vehicleList}>{vehicleListUI}</ul>
    </div>
  );
};
