import { Component } from 'react';
import s from './style.module.scss';
import cx from 'classnames';

import SectionComponent from '../../_sectionComponent';

class ScreenRearSaloon extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={cx(s.glass, s.screenRear)}
        d="M887.5,314.3L887.5,314.3c-1.2,0-86.1,5.2-87.3,5.5c2.5,0.9,3.3,4.7,5.5,21.2c3.7,34.9,3.7,202.2,0,237.2  c-2.5,19.2-3,23.6-6.8,24.5c1.4,0.5,88,3.6,88.1,3.6l0.3-0.5C925.2,545.1,928.2,367.8,887.5,314.3z"
      />
    );
  }
}

let composed = ScreenRearSaloon;
composed = SectionComponent({
  name: 'Rear Screen',
  category: 'Screens',
  questionSets: ['Rear'],
  highlighted: false,
})(composed);

export default composed;
