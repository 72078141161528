import { Component } from 'react';
import s from './VehicleSelector.module.scss';

import RaisedButton from 'material-ui/RaisedButton';
import { FlatButton } from 'material-ui';

class VehicleSelector extends Component {
  render() {
    return (
      <div className={s.root}>
        <h2>Select a Vehicle Body Type to Continue</h2>
        <ul className={s.vehicleList}>
          {this.props.vehicles.map((result) => {
            const selectedItem =
              result.key === this.props.selectedIndex ? s.selected : null;
            return (
              <li
                key={result.key}
                className={selectedItem}
                onClick={this.props.handleSelect.bind(this, result.key)}
              >
                <span>{result.name}</span>
              </li>
            );
          })}
        </ul>
        <div className={s.actions}>
          <FlatButton
            onClick={this.props.handleNoneOfTheAbove}
            className={s.noneOfTheAbove}
          >
            None of the above
          </FlatButton>
          <RaisedButton
            onClick={this.props.handleSubmit}
            disabled={this.props.selectedIndex === -1}
          >
            Submit
          </RaisedButton>
        </div>
      </div>
    );
  }
}

let composed = VehicleSelector;

export default composed;
