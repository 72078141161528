import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftSaloon extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M999.7,790.8c-6.4-6.6-6.4-9.2-3-43.1c1.4-16.4,3.9-26.8,4.9-31.3c0.2-1-0.5-2-1.6-2l-58-0.2c-0.8,0-78.5-51.6-180.9-89.5  H761c-15.3-4.7-39-7.8-45.3-8.6c-0.9-0.1-1.7,0.5-1.8,1.4c-0.9,6.3-4.5,30-7.7,50.9c-6.2,39.2-7.7,53.5-11.6,118.8l-5.3,115.3  c0,0.9,0.7,1.7,1.6,1.7H789c1.7,0,3-1.3,3.3-3l-0.2-29.1c1-37.4,4.7-45.6,11.6-56.6c19.5-32,61.9-39.4,87-21.2  c18.2,13.3,31.3,30.8,31.8,56.8v0.1l-1,17.9c0.2,1.7,1.5,3,3.3,3l27.1-0.5c37.4-1,45.1-2.8,45.1-10.6c0-3.4,2.5-8.1,4.9-10.3  c3.6-3,4.9-11.1,4.9-28.1C1006.4,802.6,1005.1,796.9,999.7,790.8z M707,721.4c1.4-14.2,8.7-72.8,9.9-82.8c0.1-0.8,0.8-4,1.7-4  c64.8,2.3,192.7,85.4,190.7,85.4L707,721.4L707,721.4z"
        />
        <path
          {...this.props}
          d="M1000.2,790.5c-6.4-6.6-6.4-9.2-3-43.1c1.4-16.4,3.9-26.8,4.9-31.3c0.2-1-0.5-2-1.6-2l-58-0.2   c-0.8,0-78.5-51.6-180.9-89.5h-0.1c-15.3-4.7-39-7.8-45.3-8.6c-0.9-0.1-1.7,0.5-1.8,1.4c-0.9,6.3-4.5,30-7.7,50.9   c-6.2,39.2-7.7,53.5-11.6,118.8l-5.3,115.3c0,0.9,0.7,1.7,1.6,1.7h98.1c1.7,0,3-1.3,3.3-3l-0.2-29.1c1-37.4,4.7-45.6,11.6-56.6   c19.5-32,61.9-39.4,87-21.2c18.2,13.3,31.3,30.8,31.8,56.8v0.1l-1,17.9c0.2,1.7,1.5,3,3.3,3l27.1-0.5c37.4-1,45.1-2.8,45.1-10.6   c0-3.4,2.5-8.1,4.9-10.3c3.6-3,4.9-11.1,4.9-28.1C1006.9,802.3,1005.6,796.6,1000.2,790.5z M966.2,731.8c6.6,0.2,18,0.2,27.6,0   c0,0-2,9.4-5.1,37.9h-26.1c-1.7,0-1.7-0.7-1.7-0.7l-0.1-31.8C960.8,733.9,963.3,731.5,966.2,731.8z M707.5,721.1   c1.4-14.2,8.7-72.8,9.9-82.8c0.1-0.8,0.8-4,1.7-4c64.8,2.3,166.7,85.4,164.7,85.4L707.5,721.1z M992.8,800.8h-85.3   c-1.7,0-3.4-0.7-4.7-2c-2-2-13.8-5.9,2.2-5.4h85.3c1.7,0,3.4,0.7,4.7,2C997.1,797.3,998.7,801.1,992.8,800.8z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftSaloon;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
