import { Component } from 'react';
import s from '../_vehicleComponent/VehicleTypes.module.scss';

import BumperFront from '../../VehicleSections/BumperFront/BumperFront/';
import BumperRear from '../../VehicleSections/BumperRear/BumperRear/';
import Bonnet from '../../VehicleSections/Bonnet/Bonnet/';
import DoorFrontLeft from '../../VehicleSections/DoorFrontLeft/DoorFrontLeftHatchback/';
import DoorFrontRight from '../../VehicleSections/DoorFrontRight/DoorFrontRightHatchback/';
import DoorBackLeft from '../../VehicleSections/DoorBackLeft/DoorBackLeftHatchback/';
import DoorBackRight from '../../VehicleSections/DoorBackRight/DoorBackRightHatchback/';
import Roof from '../../VehicleSections/Roof/Roof4x4/';
import ScreenRear from '../../VehicleSections/ScreenRear/ScreenRear4x4/';
import ScreenWind from '../../VehicleSections/ScreenWind/ScreenWind/';
import SillLeft from '../../VehicleSections/SillLeft/SillLeftHatchback/';
import SillRight from '../../VehicleSections/SillRight/SillRightHatchback/';
import Tailgate from '../../VehicleSections/Tailgate/Tailgate/';
import WheelFrontLeft from '../../VehicleSections/WheelFrontLeft/WheelFrontLeft/';
import WheelFrontRight from '../../VehicleSections/WheelFrontRight/WheelFrontRight/';
import WheelBackLeft from '../../VehicleSections/WheelBackLeft/WheelBackLeft/';
import WheelBackRight from '../../VehicleSections/WheelBackRight/WheelBackRight/';
import WindowBackLeft from '../../VehicleSections/WindowBackLeft/WindowBackLeftEstate/';
import WindowBackRight from '../../VehicleSections/WindowBackRight/WindowBackRightEstate/';
import WindowFrontLeft from '../../VehicleSections/WindowFrontLeft/WindowFrontLeftHatchback/';
import WindowFrontRight from '../../VehicleSections/WindowFrontRight/WindowFrontRightHatchback/';
import WingFrontLeft from '../../VehicleSections/WingFrontLeft/WingFrontLeftConvertible/';
import WingFrontRight from '../../VehicleSections/WingFrontRight/WingFrontRightConvertible/';
import WingBackLeft from '../../VehicleSections/WingBackLeft/WingBackLeftEstate/';
import WingBackRight from '../../VehicleSections/WingBackRight/WingBackRightEstate/';
import WingMirrorLeft from '../../VehicleSections/WingMirrorLeft/WingMirrorLeft';
import WingMirrorRight from '../../VehicleSections/WingMirrorRight/WingMirrorRight';
import VehicleComponent from '../_vehicleComponent/';

class FiveDoorEstate extends Component {
  render() {
    return (
      <div className={s.svgWrapper}>
        <canvas className={s.svgCanvas} height={93} width={120}></canvas>
        <svg className={s.svg} {...this.props}>
          <text
            transform="translate(0,24)"
            style={{ fill: 'rgb(0,188,212)', fontSize: '24px' }}
          >
            Five Door Estate
          </text>
          <BumperFront {...this.props} />
          <BumperRear {...this.props} />

          <Bonnet {...this.props} />
          <Tailgate {...this.props} />

          <Roof {...this.props} />

          <ScreenWind {...this.props} />
          <ScreenRear {...this.props} />

          <DoorFrontLeft {...this.props} />
          <DoorFrontRight {...this.props} />
          <DoorBackLeft {...this.props} />
          <DoorBackRight {...this.props} />

          <SillLeft {...this.props} />
          <SillRight {...this.props} />

          <WingFrontRight {...this.props} />
          <WingFrontLeft {...this.props} />
          <WingBackLeft {...this.props} />
          <WingBackRight {...this.props} />

          <WindowFrontRight {...this.props} />
          <WindowFrontLeft {...this.props} />
          <WindowBackLeft {...this.props} />
          <WindowBackRight {...this.props} />

          <WheelFrontRight {...this.props} />
          <WheelFrontLeft {...this.props} />
          <WheelBackLeft {...this.props} />
          <WheelBackRight {...this.props} />

          <WingMirrorLeft {...this.props} />
          <WingMirrorRight {...this.props} />
        </svg>
      </div>
    );
  }
}

let composed = FiveDoorEstate;
composed = VehicleComponent(composed);

export default composed;
