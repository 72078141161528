import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorBackLeftMPV extends Component {
  render() {
    return (
      <path
        className={s.doorBackLeft}
        {...this.props}
        d="M787.7,692.1H605.5c-6.7,64-7.6,111.4-10.5,140c46,0,90,0,136,0c0,0,1.9-40.6,40.3-75   c16.4-14.6,25.7-27.5,22.1-42.9C791.6,706.6,789.7,699.1,787.7,692.1z"
      />
    );
  }
}

let composed = DoorBackLeftMPV;
composed = SectionComponent({
  name: 'Back Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Back Passenger Door Damage',
    content: <Modal name="Back Passenger Door" />,
  },
})(composed);

export default composed;
