import { apiServer } from '../config.js';
import AppAPI from './appAPI';

const eventAPI = {
  getToken() {
    return AppAPI.getIdentity() === null
      ? null
      : JSON.parse(AppAPI.getIdentity()).Token;
  },

  makeAPIGetCall(apiEndPoint, callName, errorHandler) {
    return new Promise((resolve, reject) => {
      fetch(apiServer + apiEndPoint, {
        method: 'GET',
        headers: {
          Auth: this.getToken(),
        },
      })
        .then((response) => {
          if (!response.ok) {
            reject(Error(callName));
          }
          return response.json();
        })
        .then((json) => {
          resolve(json);
        });
    }).catch((reason) => {
      if (errorHandler) errorHandler(reason);
    });
  },

  getAllElmahData(errorHandler) {
    return this.makeAPIGetCall(
      '/events/eventtype/elmah',
      'getAllElmahData',
      errorHandler
    );
  },

  getElmahDataByDate(date, errorHandler) {
    return this.makeAPIGetCall(
      '/events/eventtype/elmah/from/' + date,
      'getElmahDataByDate',
      errorHandler
    );
  },
};

export default eventAPI;
