import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearModal/';

class DoorBackLeftPV extends Component {
  render() {
    return (
      <g className={s.doorFrontLeft}>
        <path
          {...this.props}
          d="M1060.5,630h-89c-4.5,0-8.5-3.9-8.5-8.6V464h106v157.4C1069,626.1,1065,630,1060.5,630z"
        />
        <path
          {...this.props}
          d="M1060.5,630h-2.5v-32h-26.2c-10.6,0-18.8,9.4-18.8,20.6V630h-41.5c-4.5,0-8.5-3.9-8.5-8.6V464h106v157.4  C1069,626.1,1065,630,1060.5,630z"
        />
      </g>
    );
  }
}

let composed = DoorBackLeftPV;
composed = SectionComponent({
  name: 'Left Back Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Rear'],
  modalModel: {
    title: 'Register Back Passenger Door Damage',
    content: <Modal name="Left Back Door" />,
  },
})(composed);

export default composed;
