import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class DoorFrontLeftHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.doorFrontLeft}
        d="M473.5,721.7c0,0-7.9,8-10.3,24.7c-1.3,8.9-4.6,30.8-5.2,51.2c-0.9,31.7-0.9,63.2-0.9,63.2l209.6-0.4  c-0.8-8.2,6-135.3,6.3-138.5L473.5,721.7L473.5,721.7z"
      />
    );
  }
}

let composed = DoorFrontLeftHatchback;
composed = SectionComponent({
  name: 'Front Passenger Door',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Front Passenger Door Damage',
    content: <Modal name="Front Passenger Door" />,
  },
})(composed);

export default composed;
