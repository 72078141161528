import Dispatcher from '../core/Dispatcher.js';
import ActionTypes from '../constants/ActionTypes.js';

const AppActions = {
  login(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.LOGIN_USER,
      data: data,
    });
  },

  remoteLogin(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.LOGIN_USER_REMOTE,
      data: data,
    });
  },

  logout() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.LOGOUT_USER,
      data: {},
    });
  },

  resetPassword(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.RESET_USER,
      data: data,
    });
  },

  changePassword(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.CHANGE_PASSWORD,
      data: data,
    });
  },

  evaluatePassword(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.EVALUATE_PASSWORD,
      data: data,
    });
  },

  addSpinner() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.SPINNER_ADD,
      data: {},
    });
  },

  removeSpinner() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.SPINNER_REMOVE,
      data: {},
    });
  },

  createModal(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.MODAL_ADD,
      data: data,
    });
  },

  updateModal(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.MODAL_UPDATE,
      data: data,
    });
  },

  destroyModal() {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.MODAL_REMOVE,
      data: {},
    });
  },

  setExpiry(data) {
    Dispatcher.queueDispatch({
      actionType: ActionTypes.SET_EXPIRY,
      data: data,
    });
  },
};

export default AppActions;
