import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeftHatchback extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M457.7,862.9l-0.8,40.7h332.8l1.5-40.7H457.7L457.7,862.9z"
      />
    );
  }
}

let composed = SillLeftHatchback;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
