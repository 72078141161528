export const validateReferenceNumber = ({ regex, referenceNumber }) => {
  if (typeof referenceNumber !== 'string' || referenceNumber === '') {
    return false;
  }

  let formedRegex;
  // Use the regex provided or fallback to the ClaimReferenceNumberRegex
  // stored on the user profile
  if (typeof regex === 'string') {
    formedRegex = new RegExp(regex, 'i');
  } else {
    const lsAppSettingsJSON = JSON.parse(localStorage.identity);
    formedRegex = lsAppSettingsJSON.AppSettings.ClaimReferenceNumberRegex;
  }

  return referenceNumber.match(formedRegex) !== null;
};
