import { Component } from 'react';
import AppStore from '../stores/AppStore';
import RoleTypes from '../constants/RoleTypes.js';

function withLoggedInUser(ComposedComponent) {
  return class WithLoggedInUser extends Component {
    constructor() {
      super();

      this.state = {
        currentUser: null,
        isAdmin: false,
      };
    }

    updateState() {
      const tmpUser =
        AppStore.userData() !== null ? AppStore.userData().User : null;
      if (tmpUser === undefined || tmpUser === null) return;
      tmpUser.isAdmin = this.isAdmin(tmpUser);
      tmpUser.isImageUser = this.isImageUser(tmpUser);
      tmpUser.isMICompanyManager = this.isMICompanyManager(tmpUser);
      tmpUser.isMIDepartmentManager = this.isMIDepartmentManager(tmpUser);
      tmpUser.isTriageUser = this.isTriageUser(tmpUser);
      tmpUser.isUsersCompanyManager = this.isUsersCompanyManager(tmpUser);
      tmpUser.isUsersDepartmentManager = this.isUsersDepartmentManager(tmpUser);

      this.setState({ currentUser: tmpUser });
    }

    UNSAFE_componentWillMount() {
      this.updateState();
    }

    componentDidMount() {}

    componentWillUnmount() {}

    isAdmin(user) {
      return this.isInRole(user, RoleTypes.ADMINISTRATOR);
    }

    isImageUser(user) {
      return this.isInRole(user, RoleTypes.IMAGEUSER);
    }

    isMICompanyManager(user) {
      return this.isInRole(user, RoleTypes.MICOMPANYMANAGER);
    }

    isMIDepartmentManager(user) {
      return this.isInRole(user, RoleTypes.MIDEPARTMENTMANAGER);
    }

    isTriageUser(user) {
      return this.isInRole(user, RoleTypes.TRIAGEUSER);
    }

    isUsersCompanyManager(user) {
      return this.isInRole(user, RoleTypes.USERSCOMPANYMANAGER);
    }

    isUsersDepartmentManager(user) {
      return this.isInRole(user, RoleTypes.USERSDEPARTMENTMANAGER);
    }

    isInRole(user, roleToCheck) {
      let result = false;
      user.Roles.find((role) => {
        if (role.toUpperCase() === roleToCheck && !result) {
          result = true;
        }
      });
      return result;
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          currentUser={this.state.currentUser}
        />
      );
    }
  };
}

export default withLoggedInUser;
