import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/MirrorsModal/';

class WingMirrorRight extends Component {
  render() {
    return (
      <g className={s.wingMirrorRight}>
        <path
          {...this.props}
          d="M231.9,235.2c-2.2,1.4-5.3,3.5-9.4,1.3c0,0-29.2-3.9-44.8-2.3c-6.1,0.6-17.7,1.6-21.2,6.6c-3.2,4.8-2.3,9.2-0.9,14.3   c1.4,5.1,1.8,9.9,6,14.4c1.9,2,3.5,3.5,5.5,5.1c4,3.2,8,4.8,11.6,6.3c2.9,1.2,5.8,2.1,9.9,2.5c8.3,0.8,13,4.1,21.3,3.9   c32.7-0.6,22.3-21.4,34.3-27.8C239.5,249.8,237,244.7,231.9,235.2z"
        />
        <path
          {...this.props}
          d="M215.2,286.6c-15,3.1-40.3-4-48.3-9.4c-14.1-7.5-15.1-26-12.6-33.5c3.6-8.6,13.4-9.6,21.9-10.1c9.1-0.6,39.8-0.3,47.5,3.5   c9.3,4.1,11.8,13.1,4.6,33.6C226.4,276,222.1,285.2,215.2,286.6z"
        />
        <path
          {...this.props}
          d="M185.6,278.4c-11.6-3.3-22.4-8.5-24.5-16.2c-2.1-7.1-2.1-12.2-0.9-17.4c1.5-6.6,15.6-6.5,22.2-6.6   c9.5-0.2,19.4,0.3,28.9,1.3c6,0.9,18.3,1.1,15.4,15.7c-1,5.2-4.6,20.6-11.3,24.7C210.6,283.2,197.6,281.8,185.6,278.4z"
        />
        <path
          {...this.props}
          d="M231.9,235.2c3-1.9,4.3-4.6,6.5-6.3c2.9-2.2,8-1.6,12-1.6c-0.1,9.9-0.2,21.9-0.3,31.9c-1.9,0.1-2.5-1.7-5.9,0.2   C239.4,249.9,236.9,244.7,231.9,235.2z"
        />
      </g>
    );
  }
}

let composed = WingMirrorRight;
composed = SectionComponent({
  name: 'Driver Door Mirror',
  category: 'Mirrors',
  highlighted: false,
  questionSets: ['Mirrors'],
  modalModel: {
    title: 'Register Driver Mirror Damage',
    content: <Modal name="Driver Mirror" />,
    actions: [],
  },
})(composed);

export default composed;
