import { Component } from 'react';
import s from '../TriageStatsPage.module.scss';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { listViewConfig } from '../_config';

export default class CustomBarChart extends Component {
  static defaultProps = {
    data: [],
    isAnimationActive: false,
    colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
  };

  state = {
    isMounted: false,
    data: [],
  };

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      //NB - FORCE UPDATE HACK TO TRIGGER BARCHART RE-RENDER - REQUIRED TO
      // FIX HEIGHT BUG - DO NOT REMOVE
      this.forceUpdate();
    }, 0);
  }

  render() {
    //NB PLEASE TAKE NOTE OF THE CSS HACK FOR POUND SIGNS

    const settings = listViewConfig.filter((i) => {
      return i.key === this.props.name;
    })[0];

    const data = Object.keys(this.props.data).map((item) => {
      return { name: item, value: this.props.data[item] };
    });

    //console.log(this.props.data, this.state.data)
    const legendData = data.map((i, x) => {
      let d = {
        value:
          i.value +
          (settings.unit !== undefined ? settings.unit : '') +
          ' - ' +
          i.name,
        color: this.props.colors[x],
      };
      return d;
    });
    return (
      <div className={s.barChartItem}>
        <BarChart
          width={this.props.maxGraphWidth || 300}
          height={300}
          data={data}
          barGap={1}
          margin={{
            top: 30,
            right: 10,
            left: 0,
          }}
        >
          <Legend
            payload={legendData}
            wrapperStyle={{
              paddingLeft: 20,
              color: 'white',
            }}
          />
          <XAxis dataKey="name" allowDataOverflow={true} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar
            dataKey="value"
            maxBarSize={60}
            unit={settings.unit !== undefined ? settings.unit : null}
            animationDuration={300}
          >
            {data.map((entry, index) => (
              <Cell
                key={index}
                stroke={'white'}
                fillOpacity={
                  this.props.canFilter === false
                    ? 0.9
                    : this.props.activeIndex === null
                    ? 0.9
                    : this.props.activeIndex === index
                    ? 0.9
                    : 0.1
                }
                fill={this.props.colors[index % this.props.colors.length]}
                strokeWidth={3}
              />
            ))}
          </Bar>
          <Tooltip />
        </BarChart>
      </div>
    );
  }
}
