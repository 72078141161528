import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackLeftHatchback5d extends Component {
  render() {
    return (
      <g className={s.wingBackLeft}>
        <path
          {...this.props}
          d="M998.4,789.8c-1.8-3.1-5.9-8.6-5.9-8.6c0.2,0.7,0.2-44.8,0.1-63.1c0-3.2-2.6-5.8-5.8-5.8l-16.8,0.1   c-0.2,0-117.5-81.4-177.8-90.1c0,0-42.6-5.8-79-6.9l-3.5,16.8c0,0,86,0.1,102.7,12.3c12.7,9.3,65.6,49.5,81.3,62.1   c7.8,6.3,0.6,14.5-2.9,15.1l-41.9-0.1c0,4.8-8,50.5-17.5,68c1.3-0.6,2.6-1.1,3.9-1.6c18.6-7.1,39.4-5.4,54.3,5.5   c18.1,13.3,32.5,29.8,32.5,55.8l-0.4,8.4l-0.5,11.3c0.2,1.7,1.7,2.3,2.3,2.3l27.1-0.5c23.6-0.6,37.1-1.8,43.8-2.6   c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1005.2,801.9,998.4,789.9,998.4,789.8z"
        />
        <path
          {...this.props}
          d="M998.3,789.8c-1.8-3.1-5.9-8.6-5.9-8.6c0.2,0.7,0.2-44.8,0.1-63.1c0-3.2-2.6-5.8-5.8-5.8l-16.8,0.1   c-0.2,0-117.5-81.4-177.8-90.1c0,0-42.6-5.8-79-6.9l-3.5,16.8c0,0,86,0.1,102.7,12.3c12.7,9.3,65.6,49.5,81.3,62.1   c7.8,6.3,0.6,14.5-2.9,15.1l-41.9-0.1c0,4.8-8,50.5-17.5,68c1.3-0.6,2.6-1.1,3.9-1.6c18.6-7.1,39.4-5.4,54.3,5.5   c18.1,13.3,32.5,29.8,32.5,55.8l-0.4,8.4l-0.5,11.3c0.2,1.7,1.7,2.3,2.3,2.3l27.1-0.5c23.6-0.6,37.1-1.8,43.8-2.6   c3.2-0.4,5.8-2.8,6.4-6.1c1.6-9.1,4.5-27.9,4.5-40.4C1005.1,801.9,998.3,789.9,998.3,789.8z M960,727.9c0-2,2.5-2.9,4.4-2.9h22   c2,0,2.6,1,2.6,2.9v28.9c0,2-0.7,4.2-2.6,4.2h-22c-2,0-4.4-2.3-4.4-4.2V727.9z M991.7,801h-85.1c-1.7,0-3.4-1-4.7-2.3   c-2-2-13.8-5.7,2.2-5.7h86c1.7,0,2.4,1,3.7,2.3C995.9,797.2,997.5,801,991.7,801z"
        />
      </g>
    );
  }
}

let composed = WingBackLeftHatchback5d;
composed = SectionComponent({
  name: 'Back Passenger Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Passenger Wing Damage',
    content: <Modal name="Back Passenger Wing" />,
  },
})(composed);

export default composed;
