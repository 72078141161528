import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/WheelsModal/';

class WheelBackRight4x45d extends Component {
  render() {
    return (
      <g className={s.wheelBackRight}>
        <path
          {...this.props}
          className={s.tyre}
          d="M871,66c0-36.5-28-66.3-62.5-66.3C774.1-0.3,746,29.3,746,66c0,36.5,28,66.3,62.5,66.3C843.1,132.3,871.1,102.8,871,66z   M846.7,66.2c0,22.5-17,40.7-38.2,40.7s-38.2-18.2-38.2-40.7s17-40.7,38.2-40.7C829.5,25.5,846.7,43.7,846.7,66.2z"
        />
        <path
          {...this.props}
          d="M844.4,66.1c0-21.2-16-38.2-35.9-38.2c-19.8,0-35.9,17-35.9,38.2s15.9,38.2,35.9,38.2S844.5,87,844.4,66.1z M786.2,69.3h1.3  c1.5,10.9,10.1,19,20.7,19s19.2-8.4,20.7-19h1.3c-1.5,11.3-10.6,20.2-21.7,20.2C797.5,89.5,788.1,80.6,786.2,69.3z M783.4,66.1h-2.5  H783.4z M830.8,62.7h-1.3C827.8,52.1,819.1,44,808.8,44c-10.6,0-19.2,8.1-20.7,18.7h-1.3c1.5-11.3,10.6-20,21.7-20  S828.8,51.3,830.8,62.7z M835.2,66.1h-1.5H835.2z"
        />
      </g>
    );
  }
}

let composed = WheelBackRight4x45d;
composed = SectionComponent({
  name: 'Back Driver Wheel',
  category: 'Wheels',
  highlighted: false,
  questionSets: ['Wheels'],
  modalModel: {
    title: 'Register Back Driver Wheel Damage',
    content: <Modal name="Back Driver Wheel" />,
  },
})(composed);

export default composed;
