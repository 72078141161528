import { Component } from 'react';
import TriageStore from '../../../../../stores/TriageStore.js';
import TriageActions from '../../../../../actions/TriageActions.js';

function QuestionComponent(CURRENT_QUESTION) {
  return (ComposedComponent) =>
    class QuestionComponent extends Component {
      state = {
        modalModel: {},
        sectionDamage: null,
      };

      updateState = () => {
        let data;
        if (this.props.sectionDamage) {
          for (let section of this.props.sectionDamage) {
            if (section.Name === CURRENT_QUESTION) {
              data = section;
            }
          }
        }

        this.setState({
          modalModel: TriageStore.getCurrentModal(),
          sectionDamage: data,
        });
      };

      UNSAFE_componentWillMount() {
        // hack. todo -- review
        this.updateState();
        setTimeout(function () {
          window.dispatchEvent(new Event('resize'));
        }, 100);
        TriageStore.addChangeListener(this.updateState);
      }

      componentWillUnmount() {
        TriageStore.removeChangeListener(this.updateState);
      }

      componentDidMount() {
        const completeQs = TriageStore.getCompletedQuestionSets();
        const questionFound = completeQs.find((i) => {
          return i === CURRENT_QUESTION;
        });
        if (questionFound !== undefined) {
          //console.log("goto next stage");
          this.state.modalModel.currentStage += 1;
          TriageActions.setModal(this.state.modalModel);
        }
      }

      setCorrectActions = (state) => {
        const checkBoxesAreValid = () => {
          for (let i in state.checkBoxes) {
            if (state.checkBoxes[i].value === true) return true;
          }
          return false;
        };

        if (state.radio == 1 && !checkBoxesAreValid()) {
          state.modalModel.actions[2] = state.disabledAction;
        } else {
          state.modalModel.actions[2] = state.enabledAction;
        }

        return state.modalModel;
      };

      getSubQuestionValues = (state) => {
        let submitValues = [];
        for (let i in state.checkBoxes) {
          if (state.checkBoxes[i].value === true) {
            submitValues.push(i);
          }
        }
        return submitValues;
      };

      checkSubQuestionsValid = (state) => {
        let isValid = false;
        for (let i in state.checkBoxes) {
          if (state.checkBoxes[i].value === true) {
            isValid = true;
          }
        }
        return isValid;
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            checkSubQuestionsValid={this.checkSubQuestionsValid}
            getSubQuestionValues={this.getSubQuestionValues}
            setCorrectActions={this.setCorrectActions}
            sectionDamage={this.state.sectionDamage}
            modalModel={this.state.modalModel}
          ></ComposedComponent>
        );
      }
    };
}

export default QuestionComponent;
