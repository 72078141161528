import { Component } from 'react';
import s from './FrontModal.module.scss';
import AirbagsFrontQuestions from '../../QuestionSets/AirbagsFrontQuestions/';
import AirbagsSideQuestions from '../../QuestionSets/AirbagsSideQuestions/';
import FluidLeakQuestions from '../../QuestionSets/FluidLeakQuestions/';
import SteeringQuestions from '../../QuestionSets/SteeringQuestions/';
import DoorsQuestions from '../../QuestionSets/DoorsQuestions/';
import LampsFrontQuestions from '../../QuestionSets/LampsFrontQuestions/';
import PanelQuestions from '../../QuestionSets/PanelQuestions/';
import ModalComponent from '../_modalComponent/';

const QUESTION_SETS = [
  { stage: 1, section: 'airbagsfront', component: <AirbagsFrontQuestions /> },
  { stage: 2, section: 'airbagsside', component: <AirbagsSideQuestions /> },
  { stage: 3, section: 'fluidleak', component: <FluidLeakQuestions /> },
  { stage: 4, section: 'steering', component: <SteeringQuestions /> },
  { stage: 5, section: 'doors', component: <DoorsQuestions /> },
  { stage: 6, section: 'lampsfront', component: <LampsFrontQuestions /> },
  { stage: 7, section: 'panelStandard', component: <PanelQuestions /> },
];

const SUBTITLE = 'Front Section Questions';

class FrontModal extends Component {
  render() {
    return (
      <div className={s.root}>
        <div>
          <div className={s.formCollection}>
            {QUESTION_SETS[this.props.modalModel.currentStage].component}
          </div>
        </div>
      </div>
    );
  }
}

const composed = ModalComponent(QUESTION_SETS, SUBTITLE)(FrontModal);

export default composed;
