import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/SideModal/';

class SillLeft extends Component {
  render() {
    return (
      <path
        {...this.props}
        className={s.sillLeft}
        d="M457.4,862.9l-0.5,41.9h229.2l2.2-41.9H457.4L457.4,862.9z"
      />
    );
  }
}

let composed = SillLeft;
composed = SectionComponent({
  name: 'Passenger Sill',
  category: 'Panels',
  highlighted: false,
  questionSets: ['Side'],
  modalModel: {
    title: 'Register Passenger Sill Damage',
    content: <Modal name="Passenger Sill" />,
  },
})(composed);

export default composed;
