import { Component } from 'react';
import s from './style.module.scss';

import SectionComponent from '../../_sectionComponent';
import Modal from '../../../Modals/RearSideModal/';

class WingBackRightPickup extends Component {
  render() {
    return (
      <g className={s.wingBackRight}>
        <path
          {...this.props}
          d="M1026.7,115c0-34.1-27.5-30.6-42.5-30.9C980,84,921,83.9,921,83.9c-4.7,16.8-13.2,31-27.2,40.1c-12.8,7.8-26.9,11-39.2,11  c-9.7-0.3-18.8-2.6-27.1-6.6c-2.9-1.4-6.3-3.4-9.9-5.8c-18-12.7-26.8-34.1-26.8-58.3c0-0.1-3-0.2-3-0.3h-0.1l0,0h-43.9  c0-0.1,0-0.1,0-0.1H637.4c1,14.5,0,144.4,0,144.4h388.5v-8.1c0-4.7-1.4-9.4-3.5-10.4c-1.8-1-3.5-14.1-3.5-28.9v-26.5l6.3-3.4  L1026.7,115z"
        />
        <path
          {...this.props}
          d="M1026.8,115c0-34.1-27.5-30.6-42.5-30.9c-2.3,0-27.5-0.1-43.4-0.1c-9.7,41.8-39.4,72.6-83.1,72.6  c-50.5,0-85.3-41.4-86.5-92.6c-10.4,0-27.3,0-27.3,0c0-0.1,0-0.1,0-0.1H637.6c1,14.5,0,144.4,0,144.4h388.5v-8.1  c0-4.7-1.4-9.4-3.5-10.4c-1.8-1-3.5-14.1-3.5-28.9v-26.6l6.3-3.4L1026.8,115z M1013.6,134h3.6v52.9h-3.6V134z M1023.3,130.2H927  v-1.7h96.3V130.2z"
        />
      </g>
    );
  }
}

let composed = WingBackRightPickup;
composed = SectionComponent({
  name: 'Back Driver Wing',
  category: 'Panels',
  highlighted: false,
  questionSets: ['RearSide'],
  modalModel: {
    title: 'Register Back Driver Wing Damage',
    content: <Modal name="Back Driver Wing" />,
  },
})(composed);

export default composed;
